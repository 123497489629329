import * as ActionTypes from '../actionTypes'
import { LBstate, LBdispatchTypes } from './types'

const initialState: LBstate = {
  ranges: null,
  boxDataOptions: null
}

const reducer = (state = initialState, action: LBdispatchTypes): LBstate => {
  switch (action.type) {
    case ActionTypes.SET_LB_RANGES:
      return {
        ...state,
        ranges: action.payload
      }

    case ActionTypes.SET_LB_BOX_DATA_OPTIONS:
      return {
        ...state,
        boxDataOptions: action.payload
      }

    case ActionTypes.RESTORE_STATE:
      return action.payload.lb || null

    default:
      return state
  }
}

export default reducer
