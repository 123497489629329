import axios, { AxiosInstance } from 'axios'
import {
  Study,
  DateCount,
  AEsummary,
  FilterObject,
  FilterOptionToTableMap,
  LBrange,
  VariableLabel,
  Subject,
  SubjectVisit,
  SubjectVisitsSummary,
  SubjectInfo,
  AEoccasion,
  TstatisticsItem,
  Visit,
  Demography,
  Disposition,
  ConcomitantMedication,
  MedicalHistoryRecord,
  VitalSign,
  LabRecord,
  AErecord,
  TableInfoItem,
  Baseline,
  AEBODSYSsummary,
  LBboxData,
  UserNoteRecord
} from '../types'
import store from '../store/store'

// TODO: add decorator to check studyDB
export class ApiController {
  private api: AxiosInstance
  private baseUrl: string = process.env.REACT_APP_XPLORATUM_API || ''
  public studyDB?: string

  constructor(baseUrl?: string) {
    this.baseUrl = baseUrl ?? this.baseUrl

    this.api = axios.create({
      withCredentials: true,
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    this.setStudyDB()
  }

  public setStudyDB(studyDB?: string) {
    if (!studyDB) {
      const selectedStudy = store.getState().studies.selected

      if (selectedStudy) studyDB = selectedStudy.studyDB
    }

    if (studyDB) this.studyDB = encodeURIComponent(studyDB)
  }

  public async get<T>(url: string): Promise<T> {
    return this.api
      .get<T>(url)
      .then((res) => res.data)
      .catch((err) => {
        const errMessage = {
          code: err.response.status,
          message: err.response?.data || err.message
        }

        throw errMessage
      })
  }

  public async post<T>(url: string, payload: any): Promise<T> {
    return this.api
      .post<T>(url, payload)
      .then((res) => res.data)
      .catch((err) => {
        const errMessage = err.response?.data || err.message

        throw new Error(errMessage)
      })
  }

  public async getStudies(): Promise<Study[]> {
    return this.get(`studies/`)
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getStudy(id: string): Promise<Study> {
    return this.get(`studies/${id}`)
      .then((res: any) => {
        const study: Study = res.data
        const { filename } = study

        if (!filename) throw new Error('Study DB filename is missing.')

        study.studyDB = filename

        delete study.filename

        return res.data
      })
      .catch((err) => {
        throw err
      })
  }

  public async getDatesCount(): Promise<DateCount[]> {
    return this.get(`/vd/dates/count/${this.studyDB}`)
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getSubjects(): Promise<Subject[]> {
    const selectedFilterOptions = store.getState().filter.selected

    return this.post(`/subjects/${this.studyDB}`, {
      options: selectedFilterOptions || {}
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getSubjectsInfo(
    selectedSubjects?: (Subject | string)[]
  ): Promise<SubjectInfo[]> {
    const selectedSubjectsIds = (
      selectedSubjects || store.getState().subjects.selected
    )?.map((subj) => (typeof subj === 'string' ? subj : subj.SUBJID))

    return this.post(`/subjects/info/${this.studyDB}`, {
      ids: selectedSubjectsIds || []
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getAEsummary(minimal = false): Promise<AEsummary> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds = selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ae/summary/${this.studyDB}`, { subjectIds, minimal })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getAEBODSYSsummary(
    variable: string,
    condition?: string
  ): Promise<AEBODSYSsummary[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds = selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ae/bodsys/summary/${this.studyDB}`, {
      subjectIds,
      variable,
      condition
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getAESubjects(condition?: string): Promise<Subject[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds = selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ae/subjects/${this.studyDB}`, {
      subjectIds,
      condition
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getFilterOptionToTableMap(): Promise<FilterOptionToTableMap> {
    return this.get(`/options/map/${this.studyDB}`)
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getFilterObject(): Promise<FilterObject> {
    const optionToTableMap = store.getState().filter.optionToTableMap

    return this.post(`/options/filter-object/${this.studyDB}`, {
      map: optionToTableMap
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getLBranges(): Promise<LBrange[]> {
    return this.get(`/lb/ranges/${this.studyDB}`)
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getVariableLabels(): Promise<VariableLabel[]> {
    return this.get(`/variables/labels/${this.studyDB}`)
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getAEcolumnNames(): Promise<string[]> {
    return this.get(`/ae/column/names/${this.studyDB}`)
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getSubjectVisits(
    subjects?: string[],
    condition?: string
  ): Promise<SubjectVisit[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    const payload: { [key: string]: any } = { subjectIds }

    if (condition) payload.condition = condition

    return this.post(`/subjects/visits/${this.studyDB}`, payload)
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getSubjectVisitsSummary(
    subjects?: string[]
  ): Promise<SubjectVisitsSummary[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/subjects/visits/summary/${this.studyDB}`, { subjectIds })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getAEoccasions(
    aesev: string,
    armcd: string,
    subjects?: string[],
    condition?: string
  ): Promise<AEoccasion[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ae/occasions/${this.studyDB}`, {
      subjectIds,
      aesev,
      armcd,
      condition
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getTstatistics(): Promise<TstatisticsItem[]> {
    return this.get(`/t-statistics/${this.studyDB}`)
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getVisits(subjects?: string[]): Promise<Visit[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/vd/records/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDemographies(subjects?: string[]): Promise<Demography[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/dm/records/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDisposition(subjects?: string[]): Promise<Disposition[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ds/records/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getConcomitantMedications(
    subjects?: string[]
  ): Promise<ConcomitantMedication[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/cm/records/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getMHrecords(
    subjects?: string[]
  ): Promise<MedicalHistoryRecord[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/mh/records/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getVitalSigns(subjects?: string[]): Promise<VitalSign[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/vs/records/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getLabRecords(subjects?: string[]): Promise<LabRecord[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/lb/records/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getAERecords(subjects?: string[]): Promise<AErecord[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ae/records/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getTableInfo(): Promise<TableInfoItem[]> {
    const globalDB = process.env.REACT_APP_GLOBAL_DB

    return this.get(`/table-info/${globalDB}`)
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getBaselines(subjects?: string[]): Promise<Baseline[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/vd/baselines/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getTableRecords(
    table: string,
    subjects?: string[]
  ): Promise<{ [key: string]: string | number }[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/tables/records/${this.studyDB}`, {
      table,
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getAEs(
    condition?: string
  ): Promise<{ [key: string]: string }[]> {
    return this.post(`/ae/aes/${this.studyDB}`, {
      condition
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDMsubjects(subjects?: string[]): Promise<Subject[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/dm/info/subjects/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDMages(
    subjects?: string[]
  ): Promise<{ [key: string]: number }[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/dm/info/ages/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDMpkpd(
    subjects?: string[]
  ): Promise<{ [key: string]: string | number }[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/dm/info/pkpdset/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDMarea(
    area: string,
    subjects?: string[]
  ): Promise<{ [key: string]: string | number }[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/dm/info/area/${this.studyDB}`, {
      area,
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDMareaWithCondition(
    condition: string
  ): Promise<{ [key: string]: string }[]> {
    return this.post(`/dm/info/condition/${this.studyDB}`, {
      condition
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDSscreened(
    subjects?: string[]
  ): Promise<{ screened: number }[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ds/sankey/screened/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDSsankeyDMsummary(subjects?: string[]): Promise<
    {
      exposed: number
      randomized: number
      safety: number
    }[]
  > {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ds/sankey/dm/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDSsankeyArmcd(subjects?: string[]): Promise<
    {
      ARMCD: string
      total: number
    }[]
  > {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ds/sankey/armcd/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDSsankeyDsdecod(subjects?: string[]): Promise<
    {
      ARMCD: string
      DSDECOD: string
      total: number
    }[]
  > {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ds/sankey/dsdecod/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDSsankeySubjects(
    condition: string,
    subjects?: string[]
  ): Promise<Subject[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ds/sankey/subjects/${this.studyDB}`, {
      subjectIds,
      condition
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDSinfoDsdecod(subjects?: string[]): Promise<
    {
      date: string
      type: string
      count: number
    }[]
  > {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ds/info/dsdecod/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getDSinfoSubjects(
    value: string,
    dates: string[],
    subjects?: string[]
  ): Promise<Subject[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/ds/info/subjects/${this.studyDB}`, {
      subjectIds,
      dates,
      value
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getLBtests(
    subjects?: string[]
  ): Promise<{ LBTESTCD: string; LBTEST: string }[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/lb/box/tests/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getLBsubjects(
    day: number,
    subjects?: string[]
  ): Promise<Subject[]> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/lb/box/subjects/${this.studyDB}`, {
      day,
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getLBrecords(
    tests: string[],
    subjects?: string[]
  ): Promise<LBboxData> {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/lb/box/records/${this.studyDB}`, {
      tests,
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getVSpulseAndPressure(subjects?: string[]): Promise<
    {
      SUBJID: string
      day: number
      VISIT: string
      VSDTC: string
      VSPOS: string
      PULSE: string
      OPULSE: string
      SYSBP: string
      DIABP: string
      OSYSBP: string
      ODIABP: string
    }[]
  > {
    const selectedSubjects = store.getState().subjects.selected

    if (!selectedSubjects) {
      throw new Error('Selected subjects are missing in the App state.')
    }

    const subjectIds =
      subjects || selectedSubjects.map((subject) => subject.SUBJID)

    return this.post(`/vs/pulse-and-pressure/${this.studyDB}`, {
      subjectIds
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getAccessLink(email: string, name: string): Promise<boolean> {
    return this.post(`/access/link`, {
      email,
      name
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async runSelectQuery(query: string): Promise<{}[]> {
    return this.post(`/query/${this.studyDB}`, {
      query
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async addNote(
    url: string,
    note: string,
    userId: string,
    created: string
  ): Promise<string> {
    return this.post(`/notes/${this.studyDB}`, {
      url,
      note,
      userId,
      created
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }

  public async getNotes(
    userId: string,
    url?: string
  ): Promise<UserNoteRecord[]> {
    return this.post(`/notes/records/${this.studyDB}`, {
      userId,
      url
    })
      .then((res: any) => res.data)
      .catch((err) => {
        throw err
      })
  }
}
