import { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { sql, addToHistory } from '../../apis/utility'
import { DataGridPro } from '@mui/x-data-grid-pro'

export const TestDayProfile = (props: any) => {
  const { study } = props
  const params = useParams()
  const { subjid, studyDatabase, date } = params

  const location = useLocation()

  const [gridData, setGridData] = useState<any>(null)
  const [columns, setColumns] = useState<any>(null)
  const [title, setTitle] = useState<any>('')

  addToHistory({ title: 'Test Day Profile', url: location })

  useEffect(() => {
    if (date) {
      setTitle(
        'All labs tests for subject ' +
          subjid +
          ' from ' +
          study +
          ' on ' +
          date.substring(0, 10)
      )

      sql(
        studyDatabase,
        `select * from lb where subjid='${subjid}' and substr(lbdtc,1,10)='${date.substring(
          0,
          10
        )}'`
      ).then((res) => {
        const tempData = res.data.map((row: any, id: any) => ({
          ...row,
          id: id
        }))
        const tempColumns = Object.keys(tempData[0]).map((key) => ({
          field: key,
          headerName: key
        }))

        setColumns(tempColumns)
        setGridData(tempData)
      })
    }
  }, [studyDatabase, subjid, date, study])

  return (
    <>
      <Typography variant="h5">{title}</Typography>
      {gridData && columns ? (
        <div style={{ height: props.screenHeight * 0.8, width: '100%' }}>
          <DataGridPro
            initialState={{
              sorting: {
                sortModel: [
                  { field: 'LBTESTCD', sort: 'asc' },
                  { field: 'SUBJID', sort: 'asc' },
                  { field: 'LBDTC', sort: 'asc' }
                ]
              }
            }}
            rows={gridData}
            columns={columns}
            density={'compact'}
            rowHeight={30}
            sx={{ fontSize: '0.7em' }}
          />
        </div>
      ) : null}
    </>
  )
}
