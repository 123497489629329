import { useState, useEffect } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { sql, globalFilter, addToHistory } from '../../apis/utility'
import { dataToBarOptions } from '../../utils'
import { HandsOnTable } from '../HandsOnTable'
import { useLocation } from 'react-router-dom'

export default function AdverseEventsGraph(props: any) {
  const location = useLocation()

  addToHistory({ title: 'AE Compare', url: location })

  const [chartOptions, setChartOptions] = useState(null)
  const [newOptions, setNewOptions] = useState<any>(null)
  const [data, setData] = useState(null)
  const [caption, setCaption] = useState<any>(null)

  const whereSubjects =
    props.selectedSubjects.length > 0
      ? 'where SUBJID in ("' + props.selectedSubjects.join('","') + '")'
      : ''
  const sql1 =
    `select * from (select count(distinct SUBJID) as subjectsWithAEs, count(*) as totalAEs from aevd ${whereSubjects}) cross join ` +
    `(select count(distinct SUBJID) as totalSubjects from vd ${whereSubjects})`

  useEffect(() => {
    sql(props.studyDatabase, sql1).then((res) => {
      const pctSubjectsWithAEs =
        (res.data[0].subjectsWithAEs / res.data[0].totalSubjects) * 100

      setCaption(
        `<b>Subjects with AEs: ${
          res.data[0].subjectsWithAEs
        } (${pctSubjectsWithAEs.toFixed(1)}%), Total Subjects: ${
          res.data[0].totalSubjects
        }, Total AEs: ${res.data[0].totalAEs}</b>`
      )

      sql(
        props.studyDatabase,
        'SELECT aebodsys, ' +
          'sum(case when aestdtc<=day1 then -1 else 0 end) as Before, ' +
          'sum(case when aestdtc>day1 then 1 else 0 end) as After ' +
          `FROM aevd ${whereSubjects}` +
          'group by aebodsys order by 3 desc, 2 desc'
      ).then((res) => {
        if (res.message === 'success') {
          const barOptions = dataToBarOptions(
            res.data,
            'aebodsys',
            ['Before', 'After'],
            undefined
          )

          setNewOptions(barOptions)
          setData(res.data)
        }
      })
    })
  }, [whereSubjects, props.studyDatabase, sql1])
  useEffect(() => {
    const subtitle = globalFilter(props.selectedOptions)
    const commonOptions = {
      chart: {
        type: 'bar',
        height: '625px',
        zoomType: 'xy'
      },
      title: { text: `Onset of Adverse Events (${props.study})` },
      subtitle: { text: caption + '<br/>' + subtitle },
      plotOptions: {
        series: { stacking: 'normal' },
        column: { colorByPoint: true },
        bar: { dataLabels: { enabled: true } }
      },
      colors: ['#ccffe6', '#ffc2b3'],
      tooltip: {
        // REFACTOR: create a utility
        formatter: function (): any {
          const { series, point } = this as any

          return (
            '<b>' +
            series.name +
            ', Body System = ' +
            point.category +
            '</b><br/>' +
            'Number of AEs: ' +
            Highcharts.numberFormat(Math.abs(point.y), 0)
          )
        }
      },
      credits: { enabled: false }
    }

    const updatedOptions = { ...commonOptions, ...newOptions }

    setChartOptions(updatedOptions)
  }, [caption, newOptions, props.selectedOptions, props.study])

  const hotColumns = ['aebodsys', 'Before', 'After']

  return (
    <>
      {chartOptions && newOptions && (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      )}
      {data && <HandsOnTable data={data} columns={hotColumns} height="400" />}
    </>
  )
}
