import { useState, useEffect } from 'react'
import {
  Typography,
  IconButton,
  Grid,
  Tooltip,
  Box,
  Button
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import { openSaveFileDialog } from '../../apis/utility'
import { DataGridPro } from '@mui/x-data-grid-pro'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { usePapaParse } from 'react-papaparse'
import { BOThString } from '../../utils'
import { ApiController } from '../../controllers/ApiController'
import { useAppSelector } from '../../hooks'

interface ViewNotesProps {
  apiController: ApiController
}

export const ViewNotes = (props: ViewNotesProps) => {
  const { apiController } = props

  const screenState = useAppSelector((state) => state.preferences.screen)
  const userNameState = useAppSelector((state) => state.access.userName)

  const location = useLocation()

  const [noteData, setNoteData] = useState<any>(null)
  const [noteColumns, setNoteColumns] = useState<any>(null)

  const { jsonToCSV } = usePapaParse()

  const exportData = () => {
    if (!noteData || noteData.length === 0) {
      console.log('nothing to save')
      //TODO: show snackbar
    } else {
      openSaveFileDialog(
        jsonToCSV(noteData),
        'notes for ' + location.pathname,
        'text/csv'
      )
    }
  }

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      if (userNameState) {
        // call async func to fetch data
        const data = await apiController.getNotes(userNameState)

        // handle fetched data
        if (isMounted) {
          const tempData = data.map((d: any, id: any) => ({ ...d, id: id }))

          setNoteData(tempData)

          if (data.length > 0) {
            setNoteColumns(
              Object.keys(data[0]).map((d) => {
                let flex = null

                if (d === 'note') flex = 6
                if (d === 'url') flex = 1
                if (d === 'created') flex = 1

                return {
                  headerName: d,
                  field: d,
                  flex: flex,
                  renderCell: (cellValues: any) => {
                    const { field } = cellValues
                    let { value } = cellValues
                    value = decodeURIComponent(value)

                    if (field === 'url') {
                      const target = `${window.location.protocol}//${window.location.host}/#${value}`

                      return (
                        <Button
                          onClick={() => {
                            window.open(target)
                          }}
                          style={{ textTransform: 'none' }}
                        >
                          {value
                            .toUpperCase()
                            .replace(new RegExp(BOThString, 'i'), BOThString)}
                        </Button>
                      )
                    } else {
                      return value
                    }
                  }
                }
              })
            )
          }
        }
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, userNameState])

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Tooltip title="Download table">
          <IconButton
            size="small"
            sx={{ mr: 1 }}
            color="primary"
            onClick={exportData}
          >
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
        {/* {props.showClose && (
          <Tooltip title="Close">
            <IconButton
              size="small"
              color="primary"
              onClick={props.handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )} */}
      </Grid>
      <Grid item xs={10}>
        <Typography id="title" variant="h6" component="h2">
          View notes for screen: {location.pathname}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {noteData && noteColumns ? (
          <Box sx={{ height: (screenState?.height || 1) * 0.8 }}>
            <DataGridPro
              rows={noteData}
              columns={noteColumns}
              density={'compact'}
              rowHeight={30}
              sx={{ fontSize: '0.7em', maxWidth: '98%' }}
            />
          </Box>
        ) : (
          <span>No notes yet</span>
        )}
      </Grid>
    </Grid>
  )
}
