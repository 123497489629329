import { Link, useParams } from 'react-router-dom'

function Home() {
  const params = useParams()
  const { id } = params

  return (
    <>
      <main>
        <h2>We can grab data from the URL to use on the page.</h2>
        <p>Here is what we got...</p>
        {id ? <b>{id}</b> : null}
      </main>
      <p></p>
      <h6>Some more links</h6>
      <nav>
        <Link to="/about">About</Link>
        <br />
        <Link to="/ae">AE</Link>
      </nav>
    </>
  )
}

export default Home
