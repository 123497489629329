import { State } from '../store/rootReducer'

export const loadState = (): State | undefined => {
  try {
    const serializedState = localStorage.getItem('state')

    if (serializedState === null) return undefined

    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (state: object) => {
  try {
    const serializedState = JSON.stringify(state)

    localStorage.setItem('state', serializedState)
  } catch (err) {
    console.log(`Error while saving state. Error: `, err)
  }
}
