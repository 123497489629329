import { useState, useEffect } from 'react'
import { globalFilter, addToHistory } from '../../../apis/utility'
import { useLocation } from 'react-router-dom'
import SubjectTable from '../../SubjectTable'
import ReactFC from 'react-fusioncharts'
import FusionCharts from 'fusioncharts'
import excelexport from 'fusioncharts/fusioncharts.excelexport'
import Charts from 'fusioncharts/fusioncharts.charts'
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel'
import { LookBelow } from '../../layout/LookBelow'
import FusionChartJSX from '../../ui/FusionChartJSX'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector } from '../../../hooks'
import { ApiController } from '../../../controllers/ApiController'

ReactFC.fcRoot(FusionCharts, Charts, GammelTheme, excelexport)

interface DMInfoProps {
  apiController: ApiController
  theme: { [key: string]: any }
}

export default function DSSankey(props: DMInfoProps) {
  const { apiController, theme } = props

  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)
  const screenState = useAppSelector((state) => state.preferences.screen)

  const chartContainerId = 'ds-sankey-chart-container'

  const location = useLocation()

  addToHistory({ title: 'DS Sankey', url: location })

  const [subtitle, setSubtitle] = useState<any>(null)
  const [links1, setLinks1] = useState<any>(null)
  const [links2, setLinks2] = useState<any>(null)
  const [links3, setLinks3] = useState<any>(null)
  const [links4, setLinks4] = useState<any>(null)
  const [nodes1, setNodes1] = useState<any>(null)
  const [nodes2, setNodes2] = useState<any>(null)
  const [nodes3, setNodes3] = useState<any>(null)
  const [nodes4, setNodes4] = useState<any>(null)
  const [nodes, setNodes] = useState<any>(null)
  const [links, setLinks] = useState<any>(null)

  const [subjectsDrilledTo, setSubjectsDrilledTo] = useState<any>(null)

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const data = await apiController.getDSscreened()

      // handle fetched data
      if (isMounted && data.length) {
        setNodes1(Object.keys(data[0]).map((key) => ({ label: key })))
        setLinks1(
          Object.keys(data[0]).map((key) => ({
            from: 'Screening',
            to: key,
            value: (data[0] as any)[key]
          }))
        )
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const data = await apiController.getDSsankeyDMsummary()

      // handle fetched data
      if (isMounted && data.length) {
        const { exposed, randomized, safety } = data[0]

        setNodes2(Object.keys(data[0]).map((key) => ({ label: key })))
        setLinks2([
          { from: 'screened', to: 'randomized', value: randomized },
          { from: 'randomized', to: 'safety', value: safety },
          { from: 'safety', to: 'exposed', value: exposed }
        ])
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const data = await apiController.getDSsankeyArmcd()

      // handle fetched data
      if (isMounted) {
        setNodes3(data.map((item) => ({ label: item.ARMCD })))
        setLinks3(
          data.map((item) => ({
            from: 'exposed',
            to: item.ARMCD,
            value: item.total
          }))
        )
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const data = await apiController.getDSsankeyDsdecod()

      // handle fetched data
      if (isMounted) {
        const labels1 = data.map((item) => ({ label: item.ARMCD }))
        const labels2 = data.map((item) => ({ label: item.DSDECOD }))

        setNodes4([...labels1, ...labels2])
        setLinks4(
          data.map((item) => ({
            from: item.ARMCD,
            to: item.DSDECOD,
            value: item.total
          }))
        )
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController])

  useEffect(() => {
    if (nodes2 && nodes3 && nodes4) {
      setSubtitle(globalFilter(selectedFilterState) || '')
      setNodes([
        { label: 'Screening' },
        ...nodes1,
        ...nodes2,
        ...nodes3,
        ...nodes4
      ])
      setLinks([...links1, ...links2, ...links3, ...links4])
    }
  }, [
    nodes1,
    nodes2,
    nodes3,
    nodes4,
    links1,
    links2,
    links3,
    links4,
    selectedFilterState
  ])

  return (
    <>
      {nodes && links ? (
        <div id={chartContainerId}>
          <FusionChartJSX
            type="sankey"
            width="100%"
            height={(screenState?.height || 1) * 0.75}
            dataFormat="JSON"
            dataSource={{
              chart: {
                caption:
                  'Disposition Summary (' + selectedStudyState?.STUDYID + ')',
                subcaption: subtitle,
                legendPosition: 'bottom',
                showLegend: '0',
                bgColor: 'EEEEEE,CCCCCC',
                bgratio: '60,40',
                bgAlpha: '70,80',
                bgAngle: '180'
              },
              nodes: nodes,
              links: links
            }}
            events={{
              dataPlotClick: function (e) {
                apiController
                  .getDSsankeySubjects(
                    `${
                      e.data.targetLinks[0] === 'exposed'
                        ? 'dm WHERE ARMCD='
                        : 'ds WHERE DSDECOD='
                    }'${e.data.label}'`
                  )
                  .then((subjects) => {
                    setSubjectsDrilledTo(
                      subjects.map((subject) => subject.SUBJID)
                    )
                  })
              }
            }}
          />
          <ChartDownload elementId={chartContainerId} fileName="DS-Sankey" />
        </div>
      ) : (
        'loading'
      )}
      {subjectsDrilledTo && (
        <LookBelow
          label="Subject Table"
          tooltip="Click to scroll to subject table appears below"
          mt={2}
          ml={4}
          mr={0}
          mb={0}
        />
      )}
      {subjectsDrilledTo && (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          theme={theme}
          apiController={apiController}
        />
      )}
    </>
  )
}
