import { useEffect, useState } from 'react'
import {
  Grid,
  TextField,
  Paper,
  Button,
  Typography,
  Link,
  Box
} from '@mui/material'
import { BOThString, validateEmail } from '../../utils'
import { ApiController } from '../../controllers/ApiController'
import { useAppDispatch } from '../../hooks'
import { setAccessKeyAndUserNameAction } from '../../store/access/action'
import { useNavigate } from 'react-router-dom'

interface AccessPageProps {
  apiController: ApiController
  keyParam: string | null
}

const AccessPage = (props: AccessPageProps) => {
  const { apiController, keyParam } = props

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [email, setEmail] = useState<string>()
  const [name, setName] = useState<string>()
  const [isEmailSent, setIsEmailSent] = useState(false)

  useEffect(() => {
    if (keyParam) {
      const keyDecrypted = atob(keyParam)

      const keyPrefix = 'Xploratum is a real deal! Granted: '

      if (keyDecrypted.indexOf(keyPrefix) > -1) {
        const accessKeyGrantedDate = keyDecrypted.replace(keyPrefix, '')

        const userName = `User with access key granted ${accessKeyGrantedDate}`

        dispatch(setAccessKeyAndUserNameAction({ key: keyParam, userName }))

        setTimeout(() => {
          navigate('/')
        }, 100)
      }
    }
  }, [keyParam, dispatch, navigate])

  const handelSend = async () => {
    if (name && email && validateEmail(email)) {
      const res = await apiController.getAccessLink(email, name)

      if (res) {
        setIsEmailSent(true)
      }
    } else {
      alert('Invalid email address.')
    }
  }

  return (
    <div style={{ padding: 30 }}>
      <Grid
        container
        spacing={3}
        direction={'column'}
        alignItems={'center'}
        component={Paper}
        elevation={1}
        square
      >
        {isEmailSent ? (
          <Typography component="h1" variant="h5" style={{ marginTop: 50 }}>
            An email with access link has been sent to {email}.
          </Typography>
        ) : (
          <>
            <Typography component="h1" variant="h5" style={{ marginTop: 50 }}>
              Please enter email address to get an access link.
            </Typography>

            <form noValidate>
              <TextField
                onChange={(event) => setName(event.target.value)}
                value={name}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="name"
                name="name"
                autoFocus
              />
              <TextField
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="email"
                name="email"
                autoFocus
                type="email"
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handelSend}
              >
                Send
              </Button>
            </form>
          </>
        )}
        <Box mt={5}>
          <Copyright />
        </Box>
      </Grid>
    </div>
  )
}

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link
        color="inherit"
        href="https://both-analytics.com/index.php/both_home.html"
        target="_blank"
      >
        {BOThString} analytics
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default AccessPage
