import { useState, useEffect, useCallback } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import Select from 'react-select'
import {
  sql,
  globalFilter,
  addToHistory,
  getStats
} from '../../../apis/utility'
import { useLocation } from 'react-router-dom'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HighchartMore from 'highcharts/highcharts-more'
import AnnotationsModule from 'highcharts/modules/annotations'
import SubjectTable from '../../SubjectTable'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector } from '../../../hooks'

HighchartMore(Highcharts)
AnnotationsModule(Highcharts)

export default function VSboxPlot(props: any) {
  const {
    params,
    selectedSubjects,
    categoryVariable,
    normalRanges,
    theme,
    apiController
  } = props

  const { table } = params

  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)

  const chartContainerId = `${table}-box-chart-container`

  const [dy, setDy] = useState()
  const [selectedSubjectsByCategory, setSelectedSubjectsByCategory] =
    useState(selectedSubjects)
  const [showSubjects, setShowSubjects] = useState(false)
  const [codeOptions, setCodeOptions] = useState(params.codeOptions)
  const [selectedOption, setSelectedOption] = useState(params.dmOptions[0])
  const [selectedWidth, setSelectedWidth] = useState(params.widthOptions[2])
  const [selectedCodes, setSelectedCodes] = useState(null)
  const [xCategoryValues, setXCategoryValues] = useState([
    params.codeOptions[0].value
  ])
  const [cachedRes, setCachedRes] = useState<any>()

  const changeSelectedOption = (e: any) => {
    setSelectedOption(e)
  }
  const changeWidth = (e: any) => {
    setSelectedWidth(e)
  }
  const changeCodes = (e: any) => {
    if (e && e[0] && typeof e === 'object') {
      const newXCategoryValues = e.map((e: any) => e.value)

      setSelectedCodes(e)
      setXCategoryValues(newXCategoryValues)
    } else if (e && e.length === 0) {
      setSelectedCodes(null)
    }
  }

  const location = useLocation()
  const [waiting, setWaiting] = useState(false)

  const whereSubjects =
    selectedSubjects.length > 0
      ? 'and SUBJID in ("' +
        selectedSubjects.map((s: any) => s.SUBJID).join('","') +
        '")'
      : ''

  const [whereValues, setWhereValues] = useState('')
  const [subtitle, setSubtitle] = useState<any>(null)
  const [dataSources, setDataSources] = useState(null)

  addToHistory({ title: 'Boxplot', url: location })

  useEffect(() => {
    const categoryVariableLabel = params.categoryVariableLabel
      ? params.categoryVariableLabel
      : params.categoryVariable

    sql(
      selectedStudyState?.studyDB,
      `select distinct ${params.categoryVariable}, ${categoryVariableLabel} as label from  ${params.table}`
    ).then((res) => {
      const newCodeOptions = res.data
        .map((e: any) => ({
          value: e[params.categoryVariable],
          label: e.label
        }))
        .sort((a: any, b: any) => a.value.localeCompare(b.value))

      setCodeOptions(newCodeOptions)

      changeCodes([newCodeOptions[0]])

      const newWhereValues = newCodeOptions
        .map((item: any) => `'${item.value}'`)
        .join(',')

      setWhereValues(newWhereValues)
    })
    // eslint-disable-next-line
  }, [categoryVariable, params.table])

  const getSubjectsByCategory = useCallback(
    (dy: any) => {
      // FIXME: fix typing
      const table = window.location.href
        .match(/\w{2}boxplot$/)![0]
        .replace('boxplot', '')

      const query = `select distinct SUBJID from ${table} where ${
        selectedSubjects.length
          ? `SUBJID in (${selectedSubjects
              .map((subj: any) => `'${subj.SUBJID}'`)
              .join(', ')}) and `
          : ''
      }${table.toUpperCase()}DY = '${dy}'`

      sql(selectedStudyState?.studyDB, query).then((res) => {
        if (res.data) {
          const subjects = res.data.map((item: any) => item.SUBJID)

          setSelectedSubjectsByCategory(subjects)
          setShowSubjects(!!subjects.length)
        }
      })
    },
    [selectedSubjects, selectedStudyState?.studyDB]
  )

  useEffect(() => {
    setWaiting(true)
    setSubtitle(globalFilter(selectedFilterState) || '')

    const processData = (res: any) => {
      const dmVariableValues = res.data
        .map((row: any) => row[selectedOption.value])
        .filter((v: any, i: any, a: any) => a.indexOf(v) === i)

      // make categories for x-axis
      const currentXCategoryValues = [
        ...new Set(
          res.data
            .filter((row: any) =>
              xCategoryValues.includes(row[params.categoryVariable])
            )
            .map((row: any) => Number(row[params.xVariable]))
        )
      ].sort((a: any, b: any) => a - b)

      const xCategories = [
        {
          category: currentXCategoryValues.map((item: any) => {
            return { label: item.toString() }
          })
        }
      ]

      // make a collection of parameters for each dm value
      const tempDataSources: any = {}

      dmVariableValues.forEach((dmVariableValue: any) => {
        // make series which determines what is actually plotted
        const series = xCategoryValues.map((testCode) => ({
          name: testCode,
          data: xCategories[0].category.map((category) => {
            const data1 = res.data.filter(
              (row: any) =>
                row[params.categoryVariable] === testCode &&
                row[params.xVariable] === category.label &&
                row[selectedOption.value] === dmVariableValue
            )

            const values = data1.map((item: any) =>
              Number(item[params.yVariable])
            )
            const boxValues = getStats(values)

            return boxValues
          }),
          point: {
            events: {
              click: function () {
                setDy((this as any).category)

                getSubjectsByCategory((this as any).category)
              }
            }
          }
        }))

        const { low, high } =
          xCategoryValues[0] && xCategoryValues[0] in normalRanges
            ? normalRanges[xCategoryValues[0]]
            : { low: null, high: null }

        const selectedData = res.data.filter((row: any) =>
          xCategoryValues.includes(row[params.categoryVariable])
        )

        const values = selectedData.map((item: any) =>
          Number(item[params.yVariable])
        )

        const convert = (n: any) => {
          const order =
            n > 0 ? Math.floor(Math.log(n) / Math.LN10 + 0.000000001) : null

          return order ? Math.pow(10, order - 1) : 1
        }

        const max = Math.max(...values)
        const min = Math.min(...values)

        const roundUp = convert(max)
        const roundDown = convert(min)

        const yAxisMaxValue = Math.ceil(Math.max(...values) / roundUp) * roundUp
        const yAxisMinValue = roundDown
          ? Math.floor(Math.min(...values) / roundDown) * roundDown
          : 0

        const title = `${xCategoryValues.map((item) => item).join(', ')}`

        const dataSource = {
          chart: { type: 'boxplot' },
          credits: {
            enabled: false
          },
          exporting: { enabled: false },
          title: {
            text: `${title} (${selectedStudyState?.STUDYID}) [${dmVariableValue}]`
          },
          subtitle: { text: subtitle },
          legend: { enabled: true },
          colors: [
            '#058DC7',
            '#50B432',
            '#ED561B',
            '#DDDF00',
            '#24CBE5',
            '#64E572',
            '#FF9655',
            '#FFF263',
            '#6AF9C4'
          ],
          series: series.map((use) => use),
          xAxis: {
            categories: xCategories[0].category.map((item) => item.label),
            title: {
              text: 'Day of Treatment'
            }
          },
          yAxis: {
            min: yAxisMinValue,
            max: yAxisMaxValue,
            title: { text: title },
            plotBands: [
              {
                from: low,
                to: high,
                color: 'white',
                label: {
                  text: 'Normal',
                  style: {
                    color: '#606060'
                  }
                }
              }
            ]
          }
        }

        tempDataSources[dmVariableValue] = dataSource
      })

      setDataSources(tempDataSources)
      setWaiting(false)
    }

    const sqlStatement = `SELECT *, ${params.categoryVariable} || ' (' || ${selectedOption.value} || ')' as combinedCategory FROM 
                        (SELECT SUBJID as ${params.table}, ${params.xVariable}, ${params.yVariable}, ${params.categoryVariable} 
                        FROM ${params.table} WHERE ${params.categoryVariable} in (${whereValues}) ${whereSubjects})
                        LEFT JOIN
                        (SELECT SUBJID as dm, ${selectedOption.value} from dm WHERE 1 ${whereSubjects})
                        ON ${params.table}=dm
                        `

    const { href } = window.location

    if (
      !cachedRes ||
      !cachedRes.res.data ||
      !cachedRes.res.data.length ||
      (cachedRes && cachedRes.href !== href)
    ) {
      sql(selectedStudyState?.studyDB, sqlStatement).then((res) => {
        if (res.message === 'success') {
          processData(res)
          setCachedRes({ res, href })
        }
      })
    } else {
      processData(cachedRes.res)
    }

    if (dy) getSubjectsByCategory(dy)

    // eslint-disable-next-line
  }, [
    selectedSubjects,
    selectedStudyState,
    xCategoryValues,
    subtitle,
    selectedOption,
    whereValues,
    selectedWidth,
    dy,
    getSubjectsByCategory
  ])

  return (
    <>
      <Grid container spacing={1} id={chartContainerId}>
        {waiting && (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        )}
        {!waiting && (
          <Grid item xs={4}>
            <Select
              id="dmSelect"
              options={params.dmOptions}
              value={selectedOption}
              onChange={(e) => changeSelectedOption(e)}
            ></Select>
          </Grid>
        )}
        {!waiting && (
          <Grid item xs={4}>
            <Select
              options={codeOptions}
              isMulti
              id={params.table + 'codeSelect'}
              closeMenuOnSelect={false}
              value={selectedCodes}
              onChange={(e) => changeCodes(e)}
              placeholder="Select test or measurement"
            ></Select>
          </Grid>
        )}
        {!waiting && (
          <Grid item xs={4}>
            <Select
              options={params.widthOptions}
              value={selectedWidth}
              onChange={(e) => changeWidth(e)}
            ></Select>
          </Grid>
        )}

        {!waiting && (
          <>
            {selectedCodes && dataSources && selectedWidth.value
              ? Object.keys(dataSources).map((key, index) => (
                  <Grid item xs={Number(selectedWidth.value)} key={index}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={dataSources[key]}
                    />
                  </Grid>
                ))
              : dataSources && selectedWidth.value
              ? 'loading'
              : null}
            {dataSources && (
              <ChartDownload
                elementId={chartContainerId}
                fileName={`${table.toUpperCase()}-Box`}
                style={{
                  position: 'relative',
                  left: 'calc(100% - 35px)',
                  bottom: '-5px',
                  cursor: 'pointer'
                }}
              />
            )}
          </>
        )}
      </Grid>
      {showSubjects && (
        <SubjectTable
          selectedSubjects={selectedSubjectsByCategory}
          filterOptions={[{ key: 'DY', value: dy }]}
          theme={theme}
          apiController={apiController}
        />
      )}
    </>
  )
}
