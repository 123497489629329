import { useState, useEffect, useRef, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import SubjectTable from '../../SubjectTable'
import { LookBelow } from '../../layout/LookBelow'
import { globalFilter, addToHistory, round } from '../../../apis/utility'
import {
  BOThString,
  getSubjIdsWithAeOnDay,
  getAEBOThData,
  AEweight,
  compareObjects,
  dataToStreamOption,
  ChartOption
} from '../../../utils'
import {
  Tooltip,
  Fab,
  Snackbar,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box
} from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Highcharts from 'highcharts'
import addSeriesLabel from 'highcharts/modules/series-label'
import addAccessibility from 'highcharts/modules/accessibility'
import addStreamgraphModule from 'highcharts/modules/streamgraph'
import HighchartsReact from 'highcharts-react-official'
import jStat from 'jstat'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { ApiController } from '../../../controllers/ApiController'
import { AEBOThChart, AEBOThData, Pvalue, FilterOptions } from '../../../types'
import { setAEBOThDataAction } from '../../../store/action'

interface AEBothColumn {
  title: string
  data: string
  headerName: number
  field: string
  width: number
  sortable: boolean
  filterable: boolean
  pinnable: boolean
  hideable: boolean
  minWidth: number
  align: string
}
interface AEBothRow {
  [key: string]: string | null
}

interface AEBothProps {
  apiController: ApiController
  theme: { [key: string]: any }
  selectedOptions?: FilterOptions
  selectedSubjects?: string[]
  columns?: AEBothColumn[]
  rows?: AEBothRow[]
  notitle?: boolean
}

export default function AEBoth(props: AEBothProps) {
  const {
    apiController,
    theme,
    columns,
    rows,
    selectedOptions,
    selectedSubjects,
    notitle
  } = props

  const dispatch = useAppDispatch()

  const AEBOThChartState = useAppSelector((state) => state.ae.AEBOThChart)
  const screenState = useAppSelector((state) => state.preferences.screen)
  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)

  const location = useLocation()
  const isChartPage = window.location.href.endsWith('aeboth')

  addToHistory({ title: `AE ${BOThString}`, url: location }, notitle)
  addStreamgraphModule(Highcharts)
  addSeriesLabel(Highcharts)
  addAccessibility(Highcharts)

  const topChartRef = useRef<any>(null)
  const bottomChartRef = useRef<any>(null)

  const contrastingColor = theme.palette.mode === 'dark' ? 'white' : 'black'

  const [showSubjects, setShowSubjects] = useState(false)
  const [dataForChart, setDataForChart] = useState<AEBOThData | null>(
    selectedSubjects ? null : AEBOThChartState?.data || null
  )
  const [subjectsDrilledTo, setSubjectsDrilledTo] = useState<string[]>([])
  const [baselineDates, setBaselineDates] = useState<{
    [key: string]: string
  } | null>(selectedSubjects ? null : AEBOThChartState?.baselineDates || null)
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false)
  const [optionName, setOptionName] = useState<string>()
  const [drillVars, setDrillVars] = useState<string[]>([])
  const [drillDownPath] = useState<[string]>(['aesev']) // define the drilldown path here
  const [drillDownLevel, setDrillDownLevel] = useState<number>(0) // define the drilldown level here
  const [newOptions, setNewOptions] = useState<{ [key: string]: ChartOption }>()
  const [chartOptions, setChartOptions] = useState<{
    [key: string]: ChartOption
  }>()
  const [aeDaySelected, setAeDaySelected] = useState<number>()
  const [minX, setMinX] = useState<number | null>(
    selectedSubjects ? null : AEBOThChartState?.min || null
  )
  const [maxX, setMaxX] = useState<number | null>(
    selectedSubjects ? null : AEBOThChartState?.max || null
  )
  const [maxY, setMaxY] = useState<{ [key: string]: number }>()
  const [sumY, setSumY] = useState<{ [key: string]: number }>()
  const [arms, setArms] = useState<string[] | null>(
    selectedSubjects ? null : AEBOThChartState?.arms.list || null
  )
  const [arm, setArm] = useState<string>()
  const [aeLeftMargin, setAeLeftMargin] = useState<number>()
  const [pValues, setPvalues] = useState<Pvalue[]>()

  const classVar = 'aesev'
  const chartContainerId = 'ae-both-chart-container'

  useEffect(() => {
    let isMounted = false

    const fetchTstatistics = async () => {
      isMounted = true

      const tStatistics = await apiController.getTstatistics()

      if (isMounted) {
        const pVals: Pvalue[] = []

        if (tStatistics) {
          tStatistics.forEach((item) => {
            const {
              t_statistic: tStatistic,
              a_treatment_group: upperArm,
              b_treatment_group: lowerArm
            } = item

            const pValue = jStat.ztest(tStatistic, 2).toFixed(4)

            pVals.push({ upperArm, lowerArm, pValue })
          })
        }

        if (pVals.length) setPvalues(pVals)
      }
    }

    if (isChartPage) fetchTstatistics()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [isChartPage, apiController])

  const drillUp = () => {
    setDrillVars(drillVars.slice(0, drillDownLevel - 1))
    setDrillDownLevel(drillDownLevel - 1)
    setShowSubjects(false)
  }
  const top = () => {
    setDrillVars(drillVars.slice(0, 0))
    setDrillDownLevel(0)
    setShowSubjects(false)
  }

  const [lowerSelectedArm, setLowerSelectedArm] = useState<string | null>(
    selectedSubjects ? null : AEBOThChartState?.arms.lower || null
  )
  const [upperSelectedArm, setUpperSelectedArm] = useState(
    selectedSubjects ? 'PCBO' : AEBOThChartState?.arms.upper || 'PCBO'
  )

  const getAEsubjects = useCallback(
    async (aesev: string, armcd: string, aeDay: number) => {
      let isMounted = true

      if (baselineDates) {
        const occasions = await apiController.getAEoccasions(
          aesev,
          armcd,
          selectedSubjects
        )

        if (isMounted) {
          const filteredSubjects = getSubjIdsWithAeOnDay(
            occasions,
            baselineDates,
            aeDay
          )

          setSubjectsDrilledTo(filteredSubjects)
        }
      }

      // cleanup func
      return () => {
        isMounted = false
      }
    },
    [baselineDates, setSubjectsDrilledTo, apiController, selectedSubjects]
  )

  useEffect(() => {
    let isMounted = false

    const getData = async () => {
      isMounted = true

      const aeBOThData = await getAEBOThData(
        apiController,
        classVar,
        selectedSubjects
      )

      if (isMounted && aeBOThData) {
        const { min, max, baselineDates: baselines, values } = aeBOThData

        const orderedArms = Object.keys(values)
        const position = orderedArms.indexOf(upperSelectedArm)
        const to = 0

        orderedArms.splice(to, 0, orderedArms.splice(position, 1)[0]) // put the default one at the top of the list

        const newAEBOThChart: AEBOThChart = {
          data: values,
          min,
          max,
          baselineDates: baselines,
          arms: {
            list: orderedArms,
            upper: orderedArms[0],
            lower: orderedArms[1]
          }
        }

        // REFACTOR
        if (selectedSubjects) {
          setDataForChart(newAEBOThChart.data)

          if (min) setMinX(min)
          else setMinX(0)

          if (max) setMaxX(max)

          setArms(orderedArms)

          if (!('PCBO' in orderedArms)) setUpperSelectedArm(orderedArms[0])

          setLowerSelectedArm(orderedArms[1])

          if (arm && drillDownPath.length && optionName) {
            setBaselineDates(baselines)
          }
        } else if (!compareObjects(AEBOThChartState, newAEBOThChart)) {
          dispatch(setAEBOThDataAction(newAEBOThChart))

          setDataForChart(newAEBOThChart.data)

          if (min) setMinX(min)
          else setMinX(0)

          if (max) setMaxX(max)

          setArms(orderedArms)

          if (!('PCBO' in orderedArms)) setUpperSelectedArm(orderedArms[0])

          setLowerSelectedArm(orderedArms[1])

          if (arm && drillDownPath.length && optionName) {
            setBaselineDates(baselines)
          }
        }
      }
    }

    getData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [
    apiController,
    classVar,
    arm,
    drillDownLevel,
    drillDownPath,
    drillVars,
    optionName,
    upperSelectedArm,
    AEBOThChartState,
    dispatch,
    selectedSubjects
  ])

  useEffect(() => {
    if (dataForChart && maxX) {
      let streamOptions: { [key: string]: ChartOption } = {}

      Object.keys(dataForChart).forEach((arm) => {
        streamOptions[arm] = dataToStreamOption(
          dataForChart[arm],
          classVar,
          1,
          maxX,
          false, // specify whether to invert values or not
          theme,
          {
            MILD: '#99ccff',
            MODERATE: '#ffb84d',
            SEVERE: '#ff4d4d',
            T1: '#99ccff',
            T2: '#DC9492',
            T3: '#ffb84d',
            T4: '#AC011D',
            T5: '#000000'
          }, // specify colors to use for values of category variable
          {
            MILD: 2,
            MODERATE: 1,
            SEVERE: 0,
            T1: 4,
            T2: 3,
            T3: 2,
            T4: 1,
            T5: 0
          }, // specify index to use for values of category variable, 0 is top subgroup
          true
        ) // force using 3 severities even if there are less
      })

      setNewOptions(streamOptions)

      // work out the min and max values for the y axis
      const tempMin: { [key: string]: number } = {}
      const tempMax: { [key: string]: number } = {}
      const tempSum: { [key: string]: number } = {}

      Object.keys(streamOptions).forEach((arm) => {
        const armObject = streamOptions[arm]

        const values = armObject.series.map((item) =>
          item.data.map((obj) => obj.y)
        )

        // add values for each day
        const numberOfCategories = values.length

        const numberOfCategoryValues = Math.max(
          values[0].length,
          values[1].length,
          values[2].length
        ) // max of mild, moderate, severe

        const totalsByCategory: number[] = []

        for (let i = 0; i < numberOfCategories; i++) {
          for (let j = 0; j < numberOfCategoryValues; j++) {
            totalsByCategory[j] = values[i][j]
              ? totalsByCategory[j]
                ? totalsByCategory[j] + values[i][j] || 0
                : values[i][j] || 0
              : totalsByCategory[j] || 0
          }
        }

        tempMin[arm] = Math.ceil(Math.min(...totalsByCategory) * 10) / 10
        tempMax[arm] = Math.ceil(Math.max(...totalsByCategory) * 10) / 10
        tempSum[arm] =
          Math.ceil(
            totalsByCategory.reduce(
              (partialSum: number, a: number) => partialSum + a,
              0
            ) * 10
          ) / 10
      })

      setMaxY(tempMax)
      setSumY(tempSum)
    }
  }, [dataForChart, classVar, minX, maxX, theme])

  useEffect(() => {
    const subtitle = globalFilter(selectedOptions || selectedFilterState) || ''

    const whatHaveIDrilledDownTo = () => {
      const tempWhereClause = []

      for (let i = 0; i < drillDownLevel; i++) {
        tempWhereClause.push(drillDownPath[i] + '="' + drillVars[i] + '"')
      }

      return tempWhereClause.join(' and ')
    }

    const commonOptions = (arm: string) => ({
      chart: {
        type: 'column',
        scrollbar: {
          enabled: true
        },
        renderTo: 'container',
        marginBottom: 65,
        height: (screenState?.height || 1) * 0.35,
        zoomType: 'xy',
        backgroundColor: 'transparent',
        divlinedashed: '1',
        divlinedashlen: '4',
        divlinedashgap: '2',
        divlinealpha: '60',
        divlinecolor: '#ACACAC'
      },
      title: {
        text: `Adverse Events (${selectedStudyState?.STUDYID})`,
        style: { color: contrastingColor }
      },
      subtitle: {
        text: subtitle + '<br/>' + whatHaveIDrilledDownTo(),
        style: { color: contrastingColor }
      },
      plotOptions: {
        column: {
          groupPadding: 0,
          pointPadding: 0,
          pointWidth: columns ? 40 : undefined
        },
        series: {
          stacking: 'normal',
          label: {
            minFontSize: 5,
            maxFontSize: 15,
            style: {
              color: contrastingColor
            }
          },
          accessibility: {
            exposeAsGroupOnly: true
          },
          point: {
            events: {
              click: function () {
                const { options, category: aeDay } = this as any as {
                  options: { name: string }
                  category: number
                }
                const { name } = options

                setAeDaySelected(aeDay)
                setShowSubjects(true)
                setOptionName(name)
                setArm(arm)

                getAEsubjects(name, arm, aeDay)
              }
            }
          },
          marker: { enabled: false },
          events: {
            legendItemClick: function (event: any) {
              // when clicking the legend for bottom chart, hide the series in the top chart
              const { index, visible } = event.target.userOptions

              topChartRef.current.chart.series[index].setVisible(
                visible === undefined ? false : !visible
              )
            }
          }
        }
      },
      yAxis: {
        visible: true,
        startOnTick: false,
        endOnTick: false,
        title: {
          text: 'Toxicity Units',
          style: { color: contrastingColor }
        },
        min: 0,
        max:
          maxY &&
          maxY[upperSelectedArm] &&
          maxY[lowerSelectedArm || upperSelectedArm]
            ? Math.ceil(maxY[upperSelectedArm]) // FIXME
            : // ? Math.ceil(maxY[upperSelectedArm], maxY[lowerSelectedArm]) // FIXME
              undefined,
        labels: { style: { color: contrastingColor } },
        allowDecimals: true
      },
      legend: {
        enabled: true,
        floating: true
      },
      tooltip: {
        formatter: function () {
          const { series, point } = this as any as {
            series: { name: string }
            point: { category: string; y: number }
          }
          const { category, y } = point

          return (
            '<b>' +
            series.name +
            ',</b><br/> ' +
            'Day = ' +
            category +
            '<br/>' +
            'Weighted % of Patients with AEs: ' +
            Highcharts.numberFormat(round(y, 1), 1)
          )
        }
      },
      credits: { enabled: false },
      exporting: { enabled: false }
    })

    const updatedOptions: { [key: string]: any } = {}
    const upperColors: { [key: string]: string } = {
      MILD: '#f4decd',
      MODERATE: '#e4b293',
      SEVERE: '#ac011d',
      T1: '#f4decd',
      T2: '#DC9492',
      T3: '#e4b293',
      T4: '#AC011D',
      T5: '#000000'
    }
    const lowerColors: { [key: string]: string } = {
      MILD: '#f4decd',
      MODERATE: '#e4b293',
      SEVERE: '#ac011d',
      T1: '#f4decd',
      T2: '#DC9492',
      T3: '#e4b293',
      T4: '#AC011D',
      T5: '#000000'
    }

    if (newOptions) {
      Object.keys(newOptions).forEach((arm) => {
        updatedOptions[arm] = { ...commonOptions(arm), ...newOptions[arm] }
        updatedOptions[arm].title = {
          text: `${BOThString} - Adverse Events (${arm}) (${selectedStudyState?.STUDYID})`,
          style: { color: contrastingColor }
        }

        if (arm !== upperSelectedArm) {
          updatedOptions[arm].series.forEach((series: any) => {
            series.color = lowerColors[series.name]
          })

          const titleMods = {
            verticalAlign: 'bottom',
            y: -10,
            floating: true
          }
          const chartMods = { marginBottom: 75 }
          const xAxisMods = {
            title: { text: null },
            enabled: false,
            visible: false,
            labels: { enabled: false }
          }
          const yAxisMods = {
            reversed: true,
            min: 0,
            max: maxY
              ? Math.max(
                  maxY[upperSelectedArm],
                  maxY[lowerSelectedArm || upperSelectedArm]
                )
              : 0
          }

          updatedOptions[arm].title = {
            ...updatedOptions[arm].title,
            ...titleMods
          }
          updatedOptions[arm].chart = {
            ...updatedOptions[arm].chart,
            ...chartMods
          }
          updatedOptions[arm].xAxis = {
            ...updatedOptions[arm].xAxis,
            ...xAxisMods
          }
          updatedOptions[arm].yAxis = {
            ...updatedOptions[arm].yAxis,
            ...yAxisMods
          }
        } else {
          updatedOptions[arm].series.forEach((series: any) => {
            series.color = upperColors[series.name]
          })
          updatedOptions[arm].legend = { enabled: false }
        }
      })

      setChartOptions(updatedOptions)
    }
  }, [
    newOptions,
    lowerSelectedArm,
    upperSelectedArm,
    contrastingColor,
    columns,
    getAEsubjects,
    maxY,
    screenState?.height,
    baselineDates,
    drillDownLevel,
    drillDownPath,
    drillVars,
    selectedOptions,
    selectedFilterState,
    selectedStudyState?.STUDYID
  ])

  const internalChart: { [key: string]: any } = {}

  const afterChartCreated = (
    chart: { [key: string]: any },
    whichOne: string
  ) => {
    // Highcharts creates a separate chart instance during export
    if (!chart.options.chart.forExport) {
      internalChart[whichOne] = chart
    }
  }

  useEffect(() => {
    if (chartOptions && chartOptions[upperSelectedArm]) {
      let leftMargin = 0

      if (columns) {
        columns.forEach((column) => {
          if (
            ['BASELINE', '...'].includes(column.title) ||
            parseInt(column.title) > 1
          ) {
          } else {
            leftMargin += column.width
          }
        })

        setAeLeftMargin(leftMargin)

        const { series } = chartOptions[upperSelectedArm]

        if (series) {
          setSumY({
            [upperSelectedArm]: series.reduce(
              (acc: number, ser) =>
                acc + ser.data.reduce((acc: number, item) => acc + item.y, 0),
              0
            )
          })
        }
      }
    }
  }, [chartOptions, columns, upperSelectedArm])

  const buildAeChart = () => {
    if (chartOptions && chartOptions[upperSelectedArm]) {
      let { columns } = props

      const colToDay: { [key: string]: string | number } = {}

      if (columns) {
        columns = columns.filter(
          (column: any) =>
            ['BASELINE', '...'].includes(column.title) ||
            parseInt(column.title) > 1
        )

        const xCategories = columns.map((column) => {
          colToDay[column.data] = column.title

          return column.title
        })

        chartOptions[upperSelectedArm].xAxis.categories = xCategories
      }

      const colors: { [key: string]: string } = {
        MILD: '#f4decd',
        MODERATE: '#e4b293',
        SEVERE: '#ac011d'
      }

      if (rows) {
        const series: {
          color?: string
          id?: string
          index?: number
          name: string
          data: {
            name: string
            y: number
          }[]
        }[] = []

        rows.forEach((row, i: number) => {
          const newSeries: { [key: string]: any } = {
            data: [],
            index: i
          }

          const aeCol = Object.keys(row).filter(
            (key) => row[key] && key !== 'key'
          )[0]
          const aeDay = colToDay[aeCol]
          const aeValue = row[aeCol] as string

          const aeDurationMatch = (aeValue.match(/^\[ae\]\[\d*\]/) || '')[0]
          const aeDuration =
            parseInt(aeDurationMatch.replace('[ae][', '').replace(']', '')) || 1
          const aeSeverity = (aeValue.match(/^\[ae\]\[\d*\]\[\w*\]/) || '')[0]
            .replace(/^\[ae\]\[\d*\]\[/, '')
            .replace(']', '')

          newSeries.id = aeSeverity
          newSeries.name = aeSeverity
          newSeries.color = colors[aeSeverity]

          chartOptions[upperSelectedArm].xAxis.categories.forEach(
            (category) => {
              let baseline

              if (aeDay === 'BASELINE') baseline = 1

              const y =
                category === aeDay
                  ? AEweight(aeSeverity)
                  : aeDuration > 1
                  ? category > (baseline || aeDay) &&
                    category <
                      ((baseline as any) || (aeDay as any)) + aeDuration
                    ? AEweight(aeSeverity)
                    : 0
                  : 0

              newSeries.data.push({ name: aeSeverity, y: y })
            }
          )

          series.push(newSeries as any)
        })

        chartOptions[upperSelectedArm].series = series
      }
    }
  }

  if (columns) buildAeChart()

  const burdenLabel = isChartPage
    ? 'Av. Burden Per Subject'
    : 'Subject Total Burden'

  return (
    <>
      <div id={chartContainerId} style={{ overflow: 'scroll' }}>
        {chartOptions && chartOptions[upperSelectedArm] && (
          <div style={{ overflow: 'scroll' }}>
            <Grid
              container
              spacing={3}
              style={
                columns
                  ? {
                      minWidth:
                        70 +
                        (chartOptions[upperSelectedArm].xAxis.categories
                          .length -
                          1) *
                          40 +
                        chartOptions[upperSelectedArm].xAxis.categories.length *
                          (chartOptions[upperSelectedArm].xAxis.categories
                            .length > 100
                            ? 1
                            : 2)
                    }
                  : {}
              }
            >
              <Grid
                item
                xs={12}
                key={'upper'}
                style={
                  columns
                    ? {
                        width:
                          70 +
                          (chartOptions[upperSelectedArm].xAxis.categories
                            .length -
                            1) *
                            40 +
                          chartOptions[upperSelectedArm].xAxis.categories
                            .length *
                            (chartOptions[upperSelectedArm].xAxis.categories
                              .length > 100
                              ? 1
                              : 2),
                        marginLeft: aeLeftMargin ? aeLeftMargin - 70 + 15 : 0
                      }
                    : {}
                }
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions[upperSelectedArm]}
                  immutable={true}
                  callback={(e: any) => afterChartCreated(e, 'upper')}
                  ref={topChartRef}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {!selectedSubjects &&
          chartOptions &&
          chartOptions[lowerSelectedArm || upperSelectedArm] && (
            <Grid container spacing={3}>
              <Grid item xs={12} key={'lower'}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions[lowerSelectedArm || upperSelectedArm]}
                  immutable={true}
                  callback={(e: any) => afterChartCreated(e, 'lower')}
                  ref={bottomChartRef}
                />
              </Grid>
            </Grid>
          )}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
          {arms && arms.length > 0 && upperSelectedArm && sumY && (
            <TextField
              id="outlined-read-only-input"
              label={burdenLabel}
              value={(sumY[upperSelectedArm] / 10).toFixed(1)}
              InputProps={{
                readOnly: true
              }}
            />
          )}
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
          <InputLabel id="upper-arm-label">Upper Arm</InputLabel>
          {arms && arms.length > 0 && upperSelectedArm && (
            <Select
              labelId="upper-arm-label"
              id="upper-arm-select"
              value={upperSelectedArm ? upperSelectedArm : ''}
              label="Upper Arm"
              onChange={(event) => {
                setUpperSelectedArm(event.target.value)
              }}
            >
              {arms &&
                arms.length > 0 &&
                arms.map((arm: string, i: number) => (
                  <MenuItem key={'up' + i} value={arm}>
                    {arm}
                  </MenuItem>
                ))}
            </Select>
          )}
        </FormControl>
        {arms && arms.length > 1 && (
          <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="lower-arm-label">Lower Arm</InputLabel>
            {arms && arms.length > 0 && lowerSelectedArm && (
              <Select
                labelId="lower-arm-label"
                id="lower-arm-select"
                value={lowerSelectedArm ? lowerSelectedArm : ''}
                label="Lower Arm"
                onChange={(event) => {
                  setLowerSelectedArm(event.target.value)
                }}
              >
                {arms &&
                  arms.length > 0 &&
                  arms.map((arm: string, i: number) => (
                    <MenuItem key={'low' + i} value={arm}>
                      {arm}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </FormControl>
        )}
        {arms && arms.length > 1 && (
          <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
            {arms &&
              arms.length > 0 &&
              lowerSelectedArm &&
              sumY &&
              sumY[lowerSelectedArm] && (
                <TextField
                  id="outlined-read-only-input"
                  label={burdenLabel}
                  value={(sumY[lowerSelectedArm] / 10).toFixed(1)}
                  InputProps={{
                    readOnly: true
                  }}
                />
              )}
          </FormControl>
        )}
        {pValues &&
          upperSelectedArm &&
          lowerSelectedArm &&
          upperSelectedArm !== lowerSelectedArm && (
            <Box sx={{ mb: 5 }}>
              P-value:{' '}
              {
                (
                  pValues.find(
                    (pValue) =>
                      pValue.upperArm === upperSelectedArm &&
                      pValue.lowerArm === lowerSelectedArm
                  ) || { pValue: 'NA' }
                ).pValue
              }
            </Box>
          )}
        {showSubjects && (
          <LookBelow
            label="Subject Table"
            tooltip="Click to scroll to subject table appears below"
            mt={2}
            ml={4}
            mr={0}
            mb={0}
          />
        )}
        {drillDownLevel > 0 ? (
          <span
            style={{
              position: 'fixed',
              bottom: '100px',
              right: '40px',
              zIndex: 100
            }}
          >
            <Tooltip title="Level we have drilled down to">
              <Fab
                size="small"
                variant="extended"
                color="info"
                sx={{ margin: 1 }}
                onClick={drillUp}
              >
                Level {drillDownLevel}
              </Fab>
            </Tooltip>
            <Tooltip title="Drill up">
              <Fab
                size="small"
                color="info"
                sx={{ margin: 1 }}
                onClick={drillUp}
              >
                <ArrowDropUpIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Go to top level">
              <Fab size="small" color="info" sx={{ margin: 1 }} onClick={top}>
                <ArrowUpwardIcon />
              </Fab>
            </Tooltip>
          </span>
        ) : null}
        {chartOptions && !rows && !columns && (
          <ChartDownload elementId={chartContainerId} fileName="AE-BOTh" />
        )}{' '}
      </div>
      {showSubjects && subjectsDrilledTo.length ? (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          filterOptions={[{ key: 'DY', value: aeDaySelected }]}
          theme={theme}
          apiController={apiController}
        />
      ) : null}
      <Snackbar
        message={"Can't drill any lower"}
        open={snackBarOpen}
        onClose={() => setSnackBarOpen(false)}
      />
    </>
  )
}
