import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePapaParse } from 'react-papaparse'
import { decodeAbbreviation } from '../utils'
import { Tooltip, IconButton } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import {
  sql,
  globalFilter,
  addToHistory,
  openSaveFileDialog
} from '../apis/utility'
import { baselineClause, dateFormat } from '../utils'
import {
  CancelTwoTone,
  FileDownload,
  ThumbDownAlt,
  MoodBad,
  Link,
  Done,
  QuestionMark
} from '@mui/icons-material'
import moment from 'moment'
import Legend from './ui/Legend'
import { useAppSelector } from '../hooks'
import { ApiController } from '../controllers/ApiController'

let aeColorPalette: any

const thumbDownIcon = (title: string, color: string) => (
  <Tooltip title={title}>
    <ThumbDownAlt sx={{ fontSize: 18, color }} />
  </Tooltip>
)

const moodBadIcon = (title: string, color: string) => (
  <Tooltip title={title}>
    <MoodBad sx={{ fontSize: 18, color }} />
  </Tooltip>
)

const linkIcon = (title: string, color?: string) => (
  <Tooltip title={title}>
    <Link
      sx={{
        fontSize: 22,
        verticalAlign: 'text-bottom',
        color
      }}
    />
  </Tooltip>
)

const cancelTwoToneIcon = (title: string, color: string) => (
  <Tooltip title={title}>
    <CancelTwoTone
      sx={{
        fontSize: 22,
        verticalAlign: 'text-bottom',
        color
      }}
    />
  </Tooltip>
)

const questionIcon = (title: string) => (
  <Tooltip title={title}>
    <QuestionMark sx={{ fontSize: 18 }} />
  </Tooltip>
)

// FIXME
const MyCellRenderer = (props: any) => {
  const { field, value, row } = props
  const {
    died,
    aeSerious,
    aeSevSevere,
    aeSevModerate,
    aeSevMild,
    aeRelProbable,
    aeRelPossible,
    aeRelUnrelated,
    aeRelNone,
    aeRelRemote,
    aeRelUndetermined
  } = row

  if (field === 'ae') {
    return (
      <span>
        {aeSerious > 0
          ? thumbDownIcon('Serious', aeColorPalette.seriousness.serious)
          : null}
        &nbsp;
        {aeSevSevere > 0
          ? moodBadIcon('Severe', aeColorPalette.severity.severe)
          : aeSevModerate > 0
          ? moodBadIcon('Moderate', aeColorPalette.severity.moderate)
          : aeSevMild > 0
          ? moodBadIcon('Mild', aeColorPalette.severity.mild)
          : null}
        &nbsp;
        {aeRelProbable > 0
          ? linkIcon('Probable', aeColorPalette.relatedness.probable)
          : aeRelPossible > 0
          ? linkIcon('Possible', aeColorPalette.relatedness.possible)
          : aeRelUnrelated > 0 || aeRelNone > 0
          ? linkIcon('Unrelated', aeColorPalette.relatedness.unrelated)
          : aeRelRemote > 0
          ? linkIcon('Remote')
          : aeRelUndetermined > 0
          ? questionIcon('Undetermined')
          : null}
        {died > 0 ? cancelTwoToneIcon('Died', 'error.main') : null}
      </span>
    )
  } else if (['dm', 'ds', 'vd', 'lb', 'vs', 'cm', 'mh'].includes(field)) {
    return <span>{value > 0 ? <Done /> : null}</span>
  } else {
    return <span>{value}</span>
  }
}

interface SubjectTableProps {
  selectedSubjects: string[]
  screenHeight?: number
  theme: { [key: string]: any }
  filterOptions?: { [key: string]: any }
  repeats?: boolean
  height?: number
  detail?: boolean
  apiController: ApiController
}

export default function SubjectTable(props: SubjectTableProps) {
  const {
    selectedSubjects: selectedSubjectsProp,
    filterOptions,
    repeats,
    detail,
    height,
    theme,
    screenHeight,
    apiController
  } = props

  const { palette } = theme
  const { ae: aePalette } = palette
  const { relatedness: aeRelatednessPallette, severity: aeSeverityPalette } =
    aePalette

  aeColorPalette = aePalette

  const location = useLocation()
  const { jsonToCSV } = usePapaParse()

  addToHistory({ title: 'Subject Table', url: location })

  const studyState = useAppSelector((state) => state.studies.selected)
  const screenState = useAppSelector((state) => state.preferences.screen)
  const selectedFilterState = useAppSelector((state) => state.filter.selected)

  const [study, setStudy] = useState<string>()
  const [studyDB, setStudyDB] = useState<string>()
  const [columnDefs, setColumnDefs] = useState(null)
  const [rows, setRows] = useState<any>(null)
  const [subtitle, setSubtitle] = useState<any>(null)

  useEffect(() => {
    if (studyState) {
      setStudy(studyState.STUDYID)
      setStudyDB(studyState.studyDB)
    }
  }, [studyState])

  useEffect(() => {
    let isMounted = false

    const globalFilterString = globalFilter(selectedFilterState)

    const subtitle = `${globalFilterString || ' '}${
      filterOptions && filterOptions.length
        ? `${globalFilterString ? ' and ' : ''}${filterOptions
            .map((option: any) => `${option.key} = ${option.value}`)
            .join(' and ')}`
        : ''
    }`

    setSubtitle(subtitle)

    const fetchData = async () => {
      isMounted = true

      if (studyDB) {
        const selectedSubjects = selectedSubjectsProp.map(
          (sub: any) => '"' + sub + '"'
        )

        const subjectsWhere =
          selectedSubjects.length > 0
            ? `WHERE SUBJID IN (${selectedSubjects.join(',')})`
            : ''

        let subjectsTableData = await apiController.getSubjectsInfo(
          selectedSubjectsProp
        )

        if (isMounted && subjectsTableData.length > 0) {
          const visitTableQuery = `SELECT SUBJID as id, date(VDDATE ) AS date FROM vd ${subjectsWhere} ${baselineClause(
            subjectsWhere
          )}`

          // fetch baseline dates for each subject
          const visitTableRes = await sql(studyDB, visitTableQuery)

          if (
            isMounted &&
            visitTableRes.message === 'success' &&
            visitTableRes.data.length > 0
          ) {
            const baselineDates: any = {}

            // format dates
            visitTableRes.data.forEach((item: any) => {
              const { id, date } = item

              baselineDates[id] = { date: moment(date).format(dateFormat) }
            })

            const aeWhere = `${
              subjectsWhere ? ' AND' : 'WHERE'
            } (UPPER(AESEV) in ("MODERATE", "SEVERE", "MILD") OR UPPER(AESER) = "Y" OR UPPER(AEOUT) = "FATAL")`

            const aeTableQuery = `SELECT SUBJID as id, AEREL as relatedness, AESEV as severity, AESER as seriousness, AEOUT as outcome, AESTDTC as startDate FROM ae ${subjectsWhere}${aeWhere}`

            let aeData: any = {}

            const aeTableRes = await sql(studyDB, aeTableQuery)

            if (
              isMounted &&
              aeTableRes.message === 'success' &&
              aeTableRes.data.length > 0
            ) {
              aeTableRes.data.forEach((item: any) => {
                const { id, seriousness, outcome } = item

                let { relatedness, severity, startDate } = item
                relatedness = relatedness.toLowerCase()
                severity = severity.toLowerCase()
                startDate = moment(startDate).format(dateFormat)

                if (baselineDates[id]) {
                  // return if ae date is before the baseline date for this subject
                  if (moment(startDate).isBefore(baselineDates[id].date)) return
                } else {
                  // there is no baseline date for subject
                  return
                }

                if (!aeData[id]) aeData[id] = {}

                // Add possible and probable AE values
                if (!aeData[id][relatedness]) {
                  if (
                    [
                      'possible',
                      'probable',
                      'unrelated',
                      'remote',
                      'none'
                    ].includes(relatedness)
                  ) {
                    aeData[id][relatedness] = 1
                  } else if (relatedness === '') {
                    aeData[id]['undetermined'] =
                      (aeData[id]['undetermined'] || 0) + 1
                  }
                } else {
                  aeData[id][relatedness] = aeData[id][relatedness] + 1
                }

                // Add mild, moderate and severe AE values
                if (!aeData[id][severity]) {
                  if (['mild', 'moderate', 'severe'].includes(severity)) {
                    aeData[id][severity] = 1
                  }
                } else {
                  aeData[id][severity] = aeData[id][severity] + 1
                }

                // Add serious AE value
                if (!aeData[id]['serious']) {
                  if (seriousness === 'Y') aeData[id]['serious'] = 1
                } else {
                  aeData[id]['serious'] = aeData[id]['serious'] + 1
                }

                // Add died AE value
                if (!aeData[id]['died']) {
                  if (outcome === 'FATAL') aeData[id]['died'] = 1
                } else {
                  aeData[id]['died'] = aeData[id]['died'] + 1
                }
              })

              // Add possible, probable, moderate, severe, serious and died AE values to the data
              subjectsTableData = subjectsTableData.map((item: any) => {
                const { id } = item

                if (aeData[id]) {
                  item.died = aeData[id].died || 0
                  item.aeSerious = aeData[id].serious || 0
                  item.aeSevSevere = aeData[id].severe || 0
                  item.aeSevModerate = aeData[id].moderate || 0
                  item.aeSevMild = aeData[id].mild || 0
                  item.aeRelProbable = aeData[id].probable || 0
                  item.aeRelPossible = aeData[id].possible || 0
                  item.aeRelUnrelated = aeData[id].unrelated || 0
                  item.aeRelNone = aeData[id].none || 0
                  item.aeRelRemote = aeData[id].remote || 0
                  item.aeRelUndetermined = aeData[id].undetermined || 0
                } else {
                }

                return item
              })
            }
          }

          const repeatsKey = 'repeats'

          if (repeats) {
            // Add number of repeats of the same SUBJID
            subjectsTableData = subjectsTableData.map((item: any) => {
              const { id } = item

              const repeats = selectedSubjects.filter(
                (subject: any) => subject === `"${id}"`
              ).length

              item[repeatsKey] = repeats

              return item
            })
          }

          setRows(subjectsTableData)

          const keys = Object.keys(subjectsTableData[0])
          const columnDefs: any = []

          keys.forEach((key) => {
            const row: any = {
              field: key,
              headerName: key,
              renderCell: MyCellRenderer,
              width: 50,
              description: decodeAbbreviation(key)
            }

            if (key === 'id') row.width = 80
            else if (['ae'].includes(key)) row.width = 100
            else if (['armcd'].includes(key)) row.width = 100

            if (key.startsWith('n_')) row.width = 60

            if (
              [
                'id',
                'ae',
                'dm',
                'ds',
                'lb',
                'vd',
                'cm',
                'mh',
                'vs',
                'sex',
                'age',
                'armcd'
              ].includes(key)
            ) {
              if (!['ae', 'id', 'armcd'].includes(key)) {
                row.width = 10
                row.hideSortIcons = true
              }

              columnDefs.push(row)
            } else if (detail) {
              columnDefs.push(row)
            }
          })

          if (repeats) {
            // Add 'repeats' column
            columnDefs.splice(1, 0, {
              field: repeatsKey,
              headerName: repeatsKey,
              renderCell: MyCellRenderer,
              width: 55,
              align: 'center'
            })
          }

          setColumnDefs(columnDefs)
        }
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }

    // eslint-disable-next-line
  }, [studyDB, selectedSubjectsProp])

  return (
    <div
      style={{
        height: height
          ? height
          : (screenHeight || screenState?.height || 1) * 0.8,
        width: '100%',
        overflow: 'scroll'
      }}
    >
      <h4>
        <Tooltip title="Download table">
          <IconButton
            color="primary"
            onClick={() => {
              openSaveFileDialog(jsonToCSV(rows), 'subjects', 'text/csv')
            }}
          >
            <FileDownload />
          </IconButton>
        </Tooltip>
        Subjects ({study})<br />
        <span style={{ fontSize: 12 }}>{subtitle}</span>
      </h4>
      {rows && columnDefs && (
        <>
          <div style={{ display: 'flex' }}>
            <Legend
              tooltip={'Click to view table info'}
              data={[
                {
                  text: (
                    <div>
                      <span>
                        Each subject row is a summary of AEs. A subject may have
                        multiple AEs.
                      </span>
                    </div>
                  )
                },
                {
                  text: (
                    <div>
                      <span>
                        AE relatedness icon{' '}
                        {
                          <span style={{ verticalAlign: 'middle' }}>
                            {linkIcon('AE Relatedness')}
                          </span>
                        }{' '}
                        represents the highest relatedness of any AE each
                        subject reports (Probable -&gt; Possible -&gt; Unrelated
                        -&gt; Remote -&gt; Undetermined).
                      </span>
                    </div>
                  ),
                  items: [
                    {
                      title: 'Probable',
                      color: aeRelatednessPallette.probable.color
                    },
                    {
                      title: 'Possible',
                      color: aeRelatednessPallette.possible.color
                    },
                    {
                      title: 'Unrelated',
                      color: aeRelatednessPallette.unrelated.color
                    },
                    {
                      title: 'Remote',
                      color: aeRelatednessPallette.remote.color
                    },
                    {
                      title: 'Undetermined',
                      color: aeRelatednessPallette.undetermined.color
                    }
                  ]
                },
                {
                  text: (
                    <div>
                      <span>
                        AE severity icon{' '}
                        {
                          <span style={{ verticalAlign: 'middle' }}>
                            {moodBadIcon('AE Severity', '')}
                          </span>
                        }{' '}
                        represents the highest severity of any AE each subject
                        reports (Severe -&gt; Moderate -&gt; Mild).
                      </span>
                    </div>
                  ),
                  items: [
                    { title: 'Severe', color: aeSeverityPalette.severe.color },
                    {
                      title: 'Moderate',
                      color: aeSeverityPalette.moderate.color
                    },
                    { title: 'Mild', color: aeSeverityPalette.mild.color }
                  ]
                }
              ]}
            />
          </div>
          {studyDB && (
            <DataGridPro
              rows={rows}
              columns={columnDefs}
              rowHeight={33}
              rowsPerPageOptions={[10, 25, 50, 100]}
              density={detail ? 'comfortable' : 'compact'}
              onRowClick={(e) => {
                window.open(
                  window.location.protocol +
                    '//' +
                    window.location.host +
                    '/#/patientprofile/' +
                    studyDB +
                    '/' +
                    e.id
                )
              }}
              sx={{ fontWeight: 'fontSize=5', fontSize: '0.7em' }}
            />
          )}
        </>
      )}
    </div>
  )
}
