import './App.css'
import { useEffect, useState, createContext, useMemo } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
// Material UI
import { Container, Grid } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { PaletteMode } from '@mui/material'
// apis
import { getWidth, getHeight, sql, checkLabAlerts } from './apis/utility'
// components
import SubjectTable from './components/SubjectTable'
//layout
import { TopMenu } from './components/layout/TopMenu'
import { History } from './components/layout/History'
// forms
import AccessPage from './components/forms/AccessPage'
//pages
import GetDataFromUrl from './components/pages/GetDataFromUrl'
import About from './components/pages/About'
import Demo from './components/pages/Demo'
import NotFound from './components/pages/NotFound'
import ViewData from './components/pages/ViewData'
import VSboxPlot from './components/pages/vs/VSboxPlot'
import PatientProfile from './components/pages/PatientProfile'
import Diagrams from './components/pages/Diagrams'
import ViewSpark from './components/pages/ViewSpark'
import Home from './components/pages/Home'
import Overview from './components/pages/Overview'
import AEDrilldown from './components/pages/ae/AEDrilldown'
import AEStreamGraph from './components/pages/ae/AEStreamGraph'
import AdverseEventsGraph from './components/pages/AdverseEventsGraph'
import TestGraph from './components/pages/TestGraph'
import AEBoth from './components/pages/ae/AEBoth'
import DMInfo from './components/pages/dm/DMInfo'
import DSInfo from './components/pages/ds/DSInfo'
import DSSankey from './components/pages/ds/DSSankey'
import LBBoxplot from './components/pages/LBBoxplot'
import LBHysLaw from './components/pages/lb/LBHysLaw'
import VSBloodPressure from './components/pages/VSBloodPressure'
import VSPulse from './components/pages/VSPulse'
import VSOBloodPressure from './components/pages/VSOBloodPressure'
import VSOPulse from './components/pages/VSOPulse'
import VSListing from './components/pages/vs/VSListing'
import VDInfo from './components/pages/vd/VDInfo'
import Ecrf from './components/pages/Ecrf'
import LBboxPlot from './components/pages/lb/LBboxPlot'
import LBBoth from './components/pages/lb/LBBoth'
import Preferences from './components/pages/Preferences'
import { TestTable } from './components/pages/TestTable'
import { TestNew } from './components/pages/TestNew'
import { LBTable } from './components/pages/LBTable'
import { InfoModal } from './components/pages/InfoModal'
import { MakeNotesModal } from './components/pages/MakeNotesModal'
import { ViewNotesModal } from './components/pages/ViewNotesModal'
import { Openai } from './components/pages/Openai'
import { Query } from './components/pages/Query'
import { AEReports } from './components/pages/ae/AEReports'
import { Reporting } from './components/pages/Reporting'
import { ViewNotes } from './components/pages/ViewNotes'
import { TestProfile } from './components/pages/TestProfile'
import { TestDayProfile } from './components/pages/TestDayProfile'
import { AESunburst } from './components/pages/ae/AESunburst'
import { MHSunburst } from './components/pages/mh/MHSunburst'
import { Tools } from './components/pages/Tools'
import {
  aeTheme,
  lbTheme,
  loadState,
  hardcodedStudyId,
  compareObjects
} from './utils'
// python
import Pythondemo from './components/python/Pythondemo'
// r
import Rdemo from './components/r/Rdemo'
import { Files } from './components/pages/Files'
import TestHighChart from './components/pages/TestHighChart'
import { ApiController } from './controllers/ApiController'
import {
  setStudiesAction,
  setSelectedStudyAction,
  restoreState,
  setSelectedSubjectsAction,
  setFilterOptionToTableMapAction,
  setLBrangesAction,
  setVariableLabelsAction,
  setPreferencesDrillDownPathAction,
  setPreferencesScreenAction,
  setFilterObjectAction
} from './store/action'
import { useDidMount, useAppSelector, useAppDispatch } from './hooks'
import { LBrangesObject, VariableLabelsObject } from './types'

const apiController = new ApiController()
let accessKey: string

export default function App() {
  const ColorModeContext = createContext({ toggleColorMode: () => {} })

  const studiesState = useAppSelector((state) => state.studies)
  const filterState = useAppSelector((state) => state.filter)
  const lbState = useAppSelector((state) => state.lb)
  const variablesState = useAppSelector((state) => state.variables)
  const preferencesState = useAppSelector((state) => state.preferences)
  const accessState = useAppSelector((state) => state.access)

  const dispatch = useAppDispatch()

  const { list: studiesList, selected: selectedStudy } = studiesState

  // FIXME
  const [studyDB, setStudyDB] = useState<string>()
  const [showAe] = useState<any>('Y')
  const [showCm] = useState<any>('Y')
  const [showDm] = useState<any>('Y')
  const [showDs] = useState<any>('Y')
  const [showLb] = useState<any>('Y')
  const [showMh] = useState<any>('Y')
  const [showVd] = useState<any>('Y')
  const [showVs] = useState<any>('Y')
  const [selectedOptions, setSelectedOptions] = useState<any>({})
  const [selectedSubjects, setSelectedSubjects] = useState<any>(null)
  const [optionsForSelection, setOptionsForSelection] = useState<any>([])
  const [alertResults, setAlertResults] = useState<any>(null)
  const [varToTable, setVarToTable] = useState<any>(null)
  const [listOfTables, setListOfTables] = useState<any>(null)
  // define variables to be used to create filters
  const [
    optionsForSelectionToBeProcessed,
    setOptionsForSelectionToBeProcessed
  ] = useState<any>(null)
  const [labCategories, setLabCategories] = useState<any>(null)
  const [mode, setMode] = useState<PaletteMode | undefined>('light')
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      }
    }),
    []
  )

  const [rowCounts, setRowCounts] = useState<any>(null)

  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: [
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
          ].join(',')
        },
        components: {
          MuiContainer: {
            styleOverrides: {
              root: {
                width: preferencesState.screen?.width + 'px',
                height: '100%',
                minHeight: '100%',
                background:
                  mode === 'dark'
                    ? 'linear-gradient(0deg, #505050, #C0C0C0)'
                    : 'linear-gradient(0deg, #f2f2f2, #d9d9d9)'
              }
            }
          }
        },
        palette: {
          mode,
          ae: aeTheme,
          lb: lbTheme
        },
        primary: {
          main: '#3f51b5'
        },
        secondary: {
          main: '#f50057'
        }
      } as any),
    [mode, preferencesState.screen?.width]
  )

  const [normalRanges, setNormalRanges] = useState<any>({
    PULSE: { low: 60, high: 100 },
    SYSBP: { low: 80, high: 120 },
    DIABP: { low: 60, high: 90 },
    OPULSE: { low: 60, high: 100 },
    OSYSBP: { low: 80, high: 120 },
    ODIABP: { low: 60, high: 90 }
  })
  const [alerts, setAlerts] = useState<LBrangesObject | null>(lbState.ranges)

  const [openInfoModal, setOpenInfoModal] = useState<any>(false)
  const [openMakeNotesModal, setOpenMakeNotesModal] = useState(false)
  const [openViewNotesModal, setOpenViewNotesModal] = useState(false)
  const [isRedirectedToAccessPage, setIsRedirectedToAccessPage] =
    useState(false)
  const [userName, setUserName] = useState(accessState.userName)
  const [userType] = useState<any>(null)
  const [, setWaiting] = useState<any>(false)
  const [isStateRestored, setIsStateRestored] = useState(false)
  const navigate = useNavigate()

  useDidMount(() => {
    const restoredState = loadState()

    if (restoredState) {
      dispatch(restoreState(restoredState))

      setIsStateRestored(true)
    }
  })

  const fetchStudiesData = async () => {
    // call async func to fetch data

    const studies = await apiController.getStudies()

    // handle fetched data
    if (studies) {
      if (!compareObjects(studiesState.list, studies)) {
        dispatch(setStudiesAction(studies))
      }
    }
  }

  const fetchStudyData = async () => {
    // call async func to fetch data

    const study = await apiController.getStudy(hardcodedStudyId)

    // handle fetched data
    if (study) {
      if (!compareObjects(selectedStudy, study)) {
        const { studyDB } = study

        apiController.setStudyDB(studyDB)

        dispatch(setSelectedStudyAction({ ...study, studyDB }))

        apiController.setStudyDB(studyDB)

        setStudyDB(studyDB)
      }
    }
  }

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const lbRanges = await apiController.getLBranges()

      // handle fetched data
      if (isMounted) {
        const lbRangesObject: LBrangesObject = {}

        lbRanges.forEach((range) => {
          const { code, high, low, test, category } = range

          lbRangesObject[code] = { high, test, category }

          if (low) lbRangesObject[code].low = low
        })

        if (!compareObjects(lbState.ranges, lbRangesObject)) {
          dispatch(setLBrangesAction(lbRangesObject))

          setAlerts(lbRangesObject)
        }
      }
    }

    if (studyDB) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [dispatch, lbState.ranges, studyDB])

  useEffect(() => {
    if (!preferencesState.drillDownPath) {
      dispatch(
        setPreferencesDrillDownPathAction(['AEBODSYS', 'AEHLT', 'AEDECOD'])
      )
    }

    if (!preferencesState.screen) {
      dispatch(
        setPreferencesScreenAction({ height: getHeight(), width: getWidth() })
      )
    }
  }, [dispatch, preferencesState])

  useDidMount(() => {
    if (apiController && (!studiesList || !studiesList.length))
      fetchStudiesData()

    if (apiController && !selectedStudy) fetchStudyData()
  })

  const location = useLocation()

  const search = new URLSearchParams(location.search)
  const keyParam = search.get('key')

  if (keyParam && !accessKey) {
    accessKey = keyParam
  }

  useEffect(() => {
    if (accessState && accessState.userName && !userName) {
      setUserName(accessState.userName)
    }
  }, [accessState, userName])

  // FIXME
  useEffect(() => {
    if (isStateRestored && !accessState.key && !isRedirectedToAccessPage) {
      setIsRedirectedToAccessPage(true)

      navigate(`/access`)
    }
  }, [
    isStateRestored,
    accessState,
    accessState.key,
    navigate,
    isRedirectedToAccessPage
  ])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      const filterOptionToTableMap =
        await apiController.getFilterOptionToTableMap()

      if (isMounted) {
        if (
          !compareObjects(filterState.optionToTableMap, filterOptionToTableMap)
        ) {
          dispatch(setFilterOptionToTableMapAction(filterOptionToTableMap))
        }

        const tempVarToTable: any = {}
        let tempOptionsForSelectionToBeProcessed = []
        let next: any = {}

        filterOptionToTableMap.forEach((item) => {
          if (item.group !== next.label) {
            tempOptionsForSelectionToBeProcessed.push(next)
            next = { label: item.group, options: {} }
          }

          next.options[item.key] = { label: item.label, table: item.table }
        })

        tempOptionsForSelectionToBeProcessed.push(next)
        tempOptionsForSelectionToBeProcessed =
          tempOptionsForSelectionToBeProcessed.slice(1)

        tempOptionsForSelectionToBeProcessed.forEach((opt) => {
          Object.keys(opt.options).forEach((key) => {
            tempVarToTable[key] = opt.options[key].table
          })
        })

        const subjects = await apiController.getSubjects()

        // handle fetched data
        if (isMounted) {
          dispatch(setSelectedSubjectsAction(subjects))

          setSelectedSubjects(subjects)
          setVarToTable(tempVarToTable)
          setOptionsForSelectionToBeProcessed(
            tempOptionsForSelectionToBeProcessed
          )
        }

        const filterObject = await apiController.getFilterObject()

        // handle fetched data
        if (isMounted && !compareObjects(filterState.object, filterObject)) {
          dispatch(setFilterObjectAction(filterObject))
        }
      }
    }

    if (studyDB) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [studyDB, dispatch, filterState])

  useEffect(() => {
    if (studyDB) {
      sql(
        studyDB,
        `SELECT name FROM sqlite_schema WHERE type='table' ORDER BY name`
      ).then((res) => {
        if (res.data.length > 0) {
          const listOfTableNames = res.data.map((item: any) => item.name) // get list of tables

          setListOfTables(listOfTableNames)

          let tempRowCounts = {}

          listOfTableNames.forEach((t: any) => {
            sql(studyDB, `SELECT count(1) as ${t} FROM ${t}`).then((res2) => {
              const item = res2.data[0]

              tempRowCounts = { ...tempRowCounts, ...item }

              setRowCounts(tempRowCounts)
            })
          })

          setSelectedOptions({}) // reset selections

          // FIXME
          // getSubjects(studyDatabase, {}, varToTable).then((res) => {
          //   setSelectedSubjects(res)
          // })
        }
      })
    }
  }, [studyDB])

  // create filter lists
  useEffect(() => {
    if (studyDB && optionsForSelectionToBeProcessed && listOfTables) {
      optionsForSelectionToBeProcessed.forEach((opt: any) => {
        Object.keys(opt.options).forEach((key) => {
          const table = opt.options[key].table

          // check if key is in table
          const sqlStatement = `SELECT sql FROM sqlite_schema WHERE name = '${table}'`

          sql(studyDB, sqlStatement).then((res) => {
            const vars =
              res.data.length > 0
                ? res.data[0]['sql']
                    .replace(/ /g, ',')
                    .replace(/\(/g, '')
                    .replace(/\)/g, '')
                    .split(',')
                : []

            if (listOfTables.includes(table) && vars.includes(key)) {
              sql(
                studyDB,
                `SELECT DISTINCT ${key} FROM ${table} ORDER BY ${key}`
              ).then((res) => {
                if (res.message === 'success') {
                  opt.options[key].options = res.data.map((item: any) => ({
                    value: item[key],
                    label:
                      key === 'AEREL'
                        ? item[key] === 'NONE'
                          ? 'UNRELATED'
                          : item[key] === ''
                          ? 'UNDETERMINED'
                          : item[key]
                        : item[key]
                  }))
                } else {
                  opt.options[key].options = []
                }
              })
            } else {
              opt.options[key].options = []
            }
          })
        })

        setOptionsForSelection(optionsForSelectionToBeProcessed)
      })
    }
  }, [studyDB, optionsForSelectionToBeProcessed, listOfTables])

  // remove keys with empty content
  useEffect(() => {
    Object.keys(selectedOptions).forEach((key) => {
      if (selectedOptions[key].length === 0) {
        delete selectedOptions[key]
      }
    })
  }, [selectedOptions])

  // check for lab alerts
  useEffect(() => {
    if (studyDB && alerts && labCategories && !alertResults) {
      checkLabAlerts(
        alerts,
        setWaiting,
        studyDB,
        labCategories,
        setAlertResults
      )
    }
  }, [studyDB, alerts, labCategories, alertResults])

  // get labCategories
  useEffect(() => {
    if (studyDB) {
      sql(studyDB, `SELECT * FROM labcategories`).then((res) => {
        if (res.message === 'success') {
          const tempLabCategories: any = {}

          res.data.forEach(
            (row: any) => (tempLabCategories[row.code] = row.category)
          )

          setLabCategories(tempLabCategories)
        }
      })
    }
  }, [studyDB])

  // get normalRanges
  useEffect(() => {
    if (studyDB) {
      sql(
        studyDB,
        `SELECT * FROM vitalsignslimits union all select * from lablimits`
      ).then((res) => {
        if (res.message === 'success') {
          const tempNormalRanges: any = {}

          res.data.forEach((row: any) => {
            if (!(row.code in tempNormalRanges)) tempNormalRanges[row.code] = {}

            tempNormalRanges[row.code].low = row.LLN
            tempNormalRanges[row.code].high = row.ULN
          })

          setNormalRanges(tempNormalRanges)
        }
      })
    }
  }, [studyDB])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const variableLabels = await apiController.getVariableLabels()

      // handle fetched data
      if (isMounted) {
        const variableLabelsObject: VariableLabelsObject = {}

        variableLabels.forEach((labelObj) => {
          const { table, label, variable } = labelObj

          if (!variableLabelsObject[table]) variableLabelsObject[table] = {}

          variableLabelsObject[table][variable] = label
        })

        if (!compareObjects(variablesState.labels, variableLabelsObject)) {
          dispatch(setVariableLabelsAction(variableLabelsObject))
        }
      }
    }

    if (studyDB) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [studyDB, dispatch, variablesState.labels])

  useEffect(() => {
    if (accessState.key) {
      accessKey = accessState.key
    }
  }, [accessState.key])

  return accessState.key ? (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            '& .MuiContainer-maxWidthLg': {
              maxWidth: '100%'
            },
            maxWidth: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            borderRadius: 1,
            p: 3
          }}
        >
          {studyDB && selectedSubjects && userName && (
            <div className="App">
              <TopMenu
                alertResults={alertResults}
                setAlertResults={setAlertResults}
                labCategories={labCategories}
                showAe={showAe}
                showDm={showDm}
                showDs={showDs}
                showLb={showLb}
                showVs={showVs}
                selectedOptions={selectedOptions}
                listOfTables={listOfTables}
                setSelectedOptions={setSelectedOptions}
                optionsForSelection={optionsForSelection}
                varToTable={varToTable}
                setSelectedSubjects={setSelectedSubjects}
                selectedSubjects={selectedSubjects}
                theme={theme}
                userName={userName}
                userType={userType}
                setOpenInfoModal={setOpenInfoModal}
                setOpenMakeNotesModal={setOpenMakeNotesModal}
                setOpenViewNotesModal={setOpenViewNotesModal}
                setTheme={colorMode.toggleColorMode}
                apiController={apiController}
              />
              <Grid container spacing={2}>
                <Grid item xs={2} xl={1}>
                  <Tools
                    theme={theme}
                    mode={mode}
                    showAe={showAe}
                    showCm={showCm}
                    showDm={showDm}
                    showDs={showDs}
                    showLb={showLb}
                    showMh={showMh}
                    showVd={showVd}
                    showVs={showVs}
                  />
                </Grid>
                <Grid item xs={10} xl={11} sx={{ mb: 1 }}>
                  <Routes>
                    {/* General */}
                    <Route
                      path="/"
                      element={<Overview selectedOptions={selectedOptions} />}
                    />
                    <Route
                      path="/home"
                      element={
                        <Home
                          rowCounts={rowCounts} // FIXME: should be in state
                          apiController={apiController}
                        />
                      }
                    />
                    <Route
                      path="/subjects"
                      element={
                        <SubjectTable
                          selectedSubjects={selectedSubjects}
                          detail={true}
                          theme={theme}
                          apiController={apiController}
                        />
                      }
                    />
                    <Route
                      path="/tools/:id"
                      element={
                        <Tools
                          selectedOptions={selectedOptions}
                          selectedSubjects={selectedSubjects}
                        />
                      }
                    />
                    <Route
                      path="testprofile/:studyDatabase/:category/:test/:subjid"
                      element={
                        <TestProfile
                          labCategories={labCategories}
                          theme={theme}
                        />
                      }
                    />
                    <Route
                      path="testdayprofile/:studyDatabase/:date/:test/:subjid"
                      element={<TestDayProfile theme={theme} />}
                    />
                    <Route
                      path="patientprofile/:studyDatabase/:id"
                      element={
                        <PatientProfile
                          theme={theme}
                          apiController={apiController}
                        />
                      }
                    />
                    <Route
                      path="/patient-profiles/#/patientprofile/:studyDatabase/:id"
                      element={
                        <PatientProfile
                          theme={theme}
                          apiController={apiController}
                        />
                      }
                    />
                    <Route
                      path="view/:table"
                      element={<ViewData apiController={apiController} />}
                    />
                    <Route
                      path="preferences"
                      element={<Preferences apiController={apiController} />}
                    />
                    <Route
                      path="/viewnotes"
                      element={<ViewNotes apiController={apiController} />}
                    />

                    {/* LB */}

                    <Route
                      path="/lbbox"
                      element={
                        <LBBoxplot
                          theme={theme}
                          selectedOptions={selectedOptions}
                          selectedSubjects={selectedSubjects}
                        />
                      }
                    />
                    <Route
                      path="/lbboxplot"
                      element={
                        <LBboxPlot
                          apiController={apiController}
                          theme={theme}
                        />
                      }
                    />
                    <Route
                      path="/lbtable"
                      element={
                        <LBTable
                          theme={theme}
                          labCategories={labCategories}
                          selectedOptions={selectedOptions}
                          selectedSubjects={selectedSubjects}
                        />
                      }
                    />
                    <Route
                      path="/lbboth"
                      element={
                        <LBBoth theme={theme} apiController={apiController} />
                      }
                    />
                    <Route
                      path="/hyslaw"
                      element={
                        <LBHysLaw theme={theme} apiController={apiController} />
                      }
                    />

                    {/* VS */}

                    <Route
                      path="/vsboxplot"
                      element={
                        <VSboxPlot
                          normalRanges={normalRanges}
                          selectedOptions={selectedOptions}
                          selectedSubjects={selectedSubjects}
                          theme={theme}
                          params={{
                            table: 'vs',
                            categoryVariable: 'VSTESTCD',
                            categoryVariableLabel: 'VSTEST',
                            xCategoryValues: ['PULSE'],
                            codeOptions: [
                              { value: 'PULSE', label: 'PULSE' },
                              { value: 'SYSBP', label: 'SYSBP' },
                              { value: 'DIABP', label: 'DIABP' }
                            ],
                            dmOptions: [
                              { value: 'ARMCD', label: 'Arm Code' },
                              { value: 'SEX', label: 'Sex' },
                              { value: 'SUBJID', label: 'Subject ID' },
                              { value: 'AGE', label: 'Age' },
                              { value: 'RACE', label: 'Race' },
                              { value: 'ETHNIC', label: 'Ethnicity' },
                              { value: 'SITEID', label: 'Site' }
                            ],
                            widthOptions: [
                              { value: '12', label: 'Full' },
                              { value: '6', label: '1/2' },
                              { value: '4', label: '1/3' },
                              { value: '3', label: '1/4' },
                              { value: '2', label: '1/6' },
                              { value: '1', label: '1/12' }
                            ],
                            xVariable: 'VSDY',
                            yVariable: 'VSSTRESN'
                          }}
                          apiController={apiController}
                        />
                      }
                    />
                    <Route
                      path="/vsbp"
                      element={
                        <VSBloodPressure
                          normalRanges={normalRanges}
                          selectedOptions={selectedOptions}
                          selectedSubjects={selectedSubjects}
                        />
                      }
                    />
                    <Route
                      path="/vsobp"
                      element={
                        <VSOBloodPressure
                          normalRanges={normalRanges}
                          selectedOptions={selectedOptions}
                          selectedSubjects={selectedSubjects}
                        />
                      }
                    />
                    <Route
                      path="/vspulse"
                      element={
                        <VSPulse
                          normalRanges={normalRanges}
                          selectedOptions={selectedOptions}
                          selectedSubjects={selectedSubjects}
                        />
                      }
                    />
                    <Route
                      path="/vsopulse"
                      element={
                        <VSOPulse
                          normalRanges={normalRanges}
                          selectedOptions={selectedOptions}
                          selectedSubjects={selectedSubjects}
                        />
                      }
                    />
                    <Route
                      path="/vslisting"
                      element={
                        <VSListing
                          apiController={apiController}
                          normalRanges={normalRanges}
                        />
                      }
                    />

                    {/* DM */}

                    <Route
                      path="/dminfo"
                      element={
                        <DMInfo
                          apiController={apiController}
                          detail={true}
                          theme={theme}
                        />
                      }
                    />

                    {/* VD */}

                    <Route
                      path="/vdinfo"
                      element={
                        <VDInfo theme={theme} apiController={apiController} />
                      }
                    />

                    {/* DS */}

                    <Route
                      path="/dsinfo"
                      element={
                        <DSInfo apiController={apiController} theme={theme} />
                      }
                    />
                    <Route
                      path="/dssankey"
                      element={
                        <DSSankey apiController={apiController} theme={theme} />
                      }
                    />

                    {/* AE */}

                    <Route
                      path="/aeboth"
                      element={
                        <AEBoth apiController={apiController} theme={theme} />
                      }
                    />
                    <Route
                      path="/ae"
                      element={
                        <AdverseEventsGraph
                          theme={theme}
                          selectedOptions={selectedOptions}
                          selectedSubjects={selectedSubjects}
                        />
                      }
                    />
                    <Route
                      path="/aestreamgraph"
                      element={
                        <AEStreamGraph
                          apiController={apiController}
                          theme={theme}
                        />
                      }
                    />
                    <Route
                      path="/drill"
                      element={
                        <AEDrilldown
                          apiController={apiController}
                          theme={theme}
                        />
                      }
                    />
                    <Route
                      path="/aesunburst"
                      element={
                        <AESunburst
                          apiController={apiController}
                          theme={theme}
                        />
                      }
                    />
                    <Route
                      path="/mhsunburst"
                      element={
                        <MHSunburst
                          theme={theme}
                          apiController={apiController}
                        />
                      }
                    />

                    {/* R & D */}

                    <Route path="/testnew" element={<TestNew />} />
                    <Route
                      path="/testgraph"
                      element={
                        <TestGraph
                          selectedOptions={selectedOptions}
                          setSelectedSubjects={setSelectedSubjects}
                          selectedSubjects={selectedSubjects}
                        />
                      }
                    />
                    <Route
                      path="/testhighchart"
                      element={
                        <TestHighChart
                          selectedOptions={selectedOptions}
                          setSelectedSubjects={setSelectedSubjects}
                          selectedSubjects={selectedSubjects}
                        />
                      }
                    />
                    <Route
                      path="/testtable"
                      element={
                        <TestTable
                          selectedOptions={selectedOptions}
                          setSelectedSubjects={setSelectedSubjects}
                          selectedSubjects={selectedSubjects}
                        />
                      }
                    />
                    <Route path="diagrams" element={<Diagrams />} />
                    <Route path="viewspark" element={<ViewSpark />} />
                    <Route path="/openai" element={<Openai />} />
                    <Route
                      path="/query"
                      element={<Query apiController={apiController} />}
                    />
                    <Route
                      path="/aereports"
                      element={<AEReports apiController={apiController} />}
                    />
                    <Route path="/reporting" element={<Reporting />} />
                    <Route path="/files" element={<Files />} />
                    <Route path="/:id" element={<GetDataFromUrl />} />
                    <Route path="about" element={<About />} />
                    <Route path="/demo" element={<Demo />} />
                    <Route path="/ecrf" element={<Ecrf />} />
                    <Route path="/rdemo" element={<Rdemo />} />
                    <Route path="/pythondemo" element={<Pythondemo />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Grid>
              </Grid>
              {/* The following code block is commented out because of https://git.4gl.io/xploratum/client/-/issues/14. */}
              {/* <MakeNotes
              setOpenViewNotesModal={setOpenViewNotesModal}
              screenWidth={screenWidth}
              userName={userName}
            /> */}
              <History className={'stickToBottom'} />
            </div>
          )}
          <InfoModal
            openInfoModal={openInfoModal}
            setOpenInfoModal={setOpenInfoModal}
          />
          <ViewNotesModal
            openViewNotesModal={openViewNotesModal}
            setOpenViewNotesModal={setOpenViewNotesModal}
            apiController={apiController}
          />
          <MakeNotesModal
            openMakeNotesModal={openMakeNotesModal}
            setOpenMakeNotesModal={setOpenMakeNotesModal}
            apiController={apiController}
          />
        </Container>
      </ThemeProvider>
    </ColorModeContext.Provider>
  ) : (
    <AccessPage apiController={apiController} keyParam={accessKey} />
  )
}
