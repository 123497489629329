import { FilterOptions } from '../types'

export const filterOptionsToString = (
  selectedOptions: FilterOptions
): string => {
  const keys = Object.keys(selectedOptions)

  return keys
    .map(
      (key) =>
        key +
        ' IN (' +
        selectedOptions[key].map((item) => "'" + item.value + "'").join(',') +
        ')'
    )
    .join(' AND ')
}
