import * as ActionTypes from '../actionTypes'
import {
  SetAccessKey,
  SetUserNameBasedOnAccessKey,
  SetAccessKeyAndUserName,
  AccessKeyAndUserName
} from './types'

export const setAccessKeyAction = (payload: string): SetAccessKey => ({
  type: ActionTypes.SET_ACCESS_KEY,
  payload
})

export const setUserNameBasedOnAccessKeyAction = (
  payload: string
): SetUserNameBasedOnAccessKey => ({
  type: ActionTypes.SET_USERNAME_BASED_ON_ACCESS_KEY,
  payload
})

export const setAccessKeyAndUserNameAction = (
  payload: AccessKeyAndUserName
): SetAccessKeyAndUserName => ({
  type: ActionTypes.SET_ACCESS_KEY_AND_USERNAME,
  payload
})
