import FusionCharts from 'fusioncharts'

/**
 * Configures Fusion Chart.
 * License key is applied as part of configuration.
 */
export const configureFusionChart = () => {
  ;(FusionCharts.options as any).license({
    key: process.env.REACT_APP_FUSION_CHARTS_LICENSE_KEY,
    creditLabel: false
  })
}
