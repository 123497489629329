import { Tooltip } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import html2canvas from 'html2canvas'
import { downloadImage } from '../../utils/file'

const ChartDownload = (props: {
  elementId: string
  fileName: string
  style?: { [key: string]: string | number }
}) => {
  const { elementId, fileName, style } = props

  return (
    <Tooltip title="Download Chart">
      <DownloadIcon
        style={
          style
            ? style
            : { position: 'absolute', top: 100, right: 25, cursor: 'pointer' }
        }
        color="primary"
        onClick={() => {
          const element = document.getElementById(elementId)

          if (element) {
            html2canvas(element).then((canvas) => {
              downloadImage(canvas, fileName)
            })
          } else {
            throw new Error(`Element with id '${elementId}' wasn't found.`)
          }
        }}
      />
    </Tooltip>
  )
}

export default ChartDownload
