import { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@mui/material'
import Select from 'react-select'
import { globalFilter, addToHistory, getStats } from '../../../apis/utility'
import { useLocation } from 'react-router-dom'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HighchartMore from 'highcharts/highcharts-more'
import AnnotationsModule from 'highcharts/modules/annotations'
import SubjectTable from '../../SubjectTable'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector } from '../../../hooks'
import { ApiController } from '../../../controllers/ApiController'
import { LBboxData } from '../../../types'

HighchartMore(Highcharts)
AnnotationsModule(Highcharts)

interface DMInfoProps {
  apiController: ApiController
  theme: { [key: string]: any }
}

export default function LBboxPlot(props: DMInfoProps) {
  const { apiController, theme } = props

  const chartContainerId = `lb-box-chart-container`

  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)
  const lbRangesState = useAppSelector((state) => state.lb.ranges)

  const [cachedData, setCachedData] = useState<LBboxData>()
  const [waiting, setWaiting] = useState(false)
  const [dy, setDy] = useState()
  const [selectedSubjectsByCategory, setSelectedSubjectsByCategory] =
    useState<string[]>()
  const [showSubjects, setShowSubjects] = useState(false)
  const [codeOptions, setCodeOptions] = useState([
    { value: 'ALT', label: 'ALT' },
    { value: 'AST', label: 'AST' }
  ])
  const [selectedOption, setSelectedOption] = useState({
    value: 'ARMCD',
    label: 'Arm Code'
  })
  const [selectedWidth, setSelectedWidth] = useState({
    value: '4',
    label: '1/3'
  })
  const [selectedCodes, setSelectedCodes] = useState(null)
  const [xCategoryValues, setXCategoryValues] = useState(['ALT'])

  const changeSelectedOption = (e: any) => {
    setSelectedOption(e)
  }
  const changeWidth = (e: any) => {
    setSelectedWidth(e)
  }
  const changeCodes = (e: any) => {
    if (e && e[0] && typeof e === 'object') {
      const newXCategoryValues = e.map((e: any) => e.value)

      setSelectedCodes(e)
      setXCategoryValues(newXCategoryValues)
    } else if (e && e.length === 0) {
      setSelectedCodes(null)
    }
  }

  const location = useLocation()

  const [whereValues, setWhereValues] = useState<string[]>()
  const [dataSources, setDataSources] = useState()

  addToHistory({ title: 'Boxplot', url: location })

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const data = await apiController.getLBtests()

      // handle fetched data
      if (isMounted) {
        const newCodeOptions = data
          .map((item) => ({
            value: item.LBTESTCD,
            label: item.LBTEST
          }))
          .sort((a: any, b: any) => a.value.localeCompare(b.value))

        setCodeOptions(newCodeOptions)

        if (
          window.location.href.endsWith('lbboxplot') &&
          selectedFilterState &&
          selectedFilterState.LBTEST &&
          selectedFilterState.LBTEST.length
        ) {
          const globalSelectedOptions = newCodeOptions.filter((code: any) =>
            selectedFilterState.LBTEST.find(
              (option: any) => option.label === code.label
            )
          )

          changeCodes(globalSelectedOptions)
        } else {
          changeCodes([newCodeOptions[0]])
        }

        const newWhereValues = newCodeOptions.map((item: any) => item.value)

        setWhereValues(newWhereValues)
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, selectedFilterState])

  useEffect(() => {
    if (cachedData) {
      const dmVariableValues = cachedData
        .map((row: any) => row[selectedOption.value])
        .filter((v: any, i: any, a: any) => a.indexOf(v) === i)
        .filter((value: string) => value)

      // make categories for x-axis
      const currentXCategoryValues = [
        ...new Set(
          cachedData
            .filter((row: any) => xCategoryValues.includes(row.LBTESTCD))
            .map((row: any) => Number(row.LBDY))
        )
      ].sort((a: any, b: any) => a - b)

      const xCategories = [
        {
          category: currentXCategoryValues.map((item: any) => {
            return { label: item.toString() }
          })
        }
      ]

      // make a collection of parameters for each dm value
      const tempDataSources: any = {}

      dmVariableValues.forEach((dmVariableValue: any) => {
        // make series which determines what is actually plotted
        const series = xCategoryValues.map((testCode) => ({
          name: testCode,
          data: xCategories[0].category.map((category) => {
            const data1 = cachedData.filter(
              (row: any) =>
                row.LBTESTCD === testCode &&
                row.LBDY === category.label &&
                row[selectedOption.value] === dmVariableValue
            )

            const values = data1.map((item: any) => Number(item.LBSTRESN))
            const boxValues = getStats(values)

            return boxValues
          }),
          point: {
            events: {
              click: function () {
                const day = (this as any).category

                setDy(day)

                apiController.getLBsubjects(day).then((subjects) => {
                  setSelectedSubjectsByCategory(subjects.map((s) => s.SUBJID))
                  setShowSubjects(!!subjects.length)
                })
              }
            }
          }
        }))

        const { low, high } =
          xCategoryValues[0] && xCategoryValues[0] in lbRangesState!
            ? lbRangesState![xCategoryValues[0]]
            : { low: null, high: null }

        const selectedData = cachedData.filter((row: any) =>
          xCategoryValues.includes(row.LBTESTCD)
        )

        const values = selectedData.map((item: any) => Number(item.LBSTRESN))

        const convert = (n: any) => {
          const order =
            n > 0 ? Math.floor(Math.log(n) / Math.LN10 + 0.000000001) : null

          return order ? Math.pow(10, order - 1) : 1
        }

        const max = Math.max(...values)
        const min = Math.min(...values)

        const roundUp = convert(max)
        const roundDown = convert(min)

        const yAxisMaxValue = Math.ceil(Math.max(...values) / roundUp) * roundUp
        const yAxisMinValue = roundDown
          ? Math.floor(Math.min(...values) / roundDown) * roundDown
          : 0

        const title = `${xCategoryValues.map((item) => item).join(', ')}`

        const dataSource = {
          chart: { type: 'boxplot' },
          credits: {
            enabled: false
          },
          exporting: { enabled: false },
          title: {
            text: `${title} (${selectedStudyState?.STUDYID}) [${dmVariableValue}]`
          },
          subtitle: { text: globalFilter(selectedFilterState) || '' },
          legend: { enabled: true },
          colors: [
            '#058DC7',
            '#50B432',
            '#ED561B',
            '#DDDF00',
            '#24CBE5',
            '#64E572',
            '#FF9655',
            '#FFF263',
            '#6AF9C4'
          ],
          series: series.map((use) => use),
          xAxis: {
            categories: xCategories[0].category.map((item) => item.label),
            title: {
              text: 'Day of Treatment'
            }
          },
          yAxis: {
            min: yAxisMinValue,
            max: yAxisMaxValue,
            title: { text: title },
            plotBands: [
              {
                from: low,
                to: high,
                color: 'white',
                label: {
                  text: 'Normal',
                  style: {
                    color: '#606060'
                  }
                }
              }
            ]
          }
        }

        tempDataSources[dmVariableValue] = dataSource
      })

      setDataSources(tempDataSources)
      setWaiting(false)
    }
  }, [
    cachedData,
    lbRangesState,
    selectedFilterState,
    selectedOption.value,
    selectedStudyState?.STUDYID,
    xCategoryValues,
    apiController
  ])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true
      setWaiting(true)

      if (whereValues) {
        // call async func to fetch data
        const data = await apiController.getLBrecords(whereValues)

        // handle fetched data
        if (isMounted) {
          setCachedData(data)
        }
      }
    }

    if (!cachedData) {
      fetchData()
    }

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [
    apiController,
    whereValues,
    lbRangesState,
    selectedOption.value,
    selectedStudyState?.STUDYID,
    xCategoryValues,
    selectedFilterState,
    cachedData
  ])

  return (
    <>
      <Grid container spacing={1} id={chartContainerId}>
        <Grid item xs={4}>
          <Select
            id="dmSelect"
            options={[
              { value: 'ARMCD', label: 'Arm Code' },
              { value: 'SEX', label: 'Sex' },
              { value: 'SUBJID', label: 'Subject ID' },
              { value: 'AGE', label: 'Age' },
              { value: 'RACE', label: 'Race' },
              { value: 'ETHNIC', label: 'Ethnicity' },
              { value: 'SITEID', label: 'Site' }
            ]}
            value={selectedOption}
            onChange={(e) => changeSelectedOption(e)}
          ></Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            options={codeOptions}
            isMulti
            id={'lbcodeSelect'}
            closeMenuOnSelect={false}
            value={selectedCodes}
            onChange={(e) => changeCodes(e)}
            placeholder="Select test or measurement"
          ></Select>
        </Grid>

        <Grid item xs={4}>
          <Select
            options={[
              { value: '12', label: 'Full' },
              { value: '6', label: '1/2' },
              { value: '4', label: '1/3' },
              { value: '3', label: '1/4' },
              { value: '2', label: '1/6' },
              { value: '1', label: '1/12' }
            ]}
            value={selectedWidth}
            onChange={(e) => changeWidth(e)}
          ></Select>
        </Grid>

        {waiting && (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        )}

        {selectedCodes && dataSources && selectedWidth.value
          ? Object.keys(dataSources).map((key, index) => (
              <Grid item xs={Number(selectedWidth.value)} key={index}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={dataSources[key]}
                />
              </Grid>
            ))
          : dataSources && selectedWidth.value
          ? 'loading'
          : null}
        {dataSources && (
          <ChartDownload
            elementId={chartContainerId}
            fileName={`LB-Box`}
            style={{
              position: 'relative',
              left: 'calc(100% - 35px)',
              bottom: '-5px',
              cursor: 'pointer'
            }}
          />
        )}
      </Grid>
      {showSubjects && selectedSubjectsByCategory && (
        <SubjectTable
          selectedSubjects={selectedSubjectsByCategory}
          filterOptions={[{ key: 'DY', value: dy }]}
          theme={theme}
          apiController={apiController}
        />
      )}
    </>
  )
}
