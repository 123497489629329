import * as ActionTypes from '../actionTypes'
import { PreferencesState, PreferencesDispatchTypes } from './types'

const initialState: PreferencesState = {
  screen: null,
  vitalSigns: null,
  drillDownPath: null
}

const reducer = (
  state = initialState,
  action: PreferencesDispatchTypes
): PreferencesState => {
  switch (action.type) {
    case ActionTypes.SET_PREFERENCES_SCREEN:
      return {
        ...state,
        screen: action.payload
      }

    case ActionTypes.SET_PREFERENCES_VITAL_SIGNS:
      return {
        ...state,
        vitalSigns: action.payload
      }

    case ActionTypes.SET_PREFERENCES_DRILLDOWN_PATH:
      return {
        ...state,
        drillDownPath: action.payload
      }

    case ActionTypes.RESTORE_STATE:
      return action.payload.preferences || null

    default:
      return state
  }
}

export default reducer
