import React, { useEffect, useState } from 'react'
import { Divider } from '@mui/material'
import Select from 'react-select'
import { getSubjects } from '../../apis/utility'

// FIXME: throws error when Global Filter Builder is used in right-hand side
function GenerateSelectsWithinAnArea(props: any, area: any) {
  const {
    selectedOptions,
    varToTable,
    setSelectedSubjects,
    setSelectedOptions,
    studyDatabase,
    selectedSubjects
  } = props

  const [options, setOptions] = useState(selectedOptions)

  const changeSelectedOptions = (e: any, key: any) => {
    const newOptions = { ...selectedOptions, [key]: e }

    setOptions(newOptions)
  }

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: '2px dotted gray',
      color: state.isSelected ? 'green' : 'black',
      padding: 0
    })
  }

  // FIXME
  // when user changes the selected options, update the selected subjects
  useEffect(() => {
    getSubjects(studyDatabase, options, varToTable).then((res) => {
      if (
        JSON.stringify(selectedSubjects.sort()) !== JSON.stringify(res.sort())
      ) {
        setSelectedSubjects(res)
      }

      setSelectedOptions(options)
    })
  }, [
    options,
    studyDatabase,
    varToTable,
    setSelectedSubjects,
    selectedSubjects,
    setSelectedOptions
  ])

  return Object.keys(area.options).map((key) => {
    const option = area.options[key]
    const theseSelectedOptions = selectedOptions[key] || null

    return (
      <React.Fragment key={key}>
        <b>{option.label}</b>
        <Select
          key={key}
          styles={customStyles}
          closeMenuOnSelect={false}
          isMulti
          options={option.options}
          value={theseSelectedOptions}
          // color={'black'}
          onChange={(e) => changeSelectedOptions(e, key)}
        ></Select>
      </React.Fragment>
    )
  })
}

export function GenerateSelects(props: any) {
  const { optionsForSelection } = props

  return optionsForSelection.map((area: any, index: any) => (
    <React.Fragment key={index}>
      <Divider>
        <b>{area.label}</b>
      </Divider>
      {GenerateSelectsWithinAnArea(props, area)}
    </React.Fragment>
  ))
}
