import { useState, useEffect } from 'react'
import { globalFilter, addToHistory } from '../../../apis/utility'
import { Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'
import SubjectTable from '../../SubjectTable'
import ReactFC from 'react-fusioncharts'
import FusionCharts from 'fusioncharts'
import excelexport from 'fusioncharts/fusioncharts.excelexport'
import Charts from 'fusioncharts/fusioncharts.charts'
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel'
import { LookBelow } from '../../layout/LookBelow'
import FusionChartJSX from '../../ui/FusionChartJSX'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector } from '../../../hooks'
import { ApiController } from '../../../controllers/ApiController'

ReactFC.fcRoot(FusionCharts, Charts, GammelTheme, excelexport)

interface DMInfoProps {
  apiController: ApiController
  theme: { [key: string]: any }
  detail: boolean
}

export default function DMInfo(props: DMInfoProps) {
  const { apiController, detail, theme } = props

  const chartContainerId = 'dm-info-chart-container'

  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)
  const screenState = useAppSelector((state) => state.preferences.screen)

  const location = useLocation()
  addToHistory({ title: 'DM Info', url: location })

  const [subjectsDrilledTo, setSubjectsDrilledTo] = useState<any>(null)
  const [subtitle, setSubtitle] = useState<any>(null)
  const [dataAge, setDataAge] = useState<any>(null)
  const [dataSex, setDataSex] = useState<any>(null)
  const [dataPkpd, setDataPkpd] = useState<any>(null)
  const [dataCountry, setDataCountry] = useState<any>(null)
  const [dataRace, setDataRace] = useState<any>(null)
  const [dataEthnic, setDataEthnic] = useState<any>(null)
  const [dataSite, setDataSite] = useState<any>(null)
  const [dataArmcd, setDataArmcd] = useState<any>(null)
  const [selectedSubjectQuery, setSelectedSubjectsQuery] = useState<string>()

  useEffect(() => {
    let isMounted = false

    setSubtitle(globalFilter(selectedFilterState) || '')

    const fetchData = async () => {
      isMounted = true

      const subjects = await apiController.getDMsubjects()

      if (isMounted) {
        const subjectIds = subjects.map((item) => item.SUBJID)

        const commonChartOptions = {
          subcaption: subtitle,
          bgColor: 'EEEEEE,CCCCCC',
          bgratio: '60,40',
          bgAlpha: '70,80',
          bgAngle: '180'
        }

        const ages = await apiController.getDMages(subjectIds)
        const sex = await apiController.getDMarea('SEX', subjectIds)
        const pkpd = await apiController.getDMpkpd(subjectIds)
        const country = await apiController.getDMarea('COUNTRY', subjectIds)
        const race = await apiController.getDMarea('RACE', subjectIds)
        const ethnic = await apiController.getDMarea('ETHNIC', subjectIds)
        const site = await apiController.getDMarea('SITE', subjectIds)
        const armcd = await apiController.getDMarea('ARMCD', subjectIds)

        if (isMounted) {
          setDataAge({
            chart: {
              caption: 'Age in years (' + selectedStudyState?.STUDYID + ')',
              xaxisname: 'Age in years',
              yaxisname: 'Number of Subjects',
              palettecolors: 'e4b293',
              ...commonChartOptions
            },
            data: Object.keys(ages[0]).map((key) => ({
              label: key,
              value: ages[0][key]
            }))
          })

          setDataSex({
            chart: {
              caption: 'Sex (' + selectedStudyState?.STUDYID + ')',
              yaxisname: 'Sex',
              xaxisname: 'Number of Subjects',
              palettecolors: 'FF33C3,3368FF',
              ...commonChartOptions
            },
            data: sex.map((item) => ({
              label: item.SEX,
              value: item.number
            }))
          })

          setDataPkpd({
            chart: {
              caption: 'PK,PD (' + selectedStudyState?.STUDYID + ')',
              yaxisname: 'PK,PD',
              xaxisname: 'Number of Subjects',
              palettecolors: 'FF33C3,3368FF',
              ...commonChartOptions
            },
            data: pkpd.map((item) => ({
              label: item.PKPD,
              value: item.number
            }))
          })

          setDataCountry({
            chart: {
              caption: 'Country (' + selectedStudyState?.STUDYID + ')',
              yaxisname: 'Country',
              xaxisname: 'Number of Subjects',
              palettecolors: 'FF33C3,3368FF',
              ...commonChartOptions
            },
            data: country.map((item) => ({
              label: item.COUNTRY,
              value: item.number
            }))
          })

          setDataRace({
            chart: {
              caption: 'Race (' + selectedStudyState?.STUDYID + ')',
              yaxisname: 'Race',
              xaxisname: 'Number of Subjects',
              ...commonChartOptions
            },
            data: race.map((item) => ({
              label: item.RACE,
              value: item.number
            }))
          })

          setDataEthnic({
            chart: {
              caption: 'Ethnic (' + selectedStudyState?.STUDYID + ')',
              yaxisname: 'Ethnic',
              xaxisname: 'Number of Subjects',
              ...commonChartOptions
            },
            data: ethnic.map((item) => ({
              label: item.ETHNIC,
              value: item.number
            }))
          })

          setDataSite({
            chart: {
              caption: 'Site (' + selectedStudyState?.STUDYID + ')',
              yaxisname: 'Site',
              xaxisname: 'Number of Subjects',
              ...commonChartOptions
            },
            data: site.map((item) => ({
              label: item.SITE,
              value: item.number
            }))
          })

          setDataArmcd({
            chart: {
              caption: 'Arm (' + selectedStudyState?.STUDYID + ')',
              yaxisname: 'Arm',
              xaxisname: 'Number of Subjects',
              ...commonChartOptions
            },
            data: armcd.map((item) => ({
              label: item.ARMCD,
              value: item.number
            }))
          })

          setSelectedSubjectsQuery(
            ` SUBJID in (${(subjectIds || [])
              .map((subject) => `'${subject}'`)
              .join(', ')})`
          )
        }
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [
    selectedFilterState,
    selectedStudyState?.STUDYID,
    subtitle,
    detail,
    apiController
  ])

  return (
    <>
      <div id={chartContainerId}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {dataAge ? (
              <FusionChartJSX
                type="bar2d"
                width="100%"
                height={detail ? (screenState?.height || 1) * 0.55 : '180'}
                dataFormat="JSON"
                dataSource={dataAge}
                events={{
                  dataPlotClick: function (e) {
                    const range = (e.data.id as string)
                      .split('-')
                      .map((range) => range.replace('[', '').replace(']', ''))

                    apiController
                      .getDMareaWithCondition(
                        `${selectedSubjectQuery} AND AGE>=${range[0]} AND AGE<${range[1]}`
                      )
                      .then((subjects) => {
                        setSubjectsDrilledTo(subjects.map((s) => s.SUBJID))
                      })
                  }
                }}
              />
            ) : (
              'loading'
            )}
          </Grid>
          <Grid item xs={3}>
            {dataSex ? (
              <FusionChartJSX
                type="pie2d"
                width="100%"
                height={detail ? (screenState?.height || 1) * 0.275 : '80'}
                dataFormat="JSON"
                dataSource={dataSex}
                events={{
                  dataPlotClick: function (e) {
                    apiController
                      .getDMareaWithCondition(
                        `${selectedSubjectQuery} AND SEX='${e.data.id}'`
                      )
                      .then((subjects) => {
                        setSubjectsDrilledTo(subjects.map((s) => s.SUBJID))
                      })
                  }
                }}
              />
            ) : (
              'loading'
            )}
            {dataPkpd ? (
              <FusionChartJSX
                type="pie2d"
                width="100%"
                height={detail ? (screenState?.height || 1) * 0.275 : '80'}
                dataFormat="JSON"
                dataSource={dataPkpd}
                events={{
                  dataPlotClick: function (e) {
                    const split = e.data.id.split(',')
                    const pkset = split[0]
                    const pdset = split[1]

                    apiController
                      .getDMareaWithCondition(
                        `${selectedSubjectQuery} AND PKSET='${pkset}' AND PDSET='${pdset}'`
                      )
                      .then((subjects) => {
                        setSubjectsDrilledTo(subjects.map((s) => s.SUBJID))
                      })
                  }
                }}
              />
            ) : (
              'loading'
            )}
          </Grid>
          <Grid item xs={3}>
            {dataArmcd ? (
              <FusionChartJSX
                type="pie2d"
                width="100%"
                height={detail ? (screenState?.height || 1) * 0.275 : '80'}
                dataFormat="JSON"
                dataSource={dataArmcd}
                events={{
                  dataPlotClick: function (e) {
                    apiController
                      .getDMareaWithCondition(
                        `${selectedSubjectQuery} AND ARMCD='${e.data.id}'`
                      )
                      .then((subjects) => {
                        setSubjectsDrilledTo(subjects.map((s) => s.SUBJID))
                      })
                  }
                }}
              />
            ) : (
              'loading'
            )}
            {dataCountry ? (
              <FusionChartJSX
                type="pie2d"
                width="100%"
                height={detail ? (screenState?.height || 1) * 0.275 : '80'}
                dataFormat="JSON"
                dataSource={dataCountry}
                events={{
                  dataPlotClick: function (e) {
                    apiController
                      .getDMareaWithCondition(
                        `${selectedSubjectQuery} AND COUNTRY='${e.data.id}'`
                      )
                      .then((subjects) => {
                        setSubjectsDrilledTo(subjects.map((s) => s.SUBJID))
                      })
                  }
                }}
              />
            ) : (
              'loading'
            )}{' '}
          </Grid>
          <Grid item xs={4}>
            {dataRace ? (
              <FusionChartJSX
                type="pie2d"
                width="100%"
                height={detail ? (screenState?.height || 1) * 0.2 : '80'}
                dataFormat="JSON"
                dataSource={dataRace}
                events={{
                  dataPlotClick: function (e) {
                    apiController
                      .getDMareaWithCondition(
                        `${selectedSubjectQuery} AND RACE='${e.data.id}'`
                      )
                      .then((subjects) => {
                        setSubjectsDrilledTo(subjects.map((s) => s.SUBJID))
                      })
                  }
                }}
              />
            ) : (
              'loading'
            )}
          </Grid>
          <Grid item xs={4}>
            {dataEthnic ? (
              <FusionChartJSX
                type="pie2d"
                width="100%"
                height={detail ? (screenState?.height || 1) * 0.2 : '80'}
                dataFormat="JSON"
                dataSource={dataEthnic}
                events={{
                  dataPlotClick: function (e) {
                    apiController
                      .getDMareaWithCondition(
                        `${selectedSubjectQuery} AND ETHNIC='${e.data.id}'`
                      )
                      .then((subjects) => {
                        setSubjectsDrilledTo(subjects.map((s) => s.SUBJID))
                      })
                  }
                }}
              />
            ) : (
              'loading'
            )}
          </Grid>
          <Grid item xs={4}>
            {dataSite ? (
              <FusionChartJSX
                type="pie2d"
                width="100%"
                height={detail ? (screenState?.height || 1) * 0.2 : '80'}
                dataFormat="JSON"
                dataSource={dataSite}
                events={{
                  dataPlotClick: function (e) {
                    apiController
                      .getDMareaWithCondition(
                        `${selectedSubjectQuery} AND SITE='${e.data.id}'`
                      )
                      .then((subjects) => {
                        setSubjectsDrilledTo(subjects.map((s) => s.SUBJID))
                      })
                  }
                }}
              />
            ) : (
              'loading'
            )}
          </Grid>
        </Grid>
        {dataAge && (
          <ChartDownload elementId={chartContainerId} fileName="DM-Info" />
        )}
      </div>
      {subjectsDrilledTo && (
        <LookBelow
          label="Subject Table"
          tooltip="Click to scroll to subject table appears below"
          mt={2}
          ml={4}
          mr={0}
          mb={0}
        />
      )}
      {subjectsDrilledTo && (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          theme={theme}
          apiController={apiController}
        />
      )}
    </>
  )
}
