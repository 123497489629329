import { useState, useEffect } from 'react'
import { sql, checkLabAlerts } from '../../apis/utility'
import { DataGridPro } from '@mui/x-data-grid-pro'
import {
  Button,
  Box,
  Checkbox,
  CircularProgress,
  Tooltip,
  IconButton
} from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'

export default function ShowAlerts(props: any) {
  const {
    alerts,
    labCategories,
    studyDatabase,
    selectedSubjects,
    alertResults,
    setAlertResults
  } = props

  const [gridData, setGridData] = useState(null)
  const [waiting, setWaiting] = useState(false)

  const renderProgress = (cellValues: any) => {
    const { value, row } = cellValues
    const backgroundColor = row.level === 'high' ? '#ffcccc' : '#b3d1ff'

    return (
      <Box
        sx={{ flexGrow: 1, backgroundColor: backgroundColor, color: 'black' }}
      >
        {value}
      </Box>
    )
  }

  const columns = [
    {
      width: 80,
      headerName: 'Subject',
      field: 'subjid',
      renderCell: (cellValues: any) => {
        const { value } = cellValues
        const target = `${window.location.protocol}//${window.location.host}/#/patientprofile/${studyDatabase}/${value}`

        return <Button onClick={() => window.open(target)}>{value}</Button>
      }
    },
    { width: 90, headerName: 'Test', field: 'test' },
    {
      width: 80,
      headerName: 'Value',
      field: 'value',
      renderCell: (cellValues: any) => {
        return renderProgress(cellValues) // removed 'PULSE' as 2nd argument
      }
    },
    {
      width: 40,
      headerName: 'Tests',
      field: 'labCategory',
      renderCell: (cellValues: any) => {
        const { value, row } = cellValues
        const { subjid, test } = row

        const target = `${window.location.protocol}//${window.location.host}/#/testprofile/${studyDatabase}/${value}/${test}/${subjid}`

        return (
          <Tooltip title={value}>
            <IconButton
              onClick={() => {
                window.open(target)
              }}
              color="info"
            >
              <LinkIcon />
            </IconButton>
          </Tooltip>
        )
      }
    },
    {
      width: 40,
      headerName: 'Day',
      field: 'lastdate',
      renderCell: (cellValues: any) => {
        const { row } = cellValues
        const { subjid, test } = row

        const target = `${window.location.protocol}//${window.location.host}/#/testdayprofile/${studyDatabase}/${row.date}/${test}/${subjid}`

        return (
          <Tooltip title={'View all tests for this subject on this day'}>
            <IconButton
              color="success"
              onClick={() => {
                window.open(target)
              }}
            >
              <LinkIcon />
            </IconButton>
          </Tooltip>
        )
      }
    },
    {
      width: 60,
      headerName: 'Checked',
      field: 'checked',
      renderCell: (cellValues: any) => {
        const { row } = cellValues

        return (
          <Checkbox
            onChange={(event) => handleCheck(event, row)}
            size="small"
            checked={cellValues.value}
          />
        )
      }
    },
    { width: 120, headerName: 'Date', field: 'date' }
  ]

  const handleCheck = (event: any, row: any) => {
    row.checked = event.target.checked

    // if checkbox ticked then add info about what was checked
    const sqlStatement = `insert into lbchecked (subjid,level,testcd,date) values ('${row.subjid}','${row.level}','${row.test}','${row.date}')`

    if (event.target.checked) {
      sql(studyDatabase, sqlStatement).then((res) => {
        console.log('inserted a row - res', res)
      })
    } else {
      const sqlStatement = `delete from lbchecked where subjid='${row.subjid}' and level='${row.level}' and testcd='${row.test}' and date='${row.date}'`

      sql(studyDatabase, sqlStatement).then((res) => {
        console.log('removed a row - res', res)
      })
    }
  }

  useEffect(() => {
    if (alertResults && selectedSubjects) {
      setGridData(
        alertResults
          .flat()
          .filter((x: any) => selectedSubjects.includes(x.subjid))
      )
    }
  }, [alertResults, selectedSubjects])

  return (
    <>
      {waiting && <CircularProgress />}
      {!waiting && (
        <>
          <Button
            onClick={() =>
              checkLabAlerts(
                alerts,
                setWaiting,
                studyDatabase,
                labCategories,
                setAlertResults
              )
            }
            size="small"
            sx={{
              flex: 1,
              color: 'text.primary',
              border: 2,
              '&:hover': { backgroundColor: 'primary.light' }
            }}
          >
            Refresh
          </Button>
          {gridData && columns ? (
            <div style={{ height: props.screenHeight * 0.4, width: '100%' }}>
              <DataGridPro
                rows={gridData}
                columns={columns}
                density={'compact'}
                rowHeight={30}
                sx={{ fontSize: '0.7em' }}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  )
}
