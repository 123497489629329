export const decodeAbbreviation = (abbreviation: string) => {
  if (typeof abbreviation !== 'string') return ''

  abbreviation = abbreviation.toUpperCase()

  const dict: { [key: string]: string } = {
    ID: `Identifier`,
    SEX: `Sex of the patient`,
    AGE: `Age of the patient`,
    ARMCD: `Arm Code (treatment group identifier)`,
    AE: `Adverse Event`,
    DM: `Demographics`,
    DS: `Disposition`,
    LB: `Laboratory Test Results`,
    VD: `Visit Day`,
    VS: `Vital Signs`,
    CM: `Concomitant Medications`,
    MH: `Medical History`,
    EX: `Exposure`,
    SC: `Subject Characteristics`,
    SE: `Subject Elements`,
    SV: `Subject Visits`,
    TA: `Trial Arms`,
    TE: `Trial Elements`,
    TI: `Trial Inclusion/Exclusion Criteria`,
    DOMAIN: `Type of dataset`,
    STUDYID: `Study Identifier`,
    SUBJID: `Subject Identifier`,
    CMTRT: `Treatment Name for Concomitant Medications`,
    CMINDC: `Concomitant Medication Indication (reason for taking the medication)`,
    CMDOSE: `Concomitant Medication Dose (amount taken)`,
    CMDOSU: `Concomitant Medication Dose Unit (mg, mL, etc.)`,
    CMDOSFRQ: `Concomitant Medication Dosing Frequency`,
    CMROUTE: `Concomitant Medication Route of Administration (oral, IV, etc.)`,
    CMONGO: `Concomitant Medication Ongoing`,
    CMCLAS: `Concomitant Medication Class`,
    VISITNUM: `Visit Number`,
    VISIT: `Visit Name`,
    VISITDY: `Visit Day`,
    EPOCH: `Study Epoch`,
    CMENDY: `Concomitant Medication End Day`,
    AELLTCD: `Adverse Event LLT Code (Lowest Level Term)`,
    AEPTCD: `Adverse Event PT Code (Preferred Term)`,
    AEHLT: `Adverse Event HLGT Code (High Level Group Term)`,
    AEHLTCD: `Adverse Event HLGT Code`,
    AEHLGTCD: `Adverse Event HLGT Code`,
    AEBDSYCD: `Adverse Event Body System Code`,
    AESOC: `Adverse Event System Organ Class`,
    AESOCCD: `Adverse Event SOC Code`,
    AESER: `Adverse Event Seriousness`,
    AEACN: `Adverse Event Action Taken`,
    AEREL: `Adverse Event Relationship`,
    AEOUT: `Adverse Event Outcome`,
    AESCAN: `Adverse Event Scan`,
    AESCONG: `Adverse Event Congenital Anomaly`,
    AESDISAB: `Adverse Event Disability`,
    AESDTH: `Adverse Event Death`,
    AESHOSP: `Adverse Event Hospitalization`,
    AESLIFE: `Adverse Event Life Threatening`,
    AESOD: `Adverse Event Other Significant`,
    AEPT: `Adverse Event Preferred Term`,
    AEDUR: `Adverse Event Duration`,
    AETE: `Adverse Event Term`,
    SUBJID2: `A secondary unique identifier for a study subject.`,
    RFXSTDTC: `The start date of a patient's treatment or study period.`,
    RFXENDTC: `The end date of a patient's treatment or study period.`,
    RFICDTC: `The date of informed consent for a patient's participation in the study.`,
    RFPENDTC: `The planned end date of the study treatment or period.`,
    DTHDTC: `The date of a patient's death, if applicable.`,
    DTHFL: `A flag indicating whether a patient has died.`,
    SITE: `The location or code of the clinic or facility where the study is conducted.`,
    AGEU: `The unit of measure for age (e.g., years, months).`,
    RACE: `The reported race of a study subject.`,
    ETHNIC: `The reported ethnicity of a study subject.`,
    ARM: `The treatment arm or group to which a study subject is assigned.`,
    ACTARMCD: `The actual treatment arm code the patient received, in case it differs from the planned arm.`,
    ACTARM: `The actual treatment arm or group the patient received.`,
    COUNTRY: `The country where the study subject is located.`,
    EXPOSED: `A flag indicating whether a subject was exposed to the study treatment.`,
    RANDOMIZED: `Indicates whether a subject was part of a randomized study group.`,
    SAF: `Safety population, related to subjects included in the safety analysis.`,
    PKSET: `Pharmacokinetic analysis set, related to subjects included in the pharmacokinetic analysis.`,
    PDSET: `Pharmacodynamic analysis set, related to subjects included in the pharmacodynamic analysis.`,
    DATE: `A general date field that could refer to various dates in the study.`,
    DSOTHSP: `Other specified disease or condition.`,
    DSLDT: `The last date of a disease-specific event or condition.`,
    LBORRES: `Laboratory Test Result - The actual result of the lab test.`,
    LBORRESU: `Laboratory Test Result Unit - The unit of measure for the lab test result.`,
    LBORNRLO: `Laboratory Test Normal Range Low - The lower limit of the normal range for the lab test.`,
    LBORNRHI: `Laboratory Test Normal Range High - The upper limit of the normal range for the lab test.`,
    LBSTRESC: `Laboratory Test Result Standardized Character - The test result as a standardized text value.`,
    LBSTRESN: `Laboratory Test Result Standardized Numeric - The test result as a standardized numeric value.`,
    LBSTRESU: `Laboratory Test Result Standardized Unit - The unit for the standardized lab test result.`,
    LBSTNRLO: `Laboratory Test Normal Range Low Standardized - The lower limit of the normal range, standardized.`,
    LBSTNRHI: `Laboratory Test Normal Range High Standardized - The upper limit of the normal range, standardized.`,
    LBNRIND: `Laboratory Test Normal Range Indicator - An indicator of whether the test result is within the normal range.`,
    LBDTC: `Laboratory Test Date/Time - The date and time the lab test was performed.`,
    MHHLT: `Medical History High-Level Term - A higher-level categorization of medical history terms.`,
    VDDATE: `Vaccine Dose Date - The date when a vaccine dose was administered.`,
    SVUPDES: `Subject Visit Update Description - Description related to updates on subject visits.`,
    VSPOS: `Vital Signs Position - The position of the subject when vital signs were taken.`,
    VSORRES: `Vital Signs Original Result - The actual result of the vital sign measurement.`,
    VSORRESU: `Vital Signs Original Result Unit - The unit of the original vital signs result.`,
    VSSTRESC: `Vital Signs Result Standardized Character - The vital sign result as a standardized text value.`,
    VSSTRESN: `Vital Signs Result Standardized Numeric - The vital sign result as a standardized numeric value.`,
    VSSTRESU: `Vital Signs Result Standardized Unit - The unit for the standardized vital signs result.`,
    VSSTAT: `Vital Signs Status - Status or outcome of the vital sign measurement.`,
    VSLOC: `Vital Signs Location - Location on the body where the vital sign was measured.`,
    VSTPT: `Visit Test Point - The time point during the visit when tests were conducted.`,
    VSTPTNUM: `Visit Test Point Number - The numerical order of the test point during the visit.`,
    VSELTM: `Visit Selection Time - The time selected for the visit window.`,
    VSTPTREF: `Visit Test Point Reference - A reference to the specific time point of a test during a visit.`
  }

  const decoded = dict[abbreviation]

  if (decoded) return decoded

  const universalDict = [
    { abbr: 'BLFL', decoded: 'Baseline Flag' },
    { abbr: 'BODSYS', decoded: 'Body System' },
    { abbr: 'CAT', decoded: 'Category' },
    { abbr: 'DTC', decoded: 'Date' },
    { abbr: 'DY', decoded: 'Day' },
    { abbr: 'ENDTC', decoded: 'End Date' },
    { abbr: 'ENDY', decoded: 'End Day' },
    { abbr: 'HLGT', decoded: 'High Level Group Term' },
    { abbr: 'LLT', decoded: 'Lower Level Term' },
    { abbr: 'SEQ', decoded: 'Sequence Number' },
    { abbr: 'SEV', decoded: 'Severity' },
    { abbr: 'SPID', decoded: 'Sponsor-Defined Identifier' },
    { abbr: 'STDTC', decoded: 'Start Date/Time' },
    { abbr: 'STDY', decoded: 'Start Day' },
    { abbr: 'DECOD', decoded: 'Standardized Name' },
    { abbr: 'TERM', decoded: 'Term' },
    { abbr: 'TEST', decoded: 'Test' },
    { abbr: 'TESTCD', decoded: 'Test Code' }
  ]

  for (const item of universalDict) {
    const { abbr, decoded } = item

    const regExp = new RegExp(`^[A-Z]{2}${abbr}`)

    if (regExp.test(abbreviation)) return decoded
  }

  return ''
}
