import { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { usePapaParse } from 'react-papaparse'
import {
  IconButton,
  Button,
  Box,
  Switch,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Tooltip
} from '@mui/material'
import {
  globalFilter,
  openSaveFileDialog,
  addToHistory,
  Heatmap2,
  arrayMax,
  arrayMin
} from '../../apis/utility'
import { decodeAbbreviation, getAeDay, compareObjects } from '../../utils'
import { DataGridPro } from '@mui/x-data-grid-pro'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { ApiController } from '../../controllers/ApiController'
import {
  setBaselinesAction,
  setTableDataAction,
  setTableInfoAction,
  setTableColumnsAction
} from '../../store/action'
import { TableInfoObject, ColumnItem, TableData } from '../../types'

interface ViewDataProps {
  apiController: ApiController
}

export default function ViewData(props: ViewDataProps) {
  const { apiController } = props

  const params = useParams()
  const { table } = params

  const dispatch = useAppDispatch()

  const baselinesState = useAppSelector((state) => state.vd.baselines)
  const screenState = useAppSelector((state) => state.preferences.screen)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)
  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedSubjectsState = useAppSelector(
    (state) => state.subjects.selected
  )
  const tableInfoState = useAppSelector((state) => state.tables.info)
  const tableDataState = useAppSelector((state) =>
    state.tables.data ? state.tables.data[table || ''] : null
  )
  const tableColumnsState = useAppSelector((state) =>
    state.tables.columns ? state.tables.columns[table || ''] : null
  )

  const location = useLocation()
  addToHistory({ title: 'View ' + table, url: location })

  const [baselines, setBaselines] = useState<{ [key: string]: string } | null>(
    baselinesState
  )
  const [gridData, setGridData] = useState<TableData | null>(tableDataState)
  const [columns, setColumns] = useState<ColumnItem[] | null>(tableColumnsState)
  const [columnsWithRenderer, setColumnsWithRenderer] = useState<ColumnItem[]>()
  const [currentParameter, setCurrentParameter] = useState<string | null>(null)
  const [checked, setChecked] = useState<boolean>(true)
  const [tableInfo, setTableInfo] = useState<TableInfoObject | null>(
    tableInfoState
  )
  const [waiting, setWaiting] = useState<boolean>(false)
  const [noSubjectsFound] = useState<boolean>(
    selectedSubjectsState ? selectedSubjectsState?.length === 0 : true
  )

  const subtitle = globalFilter(selectedFilterState)

  const handleChange = (event: any) => {
    setChecked(event.target.checked)
  }

  const { jsonToCSV } = usePapaParse()

  const exportData = () => {
    openSaveFileDialog(jsonToCSV(gridData), table, 'text/csv')
  }

  useEffect(() => {
    let isMounted = false

    const fetchTableInfo = async () => {
      isMounted = true

      const tableInfo = await apiController.getTableInfo()

      if (isMounted) {
        const tempTableInfo: TableInfoObject = {}

        tableInfo.forEach((row) => {
          tempTableInfo[row.name] = { short: row.short, long: row.long }
        })

        if (!compareObjects(tableInfoState, tempTableInfo)) {
          dispatch(setTableInfoAction(tempTableInfo))

          setTableInfo(tempTableInfo)
        }
      }
    }

    if (!tableInfo) fetchTableInfo()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, dispatch, tableInfoState, tableInfo])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      const baselines = await apiController.getBaselines()

      const baselinesObject: { [key: string]: string } = {}

      if (isMounted) {
        baselines.forEach((baseLine) => {
          const { SUBJID, VDDATE } = baseLine

          baselinesObject[SUBJID] = VDDATE
        })

        if (!compareObjects(baselinesState, baselinesObject)) {
          dispatch(setBaselinesAction(baselinesObject))

          setBaselines(baselinesObject)
        }
      }
    }

    if (!baselines) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, baselinesState, dispatch, baselines])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      if (table && baselines) {
        // call async func to fetch data
        const records = await apiController.getTableRecords(table)

        // handle fetched data
        if (isMounted && records.length > 0) {
          const tempData: TableData = records.map((row, id) => {
            const { SUBJID, AESTDTC, AEENDTC, AEDUR } = row

            if (AESTDTC && AEENDTC && AEDUR) {
              row.AESTDY = getAeDay(baselines[SUBJID], AESTDTC as string)
              row.AEENDY = getAeDay(baselines[SUBJID], AEENDTC as string)

              row.AEDUR = parseInt((AEDUR as string).replace(' days', '')) + 1
              row.AEDUR = row.AEDUR + ' day' + (row.AEDUR > 1 ? 's' : '')
            }

            return {
              ...row,
              id: id
            }
          })

          const firstTwoHundredRows = tempData.slice(0, 200)

          if (!compareObjects(tableDataState, firstTwoHundredRows)) {
            dispatch(setTableDataAction({ [table]: firstTwoHundredRows }))
          }

          setGridData(tempData)

          const keys = Object.keys(records[0])
          const columns: ColumnItem[] = []
          const ages = records.map((item) => item.age)
          const ageMin = arrayMin(ages)
          const ageMax = arrayMax(ages)

          keys.forEach((key) => {
            columns.push({
              headerName: key,
              description: decodeAbbreviation(key),
              field: key,
              ageMin,
              ageMax
            } as any)
          })

          if (!compareObjects(tableColumnsState, columns)) {
            dispatch(setTableColumnsAction({ [table]: columns }))

            setColumns(columns)
          }

          setWaiting(false)
        }
      }
    }

    if (selectedStudyState?.studyDB) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [
    apiController,
    baselines,
    table,
    selectedStudyState,
    dispatch,
    tableColumnsState,
    tableDataState
  ])

  if (currentParameter !== table) {
    setCurrentParameter(table || null)
  }

  useEffect(() => {
    if (columns && !columnsWithRenderer) {
      // define what special renderers to use for certain columns
      const renderOptions: { [key: string]: any } = {
        age: Heatmap2
      }

      const columnsCopy = [...columns].map((column: any) => ({
        ...column,
        renderCell: (cellValues: any) => {
          const { value, field } = cellValues

          if (field in renderOptions) {
            const func = renderOptions[field]

            return func(value, column.ageMin, column.ageMax, 0, -1, 1, 0.5)
          } else if (field === 'SUBJID') {
            const target = `${window.location.protocol}//${window.location.host}/#/patientprofile/${selectedStudyState?.studyDB}/${value}`

            return (
              <Button
                onClick={() => {
                  window.open(target)
                }}
              >
                {value}
              </Button>
            )
          } else if (field === 'sex') {
            const color = value === 'F' ? 'red' : 'blue'

            return (
              <b>
                <span style={{ color: color }}>{value}</span>
              </b>
            )
          }

          return value
        }
      }))

      setColumnsWithRenderer(columnsCopy)
    }
  }, [columns, selectedStudyState?.studyDB, columnsWithRenderer])

  return (
    <>
      {waiting && <CircularProgress />}
      {!waiting && (
        <>
          <Box sx={{ mt: 1, display: 'flex' }}>
            <Box
              sx={{
                color: 'primary',
                alignContent: 'left',
                justifyContent: 'start'
              }}
            >
              <Tooltip title="Download table">
                <IconButton color="primary" onClick={exportData}>
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ flexGrow: 1, color: 'primary' }}>
              <b>
                {tableInfo && table && table in tableInfo
                  ? tableInfo[table].short
                  : table}
              </b>{' '}
              ({selectedStudyState?.STUDYID})
            </Box>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={checked} onChange={handleChange} />}
                label="Subjects"
                sx={{ color: 'primary' }}
              />
            </FormGroup>
          </Box>
          <Box sx={{ display: 'flex', bgcolor: 'background.paper' }}>
            <Box sx={{ flexGrow: 1, justifyContent: 'center', fontSize: 10 }}>
              {subtitle}
            </Box>
          </Box>
          {noSubjectsFound ? (
            <div style={{ marginTop: 20 }}>No subjects found</div>
          ) : gridData && columnsWithRenderer ? (
            <div
              style={{
                height: (screenState?.height || 1) - 160,
                width: '100%'
              }}
            >
              <DataGridPro
                rows={gridData}
                columns={columnsWithRenderer}
                density={'compact'}
                rowHeight={30}
                sx={{ fontSize: '0.7em' }}
              />
            </div>
          ) : (
            'Loading...'
          )}
        </>
      )}
    </>
  )
}
