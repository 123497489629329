import React, { useState, Dispatch } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Stack,
  Chip,
  Box,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Typography
} from '@mui/material'
import SubjectTable from '../SubjectTable'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { GenerateSelects } from '../forms/GenerateSelects'
import ShowAlerts from '../pages/ShowAlerts'
import { setSelectedStudyAction } from '../../store/action'
import { Study } from '../../types'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { ApiController } from '../../controllers/ApiController'

// TODO: improve
interface DrawerContentsProps {
  listOfTables: string[]
  selectedSubjects: string[]
  selectedOptions: string[]
  optionsForSelection: string[]
  varToTable: string[]
  alertResults: string[]
  setAlertResults: Dispatch<any>
  setSelectedOptions: Dispatch<any>
  setSelectedSubjects: Dispatch<any>
  labCategories: string[]

  theme: any
  apiController: ApiController
}

function DrawerContents(props: DrawerContentsProps) {
  const {
    listOfTables,
    apiController,
    selectedSubjects,
    alertResults,
    setAlertResults,
    labCategories,
    selectedOptions,
    theme,
    setSelectedOptions,
    setSelectedSubjects,
    optionsForSelection,
    varToTable
  } = props

  const dispatch = useAppDispatch()

  const studiesState = useAppSelector((state) => state.studies)
  const { list: studiesList, selected: selectedStudy } = studiesState

  const [expanded, setExpanded] = useState('panel0')

  const navigate = useNavigate()

  const handleChange = (panel: any) => (_: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleStudySelection = (study: Study) => {
    apiController.setStudyDB(study.studyDB)

    dispatch(setSelectedStudyAction(study))
  }

  return (
    <div>
      <Box
        sx={{
          backgroundColor: 'primary.light'
        }}
      >
        <Accordion
          expanded={expanded === 'panel0'}
          onChange={handleChange('panel0')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel0a-header">
            <Typography>Select Study</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" spacing={1}>
              {selectedStudy &&
                studiesList &&
                studiesList.length &&
                studiesList.map((study: Study, i: number) => {
                  const { STUDYID } = study
                  const { STUDYID: selectedSTUDYID } = selectedStudy

                  return (
                    <Chip
                      label={STUDYID}
                      variant={'filled'}
                      color={STUDYID === selectedSTUDYID ? 'success' : 'info'}
                      disabled={STUDYID !== selectedSTUDYID}
                      clickable={true}
                      onClick={(e) => {
                        handleStudySelection(study)
                      }}
                      key={`study-${i}`}
                    />
                  )
                })}
            </Stack>
          </AccordionDetails>
        </Accordion>
        {selectedStudy ? (
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel1a-header"
            >
              <Typography>
                {selectedStudy.STUDYID
                  ? `Lab Alerts for ${selectedStudy.STUDYID}`
                  : 'Select a study first (above)'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ShowAlerts
                selectedSubjects={selectedSubjects}
                alertResults={alertResults}
                setAlertResults={setAlertResults}
                labCategories={labCategories}
              />
            </AccordionDetails>
          </Accordion>
        ) : null}
        {selectedStudy ? (
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2a-header"
            >
              <Typography>
                {selectedStudy.STUDYID
                  ? `View a table from database for ${selectedStudy.STUDYID}`
                  : 'Select a study first (above)'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {listOfTables &&
                listOfTables.map((thisTable: any, index: any) => {
                  return (
                    <React.Fragment key={'tab' + index}>
                      <button
                        onClick={() => {
                          navigate(`/view/${thisTable}`)
                        }}
                      >
                        {thisTable}
                      </button>
                      &nbsp;
                    </React.Fragment>
                  )
                })}
            </AccordionDetails>
          </Accordion>
        ) : null}
        {selectedStudy ? (
          <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel3a-header"
            >
              <Typography>
                {selectedStudy.STUDYID
                  ? `Subject Overview for ${selectedStudy.STUDYID}`
                  : 'Select a study first (above)'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SubjectTable
                selectedSubjects={selectedSubjects}
                screenHeight={400}
                theme={theme}
                apiController={apiController}
              />
            </AccordionDetails>
          </Accordion>
        ) : null}
        {studiesState ? (
          <Accordion
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel4a-header"
            >
              <Typography>Global Filter Builder</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GenerateSelects
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                setSelectedSubjects={setSelectedSubjects}
                optionsForSelection={optionsForSelection}
                varToTable={varToTable}
              />
            </AccordionDetails>
          </Accordion>
        ) : null}
      </Box>
    </div>
  )
}
export default DrawerContents
