import { useState, useEffect } from 'react'
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
  Tooltip
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import { openSaveFileDialog } from '../../apis/utility'
import { DataGridPro } from '@mui/x-data-grid-pro'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import CloseIcon from '@mui/icons-material/Close'
import { usePapaParse } from 'react-papaparse'
import { ApiController } from '../../controllers/ApiController'
import { useAppSelector } from '../../hooks'

interface ViewNotesModalProps {
  apiController: ApiController
  setOpenViewNotesModal: React.Dispatch<boolean>
  openViewNotesModal: boolean
}

export const ViewNotesModal = (props: ViewNotesModalProps) => {
  const { apiController, setOpenViewNotesModal, openViewNotesModal } = props

  const screenState = useAppSelector((state) => state.preferences.screen)
  const userNameState = useAppSelector((state) => state.access.userName)

  const location = useLocation()

  const handleClose = () => setOpenViewNotesModal(false)

  const style = {
    position: 'absolute',
    top: '20%',
    left: '20%',
    transform: 'translate(-10%, -10%)',
    width: (screenState?.width || 1) * 0.8,
    height: (screenState?.height || 1) * 0.8,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  }

  const [noteData, setNoteData] = useState<any>(null)
  const [noteColumns, setNoteColumns] = useState<any>(null)

  const { jsonToCSV } = usePapaParse()

  const exportData = () => {
    if (!noteData || noteData.length === 0) {
      // FIXME
      console.log('nothing to save')
    } else {
      openSaveFileDialog(
        jsonToCSV(noteData),
        'notes for ' + location.pathname,
        'text/csv'
      )
    }
  }

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      if (userNameState && apiController.studyDB) {
        const url = location.pathname.replace(/\//g, '%2F')

        // call async func to fetch data
        const data = await apiController.getNotes(userNameState, url)

        // handle fetched data
        if (isMounted) {
          const tempData = data
            .map((item) => ({ ...item, url: decodeURIComponent(item.url) }))
            .map((d: any, id: any) => ({ ...d, id: id }))

          setNoteData(tempData)

          if (data.length > 0) {
            setNoteColumns(
              Object.keys(data[0]).map((d) => {
                let flex = null

                if (d === 'note') flex = 6
                if (d === 'url') flex = 1
                if (d === 'created') flex = 1

                return { headerName: d, field: d, flex: flex }
              })
            )
          }
        }
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, userNameState, location.pathname])

  return (
    <Modal open={openViewNotesModal} onClose={handleClose}>
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Tooltip title="Download table">
              <IconButton
                size="small"
                sx={{ mr: 1 }}
                color="primary"
                onClick={exportData}
              >
                <FileDownloadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton
                size="small"
                // variant="contained" // FIXME
                color="primary"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={10}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              View notes for screen: {location.pathname}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ height: (screenState?.height || 1) * 0.75 }}>
            {noteData && noteColumns ? (
              <DataGridPro
                rows={noteData}
                columns={noteColumns}
                density={'compact'}
                rowHeight={30}
                sx={{ fontSize: '0.7em' }}
              />
            ) : (
              <span>No notes yet</span>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
