import { useState } from 'react'
import { Typography, Button, Divider, TextField } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { addToHistory } from '../../apis/utility'
import { getAnswer } from '../../apis/utility'

export const Openai = () => {
  const location = useLocation()

  const askOpenai = (chat: any) => {
    getAnswer(chat, setAnswer)
  }

  const [question, setQuestion] = useState<any>('')
  const [answer, setAnswer] = useState<any>('')

  addToHistory({ title: 'Openai', url: location })

  return (
    <>
      <Typography sx={{ mt: 2, mb: 3 }}>A.I. (experimental)</Typography>
      <TextField
        label="Question"
        value={question}
        onChange={(event) => setQuestion(event.target.value)}
        fullWidth
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ ml: 2, mt: 1, mb: 2 }}
        onClick={() => {
          askOpenai(question)
        }}
      >
        Ask OpenAI
      </Button>
      <Divider />
      <TextField
        label="Answer"
        value={answer}
        fullWidth
        multiline={true}
        maxRows={40}
      />
    </>
  )
}
