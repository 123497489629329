import { useState, useEffect } from 'react'
import { sql, globalFilter, addToHistory } from '../../../apis/utility'
import { useLocation } from 'react-router-dom'
import SubjectTable from '../../SubjectTable'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import AnnotationsModule from 'highcharts/modules/annotations'
import { LookBelow } from '../../layout/LookBelow'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector } from '../../../hooks'

AnnotationsModule(Highcharts)

export default function LBHysLaw(props: any) {
  const { theme, apiController } = props
  const location = useLocation()

  const chartContainerId = 'lb-both-charts-container'

  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)
  const selectedSubjectsState = useAppSelector(
    (state) => state.subjects.selected
  )
  const screenState = useAppSelector((state) => state.preferences.screen)

  addToHistory({ title: 'Hys Law', url: location })

  const whereSubjects =
    selectedSubjectsState && selectedSubjectsState.length > 0
      ? 'and SUBJID in ("' +
        selectedSubjectsState.map((s) => s.SUBJID).join('","') +
        '")'
      : ''

  const contrastingColor = theme.palette.mode === 'dark' ? 'white' : 'black'

  const [chartOptions, setChartOptions] = useState<any>(null)
  const [subjectsDrilledTo, setSubjectsDrilledTo] = useState<any>(null)
  const [levels, setLevels] = useState<any>(null)
  const [subtitle, setSubtitle] = useState<any>(null)

  const sqlStatement = `SELECT SUBJID,
                                lbdy,
                                max(CASE WHEN lbtestcd = 'ALT' THEN value END) AS alt,
                                max(CASE WHEN lbtestcd = 'AST' THEN value END) AS ast,
                                max(CASE WHEN lbtestcd = 'BILI' or lbtestcd = 'TBILI' THEN value END) AS tbili
                        FROM (
                                    SELECT SUBJID,
                                        lbtestcd,
                                        lbdy,
                                        max(lbstresn) AS value
                                    FROM lb
                                    WHERE LBTESTCD IN ('ALT', 'AST', 'TBILI', 'BILI') 
                                    ${whereSubjects}
                                    GROUP BY SUBJID,
                                            lbdy,
                                            lbtestcd
                                )
                        GROUP BY SUBJID,
                                lbdy;`

  useEffect(() => {
    sql(
      selectedStudyState?.studyDB,
      `SELECT DISTINCT LBTESTCD,
                    max(LBSTNRLO) AS LBSTNRLO,
                    max(LBSTNRHI) AS LBSTNRHI,
                    max(LBSTRESN) AS MAX,
                    max(LBSTRESU) as UNIT
            FROM lb
            WHERE LBTESTCD IN ('AST', 'ALT', 'TBILI', 'BILI') 
            ${whereSubjects}
            GROUP BY LBTESTCD;
            `
    ).then((res) => {
      if (res.message === 'success') {
        const tempLevels: any = []

        res.data.forEach((row: any) => {
          if (row.LBSTNRHI) {
            tempLevels[row.LBTESTCD === 'BILI' ? 'TBILI' : row.LBTESTCD] = {
              low: row.LBSTNRLO,
              high: row.LBSTNRHI,
              max: row.MAX,
              unit: row.UNIT
            }
          }
        })

        setLevels(tempLevels)
      }
    })
  }, [whereSubjects, selectedStudyState])

  useEffect(() => {
    setSubtitle(globalFilter(selectedFilterState) || '')

    if (levels) {
      sql(selectedStudyState?.studyDB, sqlStatement).then((res) => {
        if (res.message === 'success') {
          const ulnALT = Number(levels['ALT'].high)
          const ulnTbili = Number(levels['TBILI'].high)

          const altData = res.data.map((item: any) => [
            Number(item.alt) / ulnALT,
            Number(item.tbili) / ulnTbili,
            item.SUBJID
          ])
          const astData = res.data.map((item: any) => [
            Number(item.ast) / ulnALT,
            Number(item.tbili) / ulnTbili
          ])

          const series = [
            {
              id: 'alt',
              name: 'ALT vs TBili',
              color: '#ff0000',
              data: altData
            },
            {
              id: 'ast',
              name: 'AST vs. TBili',
              color: '#00ff00',
              data: astData
            }
          ]

          const altUnit = levels['ALT'].unit
          const tbiliUnit = levels['TBILI'].unit

          const xLines = []
          const yLines = []

          xLines.push({
            color: contrastingColor,
            dashStyle: 'longdashdot',
            value: 3,
            width: 2,
            label: {
              style: { color: contrastingColor },
              text: '3 * ULN',
              verticalAlign: 'top'
            }
          })

          yLines.push({
            color: contrastingColor,
            dashStyle: 'longdashdot',
            value: 2,
            width: 2,
            label: {
              style: { color: contrastingColor },
              text: '2 * ULN',
              align: 'left'
            }
          })

          setChartOptions({
            chart: {
              type: 'scatter',
              height: (screenState?.height || 1) * 0.75,
              zoomType: 'xy',
              backgroundColor: 'transparent'
            },
            tooltip: {
              formatter: function (): any {
                const { point } = this as any
                const x = point.series.name.split(' ')[0]
                const y = point.series.name.split(' ')[2]

                return (
                  x +
                  ' = <b>' +
                  (this as any).x +
                  '</b><br/>' +
                  y +
                  ' = <b>' +
                  (this as any).y +
                  '</b>'
                )
              }
            },
            xAxis: {
              min: 0.1,
              max: 100,
              type: 'logarithmic',
              plotLines: xLines,
              labels: { style: { color: contrastingColor } },
              title: {
                text: 'ALT & AST (' + altUnit + ')',
                style: { color: contrastingColor }
              }
            },
            yAxis: {
              min: 0.1,
              max: 100,
              type: 'logarithmic',
              plotLines: yLines,
              labels: { style: { color: contrastingColor } },
              title: {
                text: 'TBILI (' + tbiliUnit + ')',
                style: { color: contrastingColor }
              }
            },
            series: series,
            legend: { itemStyle: { color: contrastingColor } },
            title: {
              text: `Hys Law (${selectedStudyState?.STUDYID})`,
              style: { color: contrastingColor }
            },
            subtitle: { text: subtitle, style: { color: contrastingColor } },
            annotations: [
              {
                labels: [
                  {
                    point: {
                      x: 40,
                      y: 40,
                      xAxis: 0,
                      yAxis: 0
                    },
                    text: "Hy's Law Range"
                  },
                  {
                    point: {
                      x: 40,
                      y: 0.2,
                      xAxis: 0,
                      yAxis: 0
                    },
                    text: "Temple's Corollary Range"
                  },
                  {
                    point: {
                      x: 0.2,
                      y: 40,
                      xAxis: 0,
                      yAxis: 0
                    },
                    text: 'Hyperbillirubinemia'
                  }
                ],
                labelOptions: {
                  borderRadius: 5,
                  backgroundColor: 'rgba(252, 255, 197, 0.7)',
                  borderWidth: 1,
                  borderColor: '#AAA'
                }
              }
            ],
            plotOptions: {
              column: { colorByPoint: true },
              scatter: {
                dataLabels: {
                  enabled: false,
                  style: { color: contrastingColor }
                },
                point: {
                  events: {
                    click: function (e: any) {
                      const index = e.point.index
                      const SUBJID = altData[index][2]

                      setSubjectsDrilledTo([SUBJID])
                    }
                  }
                }
              }
            },
            credits: { enabled: false },
            exporting: { enabled: false }
          })
        }
      })
    }
    // eslint-disable-next-line
  }, [
    selectedSubjectsState,
    selectedFilterState,
    subtitle,
    sqlStatement,
    levels,
    contrastingColor
  ])

  return (
    <>
      {chartOptions ? (
        <div id={chartContainerId}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          <ChartDownload elementId={chartContainerId} fileName="LB-HysLaw" />
        </div>
      ) : (
        'loading'
      )}
      {subjectsDrilledTo && <LookBelow />}
      {subjectsDrilledTo && (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          theme={theme}
          apiController={apiController}
        />
      )}
    </>
  )
}
