import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import SubjectTable from '../../SubjectTable'
import { globalFilter, addToHistory } from '../../../apis/utility'
import { dateFormat } from '../../../utils'
import ReactFC from 'react-fusioncharts'
import FusionCharts from 'fusioncharts'
import TimeSeries from 'fusioncharts/fusioncharts.timeseries'
import excelexport from 'fusioncharts/fusioncharts.excelexport'
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel'
import moment from 'moment'
import FusionChartJSX from '../../ui/FusionChartJSX'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector } from '../../../hooks'
import { ApiController } from '../../../controllers/ApiController'

ReactFC.fcRoot(FusionCharts, TimeSeries, GammelTheme, excelexport)

interface DMInfoProps {
  apiController: ApiController
  theme: { [key: string]: any }
}

export default function DSInfo(props: DMInfoProps) {
  const { apiController, theme } = props

  const chartContainerId = 'ds-info-chart-container'

  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const screenState = useAppSelector((state) => state.preferences.screen)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)

  const location = useLocation()

  addToHistory({ title: 'DS Info', url: location })

  const [subjectsDrilledTo, setSubjectsDrilledTo] = useState<string[]>()

  const [data, setData] = useState<any>(null)
  const [timeSeriesDs, setTimeSeriesDs] = useState<any>(null)

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const data = await apiController.getDSinfoDsdecod()

      // handle fetched data
      if (isMounted) {
        setData(data.map((item) => [item.date, item.type, item.count]))
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController])

  useEffect(() => {
    const schema = [
      {
        name: 'Time',
        type: 'date',
        format: '%Y-%m-%d'
      },
      {
        name: 'Type',
        type: 'string'
      },
      {
        name: 'Visits',
        type: 'number'
      }
    ]

    if (data && data.length) {
      const fusionTable = new FusionCharts.DataStore().createDataTable(
        data,
        schema
      )

      const subtitle = globalFilter(selectedFilterState) || ''
      const temp: any = {
        type: 'timeseries',
        renderAt: 'container',
        width: '100%',
        height: (screenState?.height || 1) * 0.8,
        dataSource: {
          chart: { theme: 'gammel' },
          caption: {
            text:
              'Disposition Events and Protocol Milestones by Calendar Day (' +
              selectedStudyState?.STUDYID +
              ')'
          },
          subcaption: { text: subtitle },
          series: 'Type',
          yaxis: [
            {
              plot: { value: 'Visits', type: 'column' },
              title: 'Visits',
              type: 'number'
            }
          ],
          data: null
        },
        events: {
          dataPlotClick: async function (event: any) {
            const { data } = event
            const { startText, endText } = data

            let { seriesValue } = data
            seriesValue = seriesValue
              .toUpperCase()
              .replace(/Study Completed/i, 'COMPLETED')
              .replace(/Withdrew due to Adverse Event/i, 'ADVERSE EVENT')

            const start = moment(startText, dateFormat)
            const end = moment(endText, dateFormat)

            const dateRange = []

            for (let m = moment(start); m < moment(end); m.add(1, 'days')) {
              const date = m.format(dateFormat)

              dateRange.push(date)
            }

            const subjects = await apiController.getDSinfoSubjects(
              seriesValue,
              dateRange
            )

            setSubjectsDrilledTo(subjects.map((subject) => subject.SUBJID))
          }
        }
      }

      temp.dataSource.data = fusionTable

      setTimeSeriesDs({ ...temp })
    }
  }, [
    data,
    screenState?.height,
    selectedFilterState,
    selectedStudyState?.STUDYID,
    apiController
  ])

  return (
    <>
      {data && timeSeriesDs ? (
        <div id={chartContainerId}>
          <FusionChartJSX {...timeSeriesDs} />
          <ChartDownload elementId={chartContainerId} fileName="DS-Days" />
        </div>
      ) : (
        'loading'
      )}
      {subjectsDrilledTo && (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          theme={theme}
          apiController={apiController}
        />
      )}
    </>
  )
}
