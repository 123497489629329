import { useState, useEffect, useCallback } from 'react'
import { globalFilter, addToHistory } from '../../../apis/utility'
import { useLocation } from 'react-router-dom'
import ReactFC from 'react-fusioncharts'
import FusionCharts from 'fusioncharts'
import excelexport from 'fusioncharts/fusioncharts.excelexport'
import Charts from 'fusioncharts/fusioncharts.charts'
import SubjectTable from '../../SubjectTable'
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel'
import { LookBelow } from '../../layout/LookBelow'
import FusionChartJSX from '../../ui/FusionChartJSX'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector } from '../../../hooks'
import { ApiController } from '../../../controllers/ApiController'

ReactFC.fcRoot(FusionCharts, Charts, GammelTheme, excelexport)

interface AEStreamGraphProps {
  apiController: ApiController
  theme: { [key: string]: any }
}

export const AESunburst = (props: AEStreamGraphProps) => {
  const { apiController, theme } = props

  const chartContainerId = 'ae-sunburst-chart-container'

  const location = useLocation()

  const drillDownPathState =
    useAppSelector((state) => state.preferences.drillDownPath) || []
  const screenState = useAppSelector((state) => state.preferences.screen)
  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)

  const [drillDownPath] = useState<string[]>(drillDownPathState)
  const [dataSource, setDataSource] = useState<{ [key: string]: any }>()
  const [showSubjects, setShowSubjects] = useState<boolean>(false)
  const [subjectsDrilledTo, setSubjectsDrilledTo] = useState<string[]>([])
  const [subjectsDrilledToLabel, setSubjectsDrilledToLabel] = useState<string>()
  const [subjectsDrilledToLevel, setSubjectsDrilledToLevel] = useState<number>()

  addToHistory({ title: 'AE Sunburst', url: location })

  const getSubjectsDrilledTo = useCallback(
    (label: string, level: number) => {
      const variable = drillDownPath[level - 1]

      apiController
        .getAESubjects(` AND ${variable}='${label}'`)
        .then((subjects) => {
          if (subjects.length) {
            setShowSubjects(true)
            setSubjectsDrilledTo(subjects.map((sub) => sub.SUBJID))
          } else {
            setShowSubjects(false)
          }
        })
    },
    [drillDownPath, setShowSubjects, setSubjectsDrilledTo, apiController]
  )

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const AEBODSYSsummaryLevel1 = await apiController.getAEBODSYSsummary(
        'AEBODSYS'
      )

      // handle fetched data
      if (isMounted) {
        const data1 = AEBODSYSsummaryLevel1.map((row) => ({
          parent: 'AEs',
          id: '1' + drillDownPath[0] + row.AEBODSYS,
          label: row.AEBODSYS,
          value: row.numberOfSubjects
        }))

        const AEBODSYSsummaryLevel2 = await apiController.getAEBODSYSsummary(
          'AEDECOD, AEBODSYS, AEHLT'
        )

        if (isMounted) {
          const data3 = AEBODSYSsummaryLevel2.map((row: any) => ({
            parent: '1' + drillDownPath[0] + row.AEBODSYS,
            id: '3' + row.AEDECOD,
            label: row.AEDECOD,
            value: row.numberOfSubjects
          }))

          setDataSource({
            chart: {
              caption: 'Adverse Events (' + selectedStudyState?.STUDYID + ')',
              subcaption: globalFilter(selectedFilterState) || '',
              bgColor: 'EEEEEE,CCCCCC',
              bgratio: '60,40',
              bgAlpha: '70,80',
              bgAngle: '180'
            },
            data: [
              {
                id: 'AEs',
                parent: '',
                label: 'AEs',
                value: ''
              },
              ...data1,
              ...data3
            ]
          })

          if (
            subjectsDrilledToLevel !== undefined &&
            subjectsDrilledToLabel !== undefined
          ) {
            getSubjectsDrilledTo(subjectsDrilledToLabel, subjectsDrilledToLevel)
          }
        }
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [
    apiController,
    drillDownPath,
    getSubjectsDrilledTo,
    selectedStudyState?.STUDYID,
    subjectsDrilledToLabel,
    subjectsDrilledToLevel,
    selectedFilterState
  ])

  return (
    <>
      {dataSource ? (
        <div id={chartContainerId}>
          <FusionChartJSX
            type="sunburst"
            width="100%"
            height={(screenState?.height || 1) * 0.75}
            dataFormat="JSON"
            dataSource={dataSource}
            events={{
              dataPlotClick: (event) => {
                const { nodeId, label } = event.data
                const level = event.data.nodeId.substring(0, 1)

                if (nodeId === 'AEs') {
                  setShowSubjects(false)
                } else {
                  getSubjectsDrilledTo(label, level)

                  setSubjectsDrilledToLabel(label)
                  setSubjectsDrilledToLevel(level)
                }
              }
            }}
          />
          <ChartDownload elementId={chartContainerId} fileName="AE-Sunburst" />
        </div>
      ) : (
        'loading'
      )}
      {showSubjects && (
        <LookBelow
          label="Subject Table"
          tooltip="Click to scroll to subject table appears below"
          mt={2}
          ml={4}
          mr={0}
          mb={0}
        />
      )}
      {showSubjects && (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          filterOptions={[{ key: 'AE', value: subjectsDrilledToLabel }]}
          theme={theme}
          apiController={apiController}
        />
      )}
    </>
  )
}
