import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import SubjectTable from '../../SubjectTable'
import { LookBelow } from '../../layout/LookBelow'
import { globalFilter, addToHistory } from '../../../apis/utility'
import { Tooltip, Fab, Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert'
import InfoIcon from '@mui/icons-material/Info'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import ChartDownload from '../../ui/ChartDownload'
import { dataToBarOptions } from '../../../utils'
import { useAppSelector } from '../../../hooks'
import { ApiController } from '../../../controllers/ApiController'

HC_exporting(Highcharts)

interface AEDrilldownProps {
  apiController: ApiController
  theme: { [key: string]: any }
}

export default function AEDrilldown(props: AEDrilldownProps) {
  const { apiController, theme } = props

  const chartContainerId = 'ae-drilldown-chart-container'

  const drillDownPathState = (
    useAppSelector((state) => state.preferences.drillDownPath) || []
  ).filter((path) => path !== 'AEHLT') // More info related to removing 'AEHLT': https://git.4gl.io/xploratum/client/-/issues/4
  const variablesLabelsState = useAppSelector((state) => state.variables.labels)
  const screenState = useAppSelector((state) => state.preferences.screen)
  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)

  const location = useLocation()

  addToHistory({ title: 'AE Drill', url: location })

  const contrastingColor = theme.palette.mode === 'dark' ? 'white' : 'black'

  const [drillDownPath] = useState<string[]>(drillDownPathState)
  const [showSubjects, setShowSubjects] = useState<boolean>(false)
  const [chartOptions, setChartOptions] = useState<{ [key: string]: any }>()
  const [newOptions, setNewOptions] = useState<{ [key: string]: any }>()
  const [data, setData] = useState<{ [key: string]: number | string }[]>()
  const [caption, setCaption] = useState<string>()
  const [drillVars, setDrillVars] = useState<string[]>([])
  const [drillDownLevel, setDrillDownLevel] = useState<number>(0)
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false)
  const [subjectsDrilledTo, setSubjectsDrilledTo] = useState<string[]>([])
  const [aeTerm, setAeTerm] = useState<string>()

  const drillUp = () => {
    setDrillVars(drillVars.slice(0, drillDownLevel - 1))
    setDrillDownLevel(drillDownLevel - 1)
    setShowSubjects(false)
    setAeTerm(undefined)
  }
  const top = () => {
    setDrillVars(drillVars.slice(0, 0))
    setDrillDownLevel(0)
    setShowSubjects(false)
  }

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const aeSummary = await apiController.getAEsummary(true)

      // handle fetched data
      if (isMounted) {
        const { subjectsWithAEs, totalSubjects, totalAEs } = aeSummary
        const pctSubjectsWithAEs = (subjectsWithAEs / totalSubjects) * 100

        setCaption(
          `<b>Total Subjects: ${totalSubjects}, ` +
            `Subjects with AEs: ${subjectsWithAEs} (${pctSubjectsWithAEs.toFixed(
              1
            )}%) , ` +
            `Total AEs: ${totalAEs}</b>`
        )
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      const classVar = drillDownPath[drillDownLevel]

      let condition = ''
      for (let i = 0; i < drillDownLevel; i++) {
        condition += ` AND ${drillDownPath[i]}='${drillVars[i]}'`
      }

      // call async func to fetch data
      const AEBODSYSsummary = await apiController.getAEBODSYSsummary(
        classVar,
        condition
      )

      // handle fetched data
      if (isMounted) {
        const barOptions = dataToBarOptions(
          AEBODSYSsummary,
          classVar,
          ['numberOfAEs', 'numberOfSubjects'],
          contrastingColor
        )

        setNewOptions(barOptions)
        setData(AEBODSYSsummary as any)
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [
    apiController,
    contrastingColor,
    drillDownLevel,
    drillDownPath,
    drillVars
  ])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      let condition = ` AND ${drillDownPath.slice(-1)[0]}='${aeTerm}'`

      // call async func to fetch data
      const subjects = await apiController.getAESubjects(condition)

      // handle fetched data
      if (isMounted) {
        const subjectIds = subjects.map((subject) => subject.SUBJID)

        setSnackBarOpen(true)
        setShowSubjects(true)
        setSubjectsDrilledTo(subjectIds)
      }
    }

    if (aeTerm) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [aeTerm, apiController, drillDownPath])

  useEffect(() => {
    let subtitle = globalFilter(selectedFilterState) || ''
    const { ae } = variablesLabelsState || {}

    const catVar = drillDownPath[drillDownLevel]
    const drillDownLevelLabel =
      'ae' in (variablesLabelsState || {}) && catVar in ae ? ae[catVar] : catVar

    const whatHaveIDrilledDownTo = () => {
      const tempWhereClause = []
      const { ae } = variablesLabelsState || {}

      for (let i = 0; i < drillDownLevel; i++) {
        const catVar = drillDownPath[i]
        const drillDownLevelLabel = catVar in ae ? ae[catVar] : catVar

        tempWhereClause.push(drillDownLevelLabel + '="' + drillVars[i] + '"')
      }

      return tempWhereClause.join(' and ')
    }

    const commonOptions = {
      chart: {
        type: 'bar',
        height: (screenState?.height || 1) * 0.75,
        zoomType: 'xy',
        backgroundColor: 'transparent'
      },
      xAxis: { labels: { style: { color: contrastingColor } } },
      yAxis: { title: { text: 'Number of Events' } },
      legend: { itemStyle: { color: contrastingColor } },
      title: {
        text: `Adverse Events by ${drillDownLevelLabel} (${
          selectedStudyState?.STUDYID || ''
        })`,
        style: { color: contrastingColor }
      },
      subtitle: {
        text: caption + '<br/>' + subtitle + '<br/>' + whatHaveIDrilledDownTo(),
        style: { color: contrastingColor }
      },
      plotOptions: {
        column: { colorByPoint: true },
        bar: {
          dataLabels: { enabled: true, style: { color: contrastingColor } },
          point: {
            events: {
              click: function (event: any) {
                const { point } = event
                const { category } = point

                if (drillDownLevel < drillDownPath.length - 1) {
                  // drilling down to next level
                  setDrillDownLevel(drillDownLevel + 1)
                  setDrillVars([...drillVars, category])
                } else {
                  setAeTerm(category)
                }
              }
            }
          }
        }
      },
      colors: ['#f4decd', '#e4b293'],
      tooltip: {
        formatter: function (): any {
          const { series, point } = this as any

          return (
            '<b>' +
            series.name +
            ', Body System = ' +
            point.category +
            '</b><br/>' +
            'Number of AEs or Subjects: ' +
            Highcharts.numberFormat(Math.abs(point.y), 0)
          )
        }
      },
      credits: { enabled: false },
      exporting: { enabled: false }
    }

    const updatedOptions = { ...commonOptions, ...newOptions }

    setChartOptions(updatedOptions)
  }, [
    data,
    caption,
    drillDownLevel,
    drillVars,
    newOptions,
    drillDownPath,
    subjectsDrilledTo,
    contrastingColor,
    screenState,
    selectedFilterState,
    selectedStudyState?.STUDYID,
    variablesLabelsState
  ])

  return (
    <>
      {drillDownLevel > 0 ? (
        // REFACTOR: make a component
        <span
          style={{
            position: 'fixed',
            bottom: '100px',
            right: '40px',
            zIndex: 100
          }}
        >
          <Tooltip title="Level we have drilled down to">
            <Fab
              size="small"
              variant="extended"
              color="info"
              sx={{ margin: 1 }}
              onClick={drillUp}
            >
              Level {drillDownLevel}
            </Fab>
          </Tooltip>
          <Tooltip title="Drill up">
            <Fab size="small" color="info" sx={{ margin: 1 }} onClick={drillUp}>
              <ArrowDropUpIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Go to top level">
            <Fab size="small" color="info" sx={{ margin: 1 }} onClick={top}>
              <ArrowUpwardIcon />
            </Fab>
          </Tooltip>
        </span>
      ) : null}
      {chartOptions && newOptions && (
        <div id={chartContainerId}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          <ChartDownload elementId={chartContainerId} fileName="AE-Drilldown" />
        </div>
      )}
      {showSubjects && (
        <LookBelow
          label="Subject Table"
          tooltip="Click to scroll to subject table appears below"
          mt={2}
          ml={4}
          mr={0}
          mb={0}
        />
      )}
      {showSubjects ? (
        subjectsDrilledTo.length ? (
          <SubjectTable
            selectedSubjects={subjectsDrilledTo}
            filterOptions={[{ key: 'AE', value: aeTerm }]}
            theme={theme}
            apiController={apiController}
          />
        ) : (
          <Alert
            icon={<InfoIcon fontSize="inherit" />}
            severity="info"
            style={{ margin: '50px 50px 0px 50px' }}
          >
            No subjects matching searching criteria found.
          </Alert>
        )
      ) : null}
      <Snackbar
        message={"Can't drill any lower"}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackBarOpen(false)}
      />
    </>
  )
}
