export const RESTORE_STATE = 'RESTORE_STATE'

export const SET_ACCESS_KEY = 'SET_ACCESS_KEY'
export const SET_USERNAME_BASED_ON_ACCESS_KEY =
  'SET_USERNAME_BASED_ON_ACCESS_KEY'
export const SET_ACCESS_KEY_AND_USERNAME = 'SET_ACCESS_KEY_AND_USERNAME'

export const SET_STUDIES = 'SET_STUDIES'
export const SET_SELECTED_STUDY = 'SET_SELECTED_STUDY'

export const SET_DATE_COUNTS = 'SET_DATE_COUNTS'
export const SET_MIN_VISIT_DATE = 'SET_MIN_VISIT_DATE'
export const SET_MAX_VISIT_DATE = 'SET_MAX_VISIT_DATE'
export const SET_VISIT_DATES_NUMBER = 'SET_VISIT_DATES_NUMBER'
export const SET_BASELINES = 'SET_BASELINES'

export const SET_SELECTED_SUBJECTS = 'SET_SELECTED_SUBJECTS'

export const SET_AE_BOTh_CHART = 'SET_AE_BOTh_CHART'
export const SET_AE_SUMMARY = 'SET_AE_SUMMARY'
export const SET_AE_STREAM_GRAPH = 'SET_AE_STREAM_GRAPH'

export const SET_FILTER_OPTION_TO_TABLE_MAP = 'SET_FILTER_OPTION_TO_TABLE_MAP'
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS'
export const REMOVE_FILTER_OPTION = 'REMOVE_FILTER_OPTION'
export const SET_FILTER_OBJECT = 'SET_FILTER_OBJECT'
export const CLEAR_FILTER = 'CLEAR_FILTER'

export const SET_LB_RANGES = 'SET_LB_RANGES'
export const SET_LB_BOX_DATA_OPTIONS = 'SET_LB_BOX_DATA_OPTIONS'

export const SET_VARIABLE_LABELS = 'SET_VARIABLE_LABELS'
export const SET_AE_VARIABLES = 'SET_AE_VARIABLES'

export const SET_PREFERENCES_SCREEN = 'SET_PREFERENCES_SCREEN'
export const SET_PREFERENCES_VITAL_SIGNS = 'SET_PREFERENCES_VITAL_SIGNS'
export const SET_PREFERENCES_DRILLDOWN_PATH = 'SET_PREFERENCES_DRILLDOWN_PATH'

export const SET_TABLE_INFO = 'SET_TABLE_INFO'
export const SET_TABLE_DATA = 'SET_TABLE_DATA'
export const SET_TABLE_COLUMNS = 'SET_TABLE_COLUMNS'
