import * as ActionTypes from '../actionTypes'
import { FilterState, FilterDispatchTypes } from './types'

const initialState: FilterState = {
  selected: null,
  optionToTableMap: null,
  object: null
}

const reducer = (
  state = initialState,
  action: FilterDispatchTypes
): FilterState => {
  switch (action.type) {
    case ActionTypes.SET_FILTER_OBJECT:
      return {
        ...state,
        object: action.payload
      }

    case ActionTypes.SET_FILTER_OPTION_TO_TABLE_MAP:
      return {
        ...state,
        optionToTableMap: action.payload
      }

    case ActionTypes.SET_FILTER_OPTIONS:
      const newState = { ...state }

      if (newState.selected) {
        newState.selected = { ...newState.selected, ...action.payload }
      } else {
        newState.selected = action.payload
      }

      return newState

    case ActionTypes.REMOVE_FILTER_OPTION:
      const stateCopy: { [key: string]: any } = JSON.parse(
        JSON.stringify(state)
      )

      delete stateCopy.selected[action.payload]

      return { ...stateCopy } as FilterState

    case ActionTypes.CLEAR_FILTER:
      return {
        ...state,
        selected: null
      }

    case ActionTypes.RESTORE_STATE:
      return action.payload.filter || initialState

    default:
      return state
  }
}

export default reducer
