import * as ActionTypes from '../actionTypes'
import { DateCount } from '../../types'
import {
  SetDateCountsAction,
  SetMinVisitDateAction,
  SetMaxVisitDateAction,
  SetVisitDatesNumberAction,
  SetBaselinesAction
} from './types'

export const setDateCountsAction = (
  payload: DateCount[]
): SetDateCountsAction => ({
  type: ActionTypes.SET_DATE_COUNTS,
  payload
})

export const setMinVisitDateAction = (
  payload: string
): SetMinVisitDateAction => ({
  type: ActionTypes.SET_MIN_VISIT_DATE,
  payload
})

export const setMaxVisitDateAction = (
  payload: string
): SetMaxVisitDateAction => ({
  type: ActionTypes.SET_MAX_VISIT_DATE,
  payload
})

export const setVisitDatesNumberAction = (
  payload: number
): SetVisitDatesNumberAction => ({
  type: ActionTypes.SET_VISIT_DATES_NUMBER,
  payload
})

export const setBaselinesAction = (payload: {
  [key: string]: string
}): SetBaselinesAction => ({
  type: ActionTypes.SET_BASELINES,
  payload
})
