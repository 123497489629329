import html2canvas from 'html2canvas'
import { downloadImage } from './file'

export const highchartsExportingOptions = (
  fileName: string,
  elementId: string
) => ({
  exporting: {
    menuItemDefinitions: {
      label: {
        onclick: () => {
          const element = document.getElementById(elementId)

          if (element) {
            html2canvas(element).then((canvas) => {
              downloadImage(canvas, fileName)
            })
          } else {
            throw new Error(`Element with id '${elementId}' wasn't found.`)
          }
        },
        text: 'Save as file'
      }
    },
    buttons: {
      contextButton: {
        menuItems: ['label', 'separator', 'viewFullscreen']
      }
    }
  }
})
