import * as ActionTypes from '../actionTypes'
import { TablesState, TableInfoDispatchTypes } from './types'

const initialState: TablesState = { info: null, data: null, columns: null }

const reducer = (
  state = initialState,
  action: TableInfoDispatchTypes
): TablesState => {
  switch (action.type) {
    case ActionTypes.SET_TABLE_INFO:
      return { ...state, info: action.payload }

    case ActionTypes.SET_TABLE_DATA:
      return { ...state, data: { ...state.data, ...action.payload } }

    case ActionTypes.SET_TABLE_COLUMNS:
      return { ...state, columns: { ...state.columns, ...action.payload } }

    case ActionTypes.RESTORE_STATE:
      return action.payload.tables || { info: null, data: null, columns: null }

    default:
      return state
  }
}

export default reducer
