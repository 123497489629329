import { useState, useEffect } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import {
  globalFilter,
  addToHistory,
  openSaveFileDialog
} from '../../../apis/utility'
import { useLocation } from 'react-router-dom'
import { Box, IconButton, Button, Tooltip } from '@mui/material'
import { usePapaParse } from 'react-papaparse'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { useAppSelector } from '../../../hooks'
import { ApiController } from '../../../controllers/ApiController'

interface VSListingProps {
  apiController: ApiController
  normalRanges: any
}

export default function VSListing(props: VSListingProps) {
  const { apiController, normalRanges } = props
  const location = useLocation()

  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)
  const screenState = useAppSelector((state) => state.preferences.screen)

  addToHistory({ title: 'VS Listing', url: location })

  const [vsData, setVsData] = useState<any>([])
  const [subtitle, setSubtitle] = useState<any>(null)
  const [columns, setColumns] = useState<any>(null)

  const { jsonToCSV } = usePapaParse()
  const exportData = () => {
    openSaveFileDialog(jsonToCSV(vsData), 'vs', 'text/csv')
  }

  const lowSys = normalRanges.SYSBP.low
  const highSys = normalRanges.SYSBP.high
  const lowDia = normalRanges.DIABP.low
  const highDia = normalRanges.DIABP.high
  const lowPulse = normalRanges.PULSE.low
  const highPulse = normalRanges.PULSE.high

  useEffect(() => {
    let isMounted = false

    setSubtitle(globalFilter(selectedFilterState) || '')

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const data = await apiController.getVSpulseAndPressure()

      // handle fetched data
      if (isMounted) {
        const tempData = data.map((row, id) => ({
          ...row,
          id: id
        }))

        setVsData(tempData)
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, selectedFilterState])

  useEffect(() => {
    const getValues = (data: any, variable: any) =>
      data
        .map((row: any) => row[variable])
        .filter((value: any) => Number.isFinite(value))

    const renderProgress = (cellValues: any, variable: any) => {
      const { value } = cellValues
      const flex = (value - min[variable]) / (max[variable] - min[variable])

      let backgroundColor = '#ffffff'

      if (variable === 'PULSE') {
        backgroundColor =
          value <= lowPulse
            ? '#b3d1ff'
            : value >= highPulse
              ? '#ffcccc'
              : '#ffff99'
      } else if (variable === 'SYSBP') {
        backgroundColor =
          value <= lowSys ? '#b3d1ff' : value >= highSys ? '#ffcccc' : '#ffff99'
      } else if (variable === 'DIABP') {
        backgroundColor =
          value <= lowDia ? '#b3d1ff' : value >= highDia ? '#ffcccc' : '#ffff99'
      }

      return (
        <Box
          sx={{
            flex: { flex },
            backgroundColor: backgroundColor,
            color: 'black'
          }}
        >
          {value}
        </Box>
      )
    }

    const pulseValues: any = getValues(vsData, 'PULSE')
    const opulseValues: any = getValues(vsData, 'OPULSE')
    const sysbpValues: any = getValues(vsData, 'SYSBP')
    const osysbpValues: any = getValues(vsData, 'OSYSBP')
    const diabpValues: any = getValues(vsData, 'DIABP')
    const odiabpValues: any = getValues(vsData, 'ODIABP')
    const min: any = []
    const max: any = []

    min['PULSE'] = Math.min(...pulseValues)
    max['PULSE'] = Math.max(...pulseValues)
    min['OPULSE'] = Math.min(...opulseValues)
    max['OPULSE'] = Math.max(...opulseValues)
    min['SYSBP'] = Math.min(...sysbpValues)
    max['SYSBP'] = Math.max(...sysbpValues)
    min['OSYSBP'] = Math.min(...osysbpValues)
    max['OSYSBP'] = Math.max(...osysbpValues)
    min['DIABP'] = Math.min(...diabpValues)
    max['DIABP'] = Math.max(...diabpValues)
    min['ODIABP'] = Math.min(...odiabpValues)
    max['ODIABP'] = Math.max(...odiabpValues)

    const cols = [
      {
        width: 80,
        headerName: 'Subject',
        field: 'SUBJID',
        renderCell: (cellValues: any) => {
          const { value } = cellValues
          const target = `${window.location.protocol}//${window.location.host}/#/patientprofile/${selectedStudyState?.studyDB}/${value}`

          return (
            <Button
              onClick={() => {
                window.open(target)
              }}
            >
              {value}
            </Button>
          )
        }
      },
      { width: 50, headerName: 'Day', field: 'day' },
      { width: 90, headerName: 'Visit', field: 'VISIT' },
      { width: 140, headerName: 'Date', field: 'VSDTC' },
      {
        width: 105,
        headerName: 'Pulse',
        field: 'PULSE',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'PULSE')
        }
      },
      {
        width: 105,
        headerName: 'Systolic BP',
        field: 'SYSBP',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'SYSBP')
        }
      },
      {
        width: 105,
        headerName: 'Diastolic BP',
        field: 'DIABP',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'DIABP')
        }
      },
      {
        width: 105,
        headerName: 'Orth. Pulse',
        field: 'OPULSE',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'OPULSE')
        }
      },
      {
        width: 105,
        headerName: 'Ortho. Systolic BP',
        field: 'OSYSBP',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'DIABP')
        }
      },
      {
        width: 105,
        headerName: 'Ortho. Diastolic BP',
        field: 'ODIABP',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'DIABP')
        }
      }
    ]

    setColumns(cols)
    // eslint-disable-next-line
  }, [vsData, highPulse, lowPulse, highSys, lowSys, highDia, lowDia])

  return (
    <>
      <h4>
        Vital Signs ({selectedStudyState?.STUDYID})
        <Tooltip title="Download table">
          <IconButton color="primary" onClick={exportData}>
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
        <br />
        <span style={{ fontSize: 12 }}>{subtitle}</span>
      </h4>
      {vsData && columns ? (
        <div
          style={{ height: (screenState?.height || 1) * 0.8, width: '100%' }}
        >
          <DataGridPro
            rows={vsData}
            columns={columns}
            density={'compact'}
            rowHeight={30}
            sx={{ fontSize: '0.7em' }}
          />
        </div>
      ) : (
        'Loading...'
      )}
    </>
  )
}
