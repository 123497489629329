import { useState, useEffect } from 'react'
import axios from 'axios'

const Pythondemo = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_XPLORATUM_API}/python/test`)
      .then((response) => {
        if (response.statusText === 'OK') setData(response.data)
      })
  }, [])

  return (
    <div>
      <h1>Python demo</h1>
      <b>The following was returned by running a Python program.</b>
      <p>{data}</p>
    </div>
  )
}

export default Pythondemo
