import * as ActionTypes from '../actionTypes'
import { SubjectsState, SubjectsDispatchTypes } from './types'

const initialState: SubjectsState = { selected: null }

const reducer = (
  state = initialState,
  action: SubjectsDispatchTypes
): SubjectsState => {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_SUBJECTS:
      return {
        ...state,
        selected: action.payload
      }

    case ActionTypes.RESTORE_STATE:
      return action.payload.subjects || null

    default:
      return state
  }
}

export default reducer
