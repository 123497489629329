import FusionCharts from 'fusioncharts'
import charts from 'fusioncharts/fusioncharts.charts'
// import zoomscatter from 'fusioncharts/fusioncharts.zoomscatter'
import { Box } from '@mui/material'
import FusionChartJSX from '../ui/FusionChartJSX'

// Resolves charts dependancy
// FIXME
// charts(FusionCharts, zoomscatter )
charts(FusionCharts)

const dataSource = {
  chart: {
    yAxisMaxValue: 120,
    yAxisMinValue: 40,
    showalloutliers: '1',
    minimizeTendency: '1'
  },
  categories: [
    {
      category: [
        {
          label: '-13'
        },
        {
          label: '-10'
        },
        {
          label: '-9'
        },
        {
          label: '-8'
        },
        {
          label: '-7'
        },
        {
          label: '-6'
        },
        {
          label: '-5'
        },
        {
          label: '-4'
        },
        {
          label: '-1'
        },
        {
          label: '2'
        },
        {
          label: '3'
        },
        {
          label: '4'
        },
        {
          label: '5'
        },
        {
          label: '6'
        },
        {
          label: '7'
        },
        {
          label: '8'
        },
        {
          label: '9'
        },
        {
          label: '10'
        },
        {
          label: '11'
        },
        {
          label: '12'
        },
        {
          label: '13'
        },
        {
          label: '14'
        },
        {
          label: '15'
        },
        {
          label: '16'
        },
        {
          label: '17'
        },
        {
          label: '18'
        },
        {
          label: '19'
        },
        {
          label: '20'
        },
        {
          label: '21'
        },
        {
          label: '22'
        },
        {
          label: '23'
        },
        {
          label: '24'
        },
        {
          label: '25'
        },
        {
          label: '26'
        },
        {
          label: '27'
        },
        {
          label: '29'
        },
        {
          label: '30'
        },
        {
          label: '31'
        }
      ]
    }
  ],
  dataset: [
    {
      seriesname: 'PULSE',
      data: [
        {
          value: ',,'
        },
        {
          value: '75,69,75,69,75,69'
        },
        {
          value: ',,'
        },
        {
          value: '84,84,84'
        },
        {
          value: '73,64,73,64,73,64'
        },
        {
          value: '67,67,67'
        },
        {
          value: '56,71,56,71,56,71'
        },
        {
          value: '81,81,81'
        },
        {
          value: '72,62,66,64,72,73,79,66,57,56,60,80,73'
        },
        {
          value: '81,68,59,70,75,69,86,58,64,60,82,81'
        },
        {
          value: '70,66,70,74,79,69,76,54,61,84,81,77'
        },
        {
          value: '73,77,60,70,72,66,78,54,61,71,74,83'
        },
        {
          value: '71,83,65,69,74,64,66,67,58,73,82,82'
        },
        {
          value: '89,70,64,71,84,74,65,56,56,91,79,78'
        },
        {
          value: '74,68,66,68,69,69,61,59,56,76,70,81'
        },
        {
          value: '70,69,61,73,73,69,94,56,59,68,82,80'
        },
        {
          value: '75,72,61,70,70,66,66,55,55,80,73,72'
        },
        {
          value: '72,76,62,73,71,67,64,60,56,72,74,72'
        },
        {
          value: '73,74,70,66,76,67,63,57,55,70,75,77'
        },
        {
          value: '74,86,70,75,71,78,61,60,58,77,73,80'
        },
        {
          value: '70,76,65,72,68,65,60,57,57,70,72,75'
        },
        {
          value: '69,68,60,62,75,65,62,54,58,64,80,70'
        },
        {
          value: '73,73,63,62,71,73,69,60,51,83,77,65'
        },
        {
          value: '72,84,61,72,70,71,69,51,55,68,75,68'
        },
        {
          value: '73,68,61,72,66,62,61,58,56,67,75,75'
        },
        {
          value: '74,69,61,66,70,65,62,59,56,63,71,74'
        },
        {
          value: '70,80,60,81,66,67,64,55,52,70,76,71'
        },
        {
          value: '73,70,64,70,66,69,63,61,54,64,77,72'
        },
        {
          value: '69,76,65,74,73,76,64,56,54,64,77,61'
        },
        {
          value: '74,80,60,75,73,74,64,52,57,68,73,69'
        },
        {
          value: '71,76,70,70,67,67,59,64,57,61,73,68'
        },
        {
          value: '74,73,70,69,67,68,59,61,56,68,71,81'
        },
        {
          value: '71,75,71,72,64,75,65,53,58,56,77,85'
        },
        {
          value: '71,87,72,79,73,63,64,53,54,66,76,81'
        },
        {
          value: '74,79,67,79,70,73,61,54,55,64,81,69'
        },
        {
          value: '80,70,63,72,75,70,59,62,55,68,83,81'
        },
        {
          value: '77,82,67,65,70,69,78,65,64,61,80,84'
        },
        {
          value: '74,71,70,81,79,68,68,59,60,68,81,72'
        }
      ]
    }
  ]
}

export default function TestGraph(props: any) {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <FusionChartJSX
        type="boxandwhisker2d"
        width="100%"
        height="650"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </Box>
  )
}
