import { combineReducers } from 'redux'
import {
  Study,
  DateCount,
  Subject,
  AEsummary,
  FilterOptions,
  FilterOptionToTableMap,
  FilterObject,
  LBrangesObject,
  VariableLabelsObject,
  PreferencesScreen,
  PreferencesVitalSigns,
  PreferencesDrillDownPath,
  AEBOThChart,
  AEStreamGraph,
  TableInfoObject,
  TableDataObject,
  TableColumnsObject
} from '../types'
import studyReducer from './study/reducer'
import vdReducer from './vd/reducer'
import subjectsReducer from './subjects/reducer'
import aeReducer from './ae/reducer'
import filterReducer from './filter/reducer'
import lbReducer from './lb/reducer'
import variablesReducer from './variables/reducer'
import preferencesReducer from './preferences/reducer'
import tablesReducer from './tables/reducer'
import accessReducer from './access/reducer'

export interface State {
  studies: {
    list: Study[]
    selected: Study
  }
  vd: {
    dateCounts: DateCount[]
    minVisitDate: string
    maxVisitDate: string
    visitDatesNumber: number
    baselines: { [key: string]: string }
  }
  subjects: { selected: Subject[] | null }
  ae: {
    summary: AEsummary | null
    AEBOThChart: AEBOThChart | null
    AEStreamGraph: AEStreamGraph | null
  }
  filter: {
    optionToTableMap: FilterOptionToTableMap | null
    selected: FilterOptions | null
    object: FilterObject | null
  }
  lb: {
    ranges: LBrangesObject | null
    boxDataOptions: { [key: string]: any } | null
  }
  variables: { labels: VariableLabelsObject | null; ae: string[] | null }
  preferences: {
    screen: PreferencesScreen | null
    vitalSigns: PreferencesVitalSigns | null
    drillDownPath: PreferencesDrillDownPath | null
  }
  tables: {
    info: TableInfoObject | null
    data: TableDataObject | null
    columns: TableColumnsObject | null
  }
  access: { key: string | null; userName: string | null }
}

export default combineReducers({
  studies: studyReducer,
  vd: vdReducer,
  subjects: subjectsReducer,
  ae: aeReducer,
  filter: filterReducer,
  lb: lbReducer,
  variables: variablesReducer,
  preferences: preferencesReducer,
  tables: tablesReducer,
  access: accessReducer
})
