import { useState, useEffect } from 'react'
import {
  Divider,
  Tooltip,
  TextField,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tab
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { compareObjects } from '../../utils'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { ApiController } from '../../controllers/ApiController'
import {
  setPreferencesScreenAction,
  setPreferencesVitalSignsAction,
  setPreferencesDrillDownPathAction,
  setAEVariableAction
} from '../../store/action'

interface PreferencesProps {
  apiController: ApiController
}

// TODO: fix selected tab after change
export default function Preferences(props: PreferencesProps) {
  const { apiController } = props

  const dispatch = useAppDispatch()
  const preferencesState = useAppSelector((state) => state.preferences)
  const variablesState = useAppSelector((state) => state.variables)
  const { labels: variableLabels } = variablesState
  const lbState = useAppSelector((state) => state.lb)
  const { ranges: lbRanges } = lbState

  let lastCategory = ''

  const [screenWidth, setScreenWidth] = useState<number | undefined>(
    preferencesState.screen?.width
  )
  const [screenHeight, setScreenHeight] = useState<number | undefined>(
    preferencesState.screen?.height
  )
  const [lowSys, setLowSys] = useState<number | undefined>(
    preferencesState.vitalSigns?.systolicBloodPressure.low
  )
  const [highSys, setHighSys] = useState<number | undefined>(
    preferencesState.vitalSigns?.systolicBloodPressure.high
  )
  const [lowDia, setLowDia] = useState<number | undefined>(
    preferencesState.vitalSigns?.diastolicBloodPressure.low
  )
  const [highDia, setHighDia] = useState<number | undefined>(
    preferencesState.vitalSigns?.diastolicBloodPressure.high
  )
  const [lowPulse, setLowPulse] = useState<number | undefined>(
    preferencesState.vitalSigns?.pulse.low
  )
  const [highPulse, setHighPulse] = useState<number | undefined>(
    preferencesState.vitalSigns?.pulse.high
  )
  const [drillDownPath, setDrillDownPath] = useState<string[] | null>(
    preferencesState.drillDownPath
  )
  const [aeVars, setAeVars] = useState<string[] | null>(variablesState.ae)
  const [tabValue, setTabValue] = useState<string>('1')

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const aeColumnNames = await apiController.getAEcolumnNames()

      // handle fetched data
      if (isMounted && !compareObjects(variablesState.ae, aeColumnNames)) {
        dispatch(setAEVariableAction(aeColumnNames))

        setAeVars(aeColumnNames)
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, dispatch, variablesState.ae])

  return (
    <>
      {screenHeight && screenWidth && (
        <Container sx={{ height: screenHeight * 0.8 }}>
          <h4>Preferences</h4>
          <TabContext value={tabValue}>
            <TabList onChange={(e, v) => setTabValue(v)}>
              <Tab label="Lab Limits" value="1" />
              <Tab label="Screen Size" value="2" />
              <Tab label="Vital Signs Limits" value="3" />
              <Tab label="Adverse Events Drill Down Path" value="4" />
            </TabList>
            <Divider />
            <TabPanel
              value={'1'}
              //  index={0}
            >
              <h5>Check Lab results using these normal ranges</h5>
              {lbRanges &&
                Object.keys(lbRanges).map((test, index1) =>
                  Object.keys(lbRanges[test]).map((level, index2) => {
                    const flag = lastCategory !== lbRanges[test].category

                    lastCategory = lbRanges[test].category

                    if (!['test', 'category'].includes(level)) {
                      return (
                        <span key={index2}>
                          {flag ? (
                            <h5 key={'h5-' + index1 + '-' + index2}>
                              {lbRanges[test].category}
                            </h5>
                          ) : null}
                          <Tooltip
                            key={'labtest-' + index1 + '-' + index2}
                            title={
                              lbRanges[test].category +
                              ' - ' +
                              level +
                              ' - ' +
                              lbRanges[test].test
                            }
                          >
                            <TextField
                              margin="dense"
                              size="small"
                              label={level + ' - ' + test}
                              value={(lbRanges[test] as any)[level]}
                              sx={{ width: 140 }}
                              key={'textfield-' + index1 + '-' + index2}
                              InputProps={{
                                readOnly: true
                              }}
                            />
                          </Tooltip>
                        </span>
                      )
                    } else return null
                  })
                )}
            </TabPanel>
            {screenHeight && screenWidth && (
              <TabPanel value="2">
                <h5>Settings</h5>
                <TextField
                  id="screenWidth"
                  name="screenWidth"
                  label="Screen Width"
                  value={screenWidth}
                  size="small"
                  onChange={(event) => {
                    const { value } = event.target
                    const width = parseInt(value)

                    if (width) setScreenWidth(width)
                  }}
                  onBlur={(event) => {
                    const { value } = event.target
                    const width = parseInt(value)

                    if (width) {
                      dispatch(
                        setPreferencesScreenAction({
                          height: screenHeight,
                          width
                        })
                      )
                    }
                  }}
                />
                <TextField
                  id="screenHeight"
                  name="screenHeight"
                  label="Screen Height"
                  value={screenHeight}
                  size="small"
                  onChange={(event) => {
                    const { value } = event.target
                    const height = parseInt(value)

                    if (height) setScreenHeight(height)
                  }}
                  onBlur={(event) => {
                    const { value } = event.target
                    const height = parseInt(value)

                    if (height) {
                      dispatch(
                        setPreferencesScreenAction({
                          width: screenWidth,
                          height
                        })
                      )
                    }
                  }}
                />
              </TabPanel>
            )}

            <TabPanel value={'3'}>
              <h5>Vital Signs (Limits of Normal)</h5>
              <TextField
                id="sysbp-low"
                name="sysbp-low"
                label="Low Systolic Blood Pressure"
                value={lowSys}
                size="small"
                onChange={(event) => {
                  const { value } = event.target
                  const lowSys = parseInt(value)

                  if (lowSys) setLowSys(lowSys)
                }}
                onBlur={(event) => {
                  const { value } = event.target
                  const lowSys = parseInt(value)

                  if (lowSys) {
                    dispatch(
                      setPreferencesVitalSignsAction({
                        systolicBloodPressure: {
                          high: highSys,
                          low: lowSys
                        },
                        diastolicBloodPressure: {
                          high: highDia,
                          low: lowDia
                        },
                        pulse: {
                          high: highPulse,
                          low: lowPulse
                        }
                      })
                    )
                  }
                }}
              />
              <TextField
                id="sysbp-high"
                name="sysbp-high"
                label="High Systolic Blood Pressure"
                value={highSys}
                size="small"
                onBlur={(event) => {
                  const { value } = event.target
                  const highSys = parseInt(value)

                  if (highSys) {
                    dispatch(
                      setPreferencesVitalSignsAction({
                        systolicBloodPressure: {
                          high: highSys,
                          low: lowSys
                        },
                        diastolicBloodPressure: {
                          high: highDia,
                          low: lowDia
                        },
                        pulse: {
                          high: highPulse,
                          low: lowPulse
                        }
                      })
                    )
                  }
                }}
                onChange={(event) => {
                  const { value } = event.target
                  const highSys = parseInt(value)

                  if (highSys) setHighSys(highSys)
                }}
              />
              <p />
              <TextField
                id="diabp-low"
                name="diabp-low"
                label="Low Diastolic Blood Pressure"
                value={lowDia}
                size="small"
                onChange={(event) => {
                  const { value } = event.target
                  const lowDia = parseInt(value)

                  if (lowDia) setLowDia(lowDia)
                }}
                onBlur={(event) => {
                  const { value } = event.target
                  const lowDia = parseInt(value)

                  if (lowDia) {
                    dispatch(
                      setPreferencesVitalSignsAction({
                        systolicBloodPressure: {
                          high: highSys,
                          low: lowSys
                        },
                        diastolicBloodPressure: {
                          high: highDia,
                          low: lowDia
                        },
                        pulse: {
                          high: highPulse,
                          low: lowPulse
                        }
                      })
                    )
                  }
                }}
              />
              <TextField
                id="diabp-high"
                name="diabp-high"
                label="High Diastolic Blood Pressure"
                value={highDia}
                size="small"
                onChange={(event) => {
                  const { value } = event.target
                  const highDia = parseInt(value)

                  if (highDia) setHighDia(highDia)
                }}
                onBlur={(event) => {
                  const { value } = event.target
                  const highDia = parseInt(value)

                  if (highDia) {
                    dispatch(
                      setPreferencesVitalSignsAction({
                        systolicBloodPressure: {
                          high: highSys,
                          low: lowSys
                        },
                        diastolicBloodPressure: {
                          high: highDia,
                          low: lowDia
                        },
                        pulse: {
                          high: highPulse,
                          low: lowPulse
                        }
                      })
                    )
                  }
                }}
              />
              <p />
              <TextField
                id="pulse-low"
                name="pulse-low"
                label="Low Pulse"
                value={lowPulse}
                size="small"
                onChange={(event) => {
                  const { value } = event.target
                  const lowPulse = parseInt(value)

                  if (lowPulse) setLowPulse(lowPulse)
                }}
                onBlur={(event) => {
                  const { value } = event.target
                  const lowPulse = parseInt(value)

                  if (lowPulse) {
                    dispatch(
                      setPreferencesVitalSignsAction({
                        systolicBloodPressure: {
                          high: highSys,
                          low: lowSys
                        },
                        diastolicBloodPressure: {
                          high: highDia,
                          low: lowDia
                        },
                        pulse: {
                          high: highPulse,
                          low: lowPulse
                        }
                      })
                    )
                  }
                }}
              />
              <TextField
                id="pulse-high"
                name="pulse-high"
                label="High Pulse"
                value={highPulse}
                size="small"
                onChange={(event) => {
                  const { value } = event.target
                  const highPulse = parseInt(value)

                  if (highPulse) setHighPulse(highPulse)
                }}
                onBlur={(event) => {
                  const { value } = event.target
                  const highPulse = parseInt(value)

                  if (highPulse) {
                    dispatch(
                      setPreferencesVitalSignsAction({
                        systolicBloodPressure: {
                          high: highSys,
                          low: lowSys
                        },
                        diastolicBloodPressure: {
                          high: highDia,
                          low: lowDia
                        },
                        pulse: {
                          high: highPulse,
                          low: lowPulse
                        }
                      })
                    )
                  }
                }}
              />
              <p />
            </TabPanel>

            <TabPanel value={'4'}>
              <h5>Adverse Events - Drill Path</h5>
              {aeVars &&
                drillDownPath &&
                drillDownPath.map((item: string, index: number) => (
                  <FormControl
                    key={'form-' + index}
                    sx={{ m: 1, minWidth: 120 }}
                    size="small"
                  >
                    <InputLabel>{'Level-' + index}</InputLabel>
                    <Select
                      value={item}
                      label={'Level ' + index}
                      onChange={(event) => {
                        const { value } = event.target

                        if (value) {
                          let updateDrillDownPath = [...drillDownPath]
                          updateDrillDownPath[index] = value

                          dispatch(
                            setPreferencesDrillDownPathAction(
                              updateDrillDownPath
                            )
                          )

                          setDrillDownPath(updateDrillDownPath)
                        }
                      }}
                      key={'select-' + index}
                    >
                      {variableLabels &&
                        aeVars.map((item: any, index2: any) => {
                          const label = variableLabels.ae[item]
                            ? variableLabels.ae[item]
                            : item

                          return (
                            <MenuItem
                              key={'MenuItem-' + index + '-' + index2}
                              value={item}
                            >
                              {label}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                ))}
            </TabPanel>
          </TabContext>
          <p>&nbsp;</p>
        </Container>
      )}
    </>
  )
}
