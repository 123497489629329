import * as ActionTypes from '../actionTypes'
import { SetSelectedSubjects } from './types'
import { Subject } from '../../types'

export const setSelectedSubjectsAction = (
  payload: Subject[]
): SetSelectedSubjects => ({
  type: ActionTypes.SET_SELECTED_SUBJECTS,
  payload
})
