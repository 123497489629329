import * as ActionTypes from '../actionTypes'
import { VDdispatchTypes, VDstate } from './types'

const initialState: VDstate = {
  dateCounts: null,
  minVisitDate: null,
  maxVisitDate: null,
  visitDatesNumber: null,
  baselines: null
}

const reducer = (state = initialState, action: VDdispatchTypes): VDstate => {
  switch (action.type) {
    case ActionTypes.SET_DATE_COUNTS:
      return {
        ...state,
        dateCounts: action.payload
      }

    case ActionTypes.SET_MIN_VISIT_DATE:
      return {
        ...state,
        minVisitDate: action.payload
      }

    case ActionTypes.SET_MAX_VISIT_DATE:
      return {
        ...state,
        maxVisitDate: action.payload
      }

    case ActionTypes.SET_VISIT_DATES_NUMBER:
      return {
        ...state,
        visitDatesNumber: action.payload
      }

    case ActionTypes.SET_BASELINES:
      return {
        ...state,
        baselines: action.payload
      }

    case ActionTypes.RESTORE_STATE:
      return action.payload.vd || null

    default:
      return state
  }
}

export default reducer
