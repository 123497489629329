import * as ActionTypes from '../actionTypes'
import { Study } from '../../types'
import { SetStudies, SetSelectedStudy } from './types'

export const setStudiesAction = (payload: Study[]): SetStudies => ({
  type: ActionTypes.SET_STUDIES,
  payload
})

export const setSelectedStudyAction = (payload: Study): SetSelectedStudy => ({
  type: ActionTypes.SET_SELECTED_STUDY,
  payload
})
