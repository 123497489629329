import { useEffect, useState } from 'react'
import {
  Typography,
  Stack,
  Paper,
  Tooltip,
  IconButton,
  Badge,
  Box
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import { addToHistory } from '../../../apis/utility'
import { saeReports, saeReports2, ciomsReports1 } from '../../../utils'
import { HealthAndSafety, Mail } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { useAppSelector } from '../../../hooks'
import { ApiController } from '../../../controllers/ApiController'

interface AEReportsProps {
  apiController: ApiController
}

export const AEReports = (props: AEReportsProps) => {
  const { apiController } = props

  const [seriousResults, setSeriousResults] =
    useState<{ [key: string]: string }[]>()

  const location = useLocation()

  const selectedStudyState = useAppSelector((state) => state.studies.selected)

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }))

  addToHistory({ title: 'Reporting', url: location })

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const seriousAEs = await apiController.getAEs(`AESER='Y'`)

      // handle fetched data
      if (isMounted) setSeriousResults(seriousAEs)
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController])

  return (
    <>
      <Typography sx={{ mt: 2, mb: 3 }}>AE Reports</Typography>
      <Typography sx={{ mb: 3, fontWeight: 'bold' }}>
        Serious AE Report examples:
      </Typography>
      The following are examples of reports that can be generated from our data
      into various formats. These examples show how to make Microsoft Word
      files, although we can also make PDF files and Excel files if required.
      <Stack
        spacing={2}
        direction="column"
        justifyContent="flex-start"
        alignItems={'flex-start'}
        sx={{ mt: 3 }}
      >
        <Item>
          {selectedStudyState && seriousResults && (
            <Tooltip title="Produce a Microsoft Word document containing a Serious Adverse Event (SAE) report for each SAE in the study (Example 1).">
              <Box>
                <IconButton
                  sx={{
                    mt: 1,
                    mb: 1,
                    backgroundColor: 'primary.light',
                    '&:hover': { backgroundColor: 'secondary.light' }
                  }}
                  size="small"
                  color="primary"
                  onClick={() => {
                    saeReports(
                      seriousResults,
                      selectedStudyState.studyDB,
                      selectedStudyState.STUDYID
                    )
                  }}
                >
                  <Badge color="primary">
                    <HealthAndSafety color="action" />
                  </Badge>
                </IconButton>
                &nbsp;<b>Serious AE Report (type 1)</b>
              </Box>
            </Tooltip>
          )}
        </Item>
        <Item>
          {selectedStudyState && seriousResults && (
            <Tooltip title="Produce a Microsoft Word document containing a Serious Adverse Event (SAE) report for each SAE in the study (Example 2).">
              <Box>
                <IconButton
                  sx={{
                    mt: 1,
                    mb: 1,
                    backgroundColor: 'primary.light',
                    '&:hover': { backgroundColor: 'secondary.light' }
                  }}
                  size="small"
                  color="primary"
                  onClick={() => {
                    saeReports2(
                      seriousResults,
                      selectedStudyState.studyDB,
                      selectedStudyState.STUDYID
                    )
                  }}
                >
                  <Badge color="primary">
                    <HealthAndSafety color="action" />
                  </Badge>
                </IconButton>
                &nbsp;<b>Serious AE Report (type 2)</b>
              </Box>
            </Tooltip>
          )}
        </Item>
        <Item>
          {selectedStudyState && seriousResults && (
            <Tooltip
              title="Produce a Microsoft Word document containing a Council for
            International Organization of Medical Sciences (CIOMS) report for each
            SAE in the study."
            >
              <Box>
                <IconButton
                  sx={{
                    mt: 1,
                    mb: 1,
                    backgroundColor: 'primary.light',
                    '&:hover': { backgroundColor: 'secondary.light' }
                  }}
                  size="small"
                  color="primary"
                  onClick={() => {
                    ciomsReports1(
                      seriousResults,
                      selectedStudyState.studyDB,
                      selectedStudyState.STUDYID
                    )
                  }}
                >
                  <Badge color="primary">
                    <Mail color="action" />
                  </Badge>
                </IconButton>
                &nbsp;<b>CIOMS</b>
              </Box>
            </Tooltip>
          )}
        </Item>
      </Stack>
    </>
  )
}
