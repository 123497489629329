import { LBvalueStatus } from '../types'

export const lbTheme = {
  result: {
    [LBvalueStatus.GOOD]: {
      class: 'lb-good',
      color: 'green'
    },
    [LBvalueStatus.HIGH]: {
      class: 'lb-result-upper-limit-of-normal',
      color: 'orange'
    },
    [LBvalueStatus.LOW]: {
      class: 'lb-result-below-limit-of-normal',
      color: 'blue'
    },
    [LBvalueStatus.HIGH_AND_LOW]: {
      class: 'lb-result-upper-and-below-limit-of-normal',
      color: 'salmon'
    }
  },
  status: {
    high: {
      class: 'lb-status-high',
      color: '#ff9999'
    },
    normal: {
      class: 'lb-status-normal',
      color: '#ffd699'
    },
    low: {
      class: 'lb-status-low',
      color: '#ccd9ff'
    }
  }
}
