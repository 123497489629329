import { useState, useEffect } from 'react'
import { sql, globalFilter, addToHistory } from '../../apis/utility'
import { useLocation } from 'react-router-dom'
import ReactFC from 'react-fusioncharts'
import FusionCharts from 'fusioncharts'
import excelexport from 'fusioncharts/fusioncharts.excelexport'
import Charts from 'fusioncharts/fusioncharts.charts'
import PowerCharts from 'fusioncharts/fusioncharts.powercharts'
import FusionChartJSX from '../ui/FusionChartJSX'

ReactFC.fcRoot(FusionCharts, Charts, PowerCharts, excelexport)

export default function VSPulse(props: any) {
  const params = {
    title: 'Pulse',
    testCodeValues: ['PULSE'],
    normalRangeKey: 'VSPULSE',
    yAxisMaxValue: '120',
    yAxisMinValue: '20'
  }
  const location = useLocation()

  const whereSubjects =
    props.selectedSubjects.length > 0
      ? 'and SUBJID in ("' + props.selectedSubjects.join('","') + '")'
      : ''
  const whereValues = params.testCodeValues.map((item) => `'${item}'`).join(',')
  const sqlStatement = `select VSDY, VSSTRESN, VSTESTCD from vs where VSTESTCD in (${whereValues}) ${whereSubjects}`

  const [subtitle, setSubtitle] = useState<any>(null)
  const [dataSource, setDataSource] = useState<any>(null)

  const { low, high } = props.normalRanges[params.normalRangeKey]

  addToHistory({ title: params.title, url: location })

  useEffect(() => {
    setSubtitle(globalFilter(props.selectedOptions))

    sql(props.studyDatabase, sqlStatement).then((res) => {
      if (res.message === 'success') {
        const categoryValues = [
          ...new Set(
            res.data
              .filter((row: any) =>
                params.testCodeValues.includes(row.VSTESTCD)
              )
              .map((row: any) => Number(row.VSDY))
          )
        ].sort((a: any, b: any) => a - b)

        const categories = [
          {
            category: categoryValues.map((item: any) => {
              return { label: item.toString() }
            })
          }
        ]

        const series = params.testCodeValues.map((testCode) => ({
          seriesname: testCode,
          data: categories[0].category.map((category) => {
            const data1 = res.data.filter(
              (row: any) =>
                row.VSTESTCD === testCode && row.VSDY === category.label
            )

            if (data1.length > 2) {
              return {
                value: data1.map((item: any) => Number(item.VSSTRESN)).join(',')
              }
            } else {
              const values = data1
                .map((item: any) => Number(item.VSSTRESN))
                .join(',')

              return { value: values + ',' + values + ',' + values }
            }
          })
        }))

        const dataSource = {
          chart: {
            caption: `${params.title} (${props.study})`,
            subcaption: subtitle,
            yaxisname: params.title,
            xaxisname: 'Day',
            yAxisMaxValue: params.yAxisMaxValue,
            yAxisMinValue: params.yAxisMinValue,
            bgColor: 'EEEEEE,CCCCCC',
            bgratio: '60,40',
            bgAlpha: '70,80',
            bgAngle: '180',
            plotspacepercent: '25',
            numVisiblePlot: '222',
            showalloutliers: '1',
            exportEnabled: '1',
            upperBoxColor: '#ffe6e6',
            lowerBoxColor: '#ccffcc'
          },
          categories: categories,
          dataset: params.testCodeValues.map((value, index) => series[index]),
          trendlines: [
            {
              line: [
                {
                  isTrendZone: '1',
                  startvalue: params.yAxisMinValue,
                  endValue: low,
                  color: '#0000ff',
                  valueOnRight: '1',
                  alpha: '20',
                  tooltext: `Low ${params.title}`,
                  displayvalue: 'Low'
                },
                {
                  isTrendZone: '1',
                  startvalue: high,
                  endValue: params.yAxisMaxValue,
                  color: '#ff0000',
                  valueOnRight: '1',
                  alpha: '20',
                  tooltext: `High ${params.title}`,
                  displayvalue: 'High'
                }
              ]
            }
          ]
        }

        setDataSource(dataSource)
      }
    })
    // eslint-disable-next-line
  }, [
    props.selectedSubjects,
    props.study,
    props.selectedOptions,
    props.studyDatabase,
    subtitle,
    sqlStatement,
    high,
    low
  ])

  return (
    <>
      {dataSource ? (
        <FusionChartJSX
          type="boxandwhisker2d"
          width="100%"
          height={props.screenHeight * 0.8}
          dataFormat="JSON"
          dataSource={dataSource}
        />
      ) : (
        'loading'
      )}
    </>
  )
}
