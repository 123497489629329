import * as ActionTypes from '../actionTypes'
import { SetVariableLabels, SetAEvariables } from './types'
import { VariableLabelsObject } from '../../types'

export const setVariableLabelsAction = (
  payload: VariableLabelsObject
): SetVariableLabels => ({
  type: ActionTypes.SET_VARIABLE_LABELS,
  payload
})

export const setAEVariableAction = (payload: string[]): SetAEvariables => ({
  type: ActionTypes.SET_AE_VARIABLES,
  payload
})
