import React, { useState, useEffect } from 'react'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import {
  Fab,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material'
import { ApiController } from '../../controllers/ApiController'
import { Divider } from '@mui/material'
import Select from 'react-select'
import { useAppSelector, useAppDispatch } from '../../hooks'
import {
  setFilterOptionToTableMapAction,
  setFilterObjectAction,
  setFilterOptionsAction,
  removeFilterOptionAction,
  setSelectedSubjectsAction
} from '../../store/action'
import { compareObjects } from '../../utils'
import { FilterObject, FilterOptionToTableMap, FilterOption } from '../../types'

interface GenerateSelectsProps {
  apiController: ApiController
}

const GenerateSelects = (props: GenerateSelectsProps) => {
  const { apiController } = props

  const filterState = useAppSelector((state) => state.filter)
  const dispatch = useAppDispatch()

  const [optionToTableMap, setOptionToTableMap] =
    useState<FilterOptionToTableMap | null>(filterState.optionToTableMap)
  const [filterObject, setFilterObject] = useState<FilterObject | null>(
    filterState.object
  )
  const selectedOptions = filterState.selected

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const filterOptionToTableMap =
        await apiController.getFilterOptionToTableMap()

      // handle fetched data
      if (isMounted) {
        if (
          !compareObjects(filterState.optionToTableMap, filterOptionToTableMap)
        ) {
          dispatch(setFilterOptionToTableMapAction(filterOptionToTableMap))

          setOptionToTableMap(filterOptionToTableMap)
        }
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, dispatch, filterState.optionToTableMap])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const filterObject = await apiController.getFilterObject()

      // handle fetched data
      if (isMounted && !compareObjects(filterState.object, filterObject)) {
        dispatch(setFilterObjectAction(filterObject))

        setFilterObject(filterObject)
      }
    }

    if (optionToTableMap) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, optionToTableMap, dispatch, filterState.object])

  const changeSelectedOptions = async (
    options: FilterOption[],
    key: string
  ) => {
    if (!options.length) {
      dispatch(removeFilterOptionAction(key))
    } else {
      const newOptions = { ...selectedOptions, [key]: options }

      dispatch(setFilterOptionsAction(newOptions))

      const subjects = await apiController.getSubjects()

      dispatch(setSelectedSubjectsAction(subjects))
    }
  }

  return (
    <>
      {optionToTableMap &&
        filterObject &&
        Object.keys(filterObject).map((group: string, index: number) => (
          <React.Fragment key={index}>
            <Divider>
              <b>{group}</b>
            </Divider>
            {Object.keys(filterObject[group]).map((option) => (
              <React.Fragment key={`${group}-${option}`}>
                <b>
                  {optionToTableMap.find((item) => item.key === option)
                    ?.label || 'NOT DETERMINED'}
                </b>
                <Select
                  key={`${group}-${option}`}
                  styles={{
                    option: (provided: any, state: any) => ({
                      ...provided,
                      borderBottom: '2px dotted gray',
                      color: state.isSelected ? 'green' : 'black',
                      padding: 0
                    })
                  }}
                  closeMenuOnSelect={false}
                  isMulti
                  options={filterObject[group][option].map((value) => ({
                    value: value,
                    label: value
                  }))}
                  value={selectedOptions ? selectedOptions[option] : null}
                  onChange={(e: any) => changeSelectedOptions(e, option)}
                ></Select>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
    </>
  )
}

interface FilterProps {
  apiController: ApiController
}

export const Filter = (props: FilterProps) => {
  const { apiController } = props

  const filterState = useAppSelector((state) => state.filter)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title="Make Global Filter">
        <Fab
          size="small"
          onClick={handleClickOpen}
          color={
            Object.keys(filterState.selected || {}).length
              ? 'secondary'
              : 'primary'
          }
        >
          <FilterAltIcon />
        </Fab>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle id="alert-dialog-title">{'Global Filter'}</DialogTitle>
        <DialogContent>
          <GenerateSelects apiController={apiController} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
