import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Tooltip, Chip } from '@mui/material'

export const LookBelow = (props: any) => {
  const { label, tooltip, mt, ml, mr, mb } = props

  const handleClick = () => {
    window.scrollBy(0, 600)
  }

  return (
    <Tooltip title={tooltip}>
      <Chip
        clickable={true}
        onClick={() => {
          handleClick()
        }}
        color="primary"
        // size="large"
        icon={<ArrowDownwardIcon />}
        sx={{ mt: mt, ml: ml, mr: mr, mb: mb }}
        label={label}
      />
    </Tooltip>
  )
}
