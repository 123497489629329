import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import SubjectTable from '../../SubjectTable'
import { LookBelow } from '../../layout/LookBelow'
import { sql, globalFilter, addToHistory } from '../../../apis/utility'
import { enumerateDaysBetweenDates } from '../../../utils'
import FusionCharts from 'fusioncharts'
import TimeSeries from 'fusioncharts/fusioncharts.timeseries'
import excelexport from 'fusioncharts/fusioncharts.excelexport'
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel'
import ReactFC from 'react-fusioncharts'
import FusionChartJSX from '../../ui/FusionChartJSX'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector } from '../../../hooks'

ReactFC.fcRoot(FusionCharts, TimeSeries, GammelTheme, excelexport)

// REFACTOR: use apiController instead of sql utility
export default function VDInfo(props: any) {
  const { theme, apiController } = props

  const chartContainerId = 'vd-info-charts-container'

  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)
  const selectedSubjectsState = useAppSelector(
    (state) => state.subjects.selected
  )
  const screenState = useAppSelector((state) => state.preferences.screen)

  const location = useLocation()

  addToHistory({ title: 'VD Info', url: location })

  const [subjectsDrilledTo, setSubjectsDrilledTo] = useState<any>(null)
  const [data, setData] = useState<any>(null)
  const [timeSeriesDs, setTimeSeriesDs] = useState<any>(null)
  const [selectedSeries, setSelectedSeries] = useState<any>(null)
  const [selectedDateRange, setSelectedDateRange] = useState<any>(null)

  const whereSubjects =
    selectedSubjectsState && selectedSubjectsState.length > 0
      ? 'where SUBJID in ("' +
        selectedSubjectsState.map((s) => s.SUBJID).join('","') +
        '")'
      : ''

  const subtitle = globalFilter(selectedFilterState || '')

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      const query = `
        SELECT DATE(vddate) AS date,
               CASE WHEN SUBSTR(visit,1,9) = 'SCREENING' THEN 'Screening Visits' WHEN visit = 'UNSCHEDULED' THEN 'Unscheduled Visits' ELSE 'Scheduled Visits' END AS type,
               count(DISTINCT SUBJID) AS n
          FROM vd
          ${whereSubjects}
          GROUP BY 1,
                   2;`

      const res = await sql(selectedStudyState?.studyDB, query)

      if (isMounted && res && res.message === 'success') {
        const tempData = res.data.map((item: any) => [
          item.date,
          item.type,
          item.n
        ])

        setData(tempData)
      }
    }

    if (!data || !data.length) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [selectedStudyState, whereSubjects, data])

  useEffect(() => {
    if (data && data.length) {
      const schema = [
        {
          name: 'Time',
          type: 'date',
          format: '%Y-%m-%d'
        },
        {
          name: 'Type',
          type: 'string'
        },
        {
          name: 'Visits',
          type: 'number'
        }
      ]

      const fusionTable = new FusionCharts.DataStore().createDataTable(
        data,
        schema
      )

      const temp: any = {
        type: 'timeseries',
        renderAt: 'container',
        width: '100%',
        height: (screenState?.height || 1) * 0.75,
        dataSource: {
          chart: { theme: 'gammel' },
          caption: {
            text: 'Visits by Calendar Day (' + selectedStudyState?.STUDYID + ')'
          },
          subcaption: { text: subtitle },
          series: 'Type',
          yaxis: [
            {
              plot: { value: 'Visits', type: 'column', aggregation: 'Sum' },
              title: 'Visits',
              type: 'number'
            }
          ],
          data: null
        },
        events: {
          dataPlotClick: async (event: any) => {
            const { data } = event
            const { seriesValue, startText, endText } = data
            const datesBetween = enumerateDaysBetweenDates(
              startText,
              endText,
              true
            )
            const lastDate = datesBetween.pop()
            const dates = [startText, ...datesBetween, lastDate]
              .map((date) => `"${date}"`)
              .join(', ')

            setSelectedSeries(seriesValue)
            setSelectedDateRange(`${startText} - ${endText}`)

            const query = `SELECT
                            DISTINCT SUBJID,
                            CASE
                              WHEN SUBSTR(visit,1,9) = 'SCREENING' THEN 'Screening Visits'
                              WHEN visit = 'UNSCHEDULED' THEN 'Unscheduled Visits'
                              ELSE 'Scheduled Visits'
                            END AS type,
                            DATE(vddate) AS date
                          FROM vd ${whereSubjects}
                          AND type='${seriesValue}'
                          AND date IN (${dates})`

            const res = await sql(selectedStudyState?.studyDB, query)

            if (res && res.message === 'success') {
              setSubjectsDrilledTo(res.data.map((d: any) => d.SUBJID))
            }
          }
        }
      }

      temp.dataSource.data = fusionTable

      setTimeSeriesDs({ ...temp })
    }
  }, [data, screenState, selectedStudyState, subtitle, whereSubjects])

  return (
    <>
      {timeSeriesDs ? (
        <div id={chartContainerId}>
          <FusionChartJSX {...timeSeriesDs} />
          <ChartDownload elementId={chartContainerId} fileName="VD-Days" />
        </div>
      ) : (
        'loading'
      )}
      {subjectsDrilledTo && (
        <LookBelow
          label="Subject Table"
          tooltip="Click to scroll to subject table appears below"
          mt={2}
          ml={4}
          mr={0}
          mb={0}
        />
      )}
      {subjectsDrilledTo && (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          theme={theme}
          filterOptions={[
            { key: 'Visit', value: `'${selectedSeries}'` },
            { key: 'Dates', value: `'${selectedDateRange}'` }
          ]}
          apiController={apiController}
        />
      )}
    </>
  )
}
