import { Fab, Tooltip } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useAppSelector, useAppDispatch } from '../../hooks'
import {
  clearFilterAction,
  setSelectedSubjectsAction
} from '../../store/action'
import { ApiController } from '../../controllers/ApiController'

interface ClearProps {
  apiController: ApiController
}

export default function Clear(props: ClearProps) {
  const { apiController } = props

  const filterState = useAppSelector((state) => state.filter)
  const dispatch = useAppDispatch()

  return (
    <>
      <Tooltip title="Clear Global Filter">
        <Fab
          size="small"
          onClick={async () => {
            dispatch(clearFilterAction())

            const subjects = await apiController.getSubjects()

            dispatch(setSelectedSubjectsAction(subjects))
          }}
          color={
            Object.keys(filterState.selected || {}).length
              ? 'secondary'
              : 'primary'
          }
          sx={{ margin: 1 }}
        >
          <ClearIcon />
        </Fab>
      </Tooltip>
    </>
  )
}
