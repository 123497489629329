import { Dispatch } from 'react'
import {
  AppBar,
  Toolbar,
  Tooltip,
  Container,
  Divider,
  IconButton,
  Button
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MainMenu from './MainMenu'
import Load from './Load'
import Clear from './Clear'
import { Filter } from './Filter'
import HomeIconLink from './HomeIconLink'
import EcrfLink from './EcrfLink'
import SubjectIconLink from './SubjectIconLink'
import SlidingDrawer from './SlidingDrawer'
import DrawerContents from './DrawContents'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import PreferencesIconLink from './PreferencesIconLink'
import logo from '../../assets/images/logo.png'
import InfoIcon from '@mui/icons-material/Info'
import CreateIcon from '@mui/icons-material/Create'
import VisibilityIcon from '@mui/icons-material/Visibility'
import OverviewLink from './OverviewLink'
import QueryIconLink from './QueryIconLink'
import { ApiController } from '../../controllers/ApiController'

interface TopMenuProps {
  userName: string
  userType: string
  theme: any
  optionsForSelection: any
  varToTable: any
  selectedOptions: any
  selectedSubjects: string[]
  showAe: string
  showLb: string
  showVs: string
  showDs: string
  showDm: string
  listOfTables: string[]
  labCategories: string[]
  alertResults: any
  setAlertResults: Dispatch<any>
  setSelectedSubjects: Dispatch<any>
  setTheme: () => void
  setOpenInfoModal: Dispatch<any>
  setOpenMakeNotesModal: Dispatch<any>
  setOpenViewNotesModal: Dispatch<any>
  setSelectedOptions: Dispatch<any>
  apiController: ApiController
}

export const TopMenu = (props: TopMenuProps) => {
  const {
    userName,
    userType,
    theme,
    optionsForSelection,
    selectedOptions,
    selectedSubjects,
    varToTable,
    showAe,
    showLb,
    showVs,
    showDs,
    showDm,
    listOfTables,
    alertResults,
    labCategories,
    setAlertResults,
    setTheme,
    setOpenInfoModal,
    setOpenMakeNotesModal,
    setOpenViewNotesModal,
    setSelectedOptions,
    setSelectedSubjects,
    apiController
  } = props

  const navigate = useNavigate()

  const infoAboutCurrentScreen = () => {
    setOpenInfoModal(true)
  }

  const makeNote = () => {
    setOpenMakeNotesModal(true)
  }
  const viewNote = () => {
    setOpenViewNotesModal(true)
  }

  return (
    <AppBar
      sx={{
        width: '100%'
      }}
      position="sticky"
      enableColorOnDark
    >
      <Container maxWidth={false}>
        <Toolbar variant="dense" disableGutters>
          <MainMenu />
          <Tooltip
            title={
              userName
                ? `You're logged in as ${userName} the ${userType}`
                : `You're not logged in`
            }
          >
            <img src={logo} width="125" alt="logo" />
          </Tooltip>
          <Divider
            orientation="vertical"
            sx={{ flexGrow: 0.1 }}
            flexItem
          ></Divider>
          <OverviewLink />
          <HomeIconLink />
          <EcrfLink />
          <Divider
            orientation="vertical"
            sx={{ flexGrow: 1 }}
            flexItem
          ></Divider>
          <Tooltip title="Make a note for this screen">
            <IconButton
              onClick={() => {
                makeNote()
              }}
            >
              <CreateIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="View notes for this screen">
            <IconButton
              onClick={() => {
                viewNote()
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <SubjectIconLink />
          <QueryIconLink />
          <PreferencesIconLink />
          <Tooltip title="Swith between dark and light mode">
            <IconButton sx={{ ml: 1 }} onClick={setTheme}>
              {theme.palette.mode === 'dark' ? (
                <Brightness7Icon />
              ) : (
                <Brightness4Icon />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Info about this screen">
            <IconButton
              onClick={() => {
                infoAboutCurrentScreen()
              }}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" sx={{ flexGrow: 0.1 }}></Divider>
          <Load
            setSelectedOptions={setSelectedOptions}
            optionsForSelection={optionsForSelection}
            varToTable={varToTable}
            setSelectedSubjects={setSelectedSubjects}
            selectedOptions={selectedOptions}
          />
          <Clear apiController={apiController} />
          <Filter apiController={apiController} />
          <Divider
            orientation="vertical"
            sx={{ ml: 1, mr: 1, flexGrow: 1 }}
            flexItem
          ></Divider>
          <Tooltip title="Adverse Events">
            <Button
              variant="contained"
              sx={{
                p: 1,
                bgcolor: 'red',
                fontSize: 10,
                mr: 1,
                '&:hover': { color: 'yellow' }
              }}
              disabled={showAe !== 'Y'}
              onClick={() => {
                navigate('/aeboth')
              }}
            >
              Adverse Events
            </Button>
          </Tooltip>
          <Tooltip title="Labs">
            <Button
              variant="contained"
              sx={{
                p: 1,
                bgcolor: 'blue',
                fontSize: 10,
                mr: 1,
                '&:hover': { color: 'yellow' }
              }}
              disabled={showLb !== 'Y'}
              onClick={() => {
                navigate('/lbboxplot')
              }}
            >
              Labs
            </Button>
          </Tooltip>
          <Tooltip title="Vital Signs">
            <Button
              variant="contained"
              sx={{
                p: 1,
                bgcolor: 'orange',
                fontSize: 10,
                mr: 1,
                '&:hover': { color: 'yellow' }
              }}
              disabled={showVs !== 'Y'}
              onClick={() => {
                navigate('/vsboxplot')
              }}
            >
              Vital Signs
            </Button>
          </Tooltip>
          <Tooltip title="Disposition">
            <Button
              variant="contained"
              sx={{
                p: 1,
                bgcolor: 'aqua',
                color: 'black',
                fontSize: 10,
                mr: 1,
                '&:hover': { color: 'yellow' }
              }}
              disabled={showDs !== 'Y'}
              onClick={() => {
                navigate('/dssankey')
              }}
            >
              Disposition
            </Button>
          </Tooltip>
          <Tooltip title="Demography">
            <Button
              variant="contained"
              sx={{
                p: 1,
                bgcolor: 'green',
                fontSize: 10,
                mr: 1,
                '&:hover': { color: 'yellow' }
              }}
              disabled={showDm !== 'Y'}
              onClick={() => {
                navigate('/dminfo')
              }}
            >
              Demography
            </Button>
          </Tooltip>
          <SlidingDrawer
            anchor="right"
            title="Options"
            contents={
              <DrawerContents
                apiController={apiController}
                listOfTables={listOfTables}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                optionsForSelection={optionsForSelection}
                varToTable={varToTable}
                selectedSubjects={selectedSubjects}
                setSelectedSubjects={setSelectedSubjects}
                alertResults={alertResults}
                setAlertResults={setAlertResults}
                labCategories={labCategories}
                theme={theme}
              />
            }
          />
        </Toolbar>
      </Container>
    </AppBar>
  )
}
