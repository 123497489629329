import { useState } from 'react'
import {
  Typography,
  Button,
  TextField,
  Box,
  Grid,
  Tooltip,
  IconButton,
  Chip,
  Stack,
  Paper
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import { addToHistory, openSaveFileDialog } from '../../apis/utility'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { usePapaParse } from 'react-papaparse'
import {
  FileDownload,
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@mui/icons-material'
import { useAppSelector } from '../../hooks'
import { ApiController } from '../../controllers/ApiController'
import { decodeAbbreviation } from '../../utils'

interface TableColumn {
  headerName: string
  field: string
  renderCell: (cellValues: { value: string; field: string }) => any
}

interface QueryProps {
  apiController: ApiController
}

export const Query = (props: QueryProps) => {
  const { apiController } = props

  const location = useLocation()

  const screenState = useAppSelector((state) => state.preferences.screen)
  const studyState = useAppSelector((state) => state.studies.selected)
  const tablesState = useAppSelector((state) => state.tables.info)

  const [sqlQuery, setSqlQuery] = useState<string>('')
  const [columns, setColumns] = useState<TableColumn[]>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [isCommonQueriesOpen, setIsCommonQueriesOpen] = useState(false)
  const [selectedCommonQuery, setSelectedCommonQuery] = useState<string>()
  const [result, setResult] = useState<
    {
      id: number
    }[]
  >()

  const { jsonToCSV } = usePapaParse()

  const runQuery = async () => {
    setResult(undefined)
    setSelectedCommonQuery(undefined)

    const data = await apiController.runSelectQuery(sqlQuery).catch((err) => {
      setErrorMessage(err?.message || 'Query failed')
    })

    if (Array.isArray(data) && data.length) {
      setErrorMessage(undefined)

      const tempData = data.map((row: {}, id: number) => ({
        ...row,
        id: id
      }))

      setResult(tempData)

      const keys = Object.keys(data[0])
      const columns: TableColumn[] = []

      keys.forEach((key) => {
        const headerName = key

        columns.push({
          headerName: headerName,
          field: key,
          renderCell: (cellValues: { value: string; field: string }) => {
            const { protocol, host } = window.location

            let { value, field } = cellValues
            if (!value) value = ''
            if (!field) field = ''

            if (field === 'SUBJID') {
              const target = `${protocol}//${host}/#/patientprofile/${studyState?.studyDB}/${value}`

              return (
                <Button
                  onClick={() => {
                    window.open(target)
                  }}
                >
                  {value}
                </Button>
              )
            } else if (field.toUpperCase() === 'SEX') {
              const color = value === 'F' ? 'red' : 'blue'

              return (
                <b>
                  <span style={{ color: color }}>{value}</span>
                </b>
              )
            } else if (
              [
                'PKSET',
                'PDSET',
                'EXPOSED',
                'RANDOMIZED',
                'SAF',
                'DSCPFL',
                'DSADFL',
                'AESER',
                'AETE',
                'CMONGO',
                'LBBLFL',
                'LBFAST',
                'ANALYSIS',
                'MHONGO',
                'VSBLFL',
                'VSDRVFL'
              ].includes(field.toUpperCase())
            ) {
              const color = ['Y', 'YES'].includes(value.toUpperCase())
                ? 'green'
                : 'red'

              return (
                <b>
                  <span style={{ color: color }}>{value}</span>
                </b>
              )
            } else if (['AESEV'].includes(field.toUpperCase())) {
              const color = ['MILD'].includes(value.toUpperCase())
                ? 'green'
                : ['MODERATE'].includes(value.toUpperCase())
                  ? 'orange'
                  : 'red'

              return (
                <b>
                  <span style={{ color: color }}>{value}</span>
                </b>
              )
            } else if (['AEREL'].includes(field.toUpperCase())) {
              const color = ['NOT RELATED'].includes(value.toUpperCase())
                ? 'green'
                : ['POSSIBLE'].includes(value.toUpperCase())
                  ? 'orange'
                  : 'red'

              return (
                <b>
                  <span style={{ color: color }}>{value}</span>
                </b>
              )
            } else if (['LBNRIND', 'LBLNRIND'].includes(field.toUpperCase())) {
              const color = ['NORMAL'].includes(value.toUpperCase())
                ? 'green'
                : ['LOW'].includes(value.toUpperCase())
                  ? 'blue'
                  : ['HIGH'].includes(value.toUpperCase())
                    ? 'red'
                    : 'black'

              return (
                <b>
                  <span style={{ color: color }}>{value}</span>
                </b>
              )
            }

            return value
          }
        })
      })

      setColumns(columns)
    } else {
      setErrorMessage('No data returned')
    }
  }

  const exportData = () => {
    openSaveFileDialog(jsonToCSV(result), 'customQuery.csv', 'text/csv')
  }

  addToHistory({ title: 'Sql', url: location })

  return (
    <>
      {studyState ? (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ mt: 1, mb: 1 }}>
                {result && result.length > 0 ? (
                  <Tooltip title="Download table">
                    <IconButton color="primary" onClick={exportData}>
                      <FileDownload />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {`Query for ${studyState.STUDYID} (${studyState.studyDB})`}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              <TextField
                label="Enter a SQL Query"
                value={sqlQuery}
                onChange={(event) => setSqlQuery(event.target.value)}
                fullWidth
                multiline={true}
                maxRows={40}
                error={!!errorMessage}
                helperText={errorMessage}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={runQuery}
                style={{ height: '100%' }}
              >
                Run
              </Button>
            </Grid>
          </Grid>
          {tablesState && (
            <Box>
              <Box
                style={{
                  display: 'flex',
                  fontSize: '0.85rem',
                  color: 'rgba(0, 0, 0, 0.6)',
                  cursor: 'pointer',
                  marginTop: 5
                }}
                onClick={() => setIsCommonQueriesOpen(!isCommonQueriesOpen)}
              >
                {isCommonQueriesOpen ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )}
                <span>common queries</span>
              </Box>
              {isCommonQueriesOpen && (
                <Paper
                  sx={{
                    listStyle: 'none',
                    p: 0.8,
                    backgroundColor: 'inherit'
                  }}
                  style={{ marginRight: 15 }}
                  component="div"
                >
                  <Stack direction="row" spacing={2} flexWrap="wrap" rowGap={1}>
                    {Object.keys(tablesState)
                      .sort()
                      .map((table) => (
                        <Tooltip title={decodeAbbreviation(table)} key={table}>
                          <Chip
                            label={table}
                            variant="outlined"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSqlQuery(`SELECT * FROM ${table} LIMIT 100`)
                              setSelectedCommonQuery(table)
                            }}
                            color={
                              selectedCommonQuery === table
                                ? 'primary'
                                : 'default'
                            }
                          />
                        </Tooltip>
                      ))}
                  </Stack>
                </Paper>
              )}
            </Box>
          )}
          {columns && result && result.length > 0 ? (
            <Box
              sx={{ height: (screenState?.height || 1) - 300, width: '100%' }}
            >
              <DataGridPro
                rows={result}
                columns={columns as any}
                rowHeight={30}
                density={'compact'}
                sx={{ fontSize: '0.7em' }}
              />
            </Box>
          ) : (
            <Typography sx={{ mt: 3 }} color="GrayText">
              Table of results will appear here
            </Typography>
          )}
        </>
      ) : (
        <div>
          <h4>Study is not selected</h4>
        </div>
      )}
    </>
  )
}
