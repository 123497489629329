import * as ActionTypes from '../actionTypes'
import { VariablesState, VariablesDispatchTypes } from './types'

const initialState: VariablesState = { labels: null, ae: null }

const reducer = (
  state = initialState,
  action: VariablesDispatchTypes
): VariablesState => {
  switch (action.type) {
    case ActionTypes.SET_VARIABLE_LABELS:
      return {
        ...state,
        labels: action.payload
      }

    case ActionTypes.SET_AE_VARIABLES:
      return {
        ...state,
        ae: action.payload
      }

    case ActionTypes.RESTORE_STATE:
      return action.payload.variables || null

    default:
      return state
  }
}

export default reducer
