import { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import SubjectTable from '../../SubjectTable'
import { LookBelow } from '../../layout/LookBelow'
import { BOThString, getTreatmentDay } from '../../../utils'
import { globalFilter, addToHistory, sql } from '../../../apis/utility'
import {
  Tooltip,
  Fab,
  Snackbar,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import addSeriesLabel from 'highcharts/modules/series-label'
import addAccessibility from 'highcharts/modules/accessibility'
import addStreamgraphModule from 'highcharts/modules/streamgraph'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector } from '../../../hooks'

export default function LBBoth(props: any) {
  const { theme, apiController } = props
  const { palette } = theme
  const { mode, lb: lbPalette } = palette

  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)
  const selectedSubjectsState = useAppSelector(
    (state) => state.subjects.selected
  )
  const screenState = useAppSelector((state) => state.preferences.screen)
  const lbRangesState = useAppSelector((state) => state.lb.ranges)

  const chartContainerId = 'lb-both-charts-container'

  const location = useLocation()

  addToHistory({ title: `LB ${BOThString}`, url: location })
  addStreamgraphModule(Highcharts)
  addSeriesLabel(Highcharts)
  addAccessibility(Highcharts)

  const contrastingColor = mode === 'dark' ? 'white' : 'black'
  const subtitle = globalFilter(selectedFilterState) || ''

  const [chartOptions, setChartOptions] = useState<any>(null)
  const [showSubjects, setShowSubjects] = useState<any>(false)
  const [dataForChart, setDataForChart] = useState<any>(null)
  const [drillVars, setDrillVars] = useState<any>([])
  const [snackBarOpen, setSnackBarOpen] = useState<any>(false)
  const [arms, setArms] = useState<any>([])
  const [drillDownLevel, setDrillDownLevel] = useState<any>(0) // define the drilldown level here
  const [drillDownPath] = useState(['category<any>', 'lbtestcd']) // define the drilldown path here
  const [classVar, setClassVar] = useState<any>('category')
  const [lowerSelectedArm, setLowerSelectedArm] = useState<any>(null)
  const [upperSelectedArm, setUpperSelectedArm] = useState<any>(null)
  const [baseLines, setBaseLines] = useState<any>([])
  const [armcdSubjects, setArmcdSubjects] = useState<any>([])
  const [subjectsDrilledTo, setSubjectsDrilledTo] = useState<any>(
    selectedSubjectsState
  )
  const [lbCategory, setLbCategory] = useState<any>()
  const [lbTest, setLbTest] = useState<any>()
  const [lbNRind, setLbNRind] = useState<any>()
  const [lbDaySelected, setLbDaySelected] = useState<any>(selectedSubjectsState)

  const whereSubjects =
    selectedSubjectsState && selectedSubjectsState.length > 0
      ? 'where SUBJID in ("' +
        selectedSubjectsState.map((s) => s.SUBJID).join('","') +
        '")'
      : ''

  const drillUp = () => {
    setDrillVars(drillVars.slice(0, drillDownLevel - 1))
    setDrillDownLevel(drillDownLevel - 1)
    setClassVar(drillDownPath[drillDownLevel - 1])
    setShowSubjects(false)
  }

  const top = () => {
    setDrillVars(drillVars.slice(0, 0))
    setDrillDownLevel(0)
    setClassVar(drillDownPath[0])
    setShowSubjects(false)
  }

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      const lbQuery = `SELECT
                        SUBJID AS lbSubId,
                        LBCAT,
                        LBDTC,
                        LBTESTCD,
                        LBTEST,
                        CAST(LBSTRESN AS DECIMAL) AS lbResult
                      FROM
                        lb
                      ${whereSubjects}`

      // call async func to fetch data
      const lbRes = await sql(selectedStudyState?.studyDB, lbQuery)

      // handle fetched data

      if (isMounted && lbRes && lbRes.message === 'success') {
        const { data: lbData } = lbRes

        if (lbData.length) {
          const vdQuery = `SELECT
                          SUBJID AS vdSubId,
                          vddate AS baseLine
                        FROM
                          vd
                        ${whereSubjects}
                        AND VISIT IN ('DAY 1', 'BASELINE', 'CYCLE 01 DAY 01')`

          const vdRes = await sql(selectedStudyState?.studyDB, vdQuery)

          if (isMounted && vdRes && vdRes.message === 'success') {
            const { data: vdData } = vdRes

            if (vdData.length) {
              const dmQuery = `SELECT
                          SUBJID AS dmSubId,
                          ARMCD
                        FROM
                          dm
                        ${whereSubjects}`

              const dmRes = await sql(selectedStudyState?.studyDB, dmQuery)

              if (isMounted && dmRes && dmRes.message === 'success') {
                const { data: dmData } = dmRes

                const lbDataCategorized: any = {}

                lbData.forEach((lbItem: any) => {
                  const { lbSubId, LBDTC, LBCAT, LBTEST } = lbItem

                  const vdMatch = vdData.find(
                    (vdItem: any) => vdItem.vdSubId === lbSubId
                  )

                  if (!vdMatch) return

                  const { baseLine } = vdMatch
                  const lbDay = getTreatmentDay(baseLine, LBDTC)

                  if (!Number.isInteger(lbDay) || lbDay < 1) return

                  const dmMatch = dmData.find(
                    (dmItem: any) => dmItem.dmSubId === lbSubId
                  )

                  if (!dmMatch) return

                  const { ARMCD } = dmMatch

                  if (!lbDataCategorized[ARMCD]) {
                    lbDataCategorized[ARMCD] = {}
                  }
                  if (!lbDataCategorized[ARMCD][LBCAT]) {
                    lbDataCategorized[ARMCD][LBCAT] = {}
                  }
                  if (!lbDataCategorized[ARMCD][LBCAT][LBTEST]) {
                    lbDataCategorized[ARMCD][LBCAT][LBTEST] = {}
                  }
                  if (!lbDataCategorized[ARMCD][LBCAT][LBTEST][lbDay]) {
                    lbDataCategorized[ARMCD][LBCAT][LBTEST][lbDay] = []
                  }

                  lbDataCategorized[ARMCD][LBCAT][LBTEST][lbDay].push(lbItem)
                })

                if (Object.keys(lbDataCategorized).length) {
                  setDataForChart(lbDataCategorized)
                  setArms(Object.keys(lbDataCategorized))
                  setBaseLines(vdData)
                  setArmcdSubjects(dmData)
                }
              }
            }
          }
        }
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [selectedStudyState, whereSubjects])

  useEffect(() => {
    if (arms.length > 1) {
      setLowerSelectedArm(arms[0])
      setUpperSelectedArm(arms[1])
    }
  }, [arms])

  const dataToChartOptions = useCallback(
    (data: any, filterLevels?: any) => {
      const options: any = {}
      const commonOptions = {
        chart: {
          type: 'column',
          marginBottom: 30,
          height: (screenState?.height || 1) * 0.375,
          zoomType: 'xy',
          backgroundColor: 'transparent',
          legendPosition: 'top-right',
          legendAllowDrag: '1'
        },
        title: {
          text: null
        },
        plotOptions: {
          column: {
            groupPadding: 0,
            pointPadding: 0
          },
          series: {
            stacking: 'normal',
            label: {
              minFontSize: 5,
              maxFontSize: 15,
              style: {
                color: contrastingColor
              }
            },
            accessibility: {
              exposeAsGroupOnly: true
            },
            point: {
              events: {
                click: async function (event: any) {
                  const { name, category: day, series } = event.point
                  const { userOptions } = series
                  const { armcd } = userOptions

                  let query = `SELECT SUBJID, LBDTC FROM "lb" ${whereSubjects} `

                  if (!drillVars.length) {
                    const category = name
                    query += `AND LBCAT='${category}'`

                    setLbCategory(category)
                  } else if (drillVars.length === 1) {
                    const [category] = drillVars
                    const test = name

                    query += `AND LBCAT='${category}' AND LBTEST='${test}'`

                    setLbCategory(category)
                    setLbTest(test)
                  } else {
                    const [category, test] = drillVars
                    const lbRange: any = Object.values(
                      lbRangesState || {}
                    ).find((range: any) => range.test === test)

                    if (lbRange) {
                      const { low, high } = lbRange
                      const LBSTRESN = `CAST(LBSTRESN AS DECIMAL)`

                      if (name === 'LOW') {
                        query += `AND LBCAT='${category}' AND LBTEST='${test}' AND ${LBSTRESN}<${low}`
                      } else if (name === 'NORMAL') {
                        query += `AND LBCAT='${category}' AND LBTEST='${test}' AND (${LBSTRESN}>=${low} AND ${LBSTRESN}<${high})`
                      } else if (name === 'HIGH') {
                        query += `AND LBCAT='${category}' AND LBTEST='${test}' AND ${LBSTRESN}>=${high}`
                      }
                    }

                    setLbCategory(category)
                    setLbTest(test)
                    setLbNRind(name)
                  }

                  const res = await sql(selectedStudyState?.studyDB, query)

                  if (res && res.message === 'success') {
                    const { data } = res

                    if (data.length) {
                      const subjects = [
                        ...new Set(
                          data.reduce((acc: any, item: any) => {
                            const { SUBJID, LBDTC } = item

                            const baseLineMatch = baseLines.find(
                              (baseLine: any) => baseLine.vdSubId === SUBJID
                            )

                            if (!baseLineMatch) return acc

                            const { baseLine } = baseLineMatch
                            const lbDay = getTreatmentDay(baseLine, LBDTC)

                            if (lbDay !== day) return acc

                            const armcdSubjectsMatch = armcdSubjects.find(
                              (item: any) => item.dmSubId === SUBJID
                            )

                            if (!armcdSubjectsMatch) return acc

                            const armcdSubject = armcdSubjectsMatch.ARMCD

                            if (armcdSubject !== armcd) return acc

                            acc.push(SUBJID)

                            return acc
                          }, [])
                        )
                      ]

                      if (subjects.length) {
                        setSubjectsDrilledTo(subjects)
                        setShowSubjects(true)
                        setLbDaySelected(day)
                      }
                    }
                  }

                  if (drillDownLevel < drillDownPath.length) {
                    setClassVar(drillDownPath[drillDownLevel + 1] || name)
                    setDrillDownLevel(drillDownLevel + 1)
                    setDrillVars([...drillVars, name])
                  }
                }
              }
            }
          }
        },
        yAxis: {
          visible: true,
          startOnTick: false,
          endOnTick: false,
          title: {
            text: 'Laboratory Values',
            style: { color: contrastingColor }
          },
          min: 0,
          labels: { style: { color: contrastingColor } }
        },
        legend: {
          enabled: true,
          floating: true
        },
        tooltip: {
          formatter: function () {
            return (
              '<b>' +
              (this as any).series.name +
              ',</b><br/> ' +
              'Day = ' +
              (this as any).point.category +
              '<br/>' +
              'Summary of LB results: ' +
              (this as any).point.y
            )
          }
        },
        credits: { enabled: false },
        xAxis: {
          maxPadding: 0,
          type: 'category',
          crosshair: true,
          labels: {
            align: 'center',
            reserveSpace: true,
            rotation: 270,
            y: 20,
            style: {
              color: 'black'
            }
          },
          lineWidth: 0,
          tickWidth: 0,
          title: {}
        },
        exporting: { enabled: false }
      }

      const whatHaveIDrilledDownTo = () => {
        const tempWhereClause = []

        for (let i = 0; i < drillDownLevel; i++) {
          tempWhereClause.push(drillDownPath[i] + '="' + drillVars[i] + '"')
        }

        return tempWhereClause.join(' and ')
      }

      let dayMaxX = 1

      Object.keys(data).forEach((armcd) => {
        if (!options[armcd]) options[armcd] = {}

        options[armcd].series = []

        Object.keys(data[armcd]).forEach((category, i) => {
          let tmpSeries: any = {
            id: category,
            name: category,
            index: i,
            series: {},
            armcd
          }

          if (filterLevels && filterLevels.length) {
            if (category === filterLevels[0]) {
              Object.keys(dataForChart[armcd][category]).forEach((test, i) => {
                tmpSeries = {
                  id: test,
                  name: test,
                  index: i,
                  series: {},
                  armcd
                }

                // ?
                let testSeries: any

                Object.keys(dataForChart[armcd][category][test]).forEach(
                  (day: any) => {
                    if (filterLevels[1]) {
                      if (test === filterLevels[1]) {
                        if (!testSeries) {
                          testSeries = {}
                        }

                        const lbnrinds = ['NORMAL', 'LOW', 'HIGH']

                        lbnrinds.forEach((lbnrind) => {
                          if (!testSeries[lbnrind]) {
                            testSeries[lbnrind] = { series: {} }
                          }

                          if (
                            lbRangesState &&
                            dataForChart[armcd][category][test][day].length
                          ) {
                            const { LBTESTCD } =
                              dataForChart[armcd][category][test][day][0]

                            const lbTestRange = lbRangesState[LBTESTCD]

                            if (lbTestRange) {
                              const { low, high } = lbTestRange

                              const lbResult = dataForChart[armcd][category][
                                test
                              ][day]
                                .filter((item: any) =>
                                  lbnrind === 'NORMAL'
                                    ? item.lbResult >= (low || 0) &&
                                      item.lbResult < high
                                    : lbnrind === 'LOW'
                                    ? item.lbResult < (low || 0)
                                    : item.lbResult >= high
                                )
                                .reduce((acc: any, item: any) => {
                                  const { LBTESTCD, lbResult } = item
                                  const lbTestRange = lbRangesState[LBTESTCD]

                                  let lbScore = 0
                                  if (lbResult && lbTestRange) lbScore = 1

                                  return acc + lbScore
                                }, 0)

                              if (testSeries[lbnrind].series[day]) {
                                const { y } = testSeries[lbnrind].series[day]

                                testSeries[lbnrind].series[day] = {
                                  ...testSeries[lbnrind].series[day],
                                  y: y + lbResult
                                }
                              } else {
                                let preDay = day - 1

                                if (
                                  day > 1 &&
                                  !testSeries[lbnrind].series[preDay]
                                ) {
                                  while (preDay > 0) {
                                    if (!testSeries[lbnrind].series[preDay]) {
                                      testSeries[lbnrind].series[preDay] = {
                                        name: lbnrind,
                                        y: 0
                                      }
                                    }

                                    preDay -= 1
                                  }
                                }

                                testSeries[lbnrind].series[day] = {
                                  name: lbnrind,
                                  y: lbResult
                                }
                              }
                            }
                          }
                        })
                      }
                    } else {
                      const lbResult = dataForChart[armcd][category][test][
                        day
                      ].reduce((acc: any, item: any) => {
                        const { LBTESTCD, lbResult } = item
                        const lbTestRange = (lbRangesState || {})[LBTESTCD]

                        let lbScore = 0
                        if (lbResult && lbTestRange) lbScore = 1

                        return acc + lbScore
                      }, 0)

                      if (tmpSeries.series[day]) {
                        const { y } = tmpSeries.series[day]

                        tmpSeries.series[day] = {
                          ...tmpSeries.series[day],
                          y: y + lbResult
                        }
                      } else {
                        let preDay = day - 1

                        if (day > 1 && !tmpSeries.series[preDay]) {
                          while (preDay > 0) {
                            if (!tmpSeries.series[preDay]) {
                              tmpSeries.series[preDay] = {
                                name: test,
                                y: 0
                              }
                            }

                            preDay -= 1
                          }
                        }

                        tmpSeries.series[day] = {
                          name: test,
                          y: lbResult
                        }
                      }
                    }
                  }
                )

                if (testSeries) {
                  const { status: lbStatusPalette } = lbPalette

                  Object.keys(testSeries).forEach((name, i) => {
                    const nameLowerCase = name.toLocaleLowerCase()

                    const color = lbStatusPalette[nameLowerCase]
                      ? lbStatusPalette[nameLowerCase].color
                      : undefined

                    const ser: any = {
                      name,
                      id: name,
                      index: i,
                      data: [],
                      color,
                      armcd
                    }

                    Object.keys(testSeries[name].series).forEach((day) => {
                      ser.data.push(testSeries[name].series[day])
                    })

                    if (ser.data.length > dayMaxX) {
                      dayMaxX = ser.data.length
                    }

                    options[armcd].series.push(ser)
                  })
                } else {
                  tmpSeries.data = Object.keys(tmpSeries.series).map(
                    (day) => tmpSeries.series[day]
                  )

                  delete tmpSeries.series

                  if (tmpSeries.data.length > dayMaxX) {
                    dayMaxX = tmpSeries.data.length
                  }

                  options[armcd].series.push(tmpSeries)
                }
              })
            }
          } else {
            Object.keys(data[armcd][category]).forEach((test) => {
              Object.keys(data[armcd][category][test]).forEach((day: any) => {
                const lbResult = data[armcd][category][test][day].reduce(
                  (acc: any, item: any) => {
                    const { LBTESTCD, lbResult } = item
                    const lbTestRange = (lbRangesState || {})[LBTESTCD]

                    let lbScore = 0
                    if (lbResult && lbTestRange) lbScore = 1

                    return acc + lbScore
                  },
                  0
                )

                if (tmpSeries.series[day]) {
                  const { y } = tmpSeries.series[day]

                  tmpSeries.series[day] = {
                    ...tmpSeries.series[day],
                    y: y + lbResult
                  }
                } else {
                  let preDay = day - 1

                  if (day > 1 && !tmpSeries.series[preDay]) {
                    while (preDay > 0) {
                      if (!tmpSeries.series[preDay]) {
                        tmpSeries.series[preDay] = {
                          name: category,
                          y: 0
                        }
                      }

                      preDay -= 1
                    }
                  }

                  tmpSeries.series[day] = {
                    name: category,
                    y: lbResult
                  }
                }
              })
            })

            tmpSeries.data = Object.keys(tmpSeries.series).map(
              (day) => tmpSeries.series[day]
            )

            delete tmpSeries.series

            if (tmpSeries.data.length > dayMaxX) {
              dayMaxX = tmpSeries.data.length
            }

            options[armcd].series.push(tmpSeries)
          }

          options[armcd] = {
            ...commonOptions,
            ...options[armcd]
          }

          options[armcd].xAxis.categories = Object.keys(
            Array(dayMaxX).fill(undefined)
          ).map((i) => parseInt(i) + 1)

          if (armcd === upperSelectedArm) {
            options[armcd].legend = {
              ...options[armcd].legend,
              verticalAlign: 'top',
              y: 20
            }
          }

          if (armcd === lowerSelectedArm) {
            const titleMods = {
              verticalAlign: 'bottom',
              y: -10,
              floating: true
            }
            const chartMods = {
              marginBottom: 75,
              legendPosition: 'bottom'
            }
            const xAxisMods = {
              title: { text: null },
              enabled: false,
              visible: false,
              labels: { enabled: false }
            }
            const yAxisMods = {
              reversed: true,
              min: 0
            }
            const subtitleMods = {
              text: subtitle + '<br/>' + whatHaveIDrilledDownTo(),
              style: { color: contrastingColor },
              verticalAlign: 'top',
              y: 5
            }

            options[armcd].title = {
              ...options[armcd].title,
              ...titleMods
            }
            options[armcd].chart = {
              ...options[armcd].chart,
              ...chartMods
            }
            options[armcd].xAxis = {
              ...options[armcd].xAxis,
              ...xAxisMods
            }
            options[armcd].yAxis = {
              ...options[armcd].yAxis,
              ...yAxisMods
            }
            options[armcd].subtitle = subtitleMods
          }
        })
      })

      return { options, dayMaxX }
    },
    [
      contrastingColor,
      dataForChart,
      drillDownLevel,
      drillDownPath,
      drillVars,
      lowerSelectedArm,
      screenState,
      upperSelectedArm,
      subtitle,
      lbPalette,
      baseLines,
      selectedStudyState,
      armcdSubjects,
      whereSubjects,
      lbRangesState
    ]
  )

  useEffect(() => {
    if (dataForChart) {
      let tempChartOptions: any = {}

      let dayMaxX = 1

      if (!drillVars.length) {
        const res = dataToChartOptions(dataForChart)

        tempChartOptions = res.options

        dayMaxX = res.dayMaxX
      } else {
        const res = dataToChartOptions(dataForChart, drillVars)

        tempChartOptions = res.options

        dayMaxX = res.dayMaxX
      }

      if (Object.keys(tempChartOptions).length) {
        let dayMaxY = 0

        Object.keys(tempChartOptions).forEach((arm) => {
          tempChartOptions[arm].series = tempChartOptions[arm].series
            .map((series: any) => {
              const { data, name } = series

              while (data.length < dayMaxX) {
                data.push({
                  name,
                  y: 0
                })
              }

              return series
            })
            .filter(
              (series: any) =>
                series.data.reduce((acc: any, item: any) => acc + item.y, 0) > 0
            )
            .sort((s1: any, s2: any) =>
              s1.name > s2.name ? 1 : s1.name < s2.name ? -1 : 0
            )
            .map((series: any, i: any) => ({ ...series, index: i }))
        })

        let dayToCheck = 0

        while (dayToCheck < dayMaxX) {
          // eslint-disable-next-line
          Object.keys(tempChartOptions).forEach((arm) => {
            const sumY = tempChartOptions[arm].series
              .map((series: any) => series.data[dayToCheck].y)
              .reduce((acc: any, item: any) => acc + item, 0)

            if (dayMaxY < sumY) dayMaxY = sumY
          })

          dayToCheck++
        }

        Object.keys(tempChartOptions).forEach((arm) => {
          tempChartOptions[arm].yAxis.max = dayMaxY
        })

        setChartOptions(tempChartOptions)
      }
    }
  }, [
    dataForChart,
    classVar,
    contrastingColor,
    drillDownLevel,
    drillDownPath,
    drillVars,
    screenState,
    lowerSelectedArm,
    upperSelectedArm,
    subtitle,
    dataToChartOptions
  ])

  const getSubjectsFilterOptions = () => {
    const options = []

    if (lbCategory) options.push({ key: 'LBCAT', value: lbCategory })
    if (lbTest) options.push({ key: 'LBTEST', value: lbTest })
    if (lbNRind) options.push({ key: 'STATUS', value: lbNRind })
    if (lbDaySelected) options.push({ key: 'DY', value: lbDaySelected })

    return options
  }

  return (
    <>
      <div id={chartContainerId}>
        {chartOptions && chartOptions[upperSelectedArm] && (
          <Grid container spacing={3}>
            <Grid item xs={12} key={'upper'}>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions[upperSelectedArm]}
                immutable={true}
              />
            </Grid>
          </Grid>
        )}
        {chartOptions && chartOptions[lowerSelectedArm] && (
          <Grid container spacing={3}>
            <Grid item xs={12} key={'lower'}>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions[lowerSelectedArm]}
                immutable={true}
              />
            </Grid>
          </Grid>
        )}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
          <InputLabel id="upper-arm-label">Upper Arm</InputLabel>
          {arms && arms.length > 0 && upperSelectedArm && (
            <Select
              labelId="upper-arm-label"
              id="upper-arm-select"
              value={upperSelectedArm ? upperSelectedArm : ''}
              label="Upper Arm"
              onChange={(event) => {
                setUpperSelectedArm(event.target.value)
              }}
            >
              {arms &&
                arms.length > 0 &&
                arms.map((arm: any, i: any) => (
                  <MenuItem key={'up' + i} value={arm}>
                    {arm}
                  </MenuItem>
                ))}
            </Select>
          )}
        </FormControl>
        {arms && arms.length > 1 && (
          <>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
              <InputLabel id="lower-arm-label">Lower Arm</InputLabel>
              {arms && arms.length > 0 && lowerSelectedArm && (
                <Select
                  labelId="lower-arm-label"
                  id="lower-arm-select"
                  value={lowerSelectedArm ? lowerSelectedArm : ''}
                  label="Lower Arm"
                  onChange={(event) => {
                    setLowerSelectedArm(event.target.value)
                  }}
                >
                  {arms &&
                    arms.length > 0 &&
                    arms.map((arm: any, i: any) => (
                      <MenuItem key={'low' + i} value={arm}>
                        {arm}
                      </MenuItem>
                    ))}
                </Select>
              )}
            </FormControl>
          </>
        )}
        <ChartDownload elementId={chartContainerId} fileName="LB-BOTh" />
      </div>
      {drillDownLevel > 0 ? (
        <span
          style={{
            position: 'fixed',
            bottom: '100px',
            right: '40px',
            zIndex: 100
          }}
        >
          <Tooltip title="Level we have drilled down to">
            <Fab
              size="small"
              variant="extended"
              color="info"
              sx={{ margin: 1 }}
              onClick={drillUp}
            >
              Level {drillDownLevel}
            </Fab>
          </Tooltip>
          <Tooltip title="Drill up">
            <Fab size="small" color="info" sx={{ margin: 1 }} onClick={drillUp}>
              <ArrowDropUpIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Go to top level">
            <Fab size="small" color="info" sx={{ margin: 1 }} onClick={top}>
              <ArrowUpwardIcon />
            </Fab>
          </Tooltip>
        </span>
      ) : null}
      {showSubjects && (
        <LookBelow
          label="Subject Table"
          tooltip="Click to scroll to subject table appears below"
          mt={2}
          ml={4}
          mr={0}
          mb={0}
        />
      )}
      {showSubjects && (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          theme={theme}
          filterOptions={getSubjectsFilterOptions()}
          apiController={apiController}
        />
      )}
      <Snackbar
        message={"Can't drill any lower"}
        open={snackBarOpen}
        onClose={() => setSnackBarOpen(false)}
      />
    </>
  )
}
