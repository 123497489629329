import React from 'react'
import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import { configureFusionChart, configureMui, saveState } from './utils/index'
import { Provider } from 'react-redux'
import store from './store/store.ts'
import _ from 'lodash'

store.subscribe(
  _.throttle(() => {
    saveState({
      studies: store.getState().studies,
      vd: store.getState().vd,
      subjects: store.getState().subjects,
      ae: store.getState().ae,
      filter: store.getState().filter,
      lb: store.getState().lb,
      variables: store.getState().variables,
      preferences: store.getState().preferences,
      tables: store.getState().tables,
      access: store.getState().access
    })
  }, 1000)
)

configureFusionChart()
configureMui()

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HashRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </HashRouter>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
