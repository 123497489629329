import * as ActionTypes from '../actionTypes'
import {
  SetFilterOptionToTableMap,
  SetFilterOptions,
  RemoveFilterOption,
  SetFilterObject,
  ClearFilter
} from './types'
import {
  FilterOptions,
  FilterOptionToTableMap,
  FilterObject
} from '../../types'

export const setFilterOptionToTableMapAction = (
  payload: FilterOptionToTableMap
): SetFilterOptionToTableMap => ({
  type: ActionTypes.SET_FILTER_OPTION_TO_TABLE_MAP,
  payload
})

export const setFilterOptionsAction = (
  payload: FilterOptions
): SetFilterOptions => ({
  type: ActionTypes.SET_FILTER_OPTIONS,
  payload
})

export const removeFilterOptionAction = (
  payload: string
): RemoveFilterOption => ({
  type: ActionTypes.REMOVE_FILTER_OPTION,
  payload
})

export const setFilterObjectAction = (
  payload: FilterObject
): SetFilterObject => ({
  type: ActionTypes.SET_FILTER_OBJECT,
  payload
})

export const clearFilterAction = (): ClearFilter => ({
  type: ActionTypes.CLEAR_FILTER
})
