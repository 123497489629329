import { useState } from 'react'
import {
  Modal,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Snackbar
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import { dateFormat } from '../../utils'
import moment from 'moment'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { ApiController } from '../../controllers/ApiController'
import { useAppSelector } from '../../hooks'

interface MakeNotesModalProps {
  setOpenMakeNotesModal: React.Dispatch<boolean>
  openMakeNotesModal: boolean
  apiController: ApiController
}

export const MakeNotesModal = (props: MakeNotesModalProps) => {
  const { apiController, setOpenMakeNotesModal, openMakeNotesModal } = props

  const screenState = useAppSelector((state) => state.preferences.screen)
  const userNameState = useAppSelector((state) => state.access.userName)

  const location = useLocation()

  const handleClose = () => setOpenMakeNotesModal(false)

  const style = {
    position: 'absolute',
    top: '20%',
    left: '20%',
    transform: 'translate(-10%, -10%)',
    width: (screenState?.width || 1) * 0.8,
    height: (screenState?.height || 1) * 0.8,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  }

  const [tempNote, setTempNote] = useState<any>('')
  const [snackMessage, setSnackMessage] = useState<any>('')
  const [openSnackbar, setOpenSnackbar] = useState<any>(false)

  const handleOpenSnackbar = (message: any) => {
    setSnackMessage(message)
    setOpenSnackbar(true)
  }
  const handleCloseSnackbar = () => setOpenSnackbar(false)

  const addNewNote = () => {
    if (userNameState) {
      const url = encodeURIComponent(location.pathname)

      const created = moment().format(`${dateFormat} HH:mm:ss`)

      apiController
        .addNote(url, tempNote, userNameState, created)
        .then((res) => {
          handleOpenSnackbar(res)

          setTempNote('')
        })
    }
  }

  return (
    <Modal open={openMakeNotesModal || false} onClose={handleClose}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Make notes - {location.pathname}
        </Typography>
        <TextField
          id="new-note"
          label="New Note"
          variant="outlined"
          multiline
          rows={6}
          maxRows={4}
          onChange={(event) => {
            setTempNote(event.target.value)
          }}
          value={tempNote}
          sx={{ m: 1, minWidth: 600 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BorderColorIcon />
              </InputAdornment>
            )
          }}
        />
        &nbsp;&nbsp;
        <Button
          variant="contained"
          color="primary"
          onClick={addNewNote}
          size="small"
        >
          Add Note
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          size="small"
          sx={{ ml: 2 }}
        >
          Close
        </Button>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackMessage}
        />
      </Box>
    </Modal>
  )
}
