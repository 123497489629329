import { useState, useEffect, useCallback } from 'react'
import { sql, globalFilter, addToHistory } from '../../../apis/utility'
import { useLocation } from 'react-router-dom'
import ReactFC from 'react-fusioncharts'
import FusionCharts from 'fusioncharts'
import excelexport from 'fusioncharts/fusioncharts.excelexport'
import Charts from 'fusioncharts/fusioncharts.charts'
import SubjectTable from '../../SubjectTable'
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel'
import { LookBelow } from '../../layout/LookBelow'
import FusionChartJSX from '../../ui/FusionChartJSX'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector } from '../../../hooks'

ReactFC.fcRoot(FusionCharts, Charts, GammelTheme, excelexport)

export const MHSunburst = (props: any) => {
  const { theme, apiController } = props
  const chartContainerId = 'mh-sunburst-charts-container'

  const selectedStudyState = useAppSelector((state) => state.studies.selected)
  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedSubjectsState = useAppSelector(
    (state) => state.subjects.selected
  )
  const screenState = useAppSelector((state) => state.preferences.screen)

  const location = useLocation()

  addToHistory({ title: 'MH Sunburst', url: location })

  const [drillDownPathMH] = useState(['MHBODSYS', 'MHCAT', 'MHDECOD'])
  const [subtitle, setSubtitle] = useState<any>(null)
  const [dataSource, setDataSource] = useState<any>(null)
  const [showSubjects, setShowSubjects] = useState<any>(false)
  const [subjectsDrilledTo, setSubjectsDrilledTo] = useState<any>([])
  const [subjectsDrilledToLabel, setSubjectsDrilledToLabel] = useState<any>()
  const [subjectsDrilledToLevel, setSubjectsDrilledToLevel] = useState<any>()

  const whereSubjects =
    selectedSubjectsState && selectedSubjectsState.length > 0
      ? 'where SUBJID in ("' +
        selectedSubjectsState.map((s) => s.SUBJID).join('","') +
        '")'
      : ''

  const sqlStatement1 = `SELECT ${drillDownPathMH[0]}, count( * ) AS count FROM mh ${whereSubjects} GROUP BY ${drillDownPathMH[0]}`
  const sqlStatement2 = `SELECT ${drillDownPathMH[0]}, ${drillDownPathMH[1]}, count( * ) AS count FROM mh ${whereSubjects} GROUP BY ${drillDownPathMH[0]}, ${drillDownPathMH[1]}`
  const sqlStatement3 = `SELECT ${drillDownPathMH[1]}, ${drillDownPathMH[2]}, count( * ) AS count FROM mh ${whereSubjects} GROUP BY ${drillDownPathMH[1]}, ${drillDownPathMH[2]}`

  const getSubjectsDrilledTo = useCallback(
    (label: any, level: any) => {
      const variable = drillDownPathMH[level - 1]

      sql(
        selectedStudyState?.studyDB,
        `select distinct SUBJID from mh where ${variable} = '${label}' ${whereSubjects.replace(
          'where',
          'and'
        )}`
      ).then((res) => {
        if (res.message === 'success') {
          setShowSubjects(true)
          setSubjectsDrilledTo(res.data.map((d: any) => d.SUBJID))
        } else {
          setShowSubjects(false)
        }
      })
    },
    [
      drillDownPathMH,
      whereSubjects,
      setShowSubjects,
      setSubjectsDrilledTo,
      selectedStudyState
    ]
  )

  useEffect(() => {
    setSubtitle(globalFilter(selectedFilterState) || '')
    if (drillDownPathMH && sqlStatement1 && sqlStatement2 && sqlStatement3)
      sql(selectedStudyState?.studyDB, sqlStatement1).then((res1) => {
        if (res1.message === 'success') {
          const data1 = res1.data.map((row: any) => ({
            parent: 'MH',
            id: '1' + drillDownPathMH[0] + row[drillDownPathMH[0]],
            label: row[drillDownPathMH[0]],
            value: row.count
          }))

          sql(selectedStudyState?.studyDB, sqlStatement2).then((res2) => {
            if (res2.message === 'success') {
              const data2 = res2.data.map((row: any) => ({
                parent: '1' + drillDownPathMH[0] + row[drillDownPathMH[0]],
                id: '2' + drillDownPathMH[1] + row[drillDownPathMH[1]],
                label: row[drillDownPathMH[1]],
                value: row.count
              }))

              sql(selectedStudyState?.studyDB, sqlStatement3).then((res3) => {
                if (res3.message === 'success') {
                  const data3 = res3.data.map((row: any) => ({
                    parent: '2' + drillDownPathMH[1] + row[drillDownPathMH[1]],
                    id: '3' + row[drillDownPathMH[2]],
                    label: row[drillDownPathMH[2]],
                    value: row.count
                  }))

                  setDataSource({
                    chart: {
                      caption:
                        'Medical History (' + selectedStudyState?.STUDYID + ')',
                      subcaption: subtitle,
                      bgColor: 'EEEEEE,CCCCCC',
                      bgratio: '60,40',
                      bgAlpha: '70,80',
                      bgAngle: '180'
                    },
                    data: [
                      {
                        id: 'MH',
                        parent: '',
                        label: 'MH',
                        value: ''
                      },
                      ...data1,
                      ...data2,
                      ...data3
                    ]
                  })

                  if (
                    subjectsDrilledToLevel !== undefined &&
                    subjectsDrilledToLabel !== undefined
                  ) {
                    getSubjectsDrilledTo(
                      subjectsDrilledToLabel,
                      subjectsDrilledToLevel
                    )
                  }
                }
              })
            }
          })
        }
      })
  }, [
    selectedSubjectsState,
    selectedStudyState,
    drillDownPathMH,
    selectedFilterState,
    subtitle,
    sqlStatement1,
    sqlStatement2,
    sqlStatement3,
    subjectsDrilledToLevel,
    subjectsDrilledToLabel,
    getSubjectsDrilledTo
  ])

  return (
    <>
      {dataSource ? (
        <div id={chartContainerId}>
          <FusionChartJSX
            type="sunburst"
            width="100%"
            height={(screenState?.height || 1) * 0.75}
            dataFormat="JSON"
            dataSource={dataSource}
            events={{
              dataPlotClick: (event) => {
                const { nodeId, label } = event.data
                const level = event.data.nodeId.substring(0, 1)

                if (nodeId === 'MH') {
                  setShowSubjects(false)
                } else {
                  getSubjectsDrilledTo(label, level)

                  setSubjectsDrilledToLabel(label)
                  setSubjectsDrilledToLevel(level)
                }
              }
            }}
          />
          <ChartDownload elementId={chartContainerId} fileName="MH-Sunburst" />
        </div>
      ) : (
        'loading'
      )}
      {showSubjects && (
        <LookBelow
          label="Subject Table"
          tooltip="Click to scroll to subject table appears below"
          mt={2}
          ml={4}
          mr={0}
          mb={0}
        />
      )}
      {showSubjects && (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          repeats={true}
          filterOptions={[{ key: 'AE', value: subjectsDrilledToLabel }]}
          theme={theme}
          apiController={apiController}
        />
      )}
    </>
  )
}
