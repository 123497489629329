import { useState, useEffect } from 'react'
import { Donut } from './Donut'
import {
  decodeAbbreviation,
  enumerateDaysBetweenDates,
  compareObjects,
  filterOptionsToString
} from '../../utils'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { ApiController } from '../../controllers/ApiController'
import { DateCount } from '../../types'
import {
  setDateCountsAction,
  setMinVisitDateAction,
  setMaxVisitDateAction,
  setVisitDatesNumberAction,
  setAEsummaryAction,
  setFilterOptionsAction,
  clearFilterAction,
  setSelectedSubjectsAction
} from '../../store/action'
import {
  Stack,
  Chip,
  Container,
  Box,
  Typography,
  Tooltip,
  Grid
} from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import HeatMap from '@uiw/react-heat-map'
import InfoIcon from '@mui/icons-material/Info'

interface RecordColumn {
  description: string
  field: string
  headerName: string
  hideSortIcons: boolean
  width: number
}

interface HomeProps {
  rowCounts: { [key: string]: number }
  apiController: ApiController
}

// FIXME: fix rerendering on AEChip click
export default function Home(props: HomeProps) {
  const { apiController, rowCounts: rowCountsProp } = props

  const dispatch = useAppDispatch()
  const screenState = useAppSelector((state) => state.preferences.screen)
  const studyState = useAppSelector((state) => state.studies.selected)
  const vdState = useAppSelector((state) => state.vd)
  const filterState = useAppSelector((state) => state.filter)
  const aeState = useAppSelector((state) => state.ae)

  const [subjectsWithAes, setSubjectsWithAes] =
    useState<string>('calculating...')
  const [totalAes, setTotalAes] = useState<number | undefined>(
    aeState.summary?.totalAEs
  )
  const [seriousAes, setSeriousAes] = useState<number | undefined>(
    aeState.summary?.serious
  )
  const [notSeriousAes, setNotSeriousAes] = useState<number | undefined>(
    aeState.summary?.notSerious
  )
  const [notRelatedAes, setNotRelatedAes] = useState<number | undefined>(
    aeState.summary?.notRelated
  )
  const [possibleAes, setPossibleAes] = useState<number | undefined>(
    aeState.summary?.possible
  )
  const [probableAes, setProbableAes] = useState<number | undefined>(
    aeState.summary?.probable
  )
  const [remoteAes, setRemoteAes] = useState<number | undefined>(
    aeState.summary?.remote
  )
  const [unDeterminedAes, setUnDeterminedAes] = useState<number | undefined>(
    aeState.summary?.unDetermined
  )
  const [fatalAes, setFatalAes] = useState<number | undefined>(
    aeState.summary?.aeFatal
  )
  const [subtitle] = useState<string | null>(
    filterState.selected ? filterOptionsToString(filterState.selected) : null
  )
  const [visits, setVisits] = useState<DateCount[] | null>(vdState.dateCounts)
  const [visitDatesNumber, setVisitDatesNumber] = useState<number | null>(
    vdState.visitDatesNumber
  )
  const [maxVisitDate, setMaxVisitDate] = useState<Date | null>(
    vdState.maxVisitDate ? new Date(vdState.maxVisitDate) : null
  )
  const [minVisitDate, setMinVisitDate] = useState<Date | null>(
    vdState.minVisitDate ? new Date(vdState.minVisitDate) : null
  )
  const [rowColDefs, setRowColDefs] = useState<RecordColumn[]>()
  const [rowCounts, setRowCounts] = useState<{ [key: string]: number }[]>()

  const gridHover = { backgroundColor: '#333333' }

  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
  const panelColors = {
    0: '#FFFFFF',
    2: '#D6E0CC',
    4: '#ADC299',
    10: '#85A366',
    20: '#5C8533',
    30: '#336600'
  }
  const heatMapRectSize = Math.round((screenState?.width || 1) * 0.0175)

  useEffect(() => {
    if (aeState.summary) {
      const { subjectsWithAEs, totalSubjects } = aeState.summary

      const pctSubjectsWithAEs = (subjectsWithAEs / totalSubjects) * 100

      setSubjectsWithAes(
        `${subjectsWithAEs} of ${totalSubjects} (${pctSubjectsWithAEs.toFixed(1)}%)`
      )
    }
  }, [aeState.summary])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const dateCounts = await apiController.getDatesCount()

      // handle fetched data
      if (isMounted) {
        const realDates = dateCounts.map(
          (row) => new Date(row.date)
        ) as any as number[]

        const maxDate = new Date(Math.max.apply(null, realDates)).toString()
        const minDate = new Date(Math.min.apply(null, realDates)).toString()

        const visitDates = enumerateDaysBetweenDates(
          dateCounts[0].date,
          dateCounts[dateCounts.length - 1].date
        )

        if (!compareObjects(vdState.dateCounts, dateCounts)) {
          dispatch(setDateCountsAction(dateCounts))

          setVisits(dateCounts)
        }
        if (vdState.minVisitDate !== minDate) {
          dispatch(setMinVisitDateAction(minDate))

          setMinVisitDate(new Date(minDate))
        }
        if (vdState.maxVisitDate !== maxDate) {
          dispatch(setMaxVisitDateAction(maxDate))

          setMaxVisitDate(new Date(maxDate))
        }
        if (vdState.visitDatesNumber !== visitDates.length) {
          dispatch(setVisitDatesNumberAction(visitDates.length))

          setVisitDatesNumber(visitDates.length)
        }
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, dispatch, vdState])

  useEffect(() => {
    if (rowCountsProp === null) return

    const keys = Object.keys(rowCountsProp).filter((item) => item.length === 2)

    const recordColumns: RecordColumn[] = keys.map((key) => ({
      field: key,
      headerName: key,
      width: 60,
      hideSortIcons: true,
      description: decodeAbbreviation(key)
    }))

    setRowColDefs(recordColumns)

    const fistRow = { ...rowCountsProp, id: 1 }

    setRowCounts([fistRow])
  }, [rowCountsProp])

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const aeSummary = await apiController.getAEsummary()

      // handle fetched data
      if (isMounted) {
        const { summary } = aeState

        if (!summary || !compareObjects(summary, aeSummary)) {
          dispatch(setAEsummaryAction(aeSummary))

          const {
            totalAEs,
            serious,
            notSerious,
            notRelated,
            possible,
            probable,
            remote,
            unDetermined,
            aeFatal,
            subjectsWithAEs,
            totalSubjects
          } = aeSummary

          setTotalAes(totalAEs)
          setSeriousAes(serious)
          setNotSeriousAes(notSerious)
          setNotRelatedAes(notRelated)
          setPossibleAes(possible)
          setProbableAes(probable)
          setRemoteAes(remote)
          setUnDeterminedAes(unDetermined)
          setFatalAes(aeFatal)

          const pctSubjectsWithAEs = (subjectsWithAEs / totalSubjects) * 100

          setSubjectsWithAes(
            `${subjectsWithAEs} of ${totalSubjects} (${pctSubjectsWithAEs.toFixed(1)}%)`
          )
        }
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [aeState, apiController, dispatch])

  // FIXME: should be retrieved from package.json
  document.title = 'Xploratum'

  const AEchip = (props: {
    label: string
    backgroundColor: string
    color?: string
    selectedOption: { [key: string]: { value: string; label: string }[] }
  }) => {
    const { label, backgroundColor, color, selectedOption } = props

    return (
      <Chip
        label={label}
        sx={{
          backgroundColor: backgroundColor,
          '&:hover': { backgroundColor: 'primary.light' },
          color: color || 'unset'
        }}
        onClick={async () => {
          dispatch(clearFilterAction())
          dispatch(setFilterOptionsAction(selectedOption))

          const subjects = await apiController.getSubjects()

          dispatch(setSelectedSubjectsAction(subjects))
        }}
      />
    )
  }

  return (
    <>
      <Container sx={{ backgroundColor: 'primary.light', mt: 2 }}>
        <Box>
          <br />
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Box sx={{ gridHover }}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {studyState?.STUDYID}
                </Typography>
                <Typography sx={{ fontWeight: 'bold', color: '#bbbbbb' }}>
                  SQLite Database used - {studyState?.studyDB}
                </Typography>
                <Typography sx={{ textDecoration: 'underline' }}>
                  {subtitle}
                </Typography>
                <br />
                {totalAes !== null && (
                  <Stack direction="column" spacing={1}>
                    <AEchip
                      label={subjectsWithAes + ' subjects have an AE'}
                      backgroundColor={'#f4decd'}
                      selectedOption={{
                        AESER: [
                          { value: 'N', label: 'N' },
                          { value: 'Y', label: 'Y' }
                        ]
                      }}
                    />
                    <AEchip
                      label={totalAes + ' AEs in total'}
                      backgroundColor={'#d48462'}
                      selectedOption={{
                        AESER: [
                          { value: 'N', label: 'N' },
                          { value: 'Y', label: 'Y' }
                        ]
                      }}
                    />
                    <AEchip
                      label={notSeriousAes + ' Not Serious AEs'}
                      backgroundColor={'#e4b293'}
                      selectedOption={{
                        AESER: [{ value: 'N', label: 'N' }]
                      }}
                    />
                    <AEchip
                      label={
                        unDeterminedAes + ' AEs with Undetermined relatedness'
                      }
                      backgroundColor={'#f4decd'}
                      selectedOption={{
                        AEREL: [{ value: '', label: 'UNDETERMINED' }]
                      }}
                    />
                    <AEchip
                      label={remoteAes + ' Remotely related AEs'}
                      backgroundColor={'#f4decd'}
                      selectedOption={{
                        AEREL: [{ value: 'REMOTE', label: 'REMOTE' }]
                      }}
                    />
                    <AEchip
                      label={notRelatedAes + ' Not Related AEs'}
                      backgroundColor={'#e4b293'}
                      selectedOption={{
                        AEREL: [{ value: 'NONE', label: 'UNRELATED' }]
                      }}
                    />
                    <AEchip
                      label={possibleAes + ' Possibly related AEs'}
                      backgroundColor={'#d48462'}
                      selectedOption={{
                        AEREL: [{ value: 'POSSIBLE', label: 'POSSIBLE' }]
                      }}
                    />
                    <AEchip
                      label={probableAes + ' Probably related AEs'}
                      backgroundColor={'#ac011d'}
                      color={'white'}
                      selectedOption={{
                        AEREL: [{ value: 'PROBABLE', label: 'PROBABLE' }]
                      }}
                    />
                    <AEchip
                      label={seriousAes + ' Serious AEs'}
                      backgroundColor={'#111111'}
                      color={'white'}
                      selectedOption={{
                        AESER: [{ value: 'Y', label: 'Y' }]
                      }}
                    />
                    <AEchip
                      label={fatalAes + ' Death'}
                      backgroundColor={'#111111'}
                      color={'white'}
                      selectedOption={{
                        AEOUT: [{ value: 'FATAL', label: 'FATAL' }]
                      }}
                    />
                  </Stack>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ gridHover, marginBottom: 5 }}>
                {visits && (
                  <>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Visits made by study subjects
                    </Typography>
                    {minVisitDate && maxVisitDate && (
                      <Typography sx={{ fontWeight: 'bold', color: '#bbbbbb' }}>
                        {minVisitDate.toLocaleDateString(
                          'en-US',
                          dateOptions as any
                        )}{' '}
                        to{' '}
                        {maxVisitDate.toLocaleDateString(
                          'en-US',
                          dateOptions as any
                        )}
                      </Typography>
                    )}

                    <div id="heat-map" style={{ overflow: 'scroll' }}>
                      {visitDatesNumber && minVisitDate && maxVisitDate && (
                        <HeatMap
                          value={visits.map((v) => ({
                            ...v,
                            content: v.count
                          }))}
                          width={
                            Math.ceil(visitDatesNumber / 7) * // 7 - days in week
                              (heatMapRectSize + 2) + // 2 - space between rectangles
                            heatMapRectSize * 3 // heatMapRectSize * 3 - heat map margins
                          }
                          height={(screenState?.height || 1) * 0.3 + 22}
                          style={{ color: '#ad001d' }}
                          startDate={minVisitDate}
                          endDate={maxVisitDate}
                          panelColors={panelColors}
                          rectSize={heatMapRectSize}
                          legendCellSize={Math.round(heatMapRectSize / 2)}
                          rectRender={(props, data) => (
                            <Tooltip
                              key={props.key}
                              placement="top"
                              title={`${data.count || 0} on ${data.date}`}
                            >
                              <rect {...props} />
                            </Tooltip>
                          )}
                          legendRender={(props: any) => (
                            <Tooltip
                              key={props.key}
                              placement="top"
                              title={`${
                                Object.keys(panelColors)[props.key - 1] || ''
                              }${props.key === 0 ? '' : ' - '}${
                                Object.keys(panelColors)[props.key]
                              }`}
                            >
                              <rect {...props} />
                            </Tooltip>
                          )}
                        />
                      )}
                    </div>
                  </>
                )}
              </Box>
              {rowCounts && rowColDefs ? (
                <Box sx={{ height: 70, marginBottom: 10 }}>
                  <b>Records in each SDTM table</b>
                  <DataGridPro
                    rows={rowCounts}
                    columns={rowColDefs}
                    sx={{ fontSize: '0.7em' }}
                    density="compact"
                    hideFooter={true}
                  />
                </Box>
              ) : null}
            </Grid>
            <Donut apiController={apiController} />
          </Grid>
        </Box>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 5,
            marginTop: 35
          }}
        >
          <InfoIcon
            sx={{
              fontSize: 15,
              color: 'gray',
              cursor: 'pointer',
              marginTop: 'auto',
              marginBottom: 'auto'
            }}
          />
          <span style={{ fontSize: '0.9rem' }}>
            This page is an overview. Please use tools from the left-hand side
            menu to get more details.
          </span>
        </div>
      </Container>
    </>
  )
}
