import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
  Container,
  Typography,
  Grid,
  Tooltip,
  Button,
  Modal,
  Box,
  TextField,
  Chip
} from '@mui/material'
import {
  BOThString,
  dateFormat,
  timeout,
  getTreatmentDay,
  capitalizeFirstLetter
} from '../../utils'
import moment from 'moment'
import { DataGridPro } from '@mui/x-data-grid-pro'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import AEBoth from './ae/AEBoth'
import { LookBelow } from '../layout/LookBelow'
import html2canvas from 'html2canvas'
import Legend from '../ui/Legend'
import { ApiController } from '../../controllers/ApiController'
import { useAppSelector } from '../../hooks'
import {
  Visit,
  ConcomitantMedication,
  MedicalHistoryRecord,
  LBvalueStatus
} from '../../types'

interface DataColumn {
  headerName: string
  field: string
  width?: number
}

interface TableColumnDef {
  data: string
  field: string
  headerName: string | number
  title: string
  width: number
  renderCell?: any
  description?: string
  sortable?: false
  align?: string
  filterable?: boolean
  pinnable?: boolean
  hideable?: boolean
  minWidth?: number
}

interface VisitColumn extends Visit {
  columnName?: string
  columnNumber?: number
  start?: Date
}

interface DemogDataItem {
  id: string
  key: string
  value: string
}

interface PatientProfileProps {
  apiController: ApiController
  theme: any
}

export default function PatientProfile(props: PatientProfileProps) {
  const { apiController, theme } = props

  const screenState = useAppSelector((state) => state.preferences.screen)
  const lbRangesState = useAppSelector((state) => state.lb.ranges)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)

  let params = useParams()

  const { id: patientId } = params

  const containerId = 'patient-profile-container'
  const aeModuleId = 'ae-module'
  const lbModuleId = 'lb-module'

  const [study] = useState<string | null>(selectedStudyState?.STUDYID || null)
  const [aeData, setAeData] = useState<{ [key: string]: string | null }[]>() // AE
  const [fullAeData, setFullAeData] = useState<DemogDataItem[]>()
  const [aeColumnDefs, setAeColumnDefs] = useState<DataColumn[]>()
  const [openAe, setOpenAe] = useState<boolean>()
  const [isAeModuleLegendOpen, setIsAeModuleLegendOpen] = useState(false)
  const [isLabsModuleLegendOpen, setLabsModuleLegendOpen] = useState(false)

  const { palette } = theme
  const { ae: aePalette, lb: lbPalette } = palette

  const { result: lbResultPalette, status: lbStatusPalette } = lbPalette

  const defaultStyles = { textAlign: 'center', color: 'black' }

  const lbStyles: { [key: string]: { [key: string]: string } } = {}
  Object.keys(lbResultPalette).forEach((key) => {
    lbStyles[`& .${lbResultPalette[key].class}`] = {
      backgroundColor: lbResultPalette[key].color,
      ...defaultStyles
    }
  })
  Object.keys(lbStatusPalette).forEach((key) => {
    lbStyles[`& .${lbStatusPalette[key].class}`] = {
      backgroundColor: lbStatusPalette[key].color,
      ...defaultStyles
    }
  })

  const { severity: aeSeverityPallette } = aePalette
  const { seriousness: aeSeriousnessPallette } = aePalette
  const { relatedness: aeRelatednessPallette } = aePalette
  const aeStyles: any = {}
  Object.keys(aeSeverityPallette).forEach((key) => {
    aeStyles[`& .${aeSeverityPallette[key].class}`] = {
      backgroundColor: aeSeverityPallette[key].color,
      ...defaultStyles
    }
  })
  Object.keys(aeSeriousnessPallette).forEach((key) => {
    aeStyles[`& .${aeSeriousnessPallette[key].class}`] = {
      backgroundColor: aeSeriousnessPallette[key].color,
      ...defaultStyles
    }
  })
  Object.keys(aeRelatednessPallette).forEach((key) => {
    aeStyles[`& .${aeRelatednessPallette[key].class}`] = {
      backgroundColor:
        aeRelatednessPallette[key].color !== 'black'
          ? aeRelatednessPallette[key].color
          : '',
      ...defaultStyles
    }
  })

  const aeStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: isAeModuleLegendOpen ? 770 : 700,
    ...aeStyles
  }

  const handleOpenAe = () => setOpenAe(true)
  const handleCloseAe = () => setOpenAe(false)

  const [dmData, setDmData] = useState<DemogDataItem[]>() // DM
  const [vdData, setVdData] = useState<{ [key: string]: string | number }>() // VD
  const [fullVdData, setFullVdData] = useState<VisitColumn[]>()
  const [vsData, setVsData] =
    useState<{ [key: string]: null | string | number }[]>() // VS
  const [lbData, setLbData] = useState<{ [key: string]: null | string }[]>() // LB
  const [lbSummaryData, setLbSummaryData] =
    useState<{ [key: string]: string | number | null }[]>()

  const [splitLbData, setSplitLbData] = useState<
    {
      [key: string]: string | number
    }[][]
  >()

  const lbStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: 700,
    ...lbStyles
  }

  const [openLb, setOpenLb] = useState(false)
  const [lbModalArea, setLbModalArea] = useState<number>()

  const handleOpenLb = (area: number) => {
    setLbModalArea(area)
    setOpenLb(true)
  }
  const handleCloseLb = () => {
    setOpenLb(false)
    setLabsModuleLegendOpen(false)
  }

  const [lbModalColumnDefs, setLbModalColumnDefs] = useState<DataColumn[]>()
  const [dsData, setDsData] = useState<DemogDataItem[]>() // DS
  const [cmData, setCmData] = useState<ConcomitantMedication[]>() // CM data
  const [cmDataCols, setCmDataCols] = useState<DataColumn[]>() // CM columns
  const [mhData, setMhData] = useState<MedicalHistoryRecord[]>() // MH data
  const [mhDataCols, setMhDataCols] = useState<DataColumn[]>() // MH columns

  const tableStyle: any = {
    mb: 3,
    width: '100%'
  }

  Object.keys(aeStyles).forEach((key) => {
    tableStyle[key] = aeStyles[key]
  })

  const [tableData, setTableData] = useState<
    {
      [key: string]: string | number | null
    }[]
  >()
  const [tableColumnDefs, setTableColumnDefs] = useState<TableColumnDef[]>()
  const [dateFirstTreatment, setDateFirstTreatment] = useState<Date>()
  const [dateOfVisit, setDateOfVisit] = useState<{
    [key: string]: string
  }>()
  const [columnDate, setColumnDate] = useState<{ [key: string]: string }>()
  const [columnGroupingModel, setColumnGroupingModel] = useState([])
  const [savingAeModule, setSavingAeModule] = useState(false)
  const [savingLbModule, setSavingLbModule] = useState(false)

  const getColumnName = useCallback(
    (number: number) => columnPrefix + pad(number, 4),
    []
  )

  const formatDate = (date: any) => moment(date).format(dateFormat)
  const addDays = (date: any, days: any) =>
    moment(date).add(days, 'days').format(dateFormat)

  const columnPrefix = 'col'

  const splitValue = (value: string) => {
    if (typeof value === 'string' && value.startsWith('[')) {
      const valueMatch = value.match(/\[(.*?)\]/g)

      if (valueMatch) {
        return valueMatch.map((val) => val.replace(/[[\]]/g, ''))
      } else {
        return []
      }
    } else {
    }
  }

  //TODO: change labTitles to come from a query to actual data
  const labsTitle = useMemo(
    () => [
      'CHEMISTRY',
      'BIOCHEMISTRY',
      'HEMATOLOGY',
      'LIPID',
      'OTHER',
      'URINALYSIS'
    ],
    []
  )

  const contrastingColor =
    palette && palette.mode === 'dark' ? 'white' : 'black'

  const valueRenderer = useCallback(
    (props: { value: string }) => {
      const { value } = props

      if (
        typeof value === 'string' &&
        (value.startsWith('[ae]') || value.startsWith('[lb]'))
      ) {
        const values = splitValue(value)

        if (values) {
          const valueForCell = values[values.length - 1]

          if (values[0] === 'ae') {
            const tooltip = `
                                  ${values[2]},
                                  ${
                                    values[3] === 'POSSIBLE'
                                      ? 'Possibly Related'
                                      : values[3]
                                  },
                                  ${values[4]},
                                  ${
                                    values[5] === 'Y'
                                      ? 'Serious'
                                      : 'Not Serious'
                                  },
                                  ${values[6]},
                                  ${values[7]}`

            return (
              <Tooltip title={tooltip}>
                <span>{valueForCell}</span>
              </Tooltip>
            )
          } else if (typeof value === 'string' && values[0] === 'lb') {
            if (values[1] === LBvalueStatus.GOOD) {
              return (
                <CheckCircleIcon
                  sx={{ color: lbPalette.result[LBvalueStatus.GOOD].color }}
                />
              )
            } else if (values[1] === LBvalueStatus.HIGH_AND_LOW) {
              return (
                <Tooltip title="Some values over Upper Limit and below Lower Limit of Normal">
                  <CancelIcon
                    sx={{
                      color: lbPalette.result[LBvalueStatus.HIGH_AND_LOW].color
                    }}
                  />
                </Tooltip>
              )
            } else if (values[1] === LBvalueStatus.HIGH) {
              return (
                <Tooltip title="Some values over Upper Limit of Normal">
                  <CancelIcon
                    sx={{ color: lbPalette.result[LBvalueStatus.HIGH].color }}
                  />
                </Tooltip>
              )
            } else if (values[1] === LBvalueStatus.LOW) {
              return (
                <Tooltip title="Some values below Lower Limit of Normal">
                  <CancelIcon
                    sx={{ color: lbPalette.result[LBvalueStatus.LOW].color }}
                  />
                </Tooltip>
              )
            }

            return <span>{values[1]}</span>
          } else {
            return <span>{value}</span>
          }
        }
      } else {
        return <span>{value}</span>
      }
    },
    [lbPalette.result]
  )

  const pad = (num: any, size: any) => {
    num = num.toString()

    while (num.length < size) num = '0' + num

    return num
  }

  // VD - Visit Data
  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      if (patientId) {
        // call async func to fetch data
        const visits = await apiController.getVisits([patientId])

        // handle fetched data
        if (isMounted) {
          const KeyRenderer = (props: { field: string; value: string }) => {
            const { field, value } = props
            const values = splitValue(value)

            if (field === 'key' && values) {
              if (values[0] === 'lab') {
                return (
                  <Button
                    variant="text"
                    onClick={() => {
                      switch (values[1]) {
                        case labsTitle[0]:
                          handleOpenLb(0)
                          break
                        case labsTitle[1]:
                          handleOpenLb(1)
                          break
                        case labsTitle[2]:
                          handleOpenLb(2)
                          break
                        case labsTitle[3]:
                          handleOpenLb(3)
                          break
                        case labsTitle[4]:
                          handleOpenLb(4)
                          break
                        case labsTitle[5]:
                          handleOpenLb(5)
                          break
                        default:
                        // code block
                      }
                    }}
                    sx={{
                      ml: 2,
                      justifyContent: 'left',
                      color: 'text.primary',
                      fontSize: 10,
                      p: 0
                    }}
                  >
                    <u>{values[1]}</u>
                  </Button>
                )
              } else if (values[0] === 'section') {
                if (values.length > 2 && values[2] === 'ae') {
                  return (
                    <Button
                      variant="text"
                      onClick={handleOpenAe}
                      sx={{ color: 'text.primary', fontSize: 15, p: 0 }}
                    >
                      <u>{values[1]}</u>
                    </Button>
                  )
                } else {
                  // AE modal
                  return (
                    <Typography sx={{ color: 'primary', fontSize: 15, p: 0 }}>
                      <b>{values[1].toUpperCase()}</b>
                    </Typography>
                  )
                }
              } else if (values[0] === 'indent') {
                return (
                  <Typography
                    sx={{ ml: 2, color: 'text.primary', fontSize: 10, p: 0 }}
                  >
                    {values[1]}
                  </Typography>
                )
              }

              return <span>{value}</span>
            } else {
              return <span>{value}</span>
            }
          }

          const data: VisitColumn[] = visits.filter(
            (visit) => visits.filter((v) => v.DATE === visit.DATE).length === 1
          )

          const tempTableRow: { [key: string]: string | number } = {}
          const tempDateOfVisit: { [key: string]: string } = {
            key: 'Date of  Visit'
          }
          const colDate: { [key: string]: string } = {}
          let firstTreatmentDate: string
          let dayCount = 2
          let columnGrouping: any = []

          let calculatedColumnName = getColumnName(dayCount - 2)

          data.forEach((row, rowIndex: any) => {
            row.start = moment(row.DATE).toDate()
            row.columnNumber = rowIndex

            calculatedColumnName = getColumnName(
              parseInt(calculatedColumnName.replace(columnPrefix, '')) + 1
            )

            if (!firstTreatmentDate) {
              row.columnName = getColumnName(rowIndex + dayCount - 1)

              colDate[row.columnName] = row.DATE
            } else {
              let dateToCheck = addDays(firstTreatmentDate, 1)
              const rowDate = formatDate(row.DATE)
              const { VISIT: visit } = row
              const columnGroup: any = {
                groupId: visit,
                headerName: visit,
                description: visit,
                children: [],
                headerClassName: 'visit-group'
              }

              if (!tempTableRow[calculatedColumnName]) {
                while (dateToCheck !== rowDate) {
                  if (!Object.values(tempTableRow).includes(dateToCheck)) {
                    if (Object.values(tempTableRow).includes(dayCount)) {
                      dateToCheck = addDays(dateToCheck, 1)
                      dayCount = dayCount + 1

                      continue
                    }

                    if (!Object.values(colDate).includes(dateToCheck)) {
                      columnGroup.children.push({ field: calculatedColumnName })

                      tempTableRow[calculatedColumnName] = dayCount

                      colDate[calculatedColumnName] = dateToCheck

                      calculatedColumnName = getColumnName(
                        parseInt(
                          calculatedColumnName.replace(columnPrefix, '')
                        ) + 1
                      )
                    }

                    dayCount = dayCount + 1
                    dateToCheck = addDays(dateToCheck, 1)
                  }
                }

                columnGrouping.push(columnGroup)

                tempTableRow[calculatedColumnName] = dayCount
                if (!Object.values(colDate).includes(dateToCheck)) {
                  colDate[calculatedColumnName] = dateToCheck
                }
              }
            }

            tempTableRow[row.columnName || calculatedColumnName] =
              firstTreatmentDate ? dayCount : row.VISIT
            tempDateOfVisit[row.columnName || calculatedColumnName] = moment(
              row.start
            ).format('DMMMYY')

            if (['DAY 1', 'BASELINE', 'CYCLE 01 DAY 01'].includes(row.VISIT)) {
              setDateFirstTreatment(row.start)

              firstTreatmentDate = formatDate(row.start)
            }
          })

          setColumnGroupingModel(columnGrouping)
          setColumnDate(colDate)
          setDateOfVisit(tempDateOfVisit)
          setVdData(tempTableRow)
          setFullVdData(data)

          const slotColumnCommonFields = {
            sortable: false,
            filterable: false,
            pinnable: false,
            hideable: false,
            minWidth: 40,
            align: 'center',
            cellClassName: (params: any) => params.value,
            colSpan: ({ row, field, value }: any) => {
              // FIXME
              let colSpan = 1

              if (typeof value === 'string' && value.startsWith('[ae]')) {
                colSpan = Number(splitValue(value)![1])
              }

              return colSpan
            }
          }

          const notDayVisitNumber = Object.values(tempTableRow).filter(
            (value) => typeof value !== 'number' && value !== 'BASELINE'
          ).length

          const tempTableColumnDefs = Object.keys(tempTableRow)
            .map((key) => ({
              title:
                typeof tempTableRow[key] === 'number'
                  ? parseInt(key.replace(columnPrefix, '')) - notDayVisitNumber
                  : tempTableRow[key],
              data: key,
              headerName:
                typeof tempTableRow[key] === 'number'
                  ? parseInt(key.replace(columnPrefix, '')) - notDayVisitNumber
                  : tempTableRow[key],
              field: key,
              width:
                typeof tempTableRow[key] === 'number' ||
                tempTableRow[key] === 'BASELINE'
                  ? 40
                  : 50,
              // align: 'center',
              renderCell: valueRenderer,
              ...slotColumnCommonFields
            }))
            .sort((rowA, rowB) => {
              const getFieldNumber = (field: any) =>
                parseInt(field.replace(columnPrefix, ''))

              const fieldA = getFieldNumber(rowA.field)
              const fieldB = getFieldNumber(rowB.field)

              return fieldA - fieldB
            })

          setTableColumnDefs([
            {
              title: '',
              data: 'key',
              headerName: '',
              field: 'key',
              renderCell: KeyRenderer,
              width: 160
            },
            ...(tempTableColumnDefs as any)
          ])
        }
      }
    }

    if (!fullVdData) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [
    patientId,
    apiController,
    getColumnName,
    valueRenderer,
    fullVdData,
    labsTitle
  ])

  // DM - Demography
  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      if (patientId) {
        // call async func to fetch data
        const demographies = await apiController.getDemographies([patientId])

        // handle fetched data
        if (isMounted) {
          const keyDictionary = (key: string) => {
            const dictionary: { [key: string]: string } = {
              Subjid: 'Subject',
              Dmdtc: 'Date',
              Ethnic: 'Ethnicity',
              Saf: 'Safety Population',
              Pkset: 'PK Set',
              Pdset: 'PD Set'
            }

            return dictionary[key] || key
          }

          const temp: DemogDataItem[] = []
          const patient = demographies[0]

          for (const key in patient) {
            const tempRow: DemogDataItem = {
              key: key,
              value: (patient as any)[key],
              id: keyDictionary(capitalizeFirstLetter(key))
            }

            temp.push(tempRow)
          }

          setDmData(temp)
        }
      }
    }

    if (!dmData) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, patientId, dmData])

  // DS - Disposition
  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      if (patientId) {
        // call async func to fetch data
        const dispositions = await apiController.getDisposition([patientId])

        // handle fetched data
        if (isMounted) {
          const temp: DemogDataItem[] = []
          const patient = dispositions[0]

          for (const key in patient) {
            const tempRow: DemogDataItem = {
              key: key,
              value: (patient as any)[key],
              id: capitalizeFirstLetter(key)
            }
            temp.push(tempRow)
          }

          setDsData(temp)
        }
      }
    }

    if (!dsData) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, patientId, dsData])

  // CM - Concomitant Medications
  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      if (patientId) {
        // call async func to fetch data
        const medications = await apiController.getConcomitantMedications([
          patientId
        ])

        // handle fetched data
        if (isMounted) {
          setCmDataCols([
            { headerName: 'Treatment', field: 'CMTRT', width: 150 },
            { headerName: 'Dose', field: 'CMDOSE', width: 75 },
            { headerName: 'Unit', field: 'CMDOSU', width: 75 },
            { headerName: 'Route', field: 'CMROUTE', width: 75 },
            { headerName: 'Freq.', field: 'CMDOSFRQ', width: 75 },
            { headerName: 'Ongoing', field: 'CMONGO', width: 75 },
            { headerName: 'Start', field: 'CMSTDTC', width: 100 },
            { headerName: 'End', field: 'CMENDTC', width: 100 }
          ])

          const tempCmData = medications.map((medication, id: number) => ({
            id: id,
            ...medication
          }))

          setCmData(tempCmData)
        }
      }
    }

    if (!cmData) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, patientId, cmData])

  // MH - Medical History
  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      if (patientId) {
        // call async func to fetch data
        const records = await apiController.getMHrecords([patientId])

        // handle fetched data
        if (isMounted) {
          setMhDataCols([
            { headerName: 'Condition', field: 'MHDECOD', width: 250 },
            { headerName: 'Start', field: 'MHSTDTC', width: 150 },
            { headerName: 'End', field: 'MHDTC', width: 150 },
            { headerName: 'Ongoing', field: 'MHONGO', width: 75 }
          ])

          const tempMhData = records.map((record, id) => ({
            id: id,
            ...record
          }))

          setMhData(tempMhData)
        }
      }
    }

    if (!mhData) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, patientId, mhData])

  // VS - Vital Signs
  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      if (patientId && tableColumnDefs) {
        // call async func to fetch data
        const signs = await apiController.getVitalSigns([patientId])

        // handle fetched data
        if (isMounted) {
          let defaultTableRow: { [key: string]: null | string | number } = {}

          tableColumnDefs.forEach((columnDef: any) => {
            defaultTableRow[columnDef.data] = null
          })

          let lastCat: string | null = null
          let lastCD: string | null = null
          let vsTableData: { [key: string]: null | string | number }[] = []
          let tableRow = { ...defaultTableRow }
          const vsSortOrder: { [key: string]: number } = {
            RESP: 1,
            TEMP: 2,
            BMI: 3,
            WEIGHT: 4,
            HEIGHT: 5,
            OSYSBP: 6,
            ODIABP: 7,
            OPULSE: 8,
            SYSBP: 9,
            DIABP: 10,
            PULSE: 11
          }

          signs.forEach((row) => {
            const {
              VSCAT: vsCategory,
              VSTESTCD: vsTest,
              VSPOS: vsPosition,
              VSSTRESN: vsResult,
              VSDTC: vsDate
            } = row

            if (lastCat && lastCD && lastCD !== vsTest) {
              tableRow['category'] = lastCat

              vsTableData.push(tableRow)

              tableRow = { ...defaultTableRow }
            }

            tableRow['key'] = vsPosition
              ? '[indent][' + vsTest + ', ' + vsPosition + ']'
              : '[indent][' + vsTest + ']'

            const useKey = tableRow['key']
              .replace(/\[/g, ',')
              .replace(/\]/g, ',')
              .split(',')

            tableRow['vsSortOrder'] = vsSortOrder[useKey[3]] // lookup the order for each test code

            if (columnDate) {
              const colName: any = Object.keys(columnDate).find(
                (key) => columnDate[key] === vsDate
              )

              const newValue: any = vsResult ? Number(vsResult) : null

              if (colName !== null) {
                tableRow[colName] = tableRow[colName]
                  ? Math.max(tableRow[colName] as any, newValue)
                  : newValue
              }
            }
            lastCat = vsCategory
            lastCD = vsTest
          })

          vsTableData.push(tableRow)
          vsTableData.sort(
            (a, b) => (a.vsSortOrder as any) - (b.vsSortOrder as any)
          ) // put vital signs in the right order

          setVsData(vsTableData)
        }
      }
    }

    if (!vsData) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, patientId, columnDate, tableColumnDefs, vsData])

  // LB - Labs
  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      if (patientId && tableColumnDefs && columnDate) {
        // call async func to fetch data
        const labRecords = await apiController.getLabRecords([patientId])

        // handle fetched data
        if (isMounted) {
          let defaultTableRow: { [key: string]: null | string } = {}

          tableColumnDefs.forEach((columnDef: any) => {
            defaultTableRow[columnDef.data] = null
          })

          let lastCat: string | null = null
          let lastTest: string | null = null
          let lbTableData: { [key: string]: null | string }[] = []
          let tempFullLbData = []
          let categoryRow: { [key: string]: string | number } = {}
          let lbTableSummary: { [key: string]: string | number }[] = []
          let tableRow: any = { ...defaultTableRow }
          let currentCategoryStatus = { ...defaultTableRow }

          labRecords.forEach((row, id) => {
            const {
              LBCAT: lbCategory,
              LBTEST: lbTest,
              LBSTRESN: lbNumResult,
              LBSTRESC: lbCharResult,
              LBSTRESU: lbStandardUnitResult,
              LBDTC: lbDate,
              LBTESTCD
            } = row

            if (lastCat !== lbCategory) {
              tableRow = { ...defaultTableRow }
              tableRow['key'] = `[lab][` + lbCategory + ']'
              lbTableData.push(tableRow)
              tableRow = { ...defaultTableRow }
            }

            if (
              lastCat &&
              lastTest &&
              (lastTest !== lbTest || id === labRecords.length - 1)
            ) {
              tableRow['category'] = lastCat
              categoryRow['category'] = lastCat

              const colKeyItem = Object.keys(categoryRow).find((key) =>
                key.startsWith(columnPrefix)
              )

              if (colKeyItem) {
                lbTableData.push(tableRow)
                tempFullLbData.push(categoryRow)

                tableRow = { ...defaultTableRow }
                categoryRow = {}
              }
            }

            tableRow['key'] = lbStandardUnitResult
              ? '[indent][' + lbTest + ' (' + lbStandardUnitResult + ')]'
              : '[indent][' + lbTest + ']'

            const lbVisitDay = getTreatmentDay(dateFirstTreatment || '', lbDate)

            categoryRow['id'] = id
            categoryRow['key'] = lbTest
            categoryRow['visit'] = lbVisitDay
            categoryRow['unit'] = lbStandardUnitResult

            const rowDate = formatDate(lbDate)

            const columnName = Object.keys(columnDate).find(
              (key) => columnDate[key] === rowDate
            )

            if (!columnName) return

            const newValue = lbNumResult ? Number(lbNumResult) : lbCharResult
            const newRowValue =
              typeof newValue === 'number'
                ? Math.max(tableRow[columnName], newValue)
                : newValue

            tableRow[columnName] = newRowValue

            categoryRow[columnName + '-value'] = newRowValue
            categoryRow[columnName + '-date'] = rowDate

            const lbRange = lbRangesState ? lbRangesState[LBTESTCD] : undefined

            if (lbRange) {
              const { low, high } = lbRange

              const lbTestValue = categoryRow[columnName + '-value']

              if (typeof lbTestValue === 'number') {
                categoryRow[columnName + '-status'] =
                  lbTestValue >= parseFloat(high)
                    ? 'HIGH'
                    : lbTestValue > parseFloat(low || '')
                      ? 'NORMAL'
                      : 'LOW'
              }

              if (low) categoryRow['lower'] = low

              categoryRow['upper'] = high
            }

            if (lastCat === null) {
              currentCategoryStatus = { ...defaultTableRow }
              currentCategoryStatus['key'] = `[lab][` + lbCategory + ']'
            }

            if (
              (lastCat && lastCat !== lbCategory) ||
              labRecords.length === id + 1
            ) {
              Object.keys(currentCategoryStatus).forEach((colKey) => {
                if (colKey !== 'key') {
                  if (
                    currentCategoryStatus[colKey] === LBvalueStatus.HIGH_AND_LOW
                  ) {
                    currentCategoryStatus[colKey] =
                      `[lb][${LBvalueStatus.HIGH_AND_LOW}]`
                  } else if (
                    currentCategoryStatus[colKey] === LBvalueStatus.HIGH
                  ) {
                    currentCategoryStatus[colKey] =
                      `[lb][${LBvalueStatus.HIGH}]`
                  } else if (
                    currentCategoryStatus[colKey] === LBvalueStatus.LOW
                  ) {
                    currentCategoryStatus[colKey] = `[lb][${LBvalueStatus.LOW}]`
                  } else if (
                    currentCategoryStatus[colKey] === LBvalueStatus.GOOD
                  ) {
                    currentCategoryStatus[colKey] =
                      `[lb][${LBvalueStatus.GOOD}]`
                  }
                }
              })

              lbTableSummary.push(currentCategoryStatus as any)

              currentCategoryStatus = { ...defaultTableRow }
              currentCategoryStatus['key'] = `[lab][` + lbCategory + ']'
            }

            if (categoryRow[columnName + '-status'] === 'HIGH') {
              if (
                currentCategoryStatus[columnName] !== LBvalueStatus.HIGH_AND_LOW
              ) {
                if (currentCategoryStatus[columnName] === LBvalueStatus.LOW) {
                  currentCategoryStatus[columnName] = LBvalueStatus.HIGH_AND_LOW
                } else {
                  currentCategoryStatus[columnName] = LBvalueStatus.HIGH
                }
              }
            } else if (categoryRow[columnName + '-status'] === 'LOW') {
              if (
                currentCategoryStatus[columnName] !== LBvalueStatus.HIGH_AND_LOW
              ) {
                if (currentCategoryStatus[columnName] === LBvalueStatus.HIGH) {
                  currentCategoryStatus[columnName] = LBvalueStatus.HIGH_AND_LOW
                } else {
                  currentCategoryStatus[columnName] = LBvalueStatus.LOW
                }
              }
            } else if (categoryRow[columnName + '-status'] === 'NORMAL') {
              if (
                currentCategoryStatus[columnName] !==
                  LBvalueStatus.HIGH_AND_LOW &&
                currentCategoryStatus[columnName] !== LBvalueStatus.HIGH &&
                currentCategoryStatus[columnName] !== LBvalueStatus.LOW
              ) {
                currentCategoryStatus[columnName] = LBvalueStatus.GOOD
              }
            }

            lastCat = lbCategory
            lastTest = lbTest
          })

          lbTableData.push(tableRow)
          tempFullLbData.push(categoryRow)

          const defaultDateCategoryKeys = {
            key: 'Date',
            id: 'date'
          }

          categoryRow = {
            category: labsTitle[0],
            ...defaultDateCategoryKeys
          }

          tempFullLbData.unshift(categoryRow)

          categoryRow = {
            category: labsTitle[1],
            ...defaultDateCategoryKeys
          }

          tempFullLbData.unshift(categoryRow)

          categoryRow = {
            category: labsTitle[2],
            ...defaultDateCategoryKeys
          }

          tempFullLbData.unshift(categoryRow)

          categoryRow = {
            category: labsTitle[3],
            ...defaultDateCategoryKeys
          }

          tempFullLbData.unshift(categoryRow)

          categoryRow = {
            category: labsTitle[4],
            ...defaultDateCategoryKeys
          }

          tempFullLbData.unshift(categoryRow)

          categoryRow = {
            category: labsTitle[5],
            ...defaultDateCategoryKeys
          }

          tempFullLbData.unshift(categoryRow)

          setLbSummaryData(lbTableSummary)
          setLbData(lbTableData)

          let splitLbData = []
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[0])
          )
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[1])
          )
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[2])
          )
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[3])
          )
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[4])
          )
          splitLbData.push(
            tempFullLbData.filter((r) => r.category === labsTitle[5])
          )

          splitLbData = splitLbData.map((category) => {
            category = category.map((item) => {
              const dateId = 'date'

              if (item.id !== dateId) {
                Object.keys(item).forEach((key) => {
                  if (key.includes(`-${dateId}`) && dateOfVisit) {
                    const dateItem = category.find((i) => i.id === dateId)

                    const column = key.replace(`-${dateId}`, '')
                    let visitDate = dateOfVisit[column]

                    if (!visitDate) {
                      const colVisitDate = columnDate[column]

                      if (colVisitDate) {
                        visitDate = moment(colVisitDate).format('DMMMYY')
                      }
                    }

                    if (visitDate && dateItem && !dateItem[column + '-value']) {
                      dateItem[column + '-value'] = visitDate
                    }
                  }
                })
              }

              return item
            })

            return category
          })

          setSplitLbData(splitLbData)

          // column def for lb modal
          const uniqueKeys: string[] = []

          tempFullLbData.forEach((obj) => {
            Object.keys(obj).forEach((key) => {
              if (!key.startsWith('null') && !uniqueKeys.includes(key)) {
                uniqueKeys.push(key)
              }
            })
          })

          uniqueKeys.sort()

          const colPart = uniqueKeys
            .map((key) => {
              const visitDate = columnDate[key.substr(0, 7)]

              if (!visitDate) return null

              const lbVisitDay = getTreatmentDay(
                dateFirstTreatment || '',
                visitDate
              )

              if (key.includes('-value')) {
                return {
                  headerName: lbVisitDay,
                  field: key,
                  width: 75,
                  renderCell: valueRenderer
                }
              } else {
                return null
              }
            })
            .filter((item: any) => item !== null)

          const tempModalColumnDefs = [
            { headerName: 'Parameter', field: 'key', width: 200 },
            { headerName: 'Result Unit', field: 'unit', width: 80 },
            { headerName: 'Lower Limit', field: 'lower', width: 80 },
            { headerName: 'Upper Limit', field: 'upper', width: 80 },
            ...colPart
          ]

          setLbModalColumnDefs(tempModalColumnDefs as any)
        }
      }
    }

    if (!lbData) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [
    apiController,
    columnDate,
    dateFirstTreatment,
    dateOfVisit,
    labsTitle,
    lbRangesState,
    patientId,
    tableColumnDefs,
    valueRenderer,
    lbData
  ])

  // AE - Adverse Events
  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      if (patientId && columnDate) {
        // call async func to fetch data
        const aes = await apiController.getAERecords([patientId])

        // handle fetched data
        if (isMounted) {
          const keyDescriptions: { [key: string]: string } = {
            AEBODSYS: 'Body System',
            AEPT: 'Preferred Term',
            AEDECOD: 'Standardized Name',
            AEDUR: 'Duration',
            AESER: 'Serious',
            AESEV: 'Severity',
            AEREL: 'Related',
            AEOUT: 'Outcome',
            AEACN: 'Changed',
            AETE: 'TE',
            AESTDTC: 'Start',
            AEENDTC: 'End'
          }

          const blankRow: { [key: string]: string | null } = { ...dateOfVisit }
          Object.keys(blankRow).forEach(
            (columnName) => (blankRow[columnName] = null)
          )

          const temp: { [key: string]: string | null }[] = []
          const tempModal: DemogDataItem[] = []
          const keysForModal = aes.length > 0 ? Object.keys(aes[0]) : []

          aes.forEach((row, i) => {
            row.AESTDTC = moment(row.AESTDTC).toDate() as any
            row.AEENDTC = moment(row.AEENDTC).toDate() as any

            const aeStartDate = formatDate(row.AESTDTC)
            const colStart = Object.keys(columnDate || {}).find(
              (key) => columnDate[key] === aeStartDate
            )

            if (!colStart) return

            const { AEREL, AEDUR } = row
            const relatedness = AEREL
              ? AEREL === 'NONE'
                ? 'UNRELATED'
                : AEREL
              : 'UNDETERMINED'

            const duration = parseInt(AEDUR.replace(' days', '')) + 1

            const tempRow = { ...blankRow }
            tempRow['key'] = '[indent][' + row.AEPT + ']'
            tempRow[colStart] =
              `[ae][${duration}][${row.AESEV}][${relatedness}][${row.AEOUT}][${row.AESER}][${duration} days][${row.AEACN}][${row.AESEV}]`
            temp.push(tempRow)

            // add data for modal
            tempModal.push({ key: '', id: 'title' + i } as any)

            keysForModal.forEach((k, id) => {
              tempModal.push({
                id: 'x' + i + 'y' + id,
                key: keyDescriptions[k],
                value: k === 'AEREL' ? relatedness : (row as any)[k]
              })
            })
          })

          setAeData(temp)
          setFullAeData(tempModal)
          setAeColumnDefs([
            { headerName: 'Variable', field: 'key' },
            { headerName: 'Value', field: 'value', width: 500 }
          ])
        }
      }
    }

    if (!aeData) fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [apiController, patientId, aeData, columnDate, dateOfVisit])

  useEffect(() => {
    // add all the main data for the table
    const tempTableData = []

    if (aeData) {
      const title: { [key: string]: string | null } = { ...dateOfVisit }

      Object.keys(title).forEach((columnName) => (title[columnName] = null))

      title.key = `[section][Adverse Events][ae]`

      tempTableData.push(title)
      tempTableData.push(...aeData)
    }

    if (vsData) {
      const title: { [key: string]: string | null } = { ...dateOfVisit }

      Object.keys(title).forEach((columnName) => (title[columnName] = null))

      title.key = '[section][Vital Signs]'

      tempTableData.push(title)
      tempTableData.push(...vsData)
    }

    if (lbSummaryData) {
      const title: { [key: string]: string | null } = { ...dateOfVisit }

      Object.keys(title).forEach((columnName) => (title[columnName] = null))

      title.key = '[section][Labs]'

      tempTableData.push(title)
      tempTableData.push(...lbSummaryData)
    }

    if (vsData) {
      // add unique id
      const withId = tempTableData.map((row, i) => {
        row.id = i

        return row
      })

      setTableData(withId)
    }

    // work out the column headers
    const tempColHeaders = []
    if (vdData) {
      Object.keys(vdData).forEach((key) => {
        tempColHeaders.push(vdData[key])
      })
    }

    document.title = 'Xploratum ' + patientId
  }, [
    dmData,
    vdData,
    aeData,
    vsData,
    lbData,
    dsData,
    dateFirstTreatment,
    dateOfVisit,
    lbSummaryData,
    patientId
  ])

  const downloadImage = (canvas: HTMLCanvasElement, fileName: string) => {
    const a = document.createElement('a')
    a.href = canvas
      .toDataURL('image/jpeg')
      .replace('image/jpeg', 'image/octet-stream')
    a.download = `${fileName}.jpg`
    a.click()
  }

  const Demog = (props: { childKey: string; lookBelow: boolean }) => {
    const { childKey, lookBelow } = props

    if (dmData && dsData)
      return (
        <React.Fragment key={childKey}>
          {dmData.map((item: any) =>
            item.value ? (
              <React.Fragment key={'dm' + item.id}>
                <TextField
                  id={'dm' + item.id}
                  label={item.id}
                  size="small"
                  value={item.value}
                  sx={{
                    width: Math.max(item.value.length * 15, 80),
                    mb: 1,
                    mr: 1,
                    fontSize: '0.7rem'
                  }}
                />
              </React.Fragment>
            ) : null
          )}
          {dsData.map((item: any) =>
            item.value ? (
              <React.Fragment key={'dm' + item.id}>
                <TextField
                  id={'ds' + item.id}
                  label={item.id}
                  size="small"
                  value={item.value}
                  sx={{
                    width: Math.max(item.value.length * 15, 70),
                    mb: 1,
                    mr: 1
                  }}
                />
              </React.Fragment>
            ) : null
          )}
          {aeData && aeData.length > 0 && lookBelow ? (
            <LookBelow
              label={`AE ${BOThString} below`}
              tooltip={`Click to scroll down to Adverse Event ${BOThString} graph`}
              mt={0.5}
            />
          ) : null}
          {!openAe && !openLb && !savingAeModule && (
            <Chip
              clickable={true}
              icon={<SaveIcon />}
              color="primary"
              sx={{ mt: 0.5 }}
              label={'Save as file'}
              style={{ marginLeft: 8 }}
              onClick={async () => {
                const containerElement = document.getElementById(containerId)

                if (containerElement) {
                  // current width of the container element
                  const initialContainerWidth = containerElement.clientWidth

                  containerElement.style.width = 'calc(100% + 450px)'

                  await timeout(200)

                  html2canvas(containerElement).then((canvas) => {
                    setSavingAeModule(true)

                    const fileName = window.location.hash
                      .replace('#/', '')
                      .replace('/', '-')

                    downloadImage(canvas, fileName)

                    // restore original width of the container element
                    containerElement.style.width = `${initialContainerWidth}px`
                  })
                }
              }}
            />
          )}
        </React.Fragment>
      )
  }

  useEffect(() => {
    const saveModule = async () => {
      await timeout(100)

      const aeModule = document.getElementById(aeModuleId)

      if (aeModule) {
        const aeModuleTable = aeModule.querySelector("[style*='height: 0px']")

        if (aeModuleTable) {
          ;(aeModuleTable as any).style.height = null

          html2canvas(aeModule).then((canvas) => {
            const fileName =
              window.location.hash.replace('#/', '').replace('/', '-') + '_ae'

            downloadImage(canvas, fileName)

            setSavingAeModule(false)
            setSavingLbModule(true)
          })
        } else {
          setSavingAeModule(false)

          console.log(`Error: couldn't save AE module.`)
        }
      }
    }

    if (savingAeModule) saveModule()
  }, [savingAeModule])

  useEffect(() => {
    const saveModule = async (index: number) => {
      setLbModalArea(index)

      await timeout(100)

      const lbModule = document.getElementById(lbModuleId)

      const moduleName =
        `${splitLbData![index][0].category}`.toLocaleLowerCase()
      if (lbModule) {
        html2canvas(lbModule).then((canvas) => {
          const fileName =
            window.location.hash.replace('#/', '').replace('/', '-') +
            '_lb_' +
            moduleName

          downloadImage(canvas, fileName)

          return Promise.resolve()
        })
      } else {
        setSavingLbModule(false)

        console.log(`Error: couldn't save LB ${moduleName} module.`)

        return Promise.reject()
      }
    }

    const saveModules = async (indexes: any) => {
      setOpenLb(true)

      for (const index of indexes) await saveModule(index)

      setOpenLb(false)
    }

    if (savingLbModule && splitLbData && splitLbData.length) {
      const dataIndexes = splitLbData.reduce((acc: number[], item, i) => {
        if (item.length > 1) acc.push(i)

        return acc
      }, [])

      saveModules(dataIndexes)
      setSavingLbModule(false)
    }
  }, [savingLbModule, splitLbData])

  const collapseBlankColumns = (
    data: { [key: string]: string | number | null }[],
    columns: TableColumnDef[]
  ) => {
    const uniqueColumnsWithData = new Set()

    data.forEach((row) => {
      Object.keys(row).forEach((rowKey) => {
        if (rowKey.startsWith(columnPrefix)) {
          // It is a column
          if (typeof row[rowKey] === 'string') {
            if ((row[rowKey] as any).startsWith('[ae]')) {
              const aeDurationMatch = (row[rowKey] as any).match(
                /^\[ae\]\[\d*\]/
              )[0]

              if (aeDurationMatch) {
                let duration = parseInt(
                  aeDurationMatch.replace('[ae][', '').replace(']', '')
                )

                if (duration > 1) {
                  while (duration > 0) {
                    duration = duration - 1

                    const colWithinAeDuration = `${
                      parseInt(rowKey.replace(columnPrefix, '')) + duration
                    }`

                    uniqueColumnsWithData.add(colWithinAeDuration)
                  }
                } else {
                  uniqueColumnsWithData.add(rowKey.replace(columnPrefix, ''))
                }
              }
            } else {
              if (row[rowKey] !== null) uniqueColumnsWithData.add(rowKey)
            }
          } else {
            if (row[rowKey] !== null) uniqueColumnsWithData.add(rowKey)
          }
        }
      })
    })

    const columnsWithData = [
      ...new Set(
        [...uniqueColumnsWithData]
          .map((column: any) => parseInt(column.replace(columnPrefix, '')))
          .sort((a, b) => a - b)
      )
    ]

    const columnsToCollapse: number[] = []

    columnsWithData.forEach((column, i) => {
      if (i < columnsWithData.length - 1) {
        const nextColumn = columnsWithData[i + 1]

        if (nextColumn - column > 10) {
          let columnNumber = column + 1

          while (columnNumber < nextColumn) {
            columnsToCollapse.push(columnNumber)

            columnNumber++
          }
        }
      } else if (i === columnsWithData.length - 1) {
        const lastColumn = columns[columns.length - 1].headerName

        if ((lastColumn as any) > column) {
          let columnNumber = column + 1

          while (columnNumber < (lastColumn as any)) {
            columnsToCollapse.push(columnNumber)

            columnNumber++
          }
        }
      }
    })

    const filteredColumns = columns.filter(
      (column: any) =>
        !columnsToCollapse.includes(
          parseInt(column.data.replace(columnPrefix, ''))
        )
    )

    const finalColumns: TableColumnDef[] = []

    filteredColumns.forEach((column, i) => {
      if (filteredColumns[i + 1]) {
        const columnTitle = parseInt(
          (column.headerName === 'BASELINE' ? 1 : column.headerName) as any
        )

        if (Number.isInteger(columnTitle)) {
          const nextColumnField = columnTitle + 1

          const nextColumn = filteredColumns.find(
            (col) => col.headerName === nextColumnField
          )

          finalColumns.push(column)

          if (!nextColumn) {
            finalColumns.push({
              title: '...',
              data: i + '...',
              headerName: '...',
              field: i + '...',
              description:
                'Columns were collapsed because there was no data to display',
              width: 40,
              align: 'center',
              sortable: false,
              filterable: false,
              pinnable: false,
              hideable: false,
              minWidth: 40
            })
          }
        } else {
          finalColumns.push(column)
        }
      } else {
        finalColumns.push(column)
      }
    })

    return finalColumns
  }

  return (
    <Container maxWidth={false} id={containerId}>
      <Typography
        align="center"
        sx={{ mt: 2, mb: 3, fontWeight: 'bold', fontSize: 14 }}
      >
        {study} - Patient Profile
      </Typography>
      <Grid container spacing={1} sx={{ mb: 12 }}>
        <Demog childKey="main" lookBelow={true} />
        {tableData && tableColumnDefs && (
          <>
            <Grid sx={tableStyle}>
              <DataGridPro
                rows={tableData}
                columnGroupingModel={columnGroupingModel}
                experimentalFeatures={{ columnGrouping: true }}
                columns={
                  collapseBlankColumns(tableData, tableColumnDefs) as any
                }
                rowHeight={30}
                autoHeight={true}
                density={'compact'}
                initialState={{ pinnedColumns: { left: ['key'] } }}
                disableColumnMenu={true}
                hideFooter={true}
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    textOverflow: 'clip',
                    whiteSpace: 'break-spaces',
                    color: contrastingColor,
                    lineHeight: 1
                  },
                  '& .MuiDataGrid-pinnedColumns': {
                    color: contrastingColor
                  },
                  '& .MuiDataGrid-pinnedColumnHeaders': {
                    color: contrastingColor
                  },
                  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  },
                  fontSize: '0.7em',
                  mt: 3,
                  mb: 3,
                  '& .visit-group': {
                    borderBottom: '1px solid rgba(224, 224, 224, 1)'
                  }
                }}
                getCellClassName={(params) => {
                  const { value } = params
                  const values = splitValue(value)

                  if (
                    values &&
                    typeof value === 'string' &&
                    value.startsWith('[ae]')
                  ) {
                    const aeSeverity = values.length >= 2 ? values[2] : null

                    if (aeSeverity) {
                      return aeSeverity === 'SEVERE'
                        ? aeSeverityPallette.severe.class
                        : aeSeverity === 'MODERATE'
                          ? aeSeverityPallette.moderate.class
                          : aeSeverityPallette.mild.class
                    }
                  }

                  return ''
                }}
              />
              <Legend
                tooltip={'Click to view color legend'}
                data={[
                  {
                    text: <span>Adverse Events color legend:</span>,
                    items: [
                      {
                        title: 'Severe',
                        color: aePalette.severity.severe.color
                      },
                      {
                        title: 'Moderate',
                        color: aePalette.severity.moderate.color
                      },
                      { title: 'Mild', color: aePalette.severity.mild.color }
                    ]
                  },
                  {
                    text: <span>Labs color legend:</span>,
                    items: [
                      {
                        title: 'Good',
                        color: lbPalette.result[LBvalueStatus.GOOD].color
                      },
                      {
                        title:
                          'Over Upper Limit and Below Lower Limit of Normal',
                        color:
                          lbPalette.result[LBvalueStatus.HIGH_AND_LOW].color
                      },
                      {
                        title: 'Over or Equal Upper Limit of Normal',
                        color: lbPalette.result[LBvalueStatus.HIGH].color
                      },
                      {
                        title: 'Below or Equal Lower Limit of Normal',
                        color: lbPalette.result[LBvalueStatus.LOW].color
                      }
                    ]
                  }
                ]}
              />
            </Grid>
            <AEBoth
              apiController={apiController}
              theme={theme}
              selectedOptions={
                patientId
                  ? {
                      SUBJID: [{ label: patientId, value: patientId }]
                    }
                  : undefined
              }
              selectedSubjects={patientId ? [patientId] : undefined}
              notitle={true}
              columns={collapseBlankColumns(tableData, tableColumnDefs) as any}
              rows={aeData}
            />
          </>
        )}
      </Grid>
      {cmData && cmData.length > 0 ? (
        <Box
          sx={{
            width: (screenState?.width || 1) - 100
          }}
        >
          <b>Concomitent Medications</b>
          {cmDataCols && (
            <DataGridPro
              rows={cmData}
              columns={cmDataCols}
              autoHeight={true}
              rowHeight={30}
              density={'compact'}
              sx={{ fontSize: '0.7em' }}
            />
          )}
        </Box>
      ) : null}
      {mhDataCols && mhData && mhData.length > 0 ? (
        <Box
          sx={{
            width: (screenState?.width || 1) - 100
          }}
        >
          <b>Medical History</b>
          <DataGridPro
            rows={mhData}
            columns={mhDataCols}
            autoHeight={true}
            rowHeight={30}
            density={'compact'}
            sx={{ fontSize: '0.7em' }}
          />
        </Box>
      ) : null}
      <Modal open={!!openAe} onClose={handleCloseAe}>
        <Box sx={aeStyle}>
          {/* REFACTOR: AE Modal should be a component */}
          {fullAeData && aeColumnDefs && (
            <div>
              <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                {study} - Patient Profile
              </Typography>
              <Demog childKey="aeModal" lookBelow={false} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: isAeModuleLegendOpen ? 'column' : 'row',
                  gap: 5,
                  alignItems: isAeModuleLegendOpen ? 'start' : 'baseline'
                }}
              >
                <Typography variant="h6" sx={{ color: 'primary.main' }}>
                  Adverse Events
                </Typography>
                <Legend
                  tooltip={'Click to view color legend'}
                  data={[
                    {
                      text: 'AE Severety:',
                      items: [
                        {
                          title: 'Severe',
                          color: aePalette.severity.severe.color
                        },
                        {
                          title: 'Moderate',
                          color: aePalette.severity.moderate.color
                        },
                        { title: 'Mild', color: aePalette.severity.mild.color }
                      ]
                    },
                    {
                      text: 'AE Seriousness:',
                      items: [
                        {
                          title: 'Y',
                          color: aePalette.seriousness.serious.color
                        }
                      ]
                    },
                    {
                      text: 'AE Relatedness:',
                      items: [
                        {
                          title: 'Probable',
                          color: aePalette.relatedness.probable.color
                        },
                        {
                          title: 'Possible',
                          color: aePalette.relatedness.possible.color
                        },
                        {
                          title: 'Unrelated',
                          color: aePalette.relatedness.unrelated.color
                        },
                        {
                          title: 'Remote',
                          color: aePalette.relatedness.remote.color
                        },
                        {
                          title: 'Undetermined',
                          color: aePalette.relatedness.undetermined.color
                        }
                      ]
                    }
                  ]}
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 5
                  }}
                  onClick={() => setIsAeModuleLegendOpen(!isAeModuleLegendOpen)}
                />
              </div>
              <DataGridPro
                rows={fullAeData}
                columns={aeColumnDefs}
                rowHeight={30}
                density={'compact'}
                initialState={{ pinnedColumns: { left: ['key'] } }}
                disableColumnMenu={true}
                hideFooter={true}
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    textOverflow: 'clip',
                    whiteSpace: 'break-spaces',
                    lineHeight: 1
                  },
                  fontSize: '0.7em',
                  height: 500
                }}
                getCellClassName={(params) => {
                  const { value, row } = params

                  if (row && typeof row.key === 'string') {
                    switch (row.key) {
                      case 'Severity':
                        return value === 'SEVERE' ||
                          value === 'T4' ||
                          value === 'T5'
                          ? aePalette.severity.severe.class
                          : value === 'MODERATE' ||
                              value === 'T2' ||
                              value === 'T3'
                            ? aePalette.severity.moderate.class
                            : value === 'MILD' || value === 'T1'
                              ? aePalette.severity.mild.class
                              : ''

                      case 'Serious':
                        return value === 'Y'
                          ? aePalette.seriousness.serious.class
                          : ''

                      case 'Related':
                        return value === 'PROBABLE'
                          ? aePalette.relatedness.probable.class
                          : value === 'POSSIBLE'
                            ? aePalette.relatedness.possible.class
                            : value === 'UNRELATED'
                              ? aePalette.relatedness.unrelated.class
                              : value === 'REMOTE'
                                ? aePalette.relatedness.remote.class
                                : value === 'UNDETERMINED'
                                  ? aePalette.relatedness.undetermined.class
                                  : ''

                      default:
                        return ''
                    }
                  }

                  return ''
                }}
              />
            </div>
          )}
        </Box>
      </Modal>
      <Modal open={openLb} onClose={handleCloseLb}>
        <span>
          {splitLbData && lbModalArea !== undefined && (
            <Box sx={lbStyle} id={lbModuleId}>
              <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                {study} - Patient Profile
              </Typography>
              <Demog childKey="lbModal" lookBelow={false} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: isLabsModuleLegendOpen ? 'column' : 'row',
                  gap: 5,
                  alignItems: isLabsModuleLegendOpen ? 'start' : 'baseline'
                }}
              >
                <Typography variant="h6" sx={{ color: 'primary.main' }}>
                  Labs - {labsTitle[lbModalArea]}
                </Typography>
                <Legend
                  tooltip={'Click to view color legend'}
                  data={[
                    {
                      text: 'Status:',
                      items: [
                        { title: 'High', color: '#ff9999' },
                        { title: 'Normal', color: '#ffd699' },
                        { title: 'Low', color: '#ccd9ff' }
                      ]
                    }
                  ]}
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 5
                  }}
                  onClick={() =>
                    setLabsModuleLegendOpen(!isLabsModuleLegendOpen)
                  }
                />
              </div>
              {lbModalColumnDefs && (
                <DataGridPro
                  rows={splitLbData[lbModalArea]}
                  columns={lbModalColumnDefs}
                  rowHeight={30}
                  density={'compact'}
                  initialState={{ pinnedColumns: { left: ['key'] } }}
                  disableColumnMenu={true}
                  hideFooter={true}
                  sx={{
                    '& .MuiDataGrid-columnHeaderTitle': {
                      textOverflow: 'clip',
                      whiteSpace: 'break-spaces',
                      lineHeight: 1
                    },
                    fontSize: '0.7em',
                    height: 500
                  }}
                  getCellClassName={(params) => {
                    const { value, field, row } = params
                    const col = field.substring(0, 7)
                    const status = row[col + '-status']

                    if (typeof value !== 'object') {
                      return status === 'HIGH'
                        ? lbPalette.status.high.class
                        : status === 'NORMAL'
                          ? lbPalette.status.normal.class
                          : status === 'LOW'
                            ? lbPalette.status.low.class
                            : ''
                    }

                    return ''
                  }}
                />
              )}
            </Box>
          )}
        </span>
      </Modal>
      {savingAeModule && fullAeData && (
        <div id={aeModuleId}>
          <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
            {study} - Patient Profile
          </Typography>
          <Demog childKey="aeModal" lookBelow={false} />
          <Typography variant="h6" sx={{ color: 'primary.main' }}>
            Adverse Events
          </Typography>
          {aeColumnDefs && (
            <DataGridPro
              rows={fullAeData}
              columns={aeColumnDefs}
              rowHeight={30}
              density={'compact'}
              initialState={{ pinnedColumns: { left: ['key'] } }}
              disableColumnMenu={true}
              hideFooter={true}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  textOverflow: 'clip',
                  whiteSpace: 'break-spaces',
                  lineHeight: 1,
                  width: '80%'
                },
                fontSize: '0.7em'
              }}
              getCellClassName={(params) => {
                const { value, row } = params

                if (row && typeof row.key === 'string') {
                  switch (row.key) {
                    case 'Severity':
                      return value === 'SEVERE' ||
                        value === 'T4' ||
                        value === 'T5'
                        ? aePalette.severity.severe.class
                        : value === 'MODERATE' ||
                            value === 'T2' ||
                            value === 'T3'
                          ? aePalette.severity.moderate.class
                          : value === 'MILD' || value === 'T1'
                            ? aePalette.severity.mild.class
                            : ''

                    case 'Serious':
                      return value === 'Y'
                        ? aePalette.seriousness.serious.class
                        : ''

                    case 'Related':
                      return value === 'PROBABLE'
                        ? aePalette.relatedness.probable.class
                        : value === 'POSSIBLE'
                          ? aePalette.relatedness.possible.class
                          : ''
                    default:
                      return ''
                  }
                }

                return ''
              }}
            />
          )}
        </div>
      )}
    </Container>
  )
}
