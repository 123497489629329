import * as ActionTypes from '../actionTypes'
import {
  SetPreferencesScreen,
  SetPreferencesVitalSigns,
  SetPreferencesDrillDownPath
} from './types'
import {
  PreferencesScreen,
  PreferencesVitalSigns,
  PreferencesDrillDownPath
} from '../../types'

export const setPreferencesScreenAction = (
  payload: PreferencesScreen
): SetPreferencesScreen => ({
  type: ActionTypes.SET_PREFERENCES_SCREEN,
  payload
})

export const setPreferencesVitalSignsAction = (
  payload: PreferencesVitalSigns
): SetPreferencesVitalSigns => ({
  type: ActionTypes.SET_PREFERENCES_VITAL_SIGNS,
  payload
})

export const setPreferencesDrillDownPathAction = (
  payload: PreferencesDrillDownPath
): SetPreferencesDrillDownPath => ({
  type: ActionTypes.SET_PREFERENCES_DRILLDOWN_PATH,
  payload
})
