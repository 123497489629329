import * as ActionTypes from '../actionTypes'
import { SetAEsummary, SetAEBOThChart, SetAEStreamGraph } from './types'
import { AEsummary, AEBOThChart, AEStreamGraph } from '../../types'

export const setAEBOThDataAction = (payload: AEBOThChart): SetAEBOThChart => ({
  type: ActionTypes.SET_AE_BOTh_CHART,
  payload
})

export const setAEsummaryAction = (payload: AEsummary): SetAEsummary => ({
  type: ActionTypes.SET_AE_SUMMARY,
  payload
})

export const setAEStreamGraphAction = (
  payload: AEStreamGraph
): SetAEStreamGraph => ({
  type: ActionTypes.SET_AE_STREAM_GRAPH,
  payload
})
