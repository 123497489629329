import { useState, useEffect } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { sql, globalFilter, addToHistory } from '../../apis/utility'
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/material'

export function TestTable(props: any) {
  const location = useLocation()

  addToHistory({ title: 'Test Table', url: location })

  const [vsData, setVsData] = useState<any>([])
  const [subtitle, setSubtitle] = useState<any>(null)
  const [columns, setColumns] = useState<any>(null)

  useEffect(() => {
    const where =
      props.selectedSubjects.length > 0
        ? ' SUBJID in ("' + props.selectedSubjects.join('","') + '")'
        : ''

    setSubtitle(globalFilter(props.selectedOptions))

    sql(
      props.studyDatabase,
      `SELECT SUBJID as id,
                    ae, dm, ds, lb, vd, vs,
                    serious as a1, moderate as a2, severe as a3, possible as a4, ae as a5, dm  as a6, ds as a7, lb as a8, vd as a9, vs as a10,
                    serious, moderate, severe, possible, probable
                    FROM subjects ${where}`
    ).then((res) => {
      if (res.message === 'success') {
        const tempData = res.data.map((row: any, id: any) => ({
          ...row,
          id: id
        }))

        setVsData(tempData)
      }
    })
  }, [props.studyDatabase, props.selectedOptions, props.selectedSubjects])

  useEffect(() => {
    const getValues = (data: any, variable: any) =>
      data
        .map((row: any) => row[variable])
        .filter((value: any) => Number.isFinite(value))

    const renderProgress = (cellValues: any, variable: any) => {
      const { value } = cellValues
      const flex = (value - min[variable]) / (max[variable] - min[variable])

      return (
        <Box
          sx={{
            flex: { flex },
            backgroundColor: 'info.main',
            color: 'white'
          }}
        >
          {value}
        </Box>
      )
    }
    const pulseValues: any = getValues(vsData, 'PULSE')
    const opulseValues: any = getValues(vsData, 'OPULSE')
    const sysbpValues: any = getValues(vsData, 'SYSBP')
    const osysbpValues: any = getValues(vsData, 'OSYSBP')
    const diabpValues: any = getValues(vsData, 'DIABP')
    const odiabpValues: any = getValues(vsData, 'ODIABP')
    const min: any = []
    const max: any = []

    min['PULSE'] = Math.min(...pulseValues)
    max['PULSE'] = Math.max(...pulseValues)
    min['OPULSE'] = Math.min(...opulseValues)
    max['OPULSE'] = Math.max(...opulseValues)
    min['SYSBP'] = Math.min(...sysbpValues)
    max['SYSBP'] = Math.max(...sysbpValues)
    min['OSYSBP'] = Math.min(...osysbpValues)
    max['OSYSBP'] = Math.max(...osysbpValues)
    min['DIABP'] = Math.min(...diabpValues)
    max['DIABP'] = Math.max(...diabpValues)
    min['ODIABP'] = Math.min(...odiabpValues)
    max['ODIABP'] = Math.max(...odiabpValues)

    const cols = [
      { width: 80, headerName: 'Subject', field: 'SUBJID' },
      { width: 50, headerName: 'Day', field: 'day' },
      { width: 90, headerName: 'Visit', field: 'VISIT' },
      { width: 140, headerName: 'Date', field: 'VSDTC' },
      {
        width: 105,
        headerName: 'Pulse',
        field: 'PULSE',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'PULSE')
        }
      },
      {
        width: 105,
        headerName: 'Orth. Pulse',
        field: 'OPULSE',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'OPULSE')
        }
      },
      {
        width: 105,
        headerName: 'Systolic BP',
        field: 'SYSBP',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'SYSBP')
        }
      },
      {
        width: 105,
        headerName: 'Ortho. Systolic BP',
        field: 'OSYSBP',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'DIABP')
        }
      },
      {
        width: 105,
        headerName: 'Diastolic BP',
        field: 'DIABP',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'DIABP')
        }
      },
      {
        width: 105,
        headerName: 'Ortho. Diastolic BP',
        field: 'ODIABP',
        renderCell: (cellValues: any) => {
          return renderProgress(cellValues, 'DIABP')
        }
      }
    ]

    setColumns(cols)
  }, [vsData])

  return (
    <>
      <h4>
        Vital Signs
        <br />
        <span style={{ fontSize: 12 }}>{subtitle}</span>
      </h4>
      {vsData && columns ? (
        <div style={{ height: 650, width: '100%' }}>
          <DataGridPro
            rows={vsData}
            columns={columns}
            density={'compact'}
            rowHeight={30}
            sx={{ fontSize: '0.7em' }}
          />
        </div>
      ) : (
        'Loading...'
      )}
    </>
  )
}
