import * as ActionTypes from '../actionTypes'
import { SetTableInfo, SetTableData, SetTableColumns } from './types'
import {
  TableInfoObject,
  TableDataObject,
  TableColumnsObject
} from '../../types'

export const setTableInfoAction = (payload: TableInfoObject): SetTableInfo => ({
  type: ActionTypes.SET_TABLE_INFO,
  payload
})

export const setTableDataAction = (payload: TableDataObject): SetTableData => ({
  type: ActionTypes.SET_TABLE_DATA,
  payload
})

export const setTableColumnsAction = (
  payload: TableColumnsObject
): SetTableColumns => ({
  type: ActionTypes.SET_TABLE_COLUMNS,
  payload
})
