import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TreeView, TreeItem } from '@mui/lab'
import { Typography, Box, Tooltip } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { BOThString } from '../../utils'

export const Tools = (props: any) => {
  const { screenHeight, theme } = props

  const location = useLocation()
  const navigate = useNavigate()

  const [expanded, setExpanded] = useState<any>([])
  const [selected, setSelected] = useState<any>([])

  const handleToggle = (_: any, nodeIds: any) => {
    setExpanded(nodeIds)
  }
  const handleSelect = (_: any, nodeIds: any) => {
    setSelected(nodeIds)
  }

  const contrastingColor = theme.palette.mode !== 'dark' ? 'white' : 'black'

  useEffect(() => {
    if (location.pathname) {
      switch (location.pathname) {
        case '/aeboth':
          setExpanded(['1'])
          setSelected(['11'])
          break
        case '/view/ae':
          setExpanded(['1'])
          setSelected(['12'])
          break
        case '/aestreamgraph':
          setExpanded(['1'])
          setSelected(['13'])
          break
        case '/drill':
          setExpanded(['1'])
          setSelected(['14'])
          break
        case '/aesunburst':
          setExpanded(['1'])
          setSelected(['15'])
          break
        case '/mhsunburst':
          setExpanded(['9'])
          setSelected(['92'])
          break
        case '/dminfo':
          setExpanded(['2'])
          setSelected(['21'])
          break
        case '/view/dm':
          setExpanded(['2'])
          setSelected(['22'])
          break
        case '/dssankey':
          setExpanded(['3'])
          setSelected(['31'])
          break
        case '/view/ds':
          setExpanded(['3'])
          setSelected(['32'])
          break
        case '/dsinfo':
          setExpanded(['3'])
          setSelected(['33'])
          break
        case '/vsboxplot':
          setExpanded(['4'])
          setSelected(['41'])
          break
        case '/view/vs':
          setExpanded(['4'])
          setSelected(['42'])
          break
        case '/vsinfo':
          setExpanded(['4'])
          setSelected(['43'])
          break
        case '/lbboth':
          setExpanded(['5'])
          setSelected(['51'])
          break
        case '/view/lb':
          setExpanded(['5'])
          setSelected(['52'])
          break
        case '/hyslaw':
          setExpanded(['5'])
          setSelected(['53'])
          break
        case '/lbboxplot':
          setExpanded(['5'])
          setSelected(['54'])
          break
        case '/vdinfo':
          setExpanded(['6'])
          setSelected(['61'])
          break
        case '/view/vd':
          setExpanded(['6'])
          setSelected(['62'])
          break
        case '/view/cm':
          setExpanded(['8'])
          setSelected(['81'])
          break
        case '/view/mh':
          setExpanded(['9'])
          setSelected(['91'])
          break
        case '/aereports':
          setExpanded(['1'])
          setSelected(['16'])
          break
        case '/viewnotes':
          setExpanded(['7'])
          setSelected(['75'])
          break
        case '/query':
          setExpanded(['7'])
          setSelected(['72'])
          break
        default:
      }
    }
  }, [location.pathname])

  return (
    <Box
      sx={{
        backgroundColor: contrastingColor,
        position: 'fixed'
      }}
    >
      <Typography variant="h6" sx={{ opacity: 0.5 }} gutterBottom>
        Tools
      </Typography>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        sx={{
          textAlign: 'left',
          height: screenHeight * 0.9,
          flexGrow: 1,
          maxWidth: 400,
          overflowY: 'auto'
        }}
      >
        <Tooltip title="Adverse Events" placement="right">
          <div>
            <TreeItem nodeId="1" label="AE" disabled={props.showAe !== 'Y'}>
              <TreeItem
                nodeId="11"
                label={BOThString}
                onClick={() => navigate('/aeboth')}
              />
              <TreeItem
                nodeId="12"
                label="Table"
                onClick={() => navigate('/view/ae')}
              />
              <TreeItem
                nodeId="13"
                label="Stream"
                onClick={() => navigate('/aestreamgraph')}
              />
              <TreeItem
                nodeId="14"
                label="Drilldown"
                onClick={() => navigate('/drill')}
              />
              <TreeItem
                nodeId="15"
                label="Sunburst"
                onClick={() => navigate('/aesunburst')}
              />
              <TreeItem
                nodeId="16"
                label="Reports"
                onClick={() => navigate('/aereports')}
              />
            </TreeItem>
          </div>
        </Tooltip>
        <Tooltip title="Concomitant Medications" placement="right">
          <div>
            <TreeItem nodeId="8" label="CM" disabled={props.showCm !== 'Y'}>
              <TreeItem
                nodeId="81"
                label="Table"
                onClick={() => navigate('/view/cm')}
              />
            </TreeItem>
          </div>
        </Tooltip>
        <Tooltip title="Demographics" placement="right">
          <div>
            <TreeItem nodeId="2" label="DM" disabled={props.showDm !== 'Y'}>
              <TreeItem
                nodeId="21"
                label="Info"
                onClick={() => navigate('/dminfo')}
              />
              <TreeItem
                nodeId="22"
                label="Table"
                onClick={() => navigate('/view/dm')}
              />
            </TreeItem>
          </div>
        </Tooltip>
        <Tooltip title="Disposition" placement="right">
          <div>
            <TreeItem nodeId="3" label="DS" disabled={props.showDs !== 'Y'}>
              <TreeItem
                nodeId="31"
                label="Sankey"
                onClick={() => navigate('/dssankey')}
              />
              <TreeItem
                nodeId="32"
                label="Table"
                onClick={() => navigate('/view/ds')}
              />
              <TreeItem
                nodeId="33"
                label="Days"
                onClick={() => navigate('/dsinfo')}
              />
            </TreeItem>
          </div>
        </Tooltip>
        <Tooltip title="Labs" placement="right">
          <div>
            <TreeItem nodeId="5" label="LB" disabled={props.showLb !== 'Y'}>
              <TreeItem
                nodeId="54"
                label="Box"
                onClick={() => navigate('/lbboxplot')}
              />
              <Tooltip title="View Labs Table" placement="right">
                <div>
                  <TreeItem
                    nodeId="52"
                    label="Table"
                    onClick={() => navigate('/view/lb')}
                  />
                </div>
              </Tooltip>
              <TreeItem
                nodeId="53"
                label="Hys"
                onClick={() => navigate('/hyslaw')}
              />
              <TreeItem
                nodeId="51"
                label={BOThString}
                onClick={() => navigate('/lbboth')}
              />
            </TreeItem>
          </div>
        </Tooltip>
        <Tooltip title="Medical History" placement="right">
          <div>
            <TreeItem nodeId="9" label="MH" disabled={props.showMh !== 'Y'}>
              <TreeItem
                nodeId="91"
                label="Table"
                onClick={() => navigate('/view/mh')}
              />
              <TreeItem
                nodeId="92"
                label="Sunburst"
                onClick={() => navigate('/mhsunburst')}
              />
            </TreeItem>
          </div>
        </Tooltip>
        <Tooltip title="Visit Data" placement="right">
          <div>
            <TreeItem nodeId="6" label="VD" disabled={props.showVd !== 'Y'}>
              <TreeItem
                nodeId="61"
                label="Days"
                onClick={() => navigate('/vdinfo')}
              />
              <TreeItem
                nodeId="62"
                label="Table"
                onClick={() => navigate('/view/vd')}
              />
            </TreeItem>
          </div>
        </Tooltip>
        <Tooltip title="Vital Signs" placement="right">
          <div>
            <TreeItem nodeId="4" label="VS" disabled={props.showVs !== 'Y'}>
              <TreeItem
                nodeId="41"
                label="Box"
                onClick={() => navigate('/vsboxplot')}
              />
              <TreeItem
                nodeId="42"
                label="Table"
                onClick={() => navigate('/view/vs')}
              />
              <TreeItem
                nodeId="43"
                label="List"
                onClick={() => navigate('/vslisting')}
              />
            </TreeItem>
          </div>
        </Tooltip>
        {/* https://git.4gl.io/xploratum/client/-/issues/72 */}
        {/* <Tooltip title="Other Tools" placement="right">
          <div>
            <TreeItem nodeId="7" label="Miscellaneous">
              <Tooltip title="Run a SQL Query" placement="right">
                <TreeItem
                  nodeId="72"
                  label="Query"
                  onClick={() => navigate('/query')}
                />
              </Tooltip>
              <Tooltip
                title="View all the notes for this study"
                placement="right"
              >
                <TreeItem
                  nodeId="75"
                  label="Notes"
                  onClick={() => navigate('/viewnotes')}
                />
              </Tooltip>
              <TreeItem
                nodeId="73"
                label="A.I."
                onClick={() => navigate('/openai')}
              />
              <TreeItem
                nodeId="74"
                label="General"
                onClick={() => navigate('/reporting')}
              />
              <TreeItem
                nodeId="76"
                label="Diagram Example"
                onClick={() => navigate('/diagrams')}
              />
              <TreeItem
                nodeId="77"
                label="Files"
                onClick={() => navigate('/files')}
              />
            </TreeItem>
          </div>
        </Tooltip> */}
      </TreeView>
    </Box>
  )
}
