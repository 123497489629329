import { useState, useEffect, useCallback } from 'react'
import { Grid } from '@mui/material'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import AnnotationsModule from 'highcharts/modules/annotations'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { ApiController } from '../../controllers/ApiController'
import { setAEsummaryAction } from '../../store/action'
import { compareObjects } from '../../utils'

AnnotationsModule(Highcharts)

interface DonutProps {
  apiController: ApiController
}

export const Donut = (props: DonutProps) => {
  const { apiController } = props

  const aeState = useAppSelector((state) => state.ae)
  const screenState = useAppSelector((state) => state.preferences.screen)
  const dispatch = useAppDispatch()

  const [seriousnessDataSource, setSeriousnessDataSource] = useState<{}>()
  const [severityDataSource, setSeverityDataSource] = useState<{}>()
  const [relatednessDataSource, setRelatednessDataSource] = useState<{}>()

  const getChartOptions = useCallback(
    (titleText: string, colors: string[], data: (string | number)[][]) => ({
      chart: {
        backgroundColor: 'transparent',
        plotBorderWidth: 0,
        plotShadow: false,
        marginBottom: 25,
        height: (screenState?.height || 1) * 0.25
      },
      credits: {
        enabled: false
      },
      title: {
        text: titleText,
        align: 'center',
        verticalAlign: 'top',
        y: 10,
        style: {
          fontWeight: 'bold',
          fontSize: '14px'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          colors,
          dataLabels: {
            enabled: true
          },
          startAngle: 45,
          endAngle: 45,
          center: ['50%', '60%'],
          size: '100%'
        }
      },
      series: [
        {
          type: 'pie',
          name: 'AE Share',
          innerSize: '60%',
          data
        }
      ]
    }),
    [screenState?.height]
  )

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      // call async func to fetch data
      const aeSummary = await apiController.getAEsummary()

      // handle fetched data
      if (isMounted) {
        const { summary } = aeState

        if (!summary) {
          dispatch(setAEsummaryAction(aeSummary))
        } else if (!compareObjects(summary, aeSummary)) {
          dispatch(setAEsummaryAction(aeSummary))
        }

        const {
          serious,
          notSerious,
          severe,
          moderate,
          mild,
          probable,
          possible,
          notRelated,
          remote,
          unDetermined
        } = aeSummary

        setSeriousnessDataSource(
          getChartOptions(
            'Seriousness',
            ['#000000', '#f4decd'],
            [
              ['Serious', serious],
              ['Not Serious', notSerious]
            ]
          )
        )

        setSeverityDataSource(
          getChartOptions(
            'Severity',
            ['#ac011d', '#e4b293', '#f4decd'],
            [
              ['Severe', severe],
              ['Moderate', moderate],
              ['Mild', mild]
            ]
          )
        )

        setRelatednessDataSource(
          getChartOptions(
            'Relatedness',
            ['#ac011d', '#e4b293', '#f4decd', '#f4decd', '#f4decd'],
            [
              ['Probable', probable],
              ['Possible', possible],
              ['Not Related', notRelated],
              ['Remote', remote],
              ['Undetermined', unDetermined]
            ]
          )
        )
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [aeState, apiController, dispatch, getChartOptions])

  return (
    <Grid container>
      <Grid item xs={4}>
        {seriousnessDataSource ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={seriousnessDataSource}
          />
        ) : (
          'loading ...'
        )}
      </Grid>
      <Grid item xs={4}>
        {severityDataSource ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={severityDataSource}
          />
        ) : (
          'loading ...'
        )}
      </Grid>
      <Grid item xs={4}>
        {relatednessDataSource ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={relatednessDataSource}
          />
        ) : (
          'loading ...'
        )}
      </Grid>
    </Grid>
  )
}
