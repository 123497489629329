import * as ActionTypes from '../actionTypes'
import { StudiesState, StudyDispatchTypes } from './types'

const initialState: StudiesState = { list: null, selected: null }

const reducer = (
  state = initialState,
  action: StudyDispatchTypes
): StudiesState => {
  switch (action.type) {
    case ActionTypes.SET_STUDIES:
      return {
        ...state,
        list: action.payload
      }

    case ActionTypes.SET_SELECTED_STUDY:
      return {
        ...state,
        selected: action.payload
      }

    case ActionTypes.RESTORE_STATE:
      return action.payload.studies || null

    default:
      return state
  }
}

export default reducer
