import { useState, useEffect } from 'react'
import {
  sql,
  globalFilter,
  addToHistory,
  openSaveFileDialog
} from '../../apis/utility'
import { useLocation } from 'react-router-dom'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Button, Box, IconButton, Tooltip } from '@mui/material'
import { usePapaParse } from 'react-papaparse'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

export const LBTable = (props: any) => {
  const location = useLocation()

  const [lbData, setLbData] = useState<any>([])
  const [subtitle, setSubtitle] = useState<any>(null)
  const [columns, setColumns] = useState<any>(null)
  const [colorLevels, setColorLevels] = useState<any>({
    default: [
      { from: 0, to: 50, color: '#d1d1e0' },
      { from: 50, to: 100, color: '#ffd9b3' },
      { from: 100, to: 99999, color: '#ff6666' }
    ]
  })

  // define  the variable we want in the table here
  const [variables] = useState([
    'ALB',
    'AST',
    'ALT',
    'ALKP',
    'BUN',
    'BUNCREAT',
    'CA',
    'CK',
    'CL',
    'CREAT',
    'GGTP',
    'GLUC',
    'HCO3',
    'K',
    'LDH',
    'MG',
    'NA',
    'PHOS',
    'TBILI',
    'TPROT',
    'UACID'
  ])

  const getSeries = (whereSubjects: any) => {
    sql(
      props.studyDatabase,
      `SELECT SUBJID,
                        visitdy,
                        ${variables
                          .map(
                            (v) =>
                              `sum(CASE WHEN lbtestcd = '${v}' THEN lborres END) AS ${v}`
                          )
                          .join(',')}
                    FROM lb
                    WHERE lbtestcd IN (${variables
                      .map((v) => `'${v}'`)
                      .join(',')}) ${whereSubjects}
                    GROUP BY SUBJID,
                            visitdy`
    ).then((res) => {
      if (res.message === 'success') {
        const tempData = res.data.map((row: any, id: any) => ({
          ...row,
          id: id
        }))

        setLbData(tempData)
      }
    })
  }

  //work out min and max for a column
  const calc = (col: any, values: any, min: any, max: any) => {
    values[col] = lbData
      .map((row: any) => row[col])
      .filter((value: any) => Number.isFinite(value))

    min[col] = Math.min(...values[col])
    max[col] = Math.max(...values[col])
  }

  const values: any = {}
  const min: any = {}
  const max: any = {}

  const backgroundColor = (value: any, colorLevels: any) => {
    if (colorLevels && colorLevels.length > 0) {
      return colorLevels.reduce(
        (result: any, currentValue: any) =>
          value >= currentValue.from && value <= currentValue.to
            ? currentValue.color
            : result,
        'null'
      )
    } else {
      return 'null'
    }
  }

  const { jsonToCSV } = usePapaParse()
  const exportData = () => {
    openSaveFileDialog(jsonToCSV(lbData), 'lb', 'text/csv')
  }

  const [testLabels, setTestLabels] = useState<any>(null)

  // work out all the min and max values for each test
  variables.forEach((v) => {
    calc(v, values, min, max)
  })

  addToHistory({ title: 'LB Table', url: location })

  useEffect(() => {
    const tempTestLabels: any = {}
    const tempVariables: any = []

    sql(props.studyDatabase, `SELECT DISTINCT LBTESTCD,LBTEST FROM lb`).then(
      (res) => {
        res.data.forEach((row: any) => {
          tempTestLabels[row.LBTESTCD] = row.LBTEST
          tempVariables.push(row.LBTESTCD)
        })
      }
    )

    setTestLabels(tempTestLabels)
    // setVariables(tempVariables)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    sql(
      props.studyDatabase,
      `SELECT DISTINCT LBTESTCD,
                                            LBSTNRLO,
                                            LBSTNRHI
                                    FROM lb
                                    ORDER BY LBTESTCD;`
    ).then((res) => {
      if (res.message === 'success') {
        const levels: any = []

        res.data.forEach((row: any) => {
          if (row.LBSTNRHI) {
            levels[row.LBTESTCD] = [
              { from: 0, to: row.LBSTNRLO, color: '#ccffff' },
              { from: row.LBSTNRLO, to: row.LBSTNRHI, color: null },
              { from: row.LBSTNRHI, to: row.LBSTNRHI * 2, color: '#ffe0b3' },
              {
                from: row.LBSTNRHI * 2,
                to: row.LBSTNRHI * 3,
                color: '#ffe6ff'
              },
              { from: row.LBSTNRHI * 3, to: 99999, color: '#ffc2b3' }
            ]
          }
        })

        setColorLevels(levels)
      }
    })
  }, [props.studyDatabase])

  useEffect(() => {
    const whereSubjects =
      props.selectedSubjects.length > 0
        ? 'and SUBJID in ("' + props.selectedSubjects.join('","') + '")'
        : ''
    setSubtitle(globalFilter(props.selectedOptions))
    getSeries(whereSubjects)
    // eslint-disable-next-line
  }, [props.selectedOptions, props.selectedSubjects])

  // define settings for each column, or use default settings
  useEffect(() => {
    const cols = [
      {
        width: 80,
        headerName: 'Subject',
        field: 'SUBJID',
        renderCell: (cellValues: any) => {
          const { value } = cellValues
          const target = `${window.location.protocol}//${window.location.host}/patientprofile/${props.studyDatabase}/${value}`

          return <Button onClick={() => window.open(target)}>{value}</Button>
        }
      },
      { width: 80, headerName: 'Visit Day', field: 'VISITDY' }
    ]

    const renderProgressHeat = (cellValues: any, variable: any) => {
        const { value } = cellValues
        const flex = (value - min[variable]) / (max[variable] - min[variable])

        const bg = backgroundColor(
          value,
          colorLevels[variable] || colorLevels.default
        )

        return (
          <Box
            sx={{
              flex: { flex },
              backgroundColor: bg,
              color: 'black',
              fontWeight: 'bold'
            }}
          >
            {value}
          </Box>
        )
      },
      varCols = variables.map((v) => {
        const headerName = testLabels ? testLabels[v] : v
        return {
          width: 80,
          headerName: headerName,
          field: v,
          renderCell: (cellValues: any) => {
            return renderProgressHeat(cellValues, v)
          }
        }
      })
    setColumns([...cols, ...varCols])
    // eslint-disable-next-line
  }, [lbData])

  return (
    <>
      <h4>
        Labs Data ({props.study})
        <Tooltip title="Download table">
          <IconButton color="primary" onClick={exportData}>
            <FileDownloadIcon />
          </IconButton>
        </Tooltip>
        <br />
        <span style={{ fontSize: 12 }}>{subtitle}</span>
      </h4>
      {lbData && columns ? (
        <div style={{ height: props.screenHeight * 0.8, width: '100%' }}>
          <DataGridPro
            rows={lbData}
            columns={columns}
            density={'compact'}
            rowHeight={30}
            sx={{ fontSize: '0.7em' }}
          />
        </div>
      ) : (
        'Loading...'
      )}
    </>
  )
}
