import axios from 'axios'
import Handsontable from 'handsontable'
import { getTreatmentDay } from '../utils'

const $ = require('jquery')
const serverUrl = process.env.REACT_APP_XPLORATUM_API

export const addToHistory = (historyItem: any, notitle?: any) => {
  // FIXME
  // get history & if it's a new page then add to history
  let history = JSON.parse(sessionStorage.getItem('history') as string) || []

  if (history.length === 0) {
    history.push(historyItem)
  } else {
    const lastItem = history[history.length - 1]

    if (lastItem.url.pathname !== historyItem.url.pathname) {
      history.push(historyItem)
    }
  }

  sessionStorage.setItem('history', JSON.stringify(history))

  if (!notitle) document.title = 'Xploratum ' + historyItem.title
}

export const round = (value: any, decimals: any) => {
  // FIXME
  return Number(Math.round((value + 'e' + decimals) as any) + 'e-' + decimals)
}

export const lightenDarkenColor = (col: any, amt: any) => {
  let usePound = false

  if (col[0] === '#') {
    col = col.slice(1)

    usePound = true
  }

  const num = parseInt(col, 16)
  let r = (num >> 16) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00ff) + amt

  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000ff) + amt

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

// run SQL against sqlite database
export function sql(db: any, sqlCode: any) {
  if (db === null || db === undefined) {
    return Promise.resolve({
      message: 'fail',
      data: [],
      info: 'No database selected'
    })
  }

  if (sqlCode.length > 2048) {
    return sqlpost(db, sqlCode)
  } else {
    return axios
      .get(serverUrl + '/sql/' + db + '/' + sqlCode)
      .then((response) => {
        return response.data
      })
      .catch((error) => ({
        message: 'fail',
        data: [],
        info:
          error && error.response && error.response.data
            ? error.response.data.error
            : error
      }))
  }
}

// run SQL against sqlite database
export function sqlpost(db: any, sqlCode: any) {
  if (db === null || db === undefined) {
    return Promise.resolve({
      message: 'fail',
      data: [],
      info: 'No database selected'
    })
  }

  const payload = { sql: sqlCode }
  const headers = { headers: { 'content-type': 'application/json' } }

  return axios
    .post(serverUrl + '/sqlpost/' + db, payload, headers)
    .then((response) => response.data)
    .catch((error) => ({
      message: 'fail',
      data: [],
      info:
        error && error.response && error.response.data
          ? error.response.data.error
          : error
    }))
}

// run SQL against url-notes sqlite database
//TODO: add study to database so we can have different notes for the same screen but different studies
export function note(sqlCode: any) {
  return axios
    .get(serverUrl + '/note/' + sqlCode)
    .then((response) => response.data)
}

// used in Table component to apply styles to cells based on their content
export function createdCell(
  td: any,
  cellData: any,
  rowData: any,
  row: any,
  col: any
) {
  const colName = Object.keys(rowData)[col]

  switch (colName) {
    case 'aesev':
      switch (cellData) {
        case 'SEVERE':
          $(td).css('background-color', '#ffcccc')
          break
        case 'MODERATE':
          $(td).css('background-color', '#eeee00')
          break
        default:
          break
      }
      break
    case 'visit':
      if (Number(cellData) < 50) {
        $(td).css('background-color', '#e6fff2')
      }
      break
    case 'col0':
    case 'col2':
      $(td).css('background-color', '#e6ffe6')
      break
    case 'col1':
      $(td).css('background-color', '#efffef')
      break
    case 'col3':
    case 'col5':
    case 'col7':
    case 'col9':
    case 'col11':
    case 'col13':
    case 'col15':
    case 'col17':
    case 'col19':
    case 'col21':
    case 'col23':
    case 'col25':
    case 'col27':
    case 'col29':
    case 'col31':
    case 'col33':
    case 'col35':
    case 'col37':
    case 'col39':
      $(td).css('background-color', '#cceeff')
      break
    case 'col4':
      $(td).css('background-color', '#e6f7ff')
      break
    default:
      if (row % 2 === 1) {
        $(td).css('background-color', '#f2f2f2')
      } else {
        $(td).css('background-color', '#ffffff')
      }
  }

  // AEs start with 4 non-breakable spaces in the key value
  if (
    typeof rowData['key'] === 'string' &&
    rowData['key'].startsWith('&nbsp;&nbsp;&nbsp;&nbsp;') &&
    cellData &&
    col !== 0
  ) {
    $(td).css('background-color', '#ffff80')

    if (cellData.includes('data-colspan')) {
      const [, days] = cellData.match(/"((?:\\.|[^"\\])*)"/)

      $(td).attr('colspan', days)
    }
  }

  //  use data-colspan to span multiple columns
  if (
    typeof rowData['key'] === 'string' &&
    rowData['key'].startsWith('&nbsp;') &&
    cellData &&
    col !== 0 &&
    typeof cellData === 'string' &&
    cellData.includes('data-colspan')
  ) {
    const [, cols] = cellData.match(/"((?:\\.|[^"\\])*)"/) as any

    $(td).attr('colspan', cols)
  }

  // highlight cells which have a key starting with underline (used for labs)
  if (
    typeof rowData['key'] === 'string' &&
    rowData['key'].startsWith('<u>') &&
    typeof cellData === 'string' &&
    col !== 0 &&
    cellData.includes('data-background')
  ) {
    const [, color] = cellData.match(/"((?:\\.|[^"\\])*)"/) as any

    $(td).css('background-color', color)
  }
}

export function formatVisits(
  td: any,
  cellData: any,
  rowData: any,
  row: any,
  col: any
) {
  if (row % 2 === 1) {
    $(td).css('background-color', '#f2f2f2')
  } else {
    $(td).css('background-color', '#ffffff')
  }

  if (col % 2 === 1) {
    $(td).css('background-color', '#b3ffd7')
  } else {
    $(td).css('background-color', '#e6ffe6')
  }
}

export const globalFilter = (selectedOptions: any) => {
  if (selectedOptions) {
    const keys = Object.keys(selectedOptions)

    return keys
      .map(
        (key) =>
          key +
          ' in (' +
          selectedOptions[key]
            .map((item: any, i: any) => "'" + item.value + "'")
            .join(',') +
          ')'
      )
      .join(' and ')
  } else {
    return null
  }
}

// DEPRECATE
// make SQL statement from selected options
export function makeSqlStatement(selectedOptions: any, varToTable: any) {
  const keys = Object.keys(selectedOptions)

  let table = 'vd' // default table

  const tempWhereClause = keys
    .map((key) => {
      table = varToTable[key]

      return (
        key +
        ' in (' +
        selectedOptions[key]
          .map((item: any) => "'" + item.value + "'")
          .join(',') +
        ')'
      )
    })
    .join(' and ')

  const whereClause = tempWhereClause === '' ? '' : ' where ' + tempWhereClause
  const tables: any = []

  keys.forEach((key) => {
    table = varToTable[key]

    if (!tables.includes(table)) tables.push(table)
  })

  const sqlStatements =
    tables && tables.length > 0
      ? tables.map((table: any, index: any) => {
          if (index === 0) {
            return `select distinct ${table}.SUBJID from ${table}`
          } else {
            return `inner join ${table} on ${tables[0]}.SUBJID=${table}.SUBJID`
          }
        })
      : []

  const sqlStatement =
    sqlStatements.length > 0
      ? sqlStatements.join(' ') + ' ' + whereClause
      : 'select distinct SUBJID from vd'

  return [sqlStatement]
}

// DEPRECATE
// apply filters to get list of subjects
export function getSubjects(studyDatabase: any, filters: any, varToTable: any) {
  const sqlStatement = makeSqlStatement(filters, varToTable)

  return sql(studyDatabase, sqlStatement).then((res) => {
    const subjects = res.data.map((row: any) => row.SUBJID)

    return subjects
  })
}

export function heatMapColorforValue(value: any) {
  var h = (1.0 - value) * 240

  return 'hsl(' + h + ', 100%, 50%)'
}

export const arrayMin = (arr: any) => {
  if (arr.length === 0) return undefined
  else return arr.reduce((p: any, v: any) => (p < v ? p : v))
}

export const arrayMax = (arr: any) => {
  if (arr.length === 0) {
    return undefined
  } else {
    const arr2 = arr.filter((item: any) => !Number.isNaN(item))

    return arr2.reduce((p: any, v: any) => (p > v ? p : v))
  }
}

export const sumByKey = (arr: any, key: any, value: any) => {
  const map = new Map()

  for (const obj of arr) {
    const currSum = map.get(obj[key]) || 0

    map.set(obj[key], currSum + obj[value])
  }

  const res = Array.from(map, ([k, v]) => ({ [key]: k, [value]: v }))

  return res
}

// Labs BOTh array
export function makeLbBOTarray(
  studyDatabase: any,
  whereSubjects: any,
  drillDownLevel: any,
  drillDownPath: any,
  drillVars: any
) {
  const tempWhereClause = whereSubjects ? whereSubjects : 'WHERE 1'
  let tempExtraWhereClause = 'WHERE 1'

  for (let i = 0; i < drillDownLevel; i++) {
    tempExtraWhereClause +=
      ' AND ' + drillDownPath[i] + '="' + drillVars[i] + '"'
  }

  // Select LBSTRESN from lb table where LBSTRESN is > low and high in ctcae table for each lbtestcd (test code)
  const query =
    `SELECT *  FROM ` +
    `( SELECT * FROM (  SELECT SUBJID AS lbsub, LBDTC,  lbtestcd, lbtest, CAST (lbstresn AS DECIMAL) AS result FROM lb ${tempWhereClause} ) LEFT JOIN ` +
    `( SELECT code,  low,  high,  ctcae FROM ctcae ) ON lbtestcd = code AND result > low AND result < high WHERE ctcae NOT NULL  )  LEFT JOIN  ` +
    `(  SELECT SUBJID AS vdsub, vddate AS baseLineDate  FROM vd WHERE visit in ('DAY 1','BASELINE','CYCLE 01 DAY 01')  )  ON lbsub = vdsub  LEFT JOIN  ` +
    `(  SELECT SUBJID AS dmsub, armcd  FROM dm WHERE ARMCD <> 'Scrnfail'  )  ON lbsub = dmsub  LEFT JOIN  ` +
    `(  SELECT code AS catcode, category  FROM labcategories  )  ON lbtestcd = catcode  ` +
    `${tempExtraWhereClause}`

  return sql(studyDatabase, query).then((res) => {
    if (res.message === 'success') {
      res.data.forEach((element: any) => {
        const lbDay = getTreatmentDay(element.baseLineDate, element.LBDTC)

        element.lbDay = lbDay
      })

      // only use data after treatment
      const dataToUse = res.data.filter((row: any) => row.lbDay >= 1)

      const lbDays = dataToUse.map((element: any) => element.lbDay)

      const min = 1
      const max = arrayMax(lbDays) + 1
      const range = max - min + 1

      const lbData: any = {}

      dataToUse.forEach((element: any) => {
        const { armcd, category, lbtestcd, lbDay } = element

        if (!lbData[armcd]) lbData[armcd] = {}
        if (!lbData[armcd][category]) lbData[armcd][category] = {}

        if (!lbData[armcd][category][lbtestcd]) {
          lbData[armcd][category][lbtestcd] = Array(range).fill(0)
        }

        lbData[armcd][category][lbtestcd][lbDay] += 1
      }, {})

      const sqlNumberOfPatients =
        `SELECT * FROM ( SELECT SUBJID, MIN(DATE(vddate ) ) AS min, ` +
        `MAX(DATE(vddate ) ) AS maxVisitDate ` +
        `FROM vd ${tempWhereClause} GROUP BY SUBJID ) ` +
        `LEFT JOIN (SELECT SUBJID AS dmsub, armcd FROM dm WHERE ARMCD <> 'Scrnfail' ) ON SUBJID = dmsub ` +
        `LEFT JOIN ( SELECT SUBJID AS vdsub, vddate as baseLineDate FROM vd WHERE visit in ('DAY 1','BASELINE','CYCLE 01 DAY 01') ) ON SUBJID = vdsub ` +
        `LEFT JOIN ( SELECT SUBJID AS lbsub, min(LBDTC) AS firstLabVisit FROM lb WHERE LBDTC <> '' AND CAST(LBDY AS INT) > 0 GROUP BY lbsub) ON SUBJID = lbsub `

      const patientsInArm: any = {}

      return sql(studyDatabase, sqlNumberOfPatients).then((res) => {
        const patientsGroupedByArm = res.data.reduce(
          (acc: any, element: any) => {
            const { baseLineDate, armcd, firstLabVisit, maxVisitDate } = element

            if (!baseLineDate) return acc

            if (!acc[armcd]) acc[armcd] = Array(range).fill(0)

            const firstDayOfLabs = getTreatmentDay(baseLineDate, firstLabVisit)
            const lastDayOfTreatment = getTreatmentDay(
              baseLineDate,
              maxVisitDate
            )

            for (
              let i = firstDayOfLabs > 0 ? firstDayOfLabs : 1;
              i <= lastDayOfTreatment;
              i++
            ) {
              if (Number.isInteger(acc[armcd][i - 1])) {
                acc[armcd][i - 1] = acc[armcd][i - 1] + 1
              }
            }

            return acc
          },
          {}
        )

        res.data.forEach((element: any) => {
          const { baseLineDate, firstLabVisit, maxVisitDate, armcd } = element

          if (!baseLineDate) return

          const firstDayOfLabs = getTreatmentDay(baseLineDate, firstLabVisit)
          const lastDayOfTreatment = getTreatmentDay(baseLineDate, maxVisitDate)

          if (!(armcd in patientsInArm)) {
            patientsInArm[armcd] = Array(range).fill(0)
          }

          for (
            let i = firstDayOfLabs > 0 ? firstDayOfLabs : 1;
            i <= lastDayOfTreatment;
            i++
          ) {
            patientsInArm[armcd][i - min] = patientsInArm[armcd][i - min] + 1
          }

          patientsInArm[armcd] = patientsInArm[armcd].filter((item: any) =>
            Number.isInteger(item)
          )
        })

        const valuesToReturn: any = {}

        Object.keys(lbData).forEach((armcd) => {
          Object.keys(lbData[armcd]).forEach((category) => {
            Object.keys(lbData[armcd][category]).forEach((lbtestcd) => {
              const values: any = []

              lbData[armcd][category][lbtestcd].forEach(
                (element: any, index: any) => {
                  const patients = patientsGroupedByArm[armcd][index]

                  const ratio = patients === 0 ? 0 : element / patients // get number as a percentage figure where 100 = 100%

                  const value = {
                    day: index,
                    count: ratio,
                    patients: patients,
                    numberOfAEs: element,
                    category,
                    lbtestcd
                  }

                  values.push(value)
                }
              )

              if (!valuesToReturn[armcd]) {
                valuesToReturn[armcd] = {}
              }
              if (!valuesToReturn[armcd][category]) {
                valuesToReturn[armcd][category] = {}
              }
              if (!valuesToReturn[armcd][category][lbtestcd]) {
                valuesToReturn[armcd][category][lbtestcd] = {}
              }

              valuesToReturn[armcd][category][lbtestcd] = values
            })
          })
        })

        return {
          min: min,
          max: max,
          values: valuesToReturn,
          tempWhereClause: tempWhereClause
        }
      })
    }
  })
}

export const TickCross = (props: any) => {
  const { value } = props

  if (value === 'Y') {
    return (
      <>
        <b>
          <span style={{ color: 'green' }}>&#10004;</span>
        </b>
      </>
    )
  } else if (value === 'N') {
    return (
      <>
        <b>
          <span style={{ color: 'red' }}>&#10007;</span>
        </b>
      </>
    )
  }

  if (value === 'Yes') {
    return (
      <>
        <b>
          <span style={{ color: 'green' }}>&#10004;</span>
        </b>
      </>
    )
  } else if (value === 'No') {
    return (
      <>
        <b>
          <span style={{ color: 'red' }}>&#10007;</span>
        </b>
      </>
    )
  }

  return (
    <>
      <b>
        <span style={{ color: 'gray' }}>{value}</span>
      </b>
    </>
  )
}

export const heatmapColor = (
  value: any,
  min: any,
  max: any,
  rMax: any,
  gMax: any,
  bMax: any,
  opacity: any
) => {
  const range = max - min
  const proportion = (value - min) / range

  const r =
    rMax === 1
      ? Math.floor(255 * proportion)
      : rMax === -1
        ? Math.floor(255 * (1 - proportion))
        : 0
  const g =
    gMax === 1
      ? Math.floor(255 * proportion)
      : gMax === -1
        ? Math.floor(255 * (1 - proportion))
        : 0
  const b =
    bMax === 1
      ? Math.floor(255 * proportion)
      : bMax === -1
        ? Math.floor(255 * (1 - proportion))
        : 0

  const backgroundColor = `rgba(${r},${g},${b},${opacity})`

  return backgroundColor
}

export const Heatmap = (props: any) => {
  const { value, min, max, rMax, gMax, bMax, opacity } = props
  const backgroundColor = heatmapColor(
    value,
    min,
    max,
    rMax,
    gMax,
    bMax,
    opacity
  )

  return (
    <>
      <div style={{ backgroundColor }}>{value}</div>
    </>
  )
}

export const addClassWhenNeeded = (props: any) => {
  const className = props.cellProperties.className

  if (className !== void 0) {
    Handsontable.dom.addClass(props.TD, className)
  }
}

export function ProgressBarRenderer(props: any) {
  const { value, min, max, cellWidth, color } = props

  addClassWhenNeeded(props)

  const range = max - min
  const width = Math.round((cellWidth * (value - min)) / range)

  return (
    <>
      <div
        className="htLeft"
        style={{ backgroundColor: color, width: `${width}px` }}
      >
        {value}
      </div>
    </>
  )
}

// bar length depends on value, and colour depends on value too
export function ProgressBarHeatmapRenderer(props: any) {
  const { value, min, max, cellWidth, rMax, gMax, bMax, opacity } = props

  addClassWhenNeeded(props)

  const range = max - min
  const width = Math.round((cellWidth * (value - min)) / range)
  const backgroundColor = heatmapColor(
    value,
    min,
    max,
    rMax,
    gMax,
    bMax,
    opacity
  )

  return (
    <>
      <div
        className="htLeft"
        style={{ backgroundColor: backgroundColor, width: `${width}px` }}
      >
        {value}
      </div>
    </>
  )
}

export function ProgressBarLevelsRenderer(props: any) {
  const { value, min, max, cellWidth, colorLevels } = props

  addClassWhenNeeded(props)

  const range = max - min
  const width = Math.round((cellWidth * (value - min)) / range)

  const backgroundColor = colorLevels.reduce(
    (total: any, currentValue: any) =>
      value >= currentValue.from && value <= currentValue.to
        ? currentValue.color
        : total,
    null
  )

  return (
    <>
      <div
        className="htLeft"
        style={{ backgroundColor: backgroundColor, width: `${width}px` }}
      >
        {value}
      </div>
    </>
  )
}
export function PatientProfileRenderer(props: any) {
  const { value, studyDatabase } = props

  return (
    <>
      <a
        href={`/#/patientprofile/${studyDatabase}/${value}`}
        target="_blank"
        rel="noreferrer"
        style={{ color: 'blue', textDecoration: 'underline' }} // style: 'bold'
      >
        {value}
      </a>
    </>
  )
}

export function openSaveFileDialog(data: any, filename: any, mimetype: any) {
  if (!data) return

  const blob =
    data.constructor !== Blob
      ? new Blob([data], { type: mimetype || 'application/octet-stream' })
      : data

  if ((navigator as any).msSaveBlob) {
    ;(navigator as any).msSaveBlob(blob, filename)

    return
  }

  const lnk = document.createElement('a')
  const url = window.URL
  let objectURL

  if (mimetype) lnk.type = mimetype

  lnk.download = filename || 'untitled'
  lnk.href = objectURL = url.createObjectURL(blob)
  lnk.dispatchEvent(new MouseEvent('click'))

  setTimeout(url.revokeObjectURL.bind(url, objectURL))
}

export const Colormap = (props: any) => {
  const { value, prop, colors } = props
  const backgroundColor = colors[prop][value]

  return (
    <>
      <div style={{ backgroundColor }}>{value}</div>
    </>
  )
}

export const sortByKey = (array: any, key: any) =>
  array.sort((a: any, b: any) => {
    const value1 = a[key]
    const value2 = b[key]

    return value1 < value2 ? -1 : value1 > value2 ? 1 : 0
  })

export const sortBy2Keys = (array: any, key1: any, key2: any) =>
  array.sort((a: any, b: any) => {
    const value1 = a[key1]
    const value2 = b[key1]
    const value3 = a[key2]
    const value4 = b[key2]

    if (value1 === value2) return value3 < value4 ? -1 : value3 > value4 ? 1 : 0
    else return value1 < value2 ? -1 : value1 > value2 ? 1 : 0
  })

export function TestRenderer(props: any) {
  const { value, row, dividerRows, subdividerRows } = props

  const backgroundColor: any = dividerRows.includes(row)
    ? 'darkgray'
    : subdividerRows.includes(row)
      ? 'lightgray'
      : null

  const fontWeight = 'bold'

  if (dividerRows.includes(row) || subdividerRows.includes(row)) {
    return <div style={{ backgroundColor, fontWeight }}>{value}</div>
  } else {
    return <div>{value}</div>
  }
}
export const Heatmap2 = (
  value: any,
  min: any,
  max: any,
  rMax: any,
  gMax: any,
  bMax: any,
  opacity: any
) => {
  const backgroundColor = heatmapColor(
    value,
    min,
    max,
    rMax,
    gMax,
    bMax,
    opacity
  )
  const flex = 1

  return <div style={{ backgroundColor, flex }}>{value}</div>
}

export const getWidth = () =>
  Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  )

export const getHeight = () => {
  const { clientHeight } = document.documentElement

  return clientHeight
}

export function Prog(props: any) {
  const { value, min, max, cellWidth, color } = props

  addClassWhenNeeded(props)

  const range = max - min
  const width = Math.round((cellWidth * (value - min)) / range)

  return (
    <>
      <div
        className="htLeft"
        style={{ backgroundColor: color, width: `${width}px` }}
      >
        {value}
      </div>
    </>
  )
}

export const checkLabAlerts = (
  alerts: any,
  setWaiting: any,
  studyDatabase: any,
  labCategories: any,
  setAlertResults: any
) => {
  // FIXME: lbtestcd table is not present
  return

  //   if (Object.keys(alerts).length === 0) return

  //   const sqlStatements = []

  //   Object.keys(alerts).forEach((test) => {
  //     Object.keys(alerts[test]).forEach((level) => {
  //       const comparison =
  //         level === 'low'
  //           ? `and lbstresn < ${alerts[test][level]}`
  //           : level === 'high'
  //           ? `and lbstresn > ${alerts[test][level]}`
  //           : null

  //       const sqlStatement =
  //         `SELECT distinct * FROM ` +
  //         `(SELECT SUBJID, LBTESTCD, '${level}' as LEVEL, LBDTC, LBSTRESN FROM lb ` +
  //         `WHERE lbtestcd='${test}' ${comparison} ) a ` +
  //         `LEFT JOIN ( SELECT subjid, testcd, date, 1 as checked FROM lbchecked ) b  ` +
  //         `ON a.subjid = b.subjid AND a.lbtestcd = b.testcd AND a.lbdtc = b.date`

  //       if (comparison !== null) sqlStatements.push(sqlStatement)
  //     })
  //   })

  //   setWaiting(true)

  //   Promise.all(
  //     sqlStatements.map((sqlStatement) =>
  //       sql(studyDatabase, sqlStatement).then((res) =>
  //         res.data.map((rowFromCheck, rowIndex) => ({
  //           id: rowFromCheck.LBTESTCD + rowFromCheck.level + rowIndex,
  //           test: rowFromCheck.LBTESTCD,
  //           level: rowFromCheck.LEVEL,
  //           subjid: rowFromCheck.SUBJID,
  //           value: rowFromCheck.LBSTRESN,
  //           date: rowFromCheck.LBDTC,
  //           labCategory:
  //             rowFromCheck.LBTESTCD in labCategories
  //               ? labCategories[rowFromCheck.LBTESTCD]
  //               : null,
  //           lastdate: rowFromCheck.lastdate,
  //           checked: Boolean(rowFromCheck.checked)
  //         }))
  //       )
  //     )
  //   ).then((res) => {
  //     setAlertResults(res)
  //     setWaiting(false)
  //   })
}

export const checkAdverseEventsAlerts = (
  setWaiting: any,
  studyDatabase: any,
  setAlertResults: any
) => {
  setWaiting(true)

  const sqlStatement = `SELECT * FROM ae WHERE aeser='Y'`

  sql(studyDatabase, sqlStatement).then((res) => {
    setAlertResults(res)
    setWaiting(false)
  })
}

// get directory listing
export function dir(folder: any) {
  return axios
    .get(serverUrl + '/dir/' + folder)
    .then((response) => response.data)
}

// download a file
export function getafile(folder: any, filename: any) {
  return axios
    .get(serverUrl + '/getafile/' + folder + '/' + filename, {
      responseType: 'blob'
    })
    .then((response) => response.data)
}

// get stats for boxplot
export function getStats(arr: any) {
  // sort array ascending
  const asc = (arr: any) => arr.sort((a: any, b: any) => a - b)
  const sum = (arr: any) => arr.reduce((a: any, b: any) => a + b, 0)
  const mean = (arr: any) => sum(arr) / arr.length

  const std = (arr: any) => {
    // sample standard deviation
    const mu = mean(arr)
    const diffArr = arr.map((a: any) => (a - mu) ** 2)

    return Math.sqrt(sum(diffArr) / (arr.length - 1))
  }

  const sorted = asc(arr)

  const quantile = (_: any, q: any) => {
    const pos = (sorted.length - 1) * q
    const base = Math.floor(pos)
    const rest = pos - base

    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base])
    } else {
      return sorted[base]
    }
  }

  return {
    low: sorted[0],
    q1: quantile(arr, 0.25),
    median: quantile(arr, 0.5),
    q3: quantile(arr, 0.75),
    high: sorted[arr.length - 1],
    std: std(arr),
    mean: mean(arr)
  }
}

// run an open ai query
export function getAnswer(question: any, setAnswer: any) {
  const payload = { question: question }
  const headers = { headers: { 'content-type': 'application/json' } }

  return axios
    .post(serverUrl + '/getAnswer', payload, headers)
    .then((response) => {
      setAnswer(response.data.message)

      return response.data
    })
    .catch((error) => {
      setAnswer(error)

      return {
        message: 'fail',
        data: [],
        info:
          error && error.response && error.response.data
            ? error.response.data.error
            : error
      }
    })
}

export const getStatistics = (db: any) =>
  axios
    .get(serverUrl + '/t-statistics/' + db)
    .then((response) => response.data)
    .catch((error) => ({
      message: 'fail',
      data: [],
      info:
        error && error.response && error.response.data
          ? error.response.data.error
          : error
    }))
