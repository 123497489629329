import { useState, useEffect } from 'react'
import { sql, globalFilter, addToHistory } from '../../apis/utility'
import { useLocation } from 'react-router-dom'
import ReactFC from 'react-fusioncharts'
import FusionCharts from 'fusioncharts'
import Charts from 'fusioncharts/fusioncharts.charts'
import excelexport from 'fusioncharts/fusioncharts.excelexport'
import PowerCharts from 'fusioncharts/fusioncharts.powercharts'
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel'
import FusionChartJSX from '../ui/FusionChartJSX'

ReactFC.fcRoot(FusionCharts, Charts, PowerCharts, GammelTheme, excelexport)

export default function VSBloodPressure(props: any) {
  const location = useLocation()

  addToHistory({ title: 'VS Blood Pressure', url: location })

  const whereSubjects =
    props.selectedSubjects.length > 0
      ? 'and SUBJID in ("' + props.selectedSubjects.join('","') + '")'
      : ''
  const sqlStatement = `select VSDY, VSSTRESN, VSTESTCD from vs where VSTESTCD in ('SYSBP','DIABP') ${whereSubjects}`

  const [subtitle, setSubtitle] = useState<any>(null)
  const [dataSource, setDataSource] = useState<any>(null)

  const lowSys = props.normalRanges.VSSYSBP.low
  const highSys = props.normalRanges.VSSYSBP.high
  const lowDia = props.normalRanges.VSDIABP.low
  const highDia = props.normalRanges.VSDIABP.high

  useEffect(() => {
    setSubtitle(globalFilter(props.selectedOptions))

    sql(props.studyDatabase, sqlStatement).then((res) => {
      if (res.message === 'success') {
        const categoryValues = [
          ...new Set(
            res.data
              .filter((row: any) => ['SYSBP', 'DIABP'].includes(row.VSTESTCD))
              .map((row: any) => Number(row.VSDY))
          )
        ].sort((a: any, b: any) => a - b)

        const categories = [
          {
            category: categoryValues.map((item: any) => ({
              label: item.toString()
            }))
          }
        ]

        const sysbpSeries = {
          seriesname: 'SYSBP',
          data: categories[0].category.map((category) => {
            const bpData = res.data.filter(
              (row: any) =>
                row.VSTESTCD === 'SYSBP' && row.VSDY === category.label
            )

            if (bpData.length > 2) {
              return {
                value: bpData
                  .map((item: any) => Number(item.VSSTRESN))
                  .join(',')
              }
            } else {
              const values = bpData
                .map((item: any) => Number(item.VSSTRESN))
                .join(',')

              return { value: values + ',' + values + ',' + values }
            }
          })
        }

        const diabpSeries = {
          seriesname: 'DIABP',
          data: categories[0].category.map((category) => {
            const bpData = res.data.filter(
              (row: any) =>
                row.VSTESTCD === 'DIABP' && row.VSDY === category.label
            )

            if (bpData.length > 2) {
              return {
                value: bpData
                  .map((item: any) => Number(item.VSSTRESN))
                  .join(',')
              }
            } else {
              const values = bpData
                .map((item: any) => Number(item.VSSTRESN))
                .join(',')

              return { value: values + ',' + values + ',' + values }
            }
          })
        }

        const dataSource = {
          chart: {
            caption: 'Blood Pressure (' + props.study + ')',
            subcaption: subtitle,
            yaxisname: 'BP',
            xaxisname: 'Day',
            bgColor: 'EEEEEE,CCCCCC',
            bgratio: '60,40',
            bgAlpha: '70,80',
            bgAngle: '180',
            plotspacepercent: '25',
            numVisiblePlot: '222',
            showalloutliers: '1',
            exportEnabled: '1'
          },
          categories: categories,
          dataset: [sysbpSeries, diabpSeries],
          trendlines: [
            {
              line: [
                {
                  isTrendZone: '0',
                  value: lowSys,
                  color: '#ff0000',
                  valueOnRight: '1',
                  alpha: '100',
                  tooltext: 'Low Systolic',
                  displayvalue: 'Systolic'
                },
                {
                  isTrendZone: '0',
                  value: highSys,
                  color: '#ff0000',
                  valueOnRight: '1',
                  alpha: '100',
                  tooltext: 'High Systolic',
                  displayvalue: 'Systolic'
                },
                {
                  isTrendZone: '0',
                  value: lowDia,
                  color: '#0000ff',
                  valueOnRight: '1',
                  alpha: '100',
                  tooltext: 'Low Diastolic',
                  displayvalue: 'Diastolic'
                },
                {
                  isTrendZone: '0',
                  value: highDia,
                  color: '#0000ff',
                  valueOnRight: '1',
                  alpha: '100',
                  tooltext: 'High Diastolic',
                  displayvalue: 'Diastolic'
                }
              ]
            }
          ]
        }

        setDataSource(dataSource)
      }
    })
  }, [
    props.selectedSubjects,
    props.study,
    props.selectedOptions,
    props.studyDatabase,
    subtitle,
    sqlStatement,
    lowSys,
    highSys,
    lowDia,
    highDia
  ])

  return (
    <>
      {dataSource ? (
        <FusionChartJSX
          type="boxandwhisker2d"
          width="100%"
          height={props.screenHeight * 0.8}
          dataFormat="JSON"
          dataSource={dataSource}
        />
      ) : (
        'loading'
      )}
    </>
  )
}
