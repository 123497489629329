import * as ActionTypes from '../actionTypes'
import { SetLBranges, SetLBboxDataOptions } from './types'
import { LBrangesObject } from '../../types'

export const setLBrangesAction = (payload: LBrangesObject): SetLBranges => ({
  type: ActionTypes.SET_LB_RANGES,
  payload
})

export const setLBboxDataOptionsAction = (payload: {
  [key: string]: any
}): SetLBboxDataOptions => ({
  type: ActionTypes.SET_LB_BOX_DATA_OPTIONS,
  payload
})
