import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { sql } from '../../apis/utility'
import { globalDB } from '../../utils'
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Tooltip
} from '@mui/material'
import { useAppSelector } from '../../hooks'
import { Study } from '../../types'

interface OverviewProps {
  selectedOptions: any
}

export default function Overview(props: OverviewProps) {
  const { selectedOptions } = props

  const studiesListState = useAppSelector((state) => state.studies.list)

  const [rowsAE, setRowsAE] = useState<any>(null)
  const [rowsDM, setRowsDM] = useState<any>(null)

  const studyDatabase = globalDB

  const navigate = useNavigate()

  useEffect(() => {
    // get AE combined summary info
    // remove "WHERE database='sdtm2" to get all databases
    sql(studyDatabase, `SELECT * from summary_ae WHERE database='sdtm2'`).then(
      (res) => {
        if (res.message === 'success') setRowsAE(res.data)
      }
    )

    // get DM combined summary info
    sql(studyDatabase, `SELECT * from summary_dm `).then((res) => {
      if (res.message === 'success') setRowsDM(res.data)
    })
  }, [selectedOptions, studyDatabase])

  document.title = 'Xploratum'

  return (
    <Container sx={{ backgroundColor: 'primary.light', mt: 2 }}>
      {rowsAE && rowsDM && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead
              style={{ fontWeight: '800', backgroundColor: 'rgba(0,0,0,.9)' }}
            >
              <TableRow>
                <TableCell style={{ color: 'white' }}>
                  Study (Database)
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: 'white' }}>Subjects</span>
                  <br />
                  <span style={{ color: 'lightgreen' }}>Male</span>
                  <br />
                  <span style={{ color: 'lightgreen' }}>Female</span>
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: 'lightgreen' }}>Arms</span>
                  <br /> <span style={{ color: 'lightgreen' }}>PK set</span>
                  <br /> <span style={{ color: 'lightgreen' }}>PD set</span>
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: 'red' }}>Total AEs</span>
                  <br />
                  <span style={{ color: 'red' }}>Not Recovered</span>
                  <br />
                  <span style={{ color: 'red' }}>Recovered</span>
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: 'red' }}>Severe</span>
                  <br />
                  <span style={{ color: 'red' }}>Moderate</span>
                  <br />
                  <span style={{ color: 'red' }}>Mild</span>
                </TableCell>
                <TableCell align="right">
                  <span style={{ color: 'red' }}>Probable</span>
                  <br />
                  <span style={{ color: 'red' }}>Possible</span>
                  <br />
                  <span style={{ color: 'red' }}>Not related</span>
                </TableCell>
              </TableRow>
            </TableHead>
            {studiesListState && studiesListState.length && (
              <TableBody style={{ backgroundColor: 'lightgray' }}>
                {rowsAE.map((rowAE: any) => {
                  const thisAE = studiesListState.filter(
                    (st: Study) => st.filename === rowAE.database
                  )[0]
                  const rowDM = rowsDM.filter(
                    (st: any) => st.database === thisAE.filename
                  )[0]

                  return (
                    <TableRow
                      key={rowAE.database}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Tooltip title={rowAE.database}>
                          <Button
                            onClick={() => {
                              navigate('/home')
                            }}
                          >
                            {thisAE.STUDYID}
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <span style={{ color: 'black' }}>{rowDM.subjects}</span>
                        <br />
                        <span style={{ color: 'green' }}>{rowDM.sex_m}</span>
                        <br />
                        <span style={{ color: 'green' }}>{rowDM.sex_f}</span>
                      </TableCell>
                      <TableCell align="right">
                        <span style={{ color: 'green' }}>{rowDM.arms}</span>
                        <br />
                        <span style={{ color: 'green' }}>{rowDM.pkset}</span>
                        <br />
                        <span style={{ color: 'green' }}>{rowDM.pdset}</span>
                      </TableCell>
                      <TableCell align="right">
                        <span style={{ color: 'red' }}>{rowAE.count}</span>
                        <br />
                        <span style={{ color: 'red' }}>
                          {rowAE.not_recovered}
                        </span>
                        <br />
                        <span style={{ color: 'red' }}>{rowAE.recovered}</span>
                      </TableCell>
                      <TableCell align="right">
                        <span style={{ color: 'red' }}>{rowAE.severe}</span>
                        <br />
                        <span style={{ color: 'red' }}>{rowAE.moderate}</span>
                        <br />
                        <span style={{ color: 'red' }}>{rowAE.mild}</span>
                      </TableCell>
                      <TableCell align="right">
                        <span style={{ color: 'red' }}>{rowAE.probable}</span>
                        <br />
                        <span style={{ color: 'red' }}>{rowAE.possible}</span>
                        <br />
                        <span style={{ color: 'red' }}>
                          {rowAE.not_related}
                        </span>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
    </Container>
  )
}
