import moment from 'moment'
import { dateFormat } from '.'
import { AEBOThData, AEBOThDataItem, AEoccasion } from '../types'
import { ApiController } from '../controllers/ApiController'
import { arrayMin, arrayMax } from '../apis/utility'

export const getSubjIdsWithAeOnDay = (
  aes: AEoccasion[],
  baselines: { [key: string]: string },
  day: number
) => {
  const filteredSubjects = aes.filter((ae: AEoccasion) => {
    const { SUBJID, AESTDTC, AEENDTC } = ae

    const startDay = getAeDay(baselines[SUBJID], AESTDTC)
    const endDay = getAeDay(baselines[SUBJID], AEENDTC)

    return startDay <= day && day <= endDay
  })

  return [...new Set(filteredSubjects.map((ae: AEoccasion) => ae.SUBJID))]
}

export const getDuration = (startDate: string, endDate: string) =>
  moment(endDate).diff(startDate, 'days')

export const getAeDay = (baselineDate: string, date: string) =>
  moment(date, dateFormat).diff(moment(baselineDate, dateFormat), 'days') + 1

export const aeTheme = {
  severity: {
    severe: { class: 'ae-severity-severe', color: '#ac011d' },
    moderate: { class: 'ae-severity-moderate', color: '#e4b293' },
    mild: { class: 'ae-severity-mild', color: '#f4decd' }
  },
  seriousness: {
    serious: { class: 'ae-seriousness-serious', color: '#ac011d' }
  },
  relatedness: {
    probable: { class: 'ae-relatedness-probable', color: '#ff9999' },
    possible: { class: 'ae-relatedness-possible', color: '#ffd699' },
    unrelated: {
      class: 'ae-relatedness-unrelated',
      color: '#2e7d32'
    },
    remote: { class: 'ae-relatedness-remote', color: 'black' },
    undetermined: {
      class: 'ae-relatedness-undetermined',
      color: 'black'
    }
  }
}

export const AEweight = (aesev: any) => {
  if (aesev === 'SEVERE') return 3
  else if (aesev === 'MODERATE') return 2
  else if (aesev === 'MILD') return 1
  else if (aesev === 'T1') return 1
  else if (aesev === 'T2') return 2
  else if (aesev === 'T3') return 3
  else if (aesev === 'T4') return 4
  else if (aesev === 'T5') return 5
  else return +aesev
}

export const getAEBOThData = async (
  apiController: ApiController,
  classVar = 'aesev',
  selectedSubjects?: string[]
) => {
  const subjectVisits = await apiController.getSubjectVisits(selectedSubjects)

  const baselineDates: { [key: string]: string } = {}

  subjectVisits.forEach((visit) => {
    const { SUBJID, firstVisit, AESTDTC, AEENDTC } = visit

    visit.firstDayOfAE = getAeDay(firstVisit, AESTDTC)
    visit.lastDayOfAE = getAeDay(firstVisit, AEENDTC)
    visit.days = visit.lastDayOfAE - visit.firstDayOfAE

    baselineDates[SUBJID] = firstVisit
  })

  const dataToUse = subjectVisits.filter((row) => (row.firstDayOfAE || 0) >= 0) // only use data after treatment

  if (!dataToUse.length) return

  const from = dataToUse.map((element) => element.firstDayOfAE)
  const to = dataToUse.map((element) => element.lastDayOfAE)

  const min: number = arrayMin(from)
  const max: number = arrayMax(to)
  const minWeek = Math.floor(min / 7) + 1
  const maxWeek = Math.floor(max / 7) + 1
  const aeData: { [key: string]: { [key: string]: number[] } } = {}
  const range = max - min + 1
  const rangeWeek = maxWeek - minWeek + 1

  // count up the weighted sum of AEs for each day for each arm
  dataToUse.forEach((visit) => {
    const visitVar = (visit as any as { [key: string]: string })[classVar]

    if (visitVar) {
      if (!(visit.armcd in aeData)) aeData[visit.armcd] = {}

      if (!aeData[visit.armcd].hasOwnProperty(visitVar)) {
        aeData[visit.armcd][visitVar] = Array(range).fill(0)
      }

      const { firstDayOfAE, lastDayOfAE } = visit

      if (firstDayOfAE && lastDayOfAE) {
        for (let i = firstDayOfAE; i <= lastDayOfAE; i++) {
          aeData[visit.armcd][visitVar][i - 1] =
            (aeData[visit.armcd][visitVar][i - 1] || 0) + AEweight(visit.aesev)
        }
      }
    }
  })

  const patientsInArm: { [key: string]: number[] } = {}
  const patientsInArmWeek: { [key: string]: number[] } = {}

  const visitsSummary =
    await apiController.getSubjectVisitsSummary(selectedSubjects)

  if (visitsSummary) {
    visitsSummary.forEach((visitSummary) => {
      const firstVisitDate = moment(
        visitSummary.firstVisit,
        'DDMMMYYYY'
      ).toDate()

      const to = new Date(visitSummary.max + 'Z')

      const firstDayOfTreatment = 1
      const lastDayOfTreatment =
        Math.round(
          (to.getTime() - firstVisitDate.getTime()) / (1000 * 60 * 60 * 24)
        ) + 1 // works out the last day of treatment

      const firstWeekOfTreatment = 1
      const lastWeekOfTreatment = 1 + Math.floor((lastDayOfTreatment - 1) / 7)

      if (!(visitSummary.armcd in patientsInArm)) {
        patientsInArm[visitSummary.armcd] = Array(range).fill(0)
      }

      for (let i = firstDayOfTreatment; i <= lastDayOfTreatment; i++) {
        patientsInArm[visitSummary.armcd][i - min] =
          (patientsInArm[visitSummary.armcd][i - min] || 0) + 1
      }

      if (!(visitSummary.armcd in patientsInArmWeek)) {
        patientsInArmWeek[visitSummary.armcd] = Array(rangeWeek).fill(0)
      }

      for (let i = firstWeekOfTreatment; i <= lastWeekOfTreatment; i++) {
        patientsInArmWeek[visitSummary.armcd][i - minWeek] =
          patientsInArmWeek[visitSummary.armcd][i - minWeek] + 1
      }
    })

    const valuesToReturn: AEBOThData = {}

    Object.keys(aeData).forEach((armcd) => {
      const values: AEBOThDataItem[] = []

      Object.keys(aeData[armcd]).forEach((key) => {
        aeData[armcd][key].forEach((element: number, index: number) => {
          if (patientsInArm[armcd]) {
            const patients = patientsInArm[armcd][index]
              ? patientsInArm[armcd][index]
              : 0

            const weekIndex = 1 + Math.floor((index - 1) / 7)

            const patientsInWeek = patientsInArmWeek[armcd][weekIndex]
              ? patientsInArmWeek[armcd][weekIndex]
              : 0

            const ratio = patients === 0 ? 0 : element / patients // get number as a percentage figure where 100 = 100%

            const value: AEBOThDataItem = {
              day: index,
              week: weekIndex,
              count: ratio,
              patients: patients,
              patientsInWeek: patientsInWeek,
              numberOfAEs: element,
              combined: key + ':' + weekIndex,
              aesev: key
            }

            if (ratio > 0) values.push(value)
          }
        })
      })

      valuesToReturn[armcd] = values
    })

    return {
      min: min,
      max: max,
      values: valuesToReturn,
      baselineDates
    }
  }
}

export interface ChartOption {
  series: {
    color?: string
    id?: string
    index?: number
    name: string
    data: {
      name: string
      y: number
    }[]
  }[]
  xAxis: {
    categories: string[]
    crosshair: boolean
    labels: {
      align: string
      reserveSpace: boolean
      rotation: number
      y: number
      style: { color: string }
    }
    lineWidth: number
    maxPadding: number
    tickWidth: number
    title: { text: string; margin: number }
    type: string
  }
}

export const dataToStreamOption = (
  data: AEBOThDataItem[],
  category: string,
  minDay: number,
  maxDay: number,
  invert: boolean,
  theme: { [key: string]: any },
  colorLookup?: { [key: string]: string },
  indexLookup?: { [key: string]: number },
  force3Severities = false
): ChartOption => {
  const categoryValues = data.map((row: any) => row[category])
  let categories = [...new Set([...categoryValues])]

  if (force3Severities) {
    if (
      categories.includes('MILD') ||
      categories.includes('MODERATE') ||
      categories.includes('SEVERE')
    ) {
      categories = ['MILD', 'MODERATE', 'SEVERE'] // we always want these 3 categories even if there is no data for them
    } else {
      categories = ['T1', 'T2', 'T3', 'T4', 'T5'] // we always want these 5 categories even if there is no data for them
    }
  }

  const invertFactor = invert ? -1 : 1

  const series = categories.map((categoryValue) => {
    const subSeries = data.filter((row: any) => row[category] === categoryValue)

    // modify color based on invert
    let colorToUse = colorLookup
      ? categoryValue in colorLookup
        ? colorLookup[categoryValue]
        : 'gray'
      : 'gray'

    const tempSeries = Array.from({ length: maxDay - minDay }, (_) => {
      return { name: categoryValue, y: 0 }
    })

    subSeries.forEach((subSer: any) => {
      const index = subSer.day

      tempSeries[index] = {
        name: categoryValue,
        y: invertFactor * subSer.count
      }
    })

    if (colorLookup && indexLookup) {
      return {
        name: categoryValue,
        id: categoryValue,
        data: tempSeries,
        color: colorToUse,
        index: indexLookup
          ? categoryValue in indexLookup
            ? indexLookup[categoryValue]
            : 0
          : 0
      }
    } else {
      return {
        name: categoryValue,
        data: tempSeries
      }
    }
  })

  const xAxisValues = []

  const contrastingColor =
    theme && theme.palette && theme.palette.mode === 'dark' ? 'white' : 'black'

  for (let i = minDay; i < maxDay + 1; i++) {
    xAxisValues.push(i.toString())
  }

  return {
    series: series,
    xAxis: {
      maxPadding: 0,
      type: 'category',
      crosshair: true,
      categories: xAxisValues,
      labels: {
        align: 'center',
        reserveSpace: true,
        rotation: 270,
        y: 20,
        style: { color: contrastingColor }
      },
      lineWidth: 0,
      tickWidth: 0,
      title: { text: 'Day of treatment', margin: 8 }
    }
  }
}

export const makeAEOnDayArray = async (
  apiController: ApiController,
  classVar: string,
  drillDownLevel: number,
  drillDownPath: string[],
  drillVars: string[]
) => {
  let condition = ''

  for (let i = 0; i < drillDownLevel; i++) {
    condition += ` AND ${drillDownPath[i]}='${drillVars[i]}'`
  }

  const visits = await apiController.getSubjectVisits(undefined, condition)

  const baselineDates: { [key: string]: string } = {}

  visits
    .filter(
      (item) =>
        moment(item.AESTDTC, 'YYYYMMDD').diff(
          moment(item.firstVisit, 'YYYYMMDD'),
          'days'
        ) >= 0
    )
    .forEach((item) => {
      const { firstVisit, AESTDTC, AEENDTC } = item

      item.firstDayOfAE = getAeDay(firstVisit, AESTDTC)
      item.lastDayOfAE = getAeDay(firstVisit, AEENDTC)
      item.days = item.lastDayOfAE - item.firstDayOfAE

      baselineDates[item.SUBJID] = item.firstVisit
    })

  const dataToUse = visits.filter((row) => (row.firstDayOfAE || -1) >= 0) // only use data after treatment

  const from = dataToUse.map((element) => element.firstDayOfAE)
  const to = dataToUse.map((element) => element.lastDayOfAE)

  const myData: { [key: string]: number[] } = {}
  const min: number = arrayMin(from)
  const max: number = arrayMax(to)
  const range = max - min + 1

  // define array for each classVar value to show the AE count on each day
  dataToUse.forEach((element: { [key: string]: any }) => {
    if (!(element[classVar] in myData)) {
      myData[element[classVar]] = Array(range).fill(0)
    }

    for (let i = element.firstDayOfAE; i <= element.lastDayOfAE; i++) {
      myData[element[classVar]][i - min] =
        (myData[element[classVar]][i - min] || 0) + 1
    }
  })

  const data: { [key: string]: number | string }[] = []

  Object.keys(myData).forEach((key) => {
    myData[key].forEach((element, index) => {
      const value: { [key: string]: number | string } = {
        day: index,
        count: element
      }
      value[classVar] = key

      data.push(value)
    })
  })

  return {
    data,
    min,
    max,
    baselineDates
  }
}

export const dataToBarOptions = (
  data: any,
  category: any,
  variables: any,
  contrastingColor: any
) => {
  const series = variables.map((variable: any) => ({
    name: variable,
    data: data.map((row: any) => ({
      name: variable,
      y: row[variable],
      drilldown: true
    }))
  }))

  const categories = data.map((row: any) => {
    let val = row[category]

    if (val === null) val = 'Unknown'

    return val
  })

  return {
    series: series,
    xAxis: {
      categories: categories,
      labels: {
        style: {
          color: contrastingColor
        }
      }
    }
  }
}
