import ReactFC from 'react-fusioncharts'

// FIXME: potentially a problem
class FusionChartJSXelement extends ReactFC {}

interface FusionChartProps {
  type: string
  width: string | number
  height: string | number
  dataFormat: string
  dataSource: any
  events?: { dataPlotClick: (e: any) => void }
}

const FusionChartJSX = (props: FusionChartProps) => {
  const { type, width, height, dataFormat, dataSource, events } = props

  return (
    <FusionChartJSXelement
      type={type}
      width={width}
      height={height}
      dataFormat={dataFormat}
      dataSource={dataSource}
      events={events}
    />
  )
}

export default FusionChartJSX
