import { useState } from 'react'
import { Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

const Legend = (props: any) => {
  const { data, size, tooltip, style, onClick: onClickProp } = props

  const [displayLegend, setDisplayLegend] = useState(false)

  return (
    <div
      style={{ display: 'flex', ...style }}
      onClick={onClickProp || function () {}}
    >
      <Tooltip title={tooltip}>
        <InfoIcon
          sx={{
            fontSize: 15,
            color: displayLegend ? 'info.main' : 'gray',
            cursor: 'pointer',
            marginTop: 'auto',
            marginBottom: 'auto'
          }}
          onClick={() => setDisplayLegend(!displayLegend)}
        />
      </Tooltip>
      {displayLegend && (
        <div
          style={{
            fontFamily: `"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
            fontWeight: 400,
            fontSize: 15,
            marginLeft: 10
          }}
        >
          {data.map((legend: any, i: any) => (
            <div
              style={{
                display: 'flex',
                gap: 5
              }}
              key={i}
            >
              {legend.text}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 2,
                  marginTop: 'auto',
                  marginBottom: 'auto'
                }}
              >
                {legend.items &&
                  legend.items.map((item: any, k: any) => (
                    <Tooltip title={item.title} key={`${i}-${k}`}>
                      <div
                        style={{
                          width: size || 15,
                          height: size || 15,
                          backgroundColor: item.color
                        }}
                      />
                    </Tooltip>
                  ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Legend
