interface LBrangeMin {
  category: string
  high: string
  low?: string
  test: string
}

export interface LBrange extends LBrangeMin {
  code: string
}

export interface LBrangesObject {
  [key: string]: LBrangeMin
}

export interface LabRecord {
  LBCAT: string
  LBTEST: string
  LBSTRESN: string
  LBSTRESC: string
  LBSTRESU: string
  LBDTC: string
  LBTESTCD: string
}

interface LBboxDataItem {
  SUBJID: string
  ARMCD: string
  LBDY: string
  LBSTRESN: string
  LBTESTCD: string
  combinedCategory: string
}

export type LBboxData = LBboxDataItem[]

export enum LBvalueStatus {
  GOOD = 'good',
  HIGH = 'upperLimitOfNormal',
  LOW = 'belowLimitOfNormal',
  HIGH_AND_LOW = 'upperAndBelowLimitOfNormal'
}
