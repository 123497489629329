import { useAppSelector } from '../../hooks'

const Demo = () => {
  const screenState = useAppSelector((state) => state.preferences.screen)

  return (
    <div id="video-container" style={{ paddingTop: 25 }}>
      <iframe
        width={(screenState?.width || 1) * 0.7}
        height={(screenState?.height || 1) * 0.7}
        src="https://www.youtube.com/embed/aBeQLDAPsas?si=nWgPsu3nchDvPzFa"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default Demo
