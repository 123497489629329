import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import SubjectTable from '../../SubjectTable'
import { LookBelow } from '../../layout/LookBelow'
import { globalFilter, addToHistory } from '../../../apis/utility'
import {
  getSubjIdsWithAeOnDay,
  dataToStreamOption,
  makeAEOnDayArray,
  compareObjects
} from '../../../utils'
import { Tooltip, Fab, Snackbar } from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import addSeriesLabel from 'highcharts/modules/series-label'
import addStreamgraphModule from 'highcharts/modules/streamgraph'
import HighchartsReact from 'highcharts-react-official'
import ChartDownload from '../../ui/ChartDownload'
import { useAppSelector, useAppDispatch } from '../../../hooks'
import { ApiController } from '../../../controllers/ApiController'
import { setAEStreamGraphAction } from '../../../store/action'

HC_exporting(Highcharts)

interface AEStreamGraphProps {
  apiController: ApiController
  theme: { [key: string]: any }
}

export default function AEStreamGraph(props: AEStreamGraphProps) {
  const { apiController, theme } = props

  const chartContainerId = 'ae-stream-chart-container'

  const dispatch = useAppDispatch()

  // More info related to removing 'AEHLT': https://git.4gl.io/xploratum/client/-/issues/4
  const drillDownPathState = (
    useAppSelector((state) => state.preferences.drillDownPath) || []
  ).filter((path) => path !== 'AEHLT')
  const variablesLabelsState = useAppSelector((state) => state.variables.labels)
  const screenState = useAppSelector((state) => state.preferences.screen)
  const selectedFilterState = useAppSelector((state) => state.filter.selected)
  const selectedStudyState = useAppSelector((state) => state.studies.selected)
  const AEStreamGraphState = useAppSelector((state) => state.ae.AEStreamGraph)

  const location = useLocation()

  addStreamgraphModule(Highcharts)
  addSeriesLabel(Highcharts)
  addToHistory({ title: 'AE Stream', url: location })

  const contrastingColor = theme.palette.mode === 'dark' ? 'white' : 'black'

  const [drillDownPath] = useState<string[]>(drillDownPathState)
  const [showSubjects, setShowSubjects] = useState<boolean>(false)
  const [dataForChart, setDataForChart] = useState<
    { [key: string]: number | string }[] | null
  >(AEStreamGraphState?.data || null)
  const [drillVars, setDrillVars] = useState<string[]>([])
  const [subjectsDrilledTo, setSubjectsDrilledTo] = useState<string[]>([])
  const [baselineDates, setBaselineDates] = useState<{
    [key: string]: string
  } | null>(AEStreamGraphState?.baselineDates || null)
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false)
  const [drillDownLevel, setDrillDownLevel] = useState<number>(0) // define the drilldown level here
  const [newOptions, setNewOptions] = useState<{ [key: string]: any }>()
  const [chartOptions, setChartOptions] = useState<{ [key: string]: any }>()
  const [minX, setMinX] = useState<number | null>(
    AEStreamGraphState?.min || null
  )
  const [maxX, setMaxX] = useState<number | null>(
    AEStreamGraphState?.max || null
  )
  const [aeDaySelected, setAeDaySelected] = useState<number>()
  const [aeLowerLevelTerm, setAeLowerLevelTerm] = useState<string>()

  const drillUp = () => {
    setDrillVars(drillVars.slice(0, drillDownLevel - 1))
    setDrillDownLevel(drillDownLevel - 1)
    setShowSubjects(false)
    setAeDaySelected(undefined)
    setAeLowerLevelTerm(undefined)
  }

  const top = () => {
    setDrillVars(drillVars.slice(0, 0))
    setDrillDownLevel(0)
    setShowSubjects(false)
  }

  useEffect(() => {
    let isMounted = false

    const fetchData = async () => {
      isMounted = true

      const classVar = drillDownPath[drillDownLevel]

      // call async func to fetch data
      const aesOnDays = await makeAEOnDayArray(
        apiController,
        classVar,
        drillDownLevel,
        drillDownPath,
        drillVars
      )

      // handle fetched data
      if (isMounted) {
        const { data, min, max, baselineDates } = aesOnDays

        if (!compareObjects(data, dataForChart)) {
          if (drillDownLevel === 0) dispatch(setAEStreamGraphAction(aesOnDays))

          setDataForChart(data)
          setMinX(min)
          setMaxX(max)
          setBaselineDates(baselineDates)
        }
      }
    }

    fetchData()

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [
    apiController,
    drillDownLevel,
    drillDownPath,
    drillVars,
    dataForChart,
    dispatch
  ])

  useEffect(() => {
    let streamOptions = null

    if (dataForChart && minX && maxX) {
      const classVar = drillDownPath[drillDownLevel]

      streamOptions = dataToStreamOption(
        dataForChart as any,
        classVar,
        minX,
        maxX,
        false,
        theme
      )

      setNewOptions(streamOptions)
    }
  }, [
    theme,
    dataForChart,
    drillDownPath,
    drillDownLevel,
    minX,
    maxX,
    contrastingColor
  ])

  useEffect(() => {
    let isMounted = false

    const fetchSubjects = async () => {
      isMounted = true

      const condition = `AND ${
        drillDownPath.slice(-1)[0]
      }='${aeLowerLevelTerm}'`

      const occasions = await apiController.getAEoccasions(
        '',
        '',
        undefined,
        condition
      )

      if (isMounted && baselineDates && aeDaySelected) {
        const filteredSubjects = getSubjIdsWithAeOnDay(
          occasions,
          baselineDates,
          aeDaySelected
        )

        setSnackBarOpen(true)
        setShowSubjects(true)
        setSubjectsDrilledTo(filteredSubjects)
      }
    }

    if (aeDaySelected && aeLowerLevelTerm) {
      fetchSubjects()
    }

    // cleanup func
    return () => {
      isMounted = false
    }
  }, [
    showSubjects,
    aeDaySelected,
    aeLowerLevelTerm,
    drillDownPath,
    baselineDates,
    apiController
  ])

  useEffect(() => {
    const whatHaveIDrilledDownTo = () => {
      const tempWhereClause = []

      for (let i = 0; i < drillDownLevel; i++) {
        const catVar = drillDownPath[i]
        const { ae } = variablesLabelsState || {}

        const drillDownLevelLabel = catVar in ae ? ae[catVar] : catVar

        tempWhereClause.push(drillDownLevelLabel + '="' + drillVars[i] + '"')
      }

      return tempWhereClause.join(' and ')
    }

    const subtitle = globalFilter(selectedFilterState)
    const catVar = drillDownPath[drillDownLevel]
    const { ae } = variablesLabelsState || {}
    const drillDownLevelLabel =
      'ae' in (variablesLabelsState || {}) && catVar in ae ? ae[catVar] : catVar

    // REFACTOR: create a utility with common chart options
    const commonOptions = {
      chart: {
        type: 'streamgraph',
        marginBottom: 30,
        height: (screenState?.height || 1) * 0.75,
        zoomType: 'xy',
        backgroundColor: 'transparent'
      },
      title: {
        text: `Adverse Events by ${drillDownLevelLabel} (${selectedStudyState?.STUDYID})`,
        style: { color: contrastingColor }
      },
      subtitle: {
        text: (subtitle || '') + '<br/>' + whatHaveIDrilledDownTo(),
        style: { color: contrastingColor }
      },
      xAxis: {
        labels: { style: { color: contrastingColor } }
      },
      plotOptions: {
        series: {
          label: {
            minFontSize: 5,
            maxFontSize: 15,
            style: {
              color: contrastingColor
            }
          },
          accessibility: {
            exposeAsGroupOnly: true
          },
          point: {
            events: {
              click: function () {
                const { category: aeDayClicked, options } = this as any
                const { name } = options

                if (drillDownLevel < drillDownPath.length - 1) {
                  setDrillDownLevel(drillDownLevel + 1)
                  setDrillVars([...drillVars, name])
                } else {
                  setAeDaySelected(aeDayClicked)
                  setAeLowerLevelTerm(name)
                }
              }
            }
          }
        }
      },
      yAxis: {
        visible: false,
        startOnTick: false,
        endOnTick: false
      },
      legend: {
        enabled: false
      },
      tooltip: {
        formatter: function (): any {
          const { series, point } = this as any

          return (
            '<b>' +
            series.name +
            ',</b><br/> ' +
            'Day = ' +
            point.category +
            '<br/>' +
            'Number of AEs: ' +
            Highcharts.numberFormat(Math.abs(point.y), 0)
          )
        }
      },
      credits: { enabled: false },
      exporting: { enabled: false }
    }

    const updatedOptions = { ...commonOptions, ...newOptions }

    setChartOptions(updatedOptions)
  }, [
    drillDownLevel,
    newOptions,
    contrastingColor,
    drillDownPath,
    drillVars,
    screenState,
    selectedFilterState,
    variablesLabelsState,
    selectedStudyState?.STUDYID
  ])

  return (
    <>
      {chartOptions && (
        <div id={chartContainerId} style={{ paddingBottom: 3 }}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          <ChartDownload elementId={chartContainerId} fileName="AE-Stream" />
        </div>
      )}
      {drillDownLevel > 0 ? (
        <span
          style={{
            position: 'fixed',
            bottom: '40px',
            right: '40px',
            zIndex: 100
          }}
        >
          <Tooltip title="Level we have drilled down to">
            <Fab
              size="small"
              variant="extended"
              color="info"
              sx={{ margin: 1 }}
              onClick={drillUp}
            >
              Level {drillDownLevel}
            </Fab>
          </Tooltip>
          <Tooltip title="Drill up">
            <Fab size="small" color="info" sx={{ margin: 1 }} onClick={drillUp}>
              <ArrowDropUpIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Go to top level">
            <Fab size="small" color="info" sx={{ margin: 1 }} onClick={top}>
              <ArrowUpwardIcon />
            </Fab>
          </Tooltip>
        </span>
      ) : null}
      {showSubjects && (
        <LookBelow
          label="Subject Table"
          tooltip="Click to scroll to subject table appears below"
          mt={2}
          ml={4}
          mr={0}
          mb={0}
        />
      )}
      {showSubjects && subjectsDrilledTo.length ? (
        <SubjectTable
          selectedSubjects={subjectsDrilledTo}
          screenHeight={400}
          filterOptions={[
            { key: 'AE', value: aeLowerLevelTerm },
            { key: 'DY', value: aeDaySelected }
          ]}
          theme={theme}
          apiController={apiController}
        />
      ) : null}
      <Snackbar
        message={"Can't drill any lower"}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackBarOpen(false)}
      />
    </>
  )
}
