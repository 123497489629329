import * as ActionTypes from '../actionTypes'
import { AccessState, AccessDispatchTypes } from './types'

const initialState: AccessState = { key: null, userName: null }

const reducer = (
  state = initialState,
  action: AccessDispatchTypes
): AccessState => {
  switch (action.type) {
    case ActionTypes.SET_ACCESS_KEY:
      return {
        ...state,
        key: action.payload
      }

    case ActionTypes.SET_USERNAME_BASED_ON_ACCESS_KEY:
      return {
        ...state,
        userName: action.payload
      }

    case ActionTypes.SET_ACCESS_KEY_AND_USERNAME:
      return {
        ...state,
        key: action.payload.key,
        userName: action.payload.userName
      }

    case ActionTypes.RESTORE_STATE:
      return action.payload.access || null

    default:
      return state
  }
}

export default reducer
