import * as ActionTypes from '../actionTypes'
import { AEstate, AEdispatchTypes } from './types'

const initialState: AEstate = {
  summary: null,
  AEBOThChart: null,
  AEStreamGraph: null
}

const reducer = (state = initialState, action: AEdispatchTypes): AEstate => {
  switch (action.type) {
    case ActionTypes.SET_AE_BOTh_CHART:
      return {
        ...state,
        AEBOThChart: action.payload
      }

    case ActionTypes.SET_AE_SUMMARY:
      return {
        ...state,
        summary: action.payload
      }

    case ActionTypes.SET_AE_STREAM_GRAPH:
      return {
        ...state,
        AEStreamGraph: action.payload
      }

    case ActionTypes.RESTORE_STATE:
      return action.payload.ae || null

    default:
      return state
  }
}

export default reducer
