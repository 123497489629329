import moment from 'moment'

export const dateFormat = 'YYYY-MM-DD'

export const enumerateDaysBetweenDates = (
  startDate: string,
  endDate: string,
  useDefaultFormat = false
) => {
  const dates = []

  const currDate = moment(startDate).startOf('day')
  const lastDate = moment(endDate).startOf('day')

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate())
  }

  return useDefaultFormat
    ? dates.map((date) => moment(date).format(dateFormat))
    : dates
}

export const getTreatmentDay = (baselineDate: string | Date, date: string) =>
  moment(date, dateFormat).diff(moment(baselineDate, dateFormat), 'days') + 1
