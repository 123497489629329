import * as ActionTypes from './actionTypes'
import { State } from './rootReducer'

export * from './access/action'
export * from './study/action'
export * from './vd/action'
export * from './subjects/action'
export * from './ae/action'
export * from './filter/action'
export * from './lb/action'
export * from './variables/action'
export * from './preferences/action'
export * from './tables/action'

export const restoreState = (payload: State) => ({
  type: ActionTypes.RESTORE_STATE,
  payload
})

export interface RestoreState {
  type: typeof ActionTypes.RESTORE_STATE
  payload: State
}
