export const compareObjects = (
  obj1: object | null | undefined,
  obj2: object | null | undefined
): boolean => {
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    const obj1Copy = [...obj1].sort()
    const obj2Copy = [...obj2].sort()

    return JSON.stringify(obj1Copy) === JSON.stringify(obj2Copy)
  }

  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}
