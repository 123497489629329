import { useNavigate } from 'react-router-dom'
import { Tooltip, IconButton, Box } from '@mui/material'
import Summarize from '@mui/icons-material/SummarizeTwoTone'

export default function QueryIconLink() {
  const navigate = useNavigate()

  return (
    <Box sx={{ flexGrow: 0, p: 1 }}>
      <Tooltip title="SQL Query">
        <IconButton onClick={() => navigate('/query')} sx={{ p: 0 }}>
          <Summarize
            sx={{ color: 'text.primary', fontSize: 24, fontWeight: 'medium' }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
